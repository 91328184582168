import React, { useState } from "react";
import Select from "react-select";
import "react-phone-input-2/lib/style.css";
import { Form, Row, Col } from "react-bootstrap";
import NewParent from "./NewParent";
import NewStudent from "./NewStudent";
import NewTutor from "./NewTutor";
import NewStudentAndParent from "./NewStudentAndParent";
import SignupNewVendor from "../SignupNewUser/SignupNewVendor";

const NewUserForm = () => {
  let options = [
    { label: "Tutor", value: <NewTutor /> },
    { label: "Vendedor", value: <SignupNewVendor /> },

    { label: "Padre", value: <NewParent /> },
    { label: "Estudiante", value: <NewStudent /> },
    { label: "Padre y Estudiante", value: <NewStudentAndParent /> },
  ];
  const [option, setOption] = useState(<></>);
  return (
    <>
      <Row className="justify-content-md-center">
        <Form.Group as={Col} lg="4" controlId="type">
          <Form.Label>Escoge tipo de usuario</Form.Label>
          <br />
          <br />
          <Form.Control
            as={Select}
            options={options}
            onChange={(opt) => setOption(opt.value)}
          />
        </Form.Group>
      </Row>
      <br />
      <br />
      {option}
    </>
  );
};

export default NewUserForm;
