import { createSlice } from "@reduxjs/toolkit";
import { getParents } from "./parent.actions";


const parentSlice = createSlice({
    name: "parent",
    initialState: {
        parent:{},
        parents: [],
        selectedParents: []
    },
    reducers:{
        setParent: (state,action) => {
            state.parent = action.payload
        },
        setParents: (state,action) => {
            state.selectedParents = action.payload
        },
    },
    extraReducers: {
        [getParents.pending] : (state,action) => {
            console.log("fetching parents")
        },
        [getParents.fulfilled]: (state,action) => {
            console.log("fetching parents succesfully!")
            state.parents = action.payload
        },

    }
})

export const {setParent,setParents} = parentSlice.actions;
export default parentSlice.reducer