// Working week = Monday - Sunday
// This function should return a Date with the monday before today (inclusive)
export function getFirstDayOfWorkingWeek() {
  let prevMonday = new Date();
  prevMonday = new Date(
    prevMonday.setDate(prevMonday.getDate() - ((prevMonday.getDay() + 6) % 7))
  );
  return prevMonday;
}
export function moveNumDays(date, numDays, lastHour = true) {
  let prev = new Date(date);
  prev.setDate(prev.getDate() + numDays);
  if (lastHour) {
    prev.setHours(23, 59, 59);
  } else {
    prev.setHours(0, 0, 0);
  }
  return prev;
}
export const parseTimestamp = (timestamp) => {
  if (!timestamp) {
    return "No se encontro fecha.";
  }
  let time = Date.parse(timestamp);
  return new Date(time).toLocaleString();
};
export const formatWorkingWeek = (nWeeks = 0) => {
  let first = getFirstDayOfWorkingWeek();
  let firstShow = moveNumDays(first, nWeeks * 7);
  let lastShow = moveNumDays(firstShow, 6);

  return `${firstShow.toLocaleDateString()} - ${lastShow.toLocaleDateString()}`;
};
export const isWithinDates = (date, startDate, endDate) => {
  startDate.setHours(0, 0, 0);
  endDate.setHours(23, 59, 59);

  return (
    startDate.getTime() <= date.getTime() && date.getTime() <= endDate.getTime()
  );
};

export const isActiveInWeek = (lastCalendarUpdate, nWeeks) => {
  let first = getFirstDayOfWorkingWeek();
  first.setHours(0, 0, 0);
  first.setDate(first.getDate() - 1); //Get sunday prior
  let firstShow = moveNumDays(first, nWeeks * 7);
  let lastShow = moveNumDays(firstShow, 6);
  lastShow.setHours(23, 59, 59);
  let updatedDate = new Date(Date.parse(lastCalendarUpdate));
  return isWithinDates(updatedDate, firstShow, lastShow);
};

export function getFirstDayOfMonth() {
  //change
  let today = new Date();
  return new Date(today.getFullYear(), today.getMonth() - 1, 1, -3);
}
export function getLastDayOfMonth(extra = 0) {
  //change
  let today = new Date();
  return new Date(today.getFullYear(), today.getMonth() + 1 - 1, extra, -3);
}

const MilisecondsPerMinute = 60000;

export const addMinutes = (start, minutes) => {
  return new Date(start.getTime() + minutes * MilisecondsPerMinute);
};
