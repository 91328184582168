import { createSlice } from "@reduxjs/toolkit";
import { getSubjects, getSubject} from "./subject.actions";
const subjectSlice = createSlice({
    name: "subject",
    initialState: {
        _id:"",
        name: "",
        subject: {
            _id:"",
            name:""
        },
        subjects: []
    },
    reducers:{
        setSubject: (state,action) => {
            state.subject = action.payload
        }
    },
    extraReducers: {
        [getSubjects.pending] : (state,action) => {
            console.log("fetching subjects")
        },
        [getSubjects.fulfilled]: (state,action) => {
            console.log("fetching subjects succesfully!")
            state.subjects = action.payload
        },
        [getSubject.pending] : (state,action) => {
            console.log("fetching subject")
        },
        [getSubject.fulfilled]: (state,action) => {
            console.log("fetching subject succesfully!")
            state.subject = action.payload
        },

    }
})

export const {setSubject} = subjectSlice.actions;
export default subjectSlice.reducer