import React , {useEffect, useState} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Form, Col, Row, Button } from 'react-bootstrap';
import axios from 'axios';
import Select from "react-select"

// import {TutorSelect} from '../Select/Select';
import { selectTempTutor } from '../../../store/tempTutor/tempTutor.slice';
import { getTempTutors } from '../../../store/tempTutor/tempTutor.actions';
// import { getSubjects } from '../../../store/subject/subject.actions';
// import {getStudents} from "../../../store/student/student.actions"

const IndividualTempTutor = ({data}) => {    
    const [salary, setSalary] = useState(0);
    const [show, setShow] = useState(true);
    const dispatch = useDispatch();
    const handleSubmit = async (e) =>{
        e.preventDefault();
        if (salary <= 0){
            alert("Salario debe de ser mayor a cero");
        } else {
            let confirmation = window.confirm(`Estas seguro que quieres activar al tutor ${data.name} ${data.surname}?`);

            if (confirmation){
                let response = await axios.post(`${process.env.REACT_APP_BACKENDURL}/tutor/activateTutor`, {tempTutorId: data._id, salary: salary});
                const savedTutor = response.data;
                console.log(savedTutor);
                try{
                    let x = await axios.post(`${process.env.REACT_APP_BACKENDURL}/jobs/welcomeTutor`, {tutorId: savedTutor._id});
                    console.log(x)
                } catch(error){
                    console.log(error);
                }
                dispatch(getTempTutors());

                // setShow(false); //Already deleted from db, so only update the UI
            }
        }
    }
    const handleDelete = async () => {
        let confirmation = window.confirm(`Estas seguro que quieres eliminar la solicitud de ${data.name} ${data.surname}?`);
        if (confirmation){
            await axios.post(`${process.env.REACT_APP_BACKENDURL}/tutor/temp/remove`, {tempTutorId: data._id});
            dispatch(getTempTutors());
            // setShow(false);
        }
    }
    return (
        <div style={{
            border: "1px black solid", 
            margin: 10, 
            padding: 5,
            borderRadius: 10,
            display: "flex",
            flexDirection: "row",
            flex: 1,
            alignItems: "center",
            justifyContent:"center",
            // opacity: show? 1 : 0.5
        }}
        >
            <div style={{flex: 1}}>
                {data.name} {data.surname} 
                <br/>
                <strong>Email :</strong> {data.email}
                <br/>
                <strong>Telefono :</strong> {data.phone}
                <br/>
                <strong>Moneda: </strong> {data.currency}
            </div>
            <Form style={{flex: 1}} onSubmit={handleSubmit}>
                <Row className="justify-content-md-center">
                    <Form.Group as={Col} lg="2" controlId="salary" style={{margin: 10}}>
                        <Form.Label><b>Salario</b></Form.Label>
                        <br/>
                        <Form.Control
                            // disabled={!show}
                            required
                            type="number"
                            placeholder="0"
                            onChange={e => {
                                // newTutor["salary"] = e.target.value
                                // dispatch(selectTutor(newTutor))
                                setSalary(e.target.value);
                            }}
                            size="lg"
                        />
                        <Form.Control.Feedback>Todo bien!</Form.Control.Feedback>
                    </Form.Group>
                </Row>
                <div style={{display:"flex", flexDirection:"row", flex:1, justifyContent:"center"}}>
                    <Button disabled={!show} onClick={handleDelete} style={{marginRight: 10}} variant="danger">Eliminar</Button>
                    <Button disabled={!show || salary <= 0} type="submit" variant="success">Activar</Button>
                </div>
            </Form>
        </div>
    )
}

const ActivateTutorForm = () => {
    const dispatch = useDispatch();
    const getLatestData = () => {
        dispatch(getTempTutors());
    }
    useEffect(() => {
        getLatestData();
    }, [])
    const {allTempTutors} = useSelector((state) => state.tempTutor);
    return(
        <div>
            Mostrando {allTempTutors.length} tutores
            <br/>
            <Button onClick={getLatestData}>Usar la información más actual.</Button>
            {allTempTutors.map((tempTutor) => (
                <IndividualTempTutor key={tempTutor._id} data={tempTutor}/>
            ))
            }
        </div>
    )
}

export default ActivateTutorForm;