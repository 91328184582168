import React, {useState, useEffect} from 'react';
import { useDispatch, useSelector } from "react-redux";
import axios from 'axios';

import {BarChart, ScatterChart,  Charts, ChartContainer, ChartRow, YAxis, LineChart, Resizable } from "react-timeseries-charts";
import { TimeSeries, TimeRange, Index, sum, avg } from "pondjs";
import Form from 'react-bootstrap/Form';
import {Row, Col} from 'react-bootstrap';
import Select from 'react-select';
let COLORS = {
    XPLAIN_GREEN: "#0AB591",
};

const TimeMetrics = ({name, metrics}) => {
    const [series, setSeries] = useState();
    const [range, setRange] = useState();
    const [firstRange, setFirstRange] = useState();
    const [filteredMetrics, setFilteredMetrics] = useState({});
    const [tracker, setTracker] = useState();
    const [viewMode, setViewMode] = useState('1d'); // 1d, 7d, 1m
    const [highlight, setHighlight] = useState();
    const clipDate = (date) => {
        let newDate = new Date(date);
        switch (viewMode) {
            case "1m":
                newDate.setDate(0); //Clip to first day in Month
                newDate.setHours(0, 0, 0, 0); //Don't care about time
                break;
            case "7d":
                newDate.setDate(date.getDate() - date.getDay()); //Clip to last Sunday
                newDate.setHours(0, 0, 0, 0); //Don't care about time
                break;
            case "1d":
                newDate.setHours(0, 0, 0, 0); //Don't care about time
                break;
        }
        return newDate
    }
    const aggregateMetrics = (validMetrics) => {
        let aggMetrics = {};
        for (let key in validMetrics) {
            let date = new Date(Number(key));
            let newKey = clipDate(date).valueOf();
            if (!aggMetrics[newKey]) {
                aggMetrics[newKey] = 0;
            }
            aggMetrics[newKey] += validMetrics[key];
        }
        return aggMetrics;
    }
    const getSeries = (validMetrics) => {
        let data = {
            name: name,
            columns: ["index", "value"],
            points: Object.entries(aggregateMetrics(validMetrics)).map(([key, value]) => ([
                // new Date(Number(key)), 
                Index.getIndexString(viewMode, new Date(Number(key))),
                Number(value),
            ])),
        }
        return new TimeSeries(data)
        
        // .fixedWindowRollup({
        //     windowSize: "1d",
        //     aggregation: sum,
        //     // toTimeEvents: false,
        // });

    }
    useEffect(() => {
        let foundSeries = getSeries(metrics);
        setSeries(foundSeries);
        setRange(foundSeries.timerange());
        setFirstRange(foundSeries.timerange());
        setFilteredMetrics(metrics);
    }, [])
    const getFilteredMetrics = () => {
        let tempFilteredMetrics = {};
        let start = new Date(range.begin());
        let end = new Date(range.end());
        for (let key in metrics) {
            key = Number(key);
            let datedKey = clipDate(new Date(key));
            if (start <= datedKey && datedKey < end) {
                tempFilteredMetrics[key] = metrics[key];
            }
        }
        return tempFilteredMetrics;
    }
    useEffect(()=>{
        if (!range){
            return;
        }
        let tempFilteredMetrics = getFilteredMetrics();
        let tempFilteredSeries = getSeries(tempFilteredMetrics);
        setFilteredMetrics(tempFilteredMetrics);
        setSeries(tempFilteredSeries)
    }, [range, viewMode])
    // const getTrackerValue = () => {
    //     // let tracker = 
    //     if (!tracker){
    //         return null;
    //     }
    //     let clipped = clipDate(tracker).valueOf();
    //     return aggregateMetrics(metrics)[clipped];
    // }
    return(
    // <>
    // <h1>asjdnasokaj</h1>
    //         {
            Object.keys(metrics).length > 0 &&
            series &&
            range &&
            <>
                <h1>{name} ({Object.values(getFilteredMetrics()).reduce((p, c) => p+c, 0)})</h1>
                <p>
                    Viendo de {new Date(range.begin()).toLocaleDateString()} a {new Date(range.end()).toLocaleDateString()}
                </p>
                <Row className="justify-content-md-center"> 
                {/* <p>Resultados por:</p> */}
                <Form.Group as={Col} lg="4" controlId="type"  className="justify-content-md-center">
                <Form.Control
                    as={Select}
                    options={[
                        {label: "Resultados por dia", value: "1d"},
                        {label: "Resultados por semana", value: "7d"},
                        {label: "Resultados por mes", value: "1m"}
                    ]}
                    placeholder="Resultados por dia"
                    onChange={opt => setViewMode(opt.value)}
                />
                </Form.Group>
                </Row>
                <Resizable
                 style={{margin: 20, padding: 10, borderRadius: 10, border: "1px solid black"}}
                  width={400}>
                    <ChartContainer 
                    width={400}
                    timeRange={range} 
                    onTimeRangeChanged={range=> {setRange(range)}}
                    onTrackerChanged={(tracker) => {setTracker(tracker)}}
                    trackerPosition={tracker}
                    enableDragZoom={true}
                    minTime={firstRange.begin()}
                    maxTime={new Date()}
                    timeScale={25}
                    >
                        <ChartRow 
                        timeRange={range} 
                        height="200"
                        >
                            <YAxis 
                                id="axis1" 
                                label="Clases creadas" 
                                min={series.min("value")} 
                                max={series.max("value")} 
                                // width="60" 
                                // type="linear" 
                                // tickCount={1} 
                                column={["value"]} 
                                format=""
                                />
                            <Charts>
                                <BarChart
                                spacing={1}    
                                columns={["value"]} 
                                axis="axis1" 
                                series={series}
                                style={{
                                    value: {
                                    normal: {
                                        fill: COLORS.XPLAIN_GREEN,
                                        opacity: 0.5,
                                    },
                                    highlighted: {
                                        fill: COLORS.XPLAIN_GREEN,
                                        opacity: 1.0,
                                    },
                                    selected: {
                                        fill: "orange",
                                        opacity: 1.0,
                                    },
                                    muted: {
                                        fill: COLORS.XPLAIN_GREEN,
                                        opacity: 0.5
                                    }
                                }}}
                                //   selected={selected}
                                //   selected={{
                                //     event: Index.getIndexString(viewMode, clipDate(tracker)),
                                //     column: "value"
                                //   }}
                                //   onSelectionChange={select => {
                                //     console.log(select);
                                //     console.log(series)
                                //     // setSelected(select)
                                //   }}
                                highlighted={highlight}
                                onHighlightChange={(highlight)=>{setHighlight(highlight)}}
                                infoStyle={{
                                    box:{
                                        opacity: 1,
                                        color: "white",
                                        stroke: "black",
                                        fill: "black",
                                        pointerEvents: "none",
                                    }
                                }}
                                infoTimeFormat={(index)=>{
                                    return <tspan style={{fill:"black"}}>
                                        {index.begin().toLocaleDateString()} - {index.end().toLocaleDateString()}
                                        </tspan>
                                }}
                                size={viewMode === "1m" ? "10": null}
                                info={[ 
                                    {label: "Cantidad", value: highlight && highlight.event.data().get('value')}
                                ]}
                                  />
                            </Charts>
                        </ChartRow>
                    </ChartContainer>
                </Resizable>
            </>
            // }
            // </>
    )
}

export default TimeMetrics;