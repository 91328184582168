import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

export const getSubjects = createAsyncThunk(
    'subject/getSubjects',
    async (data) => {
        const response = await axios.get(`${process.env.REACT_APP_BACKENDURL}/subject/all`,{
            params: data
        })
        const students = response['data']
        return students
    }
)


export const getSubject = createAsyncThunk(
    'subject/getSubject',
    async (data) => {
        const response = await axios.get(`${process.env.REACT_APP_BACKENDURL}/subject`,{
            params: data
        })
        const subject = response['data']
        return subject
    }
)