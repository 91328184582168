import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

export const checkToken = createAsyncThunk(
    'auth/checkToken',
    async (data) => {
        try{
            const response = await axios.post(`${process.env.REACT_APP_BACKENDURL}/auth/checktoken`,data)
            console.log(response.data)
            const {user,token} = response['data']
            localStorage.setItem("token",token)
            return {user,token}
        }catch (err){
            return err.message
        }
        
    }
)

export const asyncLogin = createAsyncThunk(
    'auth/asyncLogin',
    async (data) => {
        const response = await axios.post(`${process.env.REACT_APP_BACKENDURL}/auth/login`,data)
        const {user,token} = response['data']
        localStorage.setItem("token",token)
        return {user,token}
        
    }
)