import { createSlice } from "@reduxjs/toolkit";
import { getStudents, getStudent } from "./student.actions";
const studentSlice = createSlice({
    name: "student",
    initialState: {
        student:{
            _id:"",
            name: "",
            surname: "",
            email: "",
            phone: "",
            channel: "",
            year: "",
            school: "",
        },
        type: "",
        students: [],
        selectedStudents: [],
    },
    reducers:{
        setStudent: (state,action) => {
            state.student = action.payload
        },
        updateType: (state,action) => {
            state.type = action.payload
        },
        setStudents: (state,action) => {
            state.selectedStudents = action.payload
        },
    },
    extraReducers: {
        [getStudents.pending] : (state,action) => {
            console.log("fetching students")
        },
        [getStudents.fulfilled]: (state,action) => {
            console.log("fetching students succesfully!")
            state.students = action.payload
            if(action.payload.length === 1){
                state.student = action.payload[0]
            }
        },
        [getStudent.pending] : (state,action) => {
            console.log("fetching students")
        },
        [getStudent.fulfilled]: (state,action) => {
            console.log("fetching students succesfully!")
            state.student = action.payload
        },

    }
})

export const {setStudent, updateType, setStudents} = studentSlice.actions;
export default studentSlice.reducer