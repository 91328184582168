import { createSlice } from "@reduxjs/toolkit";
import { getTutors,getTutorsFirstClass,getTutor } from "./tutor.actions";
const tutorSlice = createSlice({
    name: "tutor",
    initialState: {
        tutor: {
            _id:"",
            name: "",
            surname: "",
            calendarId:"",
            subjects:[]
        },
        tutors: [{_id:"", name: "", subjects:[]}],
        allTutors: [{_id:"", name: "", subjects:[]}]
    },
    reducers:{
        selectTutor: (state,action) => {
            state.tutor = action.payload
        },
        filterTutors: (state,action) => {
            let newTutors = []
            for (const tutor of state.allTutors) {
                if (tutor['subjects'].includes(action.payload)) {
                    newTutors.push(tutor)
                }
            }
            state.tutors = newTutors
        }
    },
    extraReducers: {
        [getTutors.pending] : (state,action) => {
            console.log("fetching tutors")
        },
        [getTutors.fulfilled]: (state,action) => {
            console.log("fetching tutors succesfully!")
            state.allTutors = action.payload
            state.tutors = action.payload
        },
        [getTutor.pending] : (state,action) => {
            console.log("fetching tutor")
        },
        [getTutor.fulfilled]: (state,action) => {
            console.log("fetching tutor succesfully!")
            state.tutor = action.payload
        },
        [getTutorsFirstClass.pending] : (state,action) => {
            console.log("fetching tutors")
        },
        [getTutorsFirstClass.fulfilled]: (state,action) => {
            console.log("fetching tutors succesfully!")
            state.allTutors = action.payload
            state.tutors = action.payload
        },

    }
})

export const {selectTutor, filterTutors} = tutorSlice.actions;
export default tutorSlice.reducer