import React, {useEffect} from 'react';
import { useSelector,useDispatch } from 'react-redux';
import {getClasses} from "../../../store/class/class.actions"
import {getSubjects} from "../../../store/subject/subject.actions"
import {getTutors} from "../../../store/tutor/tutor.actions"
import { Table, Button} from 'react-bootstrap';
import axios from "axios"
import {getParents} from "../../../store/parent/parent.actions"
let today = new Date()
let tomorrow = new Date(today)
tomorrow.setDate(today.getDate() + 1)
let nextWeek = new Date(today)
nextWeek.setDate(today.getDate() + 7)
let todayMorning = new Date()
todayMorning.setHours(0,0,0,0)


const TableStudentClasses = () => {
    const dispatch = useDispatch() 
    const {user} = useSelector((state) => state.auth)
    const {student} = useSelector((state) => state.student)
    const {classes} = useSelector((state) => state.class)
    const {subjects} = useSelector((state) => state.subject)
    const {tutors} = useSelector((state) => state.tutor)
    // const {students} = useSelector((state) => state.student)
    const {parents} = useSelector((state) => state.parent)
    useEffect(() => {
        dispatch(getClasses({filter:{studentId:student._id, end:{"$lte":nextWeek} , start:{"$gte": todayMorning}}, selection:{}}))
        dispatch(getSubjects({filter:{_id:{"$in":student.subjects}},selection:{}}))
        dispatch(getTutors({filter:{ _id:{$in:student.tutors}}, selection:{}}))
        // dispatch(getStudents({filter:{},selection:{}}))
        dispatch(getParents({filter:{family:user.family}, selection: {}}))
    }, [])
    let newClasses = []
    for (const currentClass of classes){
        let newClass = {...currentClass}
        if(newClass.studentIds.includes(student._id)){
            for (const tutor of tutors){
                if (tutor._id == newClass.tutorId){
                    newClass["tutorName"] = tutor['name'].split(" ")[0] + " " + tutor['surname'].split(" ")[0]
                }
            }
            for (const subject of subjects){
                if (subject._id == newClass.subjectId){
                    newClass["subjectName"] = subject['name']
                }
            }
            newClasses.push(newClass)
        }
    }
    const deleteEvent = (currentClass) => {
        if (new Date(currentClass.start) < tomorrow){
            alert("No se pueden cancelar clases con menos de 24 horas de antelación. En caso sea una emergencia, contacta con el coordinador académico.")
        }else{
            // let newStudents = []
            let subject = {}
            // for (const student of students){
            //     if (currentClass.studentIds.includes(student._id)){
            //         newStudents.push(student)
            //     }
            // }
            for (const thisSubject of subjects){
                if (currentClass.subjectId = thisSubject._id){
                    subject = thisSubject
                }
            }
            let newParents = []
            for (const parent of parents){
                if (currentClass.families.includes(parent.family)){
                    newParents.push(parent)
                }
            }
            let tutor = {}
            for (const thisTutor of tutors){
                if (currentClass.tutorId = thisTutor._id){
                    tutor = thisTutor
                }
            }
            axios.delete(`${process.env.REACT_APP_BACKENDURL}/class/newclass`,{data:{
                tutor:tutor,
                students:[student],
                subject:subject,
                parents:newParents,
                event: {
                    id:currentClass._id,
                    googleId:currentClass.googleId,
                    start:currentClass.start, //Not needed
                    end: currentClass.end, //Not needed
                    calendarId:tutor.calendarId //Not needed
                }}
            }).then(response => {
                alert("Se ha eliminado la clase");
                window.location.reload(false)
            }).catch(err => {
                alert("No se pudo eliminar la clase "+JSON.stringify(err));
                console.log(err)
            })
        }
    }
    let NEED_TO_CONFIRM = (
        <strong style={{color:"red"}}>El tutor necesita confirmar</strong>
    )
    return(
        <>
            <h3>Próximas Clases</h3>
            <br/>
            <Table striped bordered hover style={{ width: '85%','margin-left':'auto','margin-right':'auto' }}>
                <thead>
                <tr>
                <th>Fecha</th>
                <th>Comienzo</th>
                <th>Final</th>
                <th>Estudiante</th>
                <th>Tutor</th>
                <th>Materia</th>
                <th>Clase</th>
                <th>Feedback</th>
                <th>Cancelar</th>
                <th>Estado</th>
                </tr>
                </thead>
                <tbody>
                {newClasses.map((currentClass,id) => {
                    let currentDate = new Date()
                    let start = new Date(currentClass.start)
                    let hoursDiff = Math.abs(start - currentDate) / 36e5
                    let month = start.getMonth().toString().padStart(2, '0')
                    month = Number(month) + 1
                    let day = `${start.getDate().toString().padStart(2, '0')}/${month.toString().padStart(2, '0')}`
                    let end = new Date(currentClass.end)
                    start = `${start.getHours()}:${start.getMinutes().toString().padStart(2, '0')}`
                    end = `${end.getHours()}:${end.getMinutes().toString().padStart(2, '0')}`
                    let status;
                    if(currentClass.pending){
                        status = <Button style={{ backgroundColor:"rgba(10, 181, 145, 0.2)", borderColor:"rgba(10, 181, 145, 0.2)", color:"rgba(0,0,0)", "pointer-events": "none"}}>Pendiente</Button> 
                    }else{
                        status = <Button style={{backgroundColor:"rgba(10, 181, 145, 1)", borderColor:"rgba(10, 181, 145, 1)", color:"rgba(0,0,0)", "pointer-events": "none"}}>Confirmado</Button>
                    }
                    let cancel;
                    if (new Date(currentClass.start) < tomorrow){
                        cancel = <Button disabled={true} variant="danger">Cancelar</Button>
                    }else{
                        cancel = <Button onClick={() => deleteEvent(currentClass) } variant="danger">Cancelar</Button>
                    }
                    return(
                        <tr >
                            <td>{day}</td>
                            <td>{start}</td>
                            <td>{end}</td>
                            <td>{student['name'].split(" ")[0] + " " + student['surname'].split(" ")[0]}</td>
                            <td>{currentClass.tutorName}</td>
                            <td>{currentClass.subjectName}</td>
                            <td>{
                                currentClass.pending ? 
                                NEED_TO_CONFIRM: 
                                <Button><a href={`${window.location.origin}/call?id=${currentClass._id}`} target="_blank" style={{color:"rgb(255,255,255)"}}>Clase</a></Button>}</td>
                            <td>{currentClass.pending ? 
                            NEED_TO_CONFIRM: 
                            <Button variant="secondary"><a href={`${window.location.origin}/studentfeedback?id=${currentClass._id}`} target="_blank" style={{color:"rgb(255,255,255)"}}>Feedback</a></Button>}</td>
                            <td>{currentClass.pending ? NEED_TO_CONFIRM:cancel}</td>
                            <td>{status}</td>
                        </tr>
                    )
                })}
                </tbody>
            </Table>
        </>
    )
}

export default TableStudentClasses