import React, {useEffect, useState} from 'react';
import TutorPage from "./TutorPage"

const TutorPageGeneral = ()=>{
    const [tutorId, setTutorId] = useState();
    useEffect(()=>{
        let params = new URLSearchParams(window.location.search);
        let _id = params.get("id");
        console.log(_id)
        if (typeof _id === "string" || _id instanceof String ){
            setTutorId(_id);
        }
    }, []);
    return(
        tutorId &&
        <TutorPage
        tutorId={tutorId}
        />
    )
}

export default TutorPageGeneral;