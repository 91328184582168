import React, {useState, useEffect} from "react"
import { useSelector } from "react-redux"
import { useNavigate } from "react-router"
import StudentClasses from "../../components/forms/StudentView/StudentClasses"
import TutorClasses from "../../components/forms/TutorView/TutorClasses"
import ParentClasses from "../../components/forms/ParentView/ParentClasses"
const Classes = () => {
    const {user} = useSelector((state) => state.auth)
    const [option,setOption] = useState(<></>)
    const navigate = useNavigate()
    useEffect(() => {
        if(user.role === "Student"){
            setOption(<StudentClasses />)
        }else if(user.role === "Tutor"){
            setOption(<TutorClasses />)
        }else if(user.role === "Parent"){
            setOption(<ParentClasses />)
        }else{
            navigate("/")
        }
    }, [])
    return (
        <>  
            <br/>
            <h1>MIS CLASES</h1>
            <br/>
            {option}
        </>
    )
}

export default Classes