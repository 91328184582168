import { useEffect, useState } from "react";
import {
  Container,
  Col,
  Form,
  Text,
  Button,
  Row,
  Toast,
} from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { getSubjects } from "../../../store/subject/subject.actions";
import axios from "axios";

const IndividualSubject = ({
  subject,
  setSuccess,
  setShowToast,
  setToastMessage,
}) => {
  const dispatch = useDispatch();
  const handleDelete = async (e) => {
    let confirmation = window.confirm(
      `Estás seguro que quieres borrar el curso ${subject.name}?`
    );
    if (!confirmation) {
      return;
    }
    try {
      let response = await axios.post(
        `${process.env.REACT_APP_BACKENDURL}/subject/delete`,
        { subjectId: subject._id }
      );
      setSuccess(true);
      setToastMessage(`Se borró el curso ${subject.name} correctamente`);
      setShowToast(true);
      dispatch(getSubjects()); //Update global state
    } catch (err) {
      setSuccess(false);
      setToastMessage(`Uh oh.. no se pudo eliminar el curso ${subject.name}`);
      setShowToast(true);
    }
  };
  const getServiceName = (service) => {
    if (service === "IB") {
      return "Tutorias Especializadas";
    } else if (service === "Tutorías") {
      return "Tutorías Pre-IB";
    } else {
      return `Typo ${service} no es valido!!`;
    }
  };
  return (
    <Row
      style={{
        border: "1px black solid",
        padding: 10,
        borderRadius: 10,
        margin: 5,
        width: "30%",
        alignItems: "center",
      }}
    >
      <Col style={{ flex: 1 }}>
        <strong>Nombre:</strong> {subject.name}
        <br />
        <strong>Servicio:</strong> {getServiceName(subject.service)}
        <br />
        <Button variant="danger" onClick={handleDelete}>
          Eliminar
        </Button>
      </Col>
      {/* <Col style={{justifyContent:"center"}}>
                <Button variant="danger" onClick={handleDelete}>Eliminar</Button>
            </Col> */}
    </Row>
  );
};
const ManageSubjects = () => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getSubjects());
  }, []);
  const { subjects } = useSelector((state) => state.subject);
  console.log(subjects);
  //New course config
  const [name, setName] = useState("");
  const [service, setService] = useState("");
  const [showNewCourse, setShowNewCourse] = useState(false);

  // Toast config
  const [success, setSuccess] = useState(false);
  const [showToast, setShowToast] = useState(false);
  const [toastMessage, setToastMessage] = useState("");
  const resetToast = () => {
    setSuccess(false);
    setShowToast(false);
    setToastMessage("");
  };

  const handleNewCourse = async (e) => {
    let no_space_name = name
      .split(" ")
      .filter((x) => x)
      .join("-");
    e.preventDefault();
    let confirmation = window.confirm(
      `Estás seguro que quieres crear un nuevo curso?`
    );
    if (!confirmation) {
      return;
    }
    try {
      await axios.post(`${process.env.REACT_APP_BACKENDURL}/subject`, {
        name: no_space_name,
        service,
      });
      dispatch(getSubjects()); //Update global state
    } catch (err) {
      console.log(err);
      setSuccess(false);
      setToastMessage(
        `Uh oh.. no se pudo crear el curso ${no_space_name}: ${err.message}`
      );
      setShowToast(true);
    }
    //reset subject state
    setName("");
    setService("");
    setShowNewCourse(false);
  };
  return (
    <div>
      <h1>Mostrando {subjects.length} cursos</h1>
      <Container
        style={{
          margin: 5,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Toast
          show={showToast}
          onClose={resetToast}
          bg={success ? "success" : "danger"}
        >
          <Toast.Header>
            <strong className="me-auto">
              {success ? "Yay!" : "Hubo un error :("}
            </strong>
            <small>Ahora</small>
          </Toast.Header>
          <Toast.Body style={{ color: "white" }}>{toastMessage}</Toast.Body>
        </Toast>
      </Container>
      <Button
        variant={showNewCourse ? "danger" : "primary"}
        onClick={(e) => setShowNewCourse((prev) => !prev)}
      >
        {showNewCourse ? "Cancelar" : "Agregar curso"}
      </Button>
      {showNewCourse && (
        <Container style={{ width: "40%", justifyContent: "center" }}>
          <Form
            style={{
              flexDirection: "column",
              alignItems: "center",
              border: "1px black solid",
              padding: 10,
              borderRadius: 10,
              margin: 5,
            }}
          >
            <Form.Group controlId="formBasicName">
              <Form.Label>
                <strong>Nombre del curso</strong>
              </Form.Label>
              <Form.Control
                type="text"
                value={name}
                onChange={(e) => setName(e.target.value)}
                placeholder="IB-Clases-Historia HL"
              />
            </Form.Group>
            <br />
            <Form.Group controlId="formBasicService">
              <Form.Label>
                <strong>Servicio</strong>
              </Form.Label>
              <br />
              <Button
                onClick={() => {
                  setService("Tutorías");
                }}
                style={{
                  marginRight: 5,
                  opacity: service === "Tutorías" ? 1 : 0.5,
                }}
                variant={service === "Tutorías" ? "primary" : "secondary"}
              >
                Tutorias Pre-IB
              </Button>
              <Button
                onClick={() => {
                  setService("IB");
                }}
                variant={service === "IB" ? "primary" : "secondary"}
                style={{ opacity: service === "IB" ? 1 : 0.5 }}
              >
                Tutorias Especializadas
              </Button>

              {/* <Form.Control 
                                type="text"
                                value={service}
                                onChange={(e) => setService(e.target.value)}
                                placeholder="IB"
                            /> */}
            </Form.Group>
            <br />
            {service && name && (
              <Button variant="success" type="submit" onClick={handleNewCourse}>
                Crear nuevo curso
              </Button>
            )}
          </Form>
        </Container>
      )}
      <Container
        style={{
          justifyContent: "center",
          flex: 1,
          display: "flex",
          flexWrap: "wrap",
        }}
      >
        {subjects.length &&
          subjects.length > 0 &&
          subjects.map((subject) => (
            <IndividualSubject
              key={subject.id}
              subject={subject}
              setSuccess={setSuccess}
              setShowToast={setShowToast}
              setToastMessage={setToastMessage}
            />
          ))}
      </Container>
    </div>
  );
};

export default ManageSubjects;
