import { useEffect, useState } from "react";
import {
  Container,
  Col,
  Form,
  Text,
  Button,
  Row,
  Toast,
} from "react-bootstrap";
import Table from "react-bootstrap/Table";
import Modal from "react-bootstrap/Modal";
import { useDispatch, useSelector } from "react-redux";
import { getHistory } from "../../../store/history/history.actions";
import axios from "axios";
import { ObjectVisualizer } from "react-object-visualizer";

/**Just removes _id, __v */
const cleanData = (data) => {
  let cleanData = Object.assign({}, data); //copying data
  delete cleanData._id;
  delete cleanData.__v;
  return cleanData;
};
let modelTranslation = {
  SubjectHistory: "Curso",
  ParentHistory: "Padre",
  UserHistory: "Usuario",
  StudentHistory: "Estudiante",
  TutorHistory: "Tutor",
  ClassHistory: "Clase",
  FamilyHistory: "Familia",
  SaleHistory: "Venta",
  TempTutorHistory: "Tutor Temporal",
  VendorHistory: "Vendedor",
  StrikeHistory: "Strike",
};
let typeTranslation = {
  create: "creado",
  update: "actualizado",
  delete: "borrado",
};
const parseTimestamp = (timestamp) => {
  let time = Date.parse(timestamp);
  return new Date(time).toLocaleString();
};

const HistoryFullDetailsModal = ({
  data,
  handleClose,
  handleShow,
  showModal,
}) => {
  // console.log(data)
  return (
    <Modal show={showModal} onHide={handleClose} size="lg">
      <Modal.Header closeButton>
        <Modal.Title>Detalles de transaccion</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <h1>Resumen</h1>
        {!data.success ? (
          <Row>
            Error encontrado:
            <ObjectVisualizer object={cleanData(data.error)} />
          </Row>
        ) : data.type === "create" ? (
          <div>
            <div>El siguiente documento fue creado</div>
            <ObjectVisualizer object={data.diff} />
          </div>
        ) : data.type === "delete" ? (
          <Row>
            El siguiente documento fue eliminado!
            <ObjectVisualizer object={data.prev} />
          </Row>
        ) : Object.keys(data.diff).length === 0 ? (
          <div>
            Esta fue una operacion de cambios, pero no se encontro nada que haya
            cambiado.
          </div>
        ) : (
          <Table striped bordered hover>
            <thead>
              <tr>
                <th>Que Cambio</th>
                <th>Antes</th>
                <th>Ahora</th>
              </tr>
            </thead>
            <tbody>
              {Object.keys(data.diff).map((key) => (
                <tr key={key}>
                  <td>{key}</td>
                  <td>{JSON.stringify(data.prev[key])}</td>
                  <td>{JSON.stringify(data.diff[key])}</td>
                </tr>
              ))}
            </tbody>
          </Table>
        )}
        <h1>Objeto original</h1>
        <ObjectVisualizer object={data} />
      </Modal.Body>
    </Modal>
  );
};
/**
 * @param {*} param0
 * @returns
 */
const HistoryComponentRow = ({ i, data }) => {
  const [showModal, setShowModal] = useState(false);
  const handleClose = () => setShowModal(false);
  const handleShow = () => setShowModal(true);

  return (
    <tr>
      <HistoryFullDetailsModal
        data={data}
        handleClose={handleClose}
        handleShow={handleShow}
        showModal={showModal}
      />
      <td>{i}</td>
      {/* <td>{data.ref_id}</td> */}
      <td>{modelTranslation[data.model]}</td>
      <td>{typeTranslation[data.type]}</td>
      <td>{data.summary || "No se encontro"}</td>
      <td>{parseTimestamp(data.timestamp)}</td>
      <td>
        <Button variant="success" onClick={handleShow}>
          👀
        </Button>
      </td>
    </tr>
  );
};
const AllHistory = () => {
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.auth);
  const getLatestHistory = () => {
    setLoading(true);
    const data = { userId: user._id };
    dispatch(getHistory(data));
  };
  useEffect(() => {
    getLatestHistory();
  }, []);
  const { successHistory, errorHistory } = useSelector(
    (state) => state.history
  );

  const [showSuccess, setShowSuccess] = useState(true);
  useEffect(() => {
    if (successHistory.length + errorHistory.length > 0) {
      setLoading(false);
    }
  }, [successHistory, errorHistory]);
  const showHistory = (list) => {
    return list.map((item, i) => (
      <HistoryComponentRow i={i + 1} data={item} key={item._id} />
    ));
  };
  if (loading) {
    return "Cargando (puede tomar unos minutos)...";
  }
  return (
    <Container style={{}}>
      <h1>
        Se encontro {showSuccess ? successHistory.length : errorHistory.length}{" "}
        transacciones
      </h1>
      <div style={{ marginBottom: 10 }}>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Form.Switch
            checked={showSuccess}
            onChange={(e) => setShowSuccess((prev) => !prev)}
            // label={showSuccess? 'Transacciones exitosas' : 'Transacciones erroneas'}
          />
          <span>
            {showSuccess ? "Transacciones exitosas" : "Transacciones erroneas"}
          </span>
        </div>
        <Button onClick={getLatestHistory}>
          Usar la información más actual.
        </Button>
      </div>
      <Table striped bordered hover>
        <thead>
          <tr>
            <th>#</th>
            {/* <th>ref_id</th> */}
            <th>Tipo</th>
            <th>Operacion</th>
            <th>Resumen</th>
            <th>Cuando</th>
            <th>Ver</th>
          </tr>
        </thead>
        <tbody>
          {showHistory(showSuccess ? successHistory : errorHistory)}
        </tbody>
      </Table>
    </Container>
  );
};

export default AllHistory;
