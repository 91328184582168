import { createSlice } from "@reduxjs/toolkit";
import { asyncLogin, checkToken } from "./auth.actions";
const authenticationSlice = createSlice({
  name: "authentication",
  initialState: {
    refresh_token: "",
    token: "",
    loggedIn: false,
    error: false,
    user: {},
    isLoading: false,
  },
  reducers: {
    login: (state, action) => {
      if (typeof action.payload === "string") {
        state.loggedIn = true;
        state.refresh_token = action.payload;
      }
    },
    setUser: (state, action) => {
      state.user = action.payload;
    },
  },
  extraReducers: {
    [asyncLogin.pending]: (state, action) => {
      console.log("logging in");
      state.loading = true;
    },
    [asyncLogin.fulfilled]: (state, action) => {
      console.log("logged in");
      state.loading = false;
      state.token = action.payload.token;
      state.user = action.payload.user;
      state.error = "";
    },
    [asyncLogin.rejected]: (state, action) => {
      state.loading = false;
      state.error = "Invalid Email or Password";
    },
    [checkToken.pending]: (state, action) => {
      console.log("logging in");
      state.loading = true;
    },
    [checkToken.fulfilled]: (state, action) => {
      console.log("logged in");
      state.loading = false;
      state.token = action.payload.token;
      state.user = action.payload.user;
      state.error = "";
    },
    [checkToken.rejected]: (state, action) => {
      state.loading = false;
    },
  },
});

export const { login, setUser } = authenticationSlice.actions;
export default authenticationSlice.reducer;
