import React , {useState,useEffect} from 'react';
import Select from "react-select"
import { setParent } from '../../../store/parent/parent.slice';
import { useDispatch, useSelector } from 'react-redux';
import { Form,Col,Row, Button } from 'react-bootstrap';
import {getStudents} from "../../../store/student/student.actions"
import {getParents} from "../../../store/parent/parent.actions"
import axios from 'axios';
import { listFormat } from '../../../name_utils';

const AllParentsForm = () => {
    const dispatch = useDispatch()
    useEffect(() => {
        dispatch(getStudents({filter:{},selection:{}}))
        dispatch(getParents({filter:{},selection:{}}))
    },[])
    const {students} = useSelector((state) => state.student)
    const {parent, parents} = useSelector((state) => state.parent)
    const newParent = {...parent}
    const [edit,setEdit] = useState(false)
    const [disabled,setDisabled] = useState(false)
    const [isInvalid, setIsInvalid] = useState(false);
    // const listFormat = (array) => {
    //     let newArray = []
    //     for (const elem of array){
    //         let newElem = {...elem}
    //         if ('surname' in newElem){
    //             newElem['label'] = elem['name'] + " " + elem['surname']
    //         }else{
    //             newElem['label'] = elem['name']
    //         }
    //         newElem['value'] = elem['_id']
    //         newArray.push(newElem)
    //     }
    //     return newArray
    // }
    const studentFormat = () => {
        let allStudents = listFormat(students)
        let formatStudents = []
        for (const parentStudent of parent.students){
            for (const student of allStudents){
                if(parentStudent === student._id){
                    formatStudents.push(student)
                }
            }
        }
        return formatStudents
    }
    const handleSubmit = (event) => {
        event.preventDefault();
        const form = event.currentTarget;
        if (form.checkValidity() === false) {
            event.stopPropagation();
            setIsInvalid(true)
        }else{
            // let answer = window.confirm("Estás seguro quieres actualizar esta info?");
            setIsInvalid(false)
            setDisabled(true)
            delete newParent._id;
            delete newParent.__v;
            delete newParent.label;
            delete newParent.value;
            axios.patch(`${process.env.REACT_APP_BACKENDURL}/parent`, {id: parent._id, update:newParent}).then((response) =>{
                window.location.reload(false)
            }).catch(error => console.log(error))
            // if(answer){
                
            // }
        }
    }
    const parentData = Object.keys(newParent).map((key,index) => {
        if(key =="_id"){
            return (
                <>
                <Row className="justify-content-md-center">
                    <Form.Group as={Col} lg="2" controlId={key}>
                    <Form.Label>{key}</Form.Label>
                    <Form.Control
                        type="text"
                        disabled
                        value={newParent[key]}
                        readOnly={!edit}
                    />
                    <Form.Control.Feedback>Todo bien!</Form.Control.Feedback>
                    </Form.Group>
                </Row>
                <br />
                </>
            )
        }else if(key =="__v" || key == "label" || key == "value"){
            return (
                <></>
            )
        }else if(key == "students"){
            return(
                <>
                <Row className="justify-content-md-center">
                    <Form.Group as={Col} lg="3" controlId="surname">
                    <Form.Label>students</Form.Label>
                    <br/>
                    <br/>
                    <Form.Control
                        as={Select}
                        required
                        isMulti={true}
                        value={studentFormat()}
                        options={listFormat(students)}
                        isDisabled={!edit}
                        onChange={(opt) => {
                            let newStudents = []
                            for (const student of opt){
                                newStudents.push(student.value)
                            }
                            newParent[key] = newStudents
                            dispatch(setParent(newParent))
                        }}
                        placeholder="Materias"
                    />
                    <Form.Control.Feedback>Todo bien!</Form.Control.Feedback>
                    </Form.Group>
                </Row>
                <br />
                </>
            )
                    }
        else{
            return(
                <>
                <Row className="justify-content-md-center">
                    <Form.Group as={Col} lg="2" controlId={key}>
                    <Form.Label>{key}</Form.Label>
                    <Form.Control
                        type="text"
                        value={newParent[key]}
                        readOnly={!edit}
                        onChange={e => {
                            newParent[key] = e.target.value
                            dispatch(setParent(newParent))
                        }}
                    />
                    <Form.Control.Feedback>Todo bien!</Form.Control.Feedback>
                    </Form.Group>
                </Row>
                <br />
                </>
            )
        }
        
    })
    return(
        <Form noValidate validated={isInvalid} onSubmit={handleSubmit}>
        <Row className="justify-content-md-center"> 
            <Form.Group as={Col} lg="4" controlId="student">
            <Form.Label>Escoja al padre</Form.Label>
            <br />
            <br />
            <Form.Control
                as={Select}
                options={listFormat(parents)}
                required
                onChange={opt => dispatch(setParent(opt))}
            />
            </Form.Group>
        </Row>
        <br/>
        {
            (parent._id == "") ?
                <></>
            :
                <>
                {parentData}
                {(edit) ?
                    <Row className="justify-content-md-center">
                        <Col lg="2">
                            <Button type="submit" variant="success" disabled={disabled}>Enviar</Button>
                        </Col>
                        <Col lg="2">
                            <Button  variant="danger" disabled={disabled} onClick={() => setEdit(false)}>Cancelar</Button>
                        </Col>
                    </Row>
                    :
                        <Button variant="success" onClick={() => setEdit(true)}>Editar</Button>

                }
                </>
        }
        </Form>
    )
}

export default AllParentsForm