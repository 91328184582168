import React, { useEffect} from 'react';
import Calendar from '@toast-ui/react-calendar';
import '@toast-ui/calendar/dist/toastui-calendar.min.css';
import 'tui-date-picker/dist/tui-date-picker.css';
import 'tui-time-picker/dist/tui-time-picker.css';
import axios from 'axios';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getTutors } from '../../../store/tutor/tutor.actions';
import { getSubjects } from '../../../store/subject/subject.actions';
import {getParents} from "../../../store/parent/parent.actions"
import { SubjectSelect, TutorSelectEvents } from '../Select/Select';
import {Card, Row, Col, Button,Form} from "react-bootstrap"
import {differenceInMinutes} from "date-fns"
import { setClasses } from '../../../store/class/class.slice';
import {setEvents, setFreeEvents} from "../../../store/calendar/calendar.slice"
import Select from "react-select"
import {setStudent} from "../../../store/student/student.slice"
import { listFormat } from '../../../name_utils';
import {getFamily} from '../../../store/family/family.actions';
/**
 * Returns a date `n` days after the specified `date`.
 * @param {} date 
 * @param {*} n 
 * @returns 
 */
const moveDate = (date, diff_days) =>{
    let copy = new Date(date);
    copy.setDate(copy.getDate() + diff_days);
    return copy;
}
// const listFormat = (array) => {
//     let newArray = []
//     for (const elem of array){
//         let newElem = {...elem}
//         if ('surname' in newElem){
//             newElem['label'] = elem['name'] + " " + elem['surname']
//         }else{
//             newElem['label'] = elem['name']
//         }
//         newElem['value'] = elem['_id']
//         newArray.push(newElem)
//     }
//     return newArray
// }


const findHourType = (subject,students) => {
    if (students.length > 1){
        if (subject.service === "SAT"){
            return "hoursSATGroup"
        }else if (subject.service === "IB"){
            return "hoursIBGroup"
        }else if (subject.service === "Tutorías"){
            return "hoursTutoringGroup"
        }else{
            return "hoursOlympiadGroup"
        }
    }else{
        if (subject.service === "SAT"){
            return "hoursSAT"
        }else if (subject.service === "IB"){
            return "hoursIB"
        }else if (subject.service === "Tutorías"){
            return "hoursTutoring"
        }else{
            return "hoursOlympiad"
        }
    }
}

const isEventValid = async (event, freeEvents, family, subject, student, tutor) => {
    let start = new Date(event.start)
    let end = new Date(event.end)
    let hourType = findHourType(subject,[student])

    //TODO: get rid of variable 'tomorrow' as is a global variable
    let today = new Date();
    let real_tomorrow = moveDate(today, 1);
    if (start < real_tomorrow){
        alert("No puedes reservar clases con menos de 24 horas de anticipación. Contacta con coordinación académica en caso sea una urgencia.")
        return false
    }else if (differenceInMinutes(end,start) > 180 || differenceInMinutes(end,start) < 60){
        alert("No se pueden reservar clases de más de 3 horas o menos de 1 hora")
        return false
    }else if(differenceInMinutes(end,start) > family[hourType]*60){
        alert(`No te quedan las suficientes horas del servicio ${subject.service}`)
        return false
    }
    else{
        let response = await axios.get(`${process.env.REACT_APP_BACKENDURL}/class/isValidClass`, {params: {
            start: start.toISOString(),
            end: end.toISOString(),
            tutorId: tutor._id
        }})
        const {valid} = response.data;
        if (!valid){
            alert("Evento inválido, por favor elige otro horario.")
            return false;
        }
        return true;
        // for (const freeEvent of freeEvents){
        //     let freeStart = new Date(freeEvent.start.dateTime)
        //     let freeEnd = new Date(freeEvent.end.dateTime)
        //     if ((start >= freeStart && start < freeEnd) || (end > freeStart && end <= freeEnd)){
        //         return true
        //     }
        // }
        // //TODO: Technically should also check it doesnt clash with a class, but 
        // //ideally that would not even be an option as it would be a blocked time
        // return false
    }
}

// Not relevant since free events doesn't necessarily mean there is no class scheduled
// const findFreeEvent = (event,freeEvents) => {
//     const eventStart = new Date(event.start)
//     for (const freeEvent of freeEvents){
//         let start = new Date(freeEvent.start.dateTime)
//         let end = new Date(freeEvent.end.dateTime)
//         if (eventStart >= start && eventStart <= end){
//             return freeEvent
//         }
//     }
//     return {}
// }

export default function NewClassForm() {
    const {user} = useSelector((state) => state.auth)
    const {parents} = useSelector((state) => state.parent)
    const {student,students} = useSelector((state) => state.student)
    const {tutor} = useSelector((state) => state.tutor)
    const {subject} = useSelector((state) => state.subject)
    const {freeEvents,events} = useSelector((state) => state.calendar)
    const {family} = useSelector((state) => state.family)
    const [currentWeekStart, setCurrentWeekStart] = useState("");
    const dispatch = useDispatch()
    useEffect(() => {
        let t = new Date();
        t.setHours(0, 0, 0, 0);
        setCurrentWeekStart(t.toJSON());
        dispatch(getTutors({filter:{'status':'Activo'}, selection:{}}))
        dispatch(getSubjects({filter:{},selection:{}}))
        dispatch(getParents({filter:{family:user.family},selection:{}}))
        dispatch(getFamily({id:user.family}));
    }, [])
    const calendarRef = React.createRef();
    
    const createEvent = async (event) => {
        const calendarInstance = calendarRef.current.getInstance();
        if (!tutor || !tutor._id){
            alert("Error: No tutor ha sido seleccionado.");
            calendarInstance.clearGridSelections()
            return;
        }
        let is_valid = await isEventValid(event,freeEvents,family,subject,student,tutor)
        if (is_valid){
            let answer = window.confirm("¿Quieres reservar esta clase?");
            if (answer){
                let prevInfo = window.prompt("Provee información para el tutor sobre esta clase (por ejemplo, qué temas el estudiante quisiera ver?");
                if (prevInfo == null){
                    alert("No se registró clase.");
                    calendarInstance.clearGridSelections()
                    return
                }
                axios.post(`${process.env.REACT_APP_BACKENDURL}/class/newclass`, {
                    // freeEvent:freeEvent,
                    students: [student],
                    parents:parents,
                    event: event,
                    tutor:tutor,
                    subject:subject,
                    prevInfo:prevInfo,
                }).then((response) => {
                    window.location.href = "/";
                }
                ).catch(error => console.log(error))
            }
        }
        calendarInstance.clearGridSelections()
    }
    // const updateEvent = (e) => {
    //     const { event, changes } = e;
    //     if (!event.isPending){
    //         changes.isPending = true
    //         changes["backgroundColor"] = 'rgba(11, 156, 49, 0.2)'
    //         changes["borderColor"] = 'rgba(11, 156, 49, 0.2)'
    //         let newEvent = {}
    //         const calendarInstance = calendarRef.current.getInstance();
    //         if ("start" in changes && "end" in changes){
    //             newEvent = {...event, start:new Date(changes.start.d.d), end:new Date(changes.end.d.d)}
    //         }
    //         else if("end" in changes){
    //             newEvent = {...event, end:new Date(changes.end.d.d), start:new Date(event.start.d.d)}
                
    //         }
    //         // else if ("start" in changes){
    //         //     newEvent = {...event, start:new Date(changes.start.d.d), end:new Date(event.end.d.d)}
    //         // }
    //         const freeEvent = findFreeEvent(newEvent,freeEvents)
            
    //         if (isEventValid(newEvent, freeEvents) && freeEvent){
    //             axios.patch(`${process.env.REACT_APP_BACKENDURL}/class/newclass`,{
    //                 newEvent:newEvent,
    //                 tutor:tutor,
    //                 student:user,
    //                 subject:subject,
    //                 family:family,
    //                 freeEvent:freeEvent
    //             }).then((response) => {
    //                 calendarInstance.updateEvent(event.id,event.calendarId,changes)
    //             }).catch(error => console.log(error))
    //         }
    //     }
    // }
    // const deleteEvent = (e) => {
    //     const {event} = e
    //     if (event.start.d.d < tomorrow){
    //         alert("No se pueden cancelar clases con menos de 24 horas de antelación. En caso sea una emergencia, contacta con el coordinador académico.")
    //     }else{
    //         let answer = window.confirm("¿Quieres cancelar esta clase?");
    //         if (answer){
    //             axios.delete(`${process.env.REACT_APP_BACKENDURL}/class/newclass`,{data:{
    //                 tutor:tutor,
    //                 student:user,
    //                 subject:subject,
    //                 event: {
    //                     id:event.id,
    //                     googleId:event.body,
    //                     start:event.start.d.d,
    //                     end: event.end.d.d,
    //                     calendarId:event.calendarId
    //                 }}
    //             }).then(response => window.location.reload(false)).catch(err => console.log(err))
    //         }
    //     }
        
    // }
        /**
     * Everytime the week start changes, get new data
     */
    useEffect(() =>{
        const fetchData = async () => {
            let today = new Date(currentWeekStart);
            let nextWeek = moveDate(today, 7);
            let todayMorning = new Date(today)
            todayMorning.setHours(0,0,0,0)
            const response1 = await axios.get(`${process.env.REACT_APP_BACKENDURL}/calendar/freeEvents`, {params:{calendarId:tutor.calendarId, start:today, end:nextWeek}})
            const response2 = await axios.get(`${process.env.REACT_APP_BACKENDURL}/class/all`, {params:{filter:{studentIds:student._id,tutorId: tutor._id, subjectId: subject._id, end:{"$lte":nextWeek} , start:{"$gte": todayMorning}}, selection:{}}})
            const tutorClassesResponse = await axios.get(`${process.env.REACT_APP_BACKENDURL}/class/all`, {params:{filter:{tutorId: tutor._id, end:{"$lte":nextWeek} , start:{"$gte": todayMorning}}, selection:{}}})

            dispatch(setFreeEvents(response1.data))
            dispatch(setClasses(response2.data))
            dispatch(setEvents({
                freeEvents: response1.data,
                classes: response2.data,
                tutor:tutor,
                subject: subject,
                today:today.toJSON(),
                tutorClasses:tutorClassesResponse.data,
            }))
        }
        if (subject && subject._id && tutor && tutor._id){
            fetchData()
        }
    }, [subject, tutor, currentWeekStart])

    const goToday = async () => {
        let t = new Date();
        t.setHours(0, 0, 0, 0);
        setCurrentWeekStart(t.toJSON());
        const calendarInstance = calendarRef.current.getInstance();
        calendarInstance.today()
    }
    const goNextWeek = async () => {
        setCurrentWeekStart(moveDate(currentWeekStart, 7).toJSON());
        const calendarInstance = calendarRef.current.getInstance();
        calendarInstance.next()
    }
    const goPrevWeek = async () => {
        setCurrentWeekStart(moveDate(currentWeekStart, -7).toJSON());
        const calendarInstance = calendarRef.current.getInstance();
        calendarInstance.prev()
    }
    const formatDate = (date) => {
        let month_names = ["Enero", "Febrero", "Marzo", "Abril", "Mayo", "Junio", "Julio", "Agosto", "Septiembre","Octubre", "Noviembre", "Diciembre"]
        let month = date.getMonth();
        let day = date.getDate();

        let today_string = month_names[month] + " " + day;
        return today_string;
    }
    /**
     * @returns string like 'January 10 - January 16'
     */
    const weekText = () => {
        let start = new Date(currentWeekStart);
        let last_day = moveDate(start, 6);
        return formatDate(start) + " - " + formatDate(last_day);
    }
    return (
      <div >
         {
            students.length > 1 && 
            <>
            <Row className="justify-content-md-center"> 
                <Form.Group as={Col} lg="4" controlId="student">
                <Form.Control
                    as={Select}
                    placeholder="Xplainer"
                    options={listFormat(students)}
                    required
                    onChange={opt => dispatch(setStudent(opt))}
                />
                </Form.Group>
            </Row>
            <br/>
            </>
        }
        {student.name !== ""
            ?
            <>
                <Row className="justify-content-md-center"  >
            <Col style={{marginRight:'-15%'}}>
                <SubjectSelect />
            </Col>
            <Col style={{marginLeft:'-15%'}}>
                <TutorSelectEvents />
            </Col>
        </Row>
        <br />
                {subject.name
                    ?
                    <>
                    {tutor.name
                        ?
                        <>
                            <br/>
                            <Row>
                                <Col style={{display:'flex', justifyContent:'center'}}>
                                    <Button style={{marginLeft:'1%', backgroundColor:"rgba(52, 52, 52, 0.2)", borderColor:"rgba(52, 52, 52, 0.2)", color:"rgba(0,0,0)", pointerEvents: "none"}}>Bloqueado</Button> 
                                    <Button  variant="light" style={{marginLeft:'1%', borderColor:"rgba(0,0,0)", pointerEvents: "none"}}>Libre</Button>
                                    <Button onClick={async () => await goPrevWeek()} style={{marginLeft:'1%', backgroundColor:"#0AB591", borderColor:"rgba(0,0,0)"}}> <b>&#60;</b> </Button>
                                    <Button onClick={async () => await goNextWeek()} style={{marginLeft:'1%', backgroundColor:"#0AB591", borderColor:"rgba(0,0,0)"}}> <b>&#62;</b> </Button>
                                    <Button onClick = {async () => await goToday()} style={{marginLeft:'1%', borderColor:"rgba(0,0,0)"}}> Hoy </Button>
                                    <div style={{marginLeft:"1%", display:"flex", alignItems:"center", justifyContent:"center"}}>{weekText()}</div>
                                </Col>
                            </Row>
                            <br />
                            <Card>
                                
                                <Calendar
                                    height="600px"
                                    view="week"
                                    ref={calendarRef}
                                    // onAfterRenderEvent={onAfterRenderEvent}
                                    events={events}
                                    onSelectDateTime={createEvent}
                                    // onBeforeUpdateEvent={updateEvent}
                                    // onClickEvent={deleteEvent}
                                    week={{
                                        taskView:false,
                                        startDayOfWeek:new Date(currentWeekStart).getDay(),
                                        eventView:['time'],
                                        // dayNames:["Lunes", "Martes", "Miércoles", "Jueves", "Viernes", "Sábado", "Domingo"]
                                    }}
                                    // useFormPopup={true}
                                    
                                    // useDetailPopup={true}
                                    theme={{
                                        week: {
                                            nowIndicatorToday: {
                                                border: '1px solid red',
                                            },
                                        },                                    
                                    }}
                                />
                            </Card>
                            
                            <br />
                        </>
                        :
                        <></>
                    }
                    </>
                    :
                    <></>
                }
                <br />
            </>
            :
            <></>
        }
        
        
      </div>
    );
  }