import React from 'react';
import { useSelector } from 'react-redux';
import FamilyHistory from "./FamilyHistory";
import TutorHistory from "./TutorHistory";
const History = () => {
    const {user} = useSelector((state) => state.auth)
    return (
        (user.role === 'Parent' || user.role === 'Student') ? <FamilyHistory familyId={user.family} />:
        (user.role === 'Tutor') ? <TutorHistory tutorId={user._id} />:
        <></>
    )
}
export default History;