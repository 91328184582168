import React, { useEffect} from 'react';
import axios from "axios"
import { useDispatch, useSelector } from 'react-redux';
import {useNavigate} from "react-router-dom"
import { useGoogleLogin } from '@react-oauth/google';
import GoogleButton from 'react-google-button'
import {setUser} from "../../store/auth/auth.slice"

const NewCalendarForm = () => {
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const {user} = useSelector((state) => state.auth)
    useEffect(() => {
        if(user && Object.keys(user).length === 0 && Object.getPrototypeOf(user) === Object.prototype){
            navigate("/login")
        }
        else{
            if((user.role !== "Tutor") || user.calendarId !== ""){
                navigate("/")
            }
        }
    },[])
    const loginButton = useGoogleLogin({
        onSuccess: (response) => {
            axios.post(`${process.env.REACT_APP_BACKENDURL}/auth/googleLogin`, {code:response.code}).then(response => {
                localStorage.setItem('googleRefreshToken',response.data.refresh_token)
                dispatch(setUser(response.data.user))
                if (response.data.user.role == "Tutor" && response.data.user.calendarId === ""){
                  axios.post(`${process.env.REACT_APP_BACKENDURL}/calendar/createCalendar`, {refresh_token:response.data.refresh_token, tutor:response.data.user}).then((response2) => {
                    dispatch(setUser(response2.data.user))
                    navigate("/")
                  })
                }else{
                    console.log(response.data.refresh_token)
                }
            }).catch(error => {
                console.log(error.message)
            })
        },
        flow: 'auth-code',
        scope: process.env.REACT_APP_GOOGLE_SCOPES
      });
    return(
        <>
        <br/>
        <br/>
        <br/>
        <br/>
        <br/>
        <p>1. Crea un calendario de Google con este formato <b>Nombre - Xplain</b></p>
        <br/>
        <p>2. Dentro de este calendario, <b>tendrás que rellenar los bloques de horas libres que tendrá todas las semanas.</b></p>
        <br />
        <p>3. Cuando termines este calendairo con tus bloques de horas libres semanales, compártelo a <b>xplainacademy@gmail.com</b> y aségurate que tengamos permisos de hacer cambios y compartir.</p>
        <br/>
        <p>4. Finalmente, avísanos por el WhatsApp de coordinación académica que ya nos compartiste tu calendario para que puedas tener acceso a la app.</p>
        <br />
        <p>En caso no sepas como hacer esto, por favor sigue este <a href="https://www.youtube.com/watch?v=HgKMb0oicQc" target="_blank">tutorial</a>.</p>
        <br/>
        {/* <GoogleButton
            style={{ 'margin-left':'auto','margin-right':'auto' }}
            type="dark"
            onClick={loginButton}
            label='Crear Calendario'
            /> */}
        </>

        
    )
}

export default NewCalendarForm