import { createSlice } from "@reduxjs/toolkit";
import { getHistory, getFamilyHistory, getTutorHistory } from "./history.actions";

const historySlice = createSlice({
    name: "history",
    initialState:{
        successHistory: [],
        errorHistory: [],
        familyHistory: [],
        gettingFamilyHistory: false,
        tutorHistory: [],
        gettingTutorHistory: false,
    },
    reducers: {
        
    },
    extraReducers: {
        [getHistory.pending]: (state, action) => {
            console.log("Fetching history...");
        },
        [getHistory.fulfilled]: (state, action) => {
            console.log("Fetched history!");
            console.log(action.payload);
            if (action.payload.success){
                state.successHistory = action.payload.success;
            }
            if (action.payload.error){
                state.errorHistory = action.payload.error;
            }
        },
        [getFamilyHistory.pending]: (state, action) => {
            console.log("Fetching family history");
            state.gettingFamilyHistory = true;
        },
        [getFamilyHistory.fulfilled]: (state, action) => {
            state.familyHistory = action.payload;
            state.gettingFamilyHistory = false;
        },
        [getTutorHistory.pending]: (state, action) => {
            console.log("Fetching tutor history");
            state.gettingTutorHistory = true;
        },
        [getTutorHistory.fulfilled]: (state, action) => {
            state.tutorHistory = action.payload;
            state.gettingTutorHistory = false;
        }
    }
})

export default historySlice.reducer; 