import React, {useState, useEffect} from "react"
import { useSelector } from "react-redux"
import { useNavigate } from "react-router"
import NewClass from "../../components/forms/StudentView/NewClass"
import ParentNewClass from "../../components/forms/ParentView/NewClass"
const ReserveClasses = () => {
    const {user} = useSelector((state) => state.auth)
    const [option,setOption] = useState(<></>)
    const navigate = useNavigate()
    useEffect(() => {
        if (user.role !== "Student" && user.role !== "Parent") {
            navigate("/")
        }
        // if(user.role === "Student"){
        //     setOption(<NewClass />)
        // }else if(user.role === "Parent"){
        //     setOption(<ParentNewClass />)
        // }else{
        // }
    }, [])
    return (
        <>  
            <br/>
            <h1>RESERVAR CLASES</h1>
            <br/>
            {user.role === "Student" ? <NewClass/> :
            user.role === "Parent"? <ParentNewClass/> : 
            <></>}
        </>
    )
}

export default ReserveClasses