import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

export const getTempTutors = createAsyncThunk(
    "tempTutors/getTempTutors",
    async (data) => {
        const response = await axios.get(`${process.env.REACT_APP_BACKENDURL}/tutor/temp/all`, {
            params:data
        })
        const tutors = response['data'];
        return tutors
    }
)