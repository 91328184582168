import {configureStore} from "@reduxjs/toolkit"
import authSlicer from "./auth/auth.slice"
import studentSlicer from "./student/student.slice"
import tutorSlicer from "./tutor/tutor.slice"
import subjectSlicer from "./subject/subject.slice"
import classSlicer from "./class/class.slice"
import calendarSlicer from "./calendar/calendar.slice"
import parentSlicer from "./parent/parent.slice"
import familySlicer from "./family/family.slice"
import tempTutorSlice from "./tempTutor/tempTutor.slice"
import historySlice from "./history/history.slice"

export default configureStore({
    reducer: {
        auth: authSlicer,
        student: studentSlicer,
        tutor: tutorSlicer,
        subject:subjectSlicer,
        class:classSlicer,
        calendar:calendarSlicer,
        parent: parentSlicer,
        family:familySlicer,
        tempTutor: tempTutorSlice,
        history: historySlice
    }
})