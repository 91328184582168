import React, {useState, useEffect} from 'react';
import Calendar from '@toast-ui/react-calendar';
import { getSubjects } from '../../store/subject/subject.actions';
import { setSubject } from '../../store/subject/subject.slice';
import { getTutor } from '../../store/tutor/tutor.actions';
import {getParents} from "../../store/parent/parent.actions"
import { useDispatch, useSelector } from 'react-redux';
import {Row, Col, Button, Card} from 'react-bootstrap'
import {setEvents, setFreeEvents} from "../../store/calendar/calendar.slice"
import { getTutorVisibleName } from '../../name_utils';

import axios from 'axios';
/**
 * Returns a date `n` days after the specified `date`.
 * @param {} date 
 * @param {*} n 
 * @returns 
 */
const moveDate = (date, diff_days) =>{
    let copy = new Date(date);
    copy.setDate(copy.getDate() + diff_days);
    return copy;
}

const NewClassTutorStudent = ({tutorId}) => {
    console.log(tutorId)
    const dispatch = useDispatch()
    const {tutor} = useSelector((state) => state.tutor);
    const {user} = useSelector((state) => state.auth)
    const {subject} = useSelector((state) => state.subject)
    const [currentWeekStart, setCurrentWeekStart] = useState("");
    const {freeEvents,events} = useSelector((state) => state.calendar)
    const calendarRef = React.createRef();
    useEffect(() => {
        let t = new Date();
        t.setHours(0, 0, 0, 0);
        setCurrentWeekStart(t.toJSON());
        dispatch(getTutor({id: tutorId}));
        dispatch(getParents({filter:{family:user.family},selection:{}}))
        dispatch(setSubject({}));
    }, []);
    useEffect(() => {
        if (tutor && tutor._id){
            dispatch(getSubjects({filter:{_id: {$in: tutor.subjects}}}));
        }
    }, [tutor]);
        /**
     * Everytime the week start changes, get new data
     */
    useEffect(() =>{
        const fetchData = async () => {
            console.log("fetching data...")
            let today = new Date(currentWeekStart);
            let nextWeek = moveDate(today, 7);
            let todayMorning = new Date(today)
            todayMorning.setHours(0,0,0,0)
            const response1 = await axios.get(`${process.env.REACT_APP_BACKENDURL}/calendar/freeEvents`, {params:{calendarId:tutor.calendarId, start:today, end:nextWeek}})
            // const response2 = await axios.get(`${process.env.REACT_APP_BACKENDURL}/class/all`, {params:{filter:{studentIds:user._id,tutorId: tutor._id, subjectId: subject._id, end:{"$lte":nextWeek} , start:{"$gte": todayMorning}}, selection:{}}})
            const tutorClassesResponse = await axios.get(`${process.env.REACT_APP_BACKENDURL}/class/all`, {params:{filter:{tutorId: tutor._id, end:{"$lte":nextWeek} , start:{"$gte": todayMorning}}, selection:{}}})

            dispatch(setFreeEvents(response1.data))
            // dispatch(setClasses(response2.data))
            dispatch(setEvents({
                freeEvents: response1.data,
                // classes: response2.data,
                tutor:tutor,
                subject: subject,
                today:today.toJSON(),
                tutorClasses: tutorClassesResponse.data
            }))
        }
        if (tutor && tutor._id){
            fetchData()
        }
    }, [subject, tutor, currentWeekStart])
    const goToday = async () => {
        let t = new Date();
        t.setHours(0, 0, 0, 0);
        setCurrentWeekStart(t.toJSON());
        const calendarInstance = calendarRef.current.getInstance();
        calendarInstance.today()
    }
    const goNextWeek = async () => {
        setCurrentWeekStart(moveDate(currentWeekStart, 7).toJSON());
        const calendarInstance = calendarRef.current.getInstance();
        calendarInstance.next()
    }
    const goPrevWeek = async () => {
        setCurrentWeekStart(moveDate(currentWeekStart, -7).toJSON());
        const calendarInstance = calendarRef.current.getInstance();
        calendarInstance.prev()
    }
    const formatDate = (date) => {
        let month_names = ["Enero", "Febrero", "Marzo", "Abril", "Mayo", "Junio", "Julio", "Agosto", "Septiembre","Octubre", "Noviembre", "Diciembre"]
        let month = date.getMonth();
        let day = date.getDate();

        let today_string = month_names[month] + " " + day;
        return today_string;
    }
    /**
     * @returns string like 'January 10 - January 16'
     */
    const weekText = () => {
        let start = new Date(currentWeekStart);
        let last_day = moveDate(start, 6);
        return formatDate(start) + " - " + formatDate(last_day);
    }
    return (
        (!tutor || !tutor._id) ? <div>Error cargando tutor</div>:
        (tutor.status !== "Activo") ? <div>Este tutor no esta actualmente activo(a)</div>:
        (!user || !user._id) ? <div>Tiene que haber iniciado sesión </div>:
        <div>
            <h1>Clases con {getTutorVisibleName(tutor)}</h1>
            <p><i>Modo de espectador</i></p>
            <div className='m-3'>
                <Row className="m-3">
                    <Col style={{display:'flex', justifyContent:'center'}}>
                        <Button style={{marginLeft:'1%', backgroundColor:"rgba(52, 52, 52, 0.2)", borderColor:"rgba(52, 52, 52, 0.2)", color:"rgba(0,0,0)", pointerEvents: "none"}}>Bloqueado</Button> 
                        <Button  variant="light" style={{marginLeft:'1%', borderColor:"rgba(0,0,0)", pointerEvents: "none"}}>Libre</Button>
                        <Button onClick={async () => await goPrevWeek()} style={{marginLeft:'1%', backgroundColor:"#0AB591", borderColor:"rgba(0,0,0)"}}> <b>&#60;</b> </Button>
                        <Button onClick={async () => await goNextWeek()} style={{marginLeft:'1%', backgroundColor:"#0AB591", borderColor:"rgba(0,0,0)"}}> <b>&#62;</b> </Button>
                        <Button onClick = {async () => await goToday()} style={{marginLeft:'1%', borderColor:"rgba(0,0,0)"}}> Hoy </Button>
                        <div style={{marginLeft:"1%", display:"flex", alignItems:"center", justifyContent:"center"}}>{weekText()}</div>
                    </Col>
                </Row>

                <Card>
                    <Row>                     
                        <Col>
                            <Calendar
                                height="600px"
                                view="week"
                                ref={calendarRef}
                                // onAfterRenderEvent={onAfterRenderEvent}
                                events={events}
                                isReadOnly={true}
                                // onSelectDateTime={createEvent}
                                // onBeforeUpdateEvent={updateEvent}
                                // onClickEvent={deleteEvent}
                                week={{
                                    taskView:false,
                                    startDayOfWeek:new Date(currentWeekStart).getDay(),
                                    eventView:['time'],
                                    // dayNames:["Lunes", "Martes", "Miércoles", "Jueves", "Viernes", "Sábado", "Domingo"]
                                }}
                                // useFormPopup={true}
                                
                                // useDetailPopup={true}
                                theme={{
                                    week: {
                                        nowIndicatorToday: {
                                            border: '1px solid red',
                                        },
                                    },                                    
                                }}
                            />
                        </Col>
                    </Row>
                </Card>
            </div>
        </div>
    )
}

export default NewClassTutorStudent;