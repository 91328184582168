import { createSlice } from "@reduxjs/toolkit";
import {getFamilies,getFamily} from "./family.actions";

const familySlice = createSlice({
    name:"family",
    initialState: {
        family:{},
        families: [],
        selectedFamilies: []
    },
    reducers: {
        setFamily: (state,action) => {
            state.family = action.payload 
        },
        setFamilies: (state,action) => {
            state.selectedFamilies = action.payload 
        }
    },
    extraReducers: {
        [getFamilies.pending] : (state,action) => {
            console.log("fetching families")
        },
        [getFamilies.fulfilled]: (state,action) => {
            console.log("fetching families succesfully!")
            state.families = action.payload
            
        },
        [getFamily.pending] : (state,action) => {
            console.log("fetching family")
        },
        [getFamily.fulfilled]: (state,action) => {
            console.log("fetching family succesfully!")
            state.family = action.payload
            
        }
    }
})


export const {setFamily, setFamilies} = familySlice.actions;
export default familySlice.reducer