import React, { useState, useEffect } from "react";
import { Form, Button, Card, Alert, Row } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import { useDispatch } from "react-redux";
import { useGoogleLogin } from "@react-oauth/google";
import GoogleButton from "react-google-button";
import { setUser } from "../../store/auth/auth.slice";
export default function LoginForm() {
  const dispatch = useDispatch();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [isInvalid, setIsInvalid] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState();
  const navigate = useNavigate();
  useEffect(() => {
    let token = localStorage.getItem("token");
    if (typeof token === "string" || token instanceof String) {
      axios
        .post(`${process.env.REACT_APP_BACKENDURL}/auth/checktoken`, {
          token: token,
        })
        .then((response) => {
          let { token, user } = response.data;
          localStorage.setItem("token", token);
          // if (user?._id) {
          // }
          dispatch(setUser(user));

          navigate("/");
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      let googleRefreshToken = localStorage.getItem("googleRefreshToken");
      if (
        typeof googleRefreshToken === "string" ||
        googleRefreshToken instanceof String
      ) {
        axios
          .post(
            `${process.env.REACT_APP_BACKENDURL}/auth/checkGoogleRefreshToken`,
            { refresh_token: googleRefreshToken }
          )
          .then((response) => {
            let { user } = response.data;
            // if (user?._id) {
            // }
            dispatch(setUser(user));
            navigate("/");
            // if (user.role == "Tutor" && user.calendarId == ""){
            //   navigate("/createcalendar")
            // }else{
            //   navigate("/")
            // }
          })
          .catch((err) => {
            console.log(err);
          });
      }
    }
  }, []);
  const handleSubmit = (event) => {
    event.preventDefault();
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.stopPropagation();
      setIsInvalid(true);
    } else {
      setIsInvalid(false);
      setLoading(true);
      axios
        .post(`${process.env.REACT_APP_BACKENDURL}/auth/login`, {
          email: email,
          password: password,
        })
        .then((response) => {
          let { token, user } = response.data;
          // if (user?._id) {
          // }
          setUser(user);

          localStorage.setItem("token", token);
          setLoading(false);
          navigate("/");
        })
        .catch((err) => {
          setError(err.response.data.message);
          setLoading(false);
        });
    }
  };
  const loginButton = useGoogleLogin({
    onSuccess: (response) => {
      axios
        .post(`${process.env.REACT_APP_BACKENDURL}/auth/googleLogin`, {
          code: response.code,
        })
        .then((response) => {
          localStorage.setItem(
            "googleRefreshToken",
            response.data.refresh_token
          );
          if (response.data.user._id) {
            dispatch(setUser(response.data.user));
          }
          if (
            response.data.user.role == "Tutor" &&
            response.data.user.calendarId == ""
          ) {
            navigate("/createcalendar");
          } else {
            navigate("/");
          }
        })
        .catch((error) => {
          setError(error.response.data.message);
          setLoading(false);
        });
    },
    flow: "auth-code",
    scope: [],
  });

  return (
    <>
      <Card
        style={{
          width: "25rem",
          "margin-left": "auto",
          "margin-right": "auto",
        }}
      >
        <Card.Body>
          <h2 className="text-center mb-4">Iniciar Sesión en Xplain</h2>
          {error && <Alert variant="danger">{error}</Alert>}
          <Form onSubmit={handleSubmit} validated={isInvalid}>
            <Form.Group id="email">
              <Form.Label>Email</Form.Label>
              <Form.Control
                type="email"
                value={email}
                required
                onChange={(e) => setEmail(e.target.value)}
              />
            </Form.Group>
            <br />
            <Form.Group id="password">
              <Form.Label>Contraseña</Form.Label>
              <Form.Control
                type="password"
                value={password}
                required
                onChange={(e) => setPassword(e.target.value)}
              />
            </Form.Group>
            <br />
            <br />

            <Button
              disabled={loading}
              className="w-100"
              type="submit"
              style={{ backgroundColor: "#0AB591", borderColor: "#0AB591" }}
            >
              Iniciar Sesión
            </Button>
            <br />
            <br />
            {/* <p><b>Esto solo está disponible para admins</b></p> */}
            <GoogleButton
              style={{ "margin-left": "auto", "margin-right": "auto" }}
              type="dark"
              label="Iniciar sesión con Google"
              onClick={loginButton}
            />
          </Form>
        </Card.Body>
        <br />
      </Card>
      <div className="w-100 text-center mt-3">
        <Link to="/newpassword">Olvidé mi contraseña</Link>
      </div>
      <div className="w-100 text-center mt-2">
        No tienes una cuenta? <Link to="/signup">Regístrate</Link>
      </div>
    </>
  );
}
