import React, {useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import { getTutors } from '../../../store/tutor/tutor.actions';
import { getSubjects } from "../../../store/subject/subject.actions";
import {Form,Row, Table, Col,Button} from "react-bootstrap"
import Select from "react-select"
import { useNavigate } from "react-router";
import { getTutorVisibleName } from "../../../name_utils";
import { listFormat } from "../../../name_utils";

const StudentTutors = () =>{
    const dispatch = useDispatch();
    const {tutors} = useSelector((state) => state.tutor)
    const {subjects} = useSelector((state) => state.subject)
    const navigate = useNavigate()

    // const listFormat = (array) => {
    //     let newArray = []
    //     for (const elem of array){
    //         let newElem = {...elem}
    //         if ('surname' in newElem){
    //             newElem['label'] = elem['name'] + " " + elem['surname']
    //         }else{
    //             newElem['label'] = elem['name']
    //         }
    //         newElem['value'] = elem['_id']
    //         newArray.push(newElem)
    //     }
    //     return newArray
    // }
    useEffect(()=>{
        dispatch(getTutors({filter: {status:"Activo"}, selection: {}}));
        dispatch(getSubjects({filter: {}, selection: {}}));
        // dispatch(getTutors({filter: {_id: {"$in": student.tutors}}}));
    }, []);
    return(
        <>
            <Row className="justify-content-md-center">
                <Form.Group as={Col} lg="4" controlId="student">
                <Form.Control
                    as={Select}
                    options={[{label:"Todos", value:"everything", name:"everything"},...listFormat(subjects)]}
                    required
                    placeholder="Filtrar por Materia"
                    onChange={opt => {
                            if(opt.value == "everything"){
                                dispatch(getTutors({filter: {status:"Activo"}, selection: {}}));
                            }else{
                                dispatch(getTutors({filter: {status:"Activo", subjects:opt._id}, selection: {}}));

                            }
                    }}
                />
                </Form.Group>
            </Row>
            <br/>
            <Table>
                <thead>
                    <tr>
                        <th>#</th>
                        <th>Nombre</th>
                        <th>Perfil</th>
                        <th>Separar clase</th>
                    </tr>
                </thead>
                <tbody>
                    {
                    tutors.map((tutor, i)=>(
                        <tr>
                            <td>{i+1}</td>
                            <td>
                                {getTutorVisibleName(tutor)}
                            </td>
                            <td>
                                <Button  style={{backgroundColor:"rgba(10, 181, 145, 1)", borderColor:"rgba(10, 181, 145, 1)"}} onClick={() => navigate(`/tutor?id=${tutor._id}`)}>Ver Perfil</Button>
                            </td>
                            <td>
                                <Button  style={{}} onClick={() => navigate(`/newclass?tutor=${tutor._id}`)}>Ver calendario</Button>
                            </td>
                        </tr>
                    ))
                    }
                </tbody>
            </Table>
        </>
    )
}

export default StudentTutors;