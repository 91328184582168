import React, {useState, useEffect} from 'react';
import Calendar from '@toast-ui/react-calendar';
import { getSubjects } from '../../store/subject/subject.actions';
import { setSubject } from '../../store/subject/subject.slice';
import { getTutor } from '../../store/tutor/tutor.actions';
import {getParents} from "../../store/parent/parent.actions"
import { useDispatch, useSelector } from 'react-redux';
import {differenceInMinutes} from "date-fns"
import { StudentSelect, TutorCalendarSubjectSelect } from '../../components/forms/Select/Select';
import {Row, Col, Button, Card} from 'react-bootstrap'
import { setClasses } from '../../store/class/class.slice';
import {setEvents, setFreeEvents} from "../../store/calendar/calendar.slice"

import axios from 'axios';
import { setStudent } from '../../store/student/student.slice';
import { getStudents } from '../../store/student/student.actions';
import { getTutorVisibleName } from '../../name_utils';
import {getFamily} from '../../store/family/family.actions';
/**
 * Returns a date `n` days after the specified `date`.
 * @param {} date 
 * @param {*} n 
 * @returns 
 */
const moveDate = (date, diff_days) =>{
    let copy = new Date(date);
    copy.setDate(copy.getDate() + diff_days);
    return copy;
}
const findHourType = (subject,students) => {
    if (students.length > 1){
        if (subject.service === "SAT"){
            return "hoursSATGroup"
        }else if (subject.service === "IB"){
            return "hoursIBGroup"
        }else if (subject.service === "Tutorías"){
            return "hoursTutoringGroup"
        }else{
            return "hoursOlympiadGroup"
        }
    }else{
        if (subject.service === "SAT"){
            return "hoursSAT"
        }else if (subject.service === "IB"){
            return "hoursIB"
        }else if (subject.service === "Tutorías"){
            return "hoursTutoring"
        }else{
            return "hoursOlympiad"
        }
    }
}

const isEventValid = async (event, freeEvents,family, subject, student, tutor) => {
    let start = new Date(event.start)
    let end = new Date(event.end)
    let hourType = findHourType(subject,[student])

    //TODO: get rid of variable 'tomorrow' as is a global variable
    let today = new Date();
    let real_tomorrow = moveDate(today, 1);
    if (start < real_tomorrow){
        alert("No puedes reservar clases con menos de 24 horas de anticipación. Contacta con coordinación académica en caso sea una urgencia.")
        return false
    }else if (differenceInMinutes(end,start) > 180 || differenceInMinutes(end,start) < 60){
        alert("No se pueden reservar clases de más de 3 horas o menos de 1 hora")
        return false
    }else if(differenceInMinutes(end,start) > family[hourType]*60){
        alert(`No te quedan las suficientes horas del servicio ${subject.service}`)
        return false
    }
    else{
        let response = await axios.get(`${process.env.REACT_APP_BACKENDURL}/class/isValidClass`, {params: {
            start: start.toISOString(),
            end: end.toISOString(),
            tutorId: tutor._id
        }})
        const {valid} = response.data;
        if (!valid){
            alert("Evento inválido, por favor elige otro horario.")
            return false;
        }
        return true;
    }
}

const NewClassTutorParent = ({tutorId}) => {
    console.log(tutorId)
    const dispatch = useDispatch()
    const {tutor} = useSelector((state) => state.tutor);
    const {user} = useSelector((state) => state.auth)
    const {parents} = useSelector((state) => state.parent)
    const {student,students} = useSelector((state) => state.student)
    const {subject} = useSelector((state) => state.subject)
    const {family} = useSelector((state) => state.family)
    const [currentWeekStart, setCurrentWeekStart] = useState("");
    const {freeEvents,events} = useSelector((state) => state.calendar)
    const calendarRef = React.createRef();
    useEffect(() => {
        let t = new Date();
        t.setHours(0, 0, 0, 0);
        setCurrentWeekStart(t.toJSON());
        dispatch(getTutor({id: tutorId}));
        dispatch(getParents({filter:{family:user.family},selection:{}}))
        dispatch(getStudents({filter:{family:user.family}, selection:{}}));
        dispatch(setSubject({}));
        dispatch(setStudent({}));
        dispatch(getFamily({id:user.family}));
    }, []);
    useEffect(() => {
        if (tutor && tutor._id){
            dispatch(getSubjects({filter:{_id: {$in: tutor.subjects}}}));
        }
    }, [tutor]);
        /**
     * Everytime the week start changes, get new data
     */
    useEffect(() =>{
        const fetchData = async () => {
            let today = new Date(currentWeekStart);
            let nextWeek = moveDate(today, 7);
            let todayMorning = new Date(today)
            todayMorning.setHours(0,0,0,0)
            const response1 = await axios.get(`${process.env.REACT_APP_BACKENDURL}/calendar/freeEvents`, {params:{calendarId:tutor.calendarId, start:today, end:nextWeek}})
            // const response2 = await axios.get(`${process.env.REACT_APP_BACKENDURL}/class/all`, {params:{filter:{studentIds:user._id,tutorId: tutor._id, subjectId: subject._id, end:{"$lte":nextWeek} , start:{"$gte": todayMorning}}, selection:{}}})
            const tutorClassesResponse = await axios.get(`${process.env.REACT_APP_BACKENDURL}/class/all`, {params:{filter:{tutorId: tutor._id, end:{"$lte":nextWeek} , start:{"$gte": todayMorning}}, selection:{}}})

            dispatch(setFreeEvents(response1.data))
            // dispatch(setClasses(response2.data))
            dispatch(setEvents({
                freeEvents: response1.data,
                // classes: response2.data,
                tutor:tutor,
                subject: subject,
                today:today.toJSON(),
                tutorClasses: tutorClassesResponse.data
            }))
        }
        if (tutor && tutor._id){
            fetchData()
        }
    }, [subject, tutor, currentWeekStart])
    const createEvent = async (event) => {
        const calendarInstance = calendarRef.current.getInstance();
        if (!tutor || !tutor._id){
            alert("Error: No tutor ha sido seleccionado.");
            calendarInstance.clearGridSelections()
            return;
        }
        if (!subject || !subject._id){
            alert("Error: No curso ha sido seleccionado.");
            calendarInstance.clearGridSelections()
            return;
        }
        if (!student ||!student._id){
            alert("Error: No estudiante ha sido seleccionado.");
            calendarInstance.clearGridSelections()
            return;
        }
        let is_valid = await isEventValid(event,freeEvents,family, subject, user, tutor)
        if (is_valid){
            console.log("es valido!")
            let answer = window.confirm("¿Quieres reservar esta clase?");
            if (answer){
                let prevInfo = window.prompt("Provee información para el tutor sobre esta clase (por ejemplo, qué temas quisieras ver?");
                if (prevInfo == null){
                    alert("No se registró clase.");
                    calendarInstance.clearGridSelections()
                    return
                }
                console.log({
                    // freeEvent:freeEvent,
                    students: [student],
                    parents:parents,
                    event: event,
                    tutor:tutor,
                    subject:subject,
                    prevInfo: prevInfo,
                })
                axios.post(`${process.env.REACT_APP_BACKENDURL}/class/newclass`, {
                    // freeEvent:freeEvent,
                    students: [student],
                    parents:parents,
                    event: event,
                    tutor:tutor,
                    subject:subject,
                    prevInfo: prevInfo,
                }).then((response) => {
                    window.location.href = "/";
                }
                ).catch(error => {
                    console.log(error)
                    alert("Hubo un error. Por favor intente nuevamente.")
                })
            }
        }
        calendarInstance.clearGridSelections()
    }
    const goToday = async () => {
        let t = new Date();
        t.setHours(0, 0, 0, 0);
        setCurrentWeekStart(t.toJSON());
        const calendarInstance = calendarRef.current.getInstance();
        calendarInstance.today()
    }
    const goNextWeek = async () => {
        setCurrentWeekStart(moveDate(currentWeekStart, 7).toJSON());
        const calendarInstance = calendarRef.current.getInstance();
        calendarInstance.next()
    }
    const goPrevWeek = async () => {
        setCurrentWeekStart(moveDate(currentWeekStart, -7).toJSON());
        const calendarInstance = calendarRef.current.getInstance();
        calendarInstance.prev()
    }
    const formatDate = (date) => {
        let month_names = ["Enero", "Febrero", "Marzo", "Abril", "Mayo", "Junio", "Julio", "Agosto", "Septiembre","Octubre", "Noviembre", "Diciembre"]
        let month = date.getMonth();
        let day = date.getDate();

        let today_string = month_names[month] + " " + day;
        return today_string;
    }
    /**
     * @returns string like 'January 10 - January 16'
     */
    const weekText = () => {
        let start = new Date(currentWeekStart);
        let last_day = moveDate(start, 6);
        return formatDate(start) + " - " + formatDate(last_day);
    }
    console.log(events);
    return (
        (!tutor || !tutor._id) ? <div>Error cargando tutor</div>:
        (tutor.status !== "Activo") ? <div>Este tutor no esta actualmente activo(a)</div>:
        (!user || !user._id) ? <div>Tiene que haber iniciado sesión </div>:
        <div>
            <h1>Clases con {getTutorVisibleName(tutor)}</h1>
            <TutorCalendarSubjectSelect label="Escoja la materia"/>
            <StudentSelect/>
            <div className='m-3'>
                <Row>
                    <Col style={{display:'flex', justifyContent:'center'}}>
                        <Button style={{marginLeft:'1%', backgroundColor:"rgba(52, 52, 52, 0.2)", borderColor:"rgba(52, 52, 52, 0.2)", color:"rgba(0,0,0)", pointerEvents: "none"}}>Bloqueado</Button> 
                        <Button  variant="light" style={{marginLeft:'1%', borderColor:"rgba(0,0,0)", pointerEvents: "none"}}>Libre</Button>
                        <Button onClick={async () => await goPrevWeek()} style={{marginLeft:'1%', backgroundColor:"#0AB591", borderColor:"rgba(0,0,0)"}}> <b>&#60;</b> </Button>
                        <Button onClick={async () => await goNextWeek()} style={{marginLeft:'1%', backgroundColor:"#0AB591", borderColor:"rgba(0,0,0)"}}> <b>&#62;</b> </Button>
                        <Button onClick = {async () => await goToday()} style={{marginLeft:'1%', borderColor:"rgba(0,0,0)"}}> Hoy </Button>
                        <div style={{marginLeft:"1%", display:"flex", alignItems:"center", justifyContent:"center"}}>{weekText()}</div>
                    </Col>
                </Row>
                {
                events.length === 0 ? 
                <div>No se encontro ningun event libre</div>:

                <Card>
                    <Row>                     
                        <Col>
 
                            <Calendar
                                height="600px"
                                view="week"
                                ref={calendarRef}
                                // onAfterRenderEvent={onAfterRenderEvent}
                                events={events}
                                onSelectDateTime={createEvent}
                                // onBeforeUpdateEvent={updateEvent}
                                // onClickEvent={deleteEvent}
                                week={{
                                    taskView:false,
                                    startDayOfWeek:new Date(currentWeekStart).getDay(),
                                    eventView:['time'],
                                    // dayNames:["Lunes", "Martes", "Miércoles", "Jueves", "Viernes", "Sábado", "Domingo"]
                                }}
                                // useFormPopup={true}
                                
                                // useDetailPopup={true}
                                theme={{
                                    week: {
                                        nowIndicatorToday: {
                                            border: '1px solid red',
                                        },
                                    },                                    
                                }}
                            />
                        </Col>
                    </Row>
                </Card>
                }
            </div>
        </div>
    )
}

export default NewClassTutorParent;