import React, {useState} from 'react';
import CalStudentClasses from './CalStudentClasses';
import { useSelector,useDispatch } from 'react-redux';
import TableStudentClasses from './TableStudentClasses';
import {Form, Row,Col} from "react-bootstrap"
import {setStudent} from "../../../store/student/student.slice"
import Select from 'react-select';
import { listFormat } from '../../../name_utils';
// const listFormat = (array) => {
//     let newArray = []
//     for (const elem of array){
//         let newElem = {...elem}
//         if ('surname' in newElem){
//             newElem['label'] = elem['name'] + " " + elem['surname']
//         }else{
//             newElem['label'] = elem['name']
//         }
//         newElem['value'] = elem['_id']
//         newArray.push(newElem)
//     }
//     return newArray
// }


const ParentClasses = () => {
    const [checked,setChecked] = useState(false)
    const dispatch = useDispatch()
    const {students,student} = useSelector((state) => state.student)
    return (
        <>
            {
                students.length > 1 && 
                <>
                <Row className="justify-content-md-center"> 
                    <Form.Group as={Col} lg="4" controlId="student">
                    <Form.Control
                        as={Select}
                        placeholder="Xplainer"
                        options={listFormat(students)}
                        required
                        onChange={opt => dispatch(setStudent(opt))}
                    />
                    </Form.Group>
                </Row>
                <br/>
                </>
            }
            {student.name !== ""
                ?
                <>
                {checked
                    ?
                    <>
                        <Row className="justify-content-md-center">
                            <Col  xs={2}>
                                <Form.Check 
                                    type="switch"
                                    id="custom-switch"
                                    label="Ver calendario"
                                    checked={checked}
                                    onClick ={() => setChecked(!checked)}
                                />
                            </Col>
                        </Row>
                        <br/>
                        <TableStudentClasses />
                    </>
                    :
                    <>
                        <Row className="justify-content-md-center">
                            <Col  xs={2}>
                                <Form.Check 
                                    type="switch"
                                    id="custom-switch"
                                    label="Ver tabla"
                                    checked={checked}
                                    onClick ={() => setChecked(!checked)}
                                />
                            </Col>
                        </Row>
                        <br/>
                        <CalStudentClasses />
                    </>
                }
                </>
                :
                <></>
            }
            
        </>
    )
}

export default ParentClasses