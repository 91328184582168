import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Form, Col, Row, Button } from "react-bootstrap";
import axios from "axios";
import Select from "react-select";
import { getTutors } from "../../../store/tutor/tutor.actions";
import { selectTutor } from "../../../store/tutor/tutor.slice";
import { TutorSelect } from "../Select/Select";
import { createStrike } from "../../../ApiConfig";

const CreateStrike = () => {
  const dispatch = useDispatch();
  const getAllTutors = () => {
    dispatch(getTutors({ filter: {}, selection: {} }));
  };
  const { tutors } = useSelector((state) => state.tutor);
  const [value, setValue] = useState();
  const [reason, setReason] = useState("");
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    getAllTutors();
    // dispatch(selectTutor({}));
  }, []);

  const { tutor } = useSelector((state) => state.tutor);
  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    if (!tutor?._id) {
      alert("Se tiene que escoger a un tutor!");
      setLoading(false);
      return;
    }
    if (!value) {
      alert("Se tiene que dar un valor del descuento!");
      setLoading(false);
      return;
    }
    if (!reason) {
      alert("Se tiene que dar una razon!!");
      setLoading(false);
      return;
    }
    try {
      await createStrike({
        tutor: tutor._id,
        value,
        reason,
        type: "created-by-admin",
      });
      alert("Se creó el strike!");
      window.location.reload(false);
    } catch (e) {
      alert(`Hubo un error: ${e.message}`);
    }
  };
  return (
    <div>
      <Form noValidate onSubmit={handleSubmit}>
        {/* {tutors.length !== 1 ? (
        <div>Se encontraron {tutors.length} tutores.</div>
      ) : (
        <div>Se encontró {tutors.length} tutor!</div>
      )} */}
        <TutorSelect />
        {tutor._id && (
          <>
            <br />
            <Row className="justify-content-md-center">
              <Form.Group as={Col} lg="3" controlId="hours" noValidate>
                <Form.Label>
                  Cuanto se le debe de descontar a {tutor.name} {tutor.surname}?
                </Form.Label>

                <Form.Control
                  type="text" //not 'number' to prevent showing arrows
                  fontSize={32}
                  // inputMode="numeric"
                  noValidate
                  required
                  //   value={value}
                  // placeholder="0.5, 1, 1.5, etc"
                  //   onChange={(e) => setValue(e.target.value)}
                  as={Select}
                  options={[0.1, 0.2, 0.25, 0.5, 1, 1.5, 2, 2.5, 3].map(
                    (v) => ({
                      label: v,
                      value: v,
                    })
                  )}
                  onChange={(opt) => setValue(opt.value)}
                />
              </Form.Group>
            </Row>
            <br />
            <Row className="justify-content-md-center">
              <Form.Group as={Col} lg="4" controlId="tutor">
                <Form.Label>
                  Cual es la razon? Este es el mensaje que el tutor vera, asi
                  que es mejor si es en 2da persona. De preferencia un mensaje
                  corto.
                </Form.Label>

                <br />
                <Form.Control
                  required
                  type="text" //not 'number' to prevent showing arrows
                  fontSize={32}
                  placeholder="Llegaste tarde a una clase"
                  value={reason}
                  onChange={(e) => setReason(e.target.value)}
                />
              </Form.Group>
            </Row>
            <br />

            <Button type="submit" variant="success" disabled={loading}>
              {loading ? "Cargando..." : "Crear strike"}
            </Button>
          </>
        )}
      </Form>
    </div>
  );
};

export default CreateStrike;
