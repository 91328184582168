import React, {useState, useEffect, useCallback} from 'react';
import Calendar from '@toast-ui/react-calendar';
import '@toast-ui/calendar/dist/toastui-calendar.min.css';
import 'tui-date-picker/dist/tui-date-picker.css';
import 'tui-time-picker/dist/tui-time-picker.css';
import axios from 'axios';
import {Card, Modal, Button, Row, Col} from "react-bootstrap"
import { useSelector,useDispatch } from 'react-redux';
import {getClasses} from "../../../store/class/class.actions"
import {getSubjects} from "../../../store/subject/subject.actions"
import {getTutors} from "../../../store/tutor/tutor.actions"
import {getParents} from "../../../store/parent/parent.actions"
const FutureClasses = () => {
    const dispatch = useDispatch() 
    const {user} = useSelector((state) => state.auth)
    const {classes} = useSelector((state) => state.class)
    const {subjects} = useSelector((state) => state.subject)
    const {tutors} = useSelector((state) => state.tutor)
    const {students, student} = useSelector((state) => state.student)
    const {parents} = useSelector((state) => state.parent)
    const [date, setDate] = useState("")
    const [start,setStart] = useState("")
    const [end,setEnd] = useState("")
    const [show,setShow] = useState(false)
    const [event, setEvent] = useState({})
    let today = new Date()
    let tomorrow = new Date(today)
    tomorrow.setDate(today.getDate() + 1)
    let nextWeek = new Date(today)
    nextWeek.setDate(today.getDate() + 7)
    let todayMorning = new Date(today)
    todayMorning.setHours(0,0,0,0)
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const calendarRef = React.createRef();
    useEffect(() => {
        dispatch(getClasses({filter:{studentIds:student._id, end:{"$lte":nextWeek} , start:{"$gte": todayMorning}}, selection:{}}))
        dispatch(getSubjects({filter:{},selection:{}}))
        dispatch(getTutors({filter:{'status':'Activo'}, selection:{}}))
        dispatch(getParents({filter:{family:user.family},selection:{}}))
    }, [])
    const [selectedClass, setSelectedClass] = useState({});

    let newClasses = []
    for (const currentClass of classes){
        let newClass = {...currentClass}
        for (const tutor of tutors){
            if (tutor._id == newClass.tutorId){
                newClass["tutorName"] = tutor['name'].split(" ")[0] + " " + tutor['surname'].split(" ")[0]
                newClass["calendarId"] = tutor.calendarId
            }
        }
        for (const subject of subjects){
            if (subject._id == newClass.subjectId){
                newClass["subjectName"] = subject['name']
            }
        }
        let calendarClass = {
            start: new Date(newClass.start),
            end: new Date(newClass.end),
            id: newClass._id,
            body: newClass.googleId,
            title: `${newClass.subjectName} con ${newClass.tutorName}`,
            isReadOnly: true,
            calendarId: newClass.calendarId,
            isPending: newClass.pending
        }
        if (calendarClass.isPending){
            calendarClass["backgroundColor"] = 'rgba(10, 181, 145, 0.2)'
            calendarClass["borderColor"] = 'rgba(10, 181, 145, 0.2)'
        }else{
            calendarClass["backgroundColor"] = 'rgba(10, 181, 145, 1)'
            calendarClass["borderColor"] = 'rgba(10, 181, 145, 1)'
        }
        newClasses.push(calendarClass)
    }
    const selectEvent = async (e) => {
        const {event} = e
        let id = event.id;
        try {
            let response = await axios.get(`${process.env.REACT_APP_BACKENDURL}/class/`, {
                params: {
                    id: id
                }
            })
            setSelectedClass(response.data);
        }catch(err){
            console.log(err);
            console.log(`Invalid class id: ${id}`);
        }
        setEvent(event)
        let classStart = new Date(event.start.d.d)
        let month = classStart.getMonth().toString().padStart(2, '0')
        month = Number(month) + 1
        let day = `${classStart.getDate().toString().padStart(2, '0')}/${month.toString().padStart(2, '0')}`
        let classEnd = new Date(event.end.d.d)
        setStart(`${classStart.getHours()}:${classStart.getMinutes().toString().padStart(2, '0')}`)
        setEnd( `${classEnd.getHours()}:${classEnd.getMinutes().toString().padStart(2, '0')}`)
        setDate(day)
        handleShow()
    }
    const deleteEvent = () => {
        let confirmation = window.confirm("Está seguro que desea eliminar este evento?");
        if (!confirmation) {
            return;
        }
        if (event.start.d.d < tomorrow){
            alert("No se pueden cancelar clases con menos de 24 horas de antelación. En caso sea una emergencia, contacta con el coordinador académico.")
        }else{
            let tutor = {}
            for (const thisTutor of tutors){
                if (thisTutor.calendarId === event.calendarId){
                    tutor = thisTutor
                }
            }
            let studentIds = []
            let newStudents = []
            let subject = {}
            for (const thisClass of classes){
                if(thisClass._id == event.id){
                    studentIds = thisClass.studentIds
                }
            }
            for (const student of students){
                if (studentIds.includes(student._id)){
                    newStudents.push(student)
                }
            }
            for (const thisSubject of subjects){
                if (event.title.includes(thisSubject.name)){
                    subject = thisSubject
                }
            }
            axios.delete(`${process.env.REACT_APP_BACKENDURL}/class/newclass`,{data:{
                tutor:tutor,
                students:newStudents,
                subject:subject,
                parents:parents,
                event: {
                    id:event.id,
                    googleId:event.body,
                    start:event.start.d.d, //Not needed
                    end: event.end.d.d, //Not needed
                    calendarId:event.calendarId //Not needed
                }}
            }).then(response => {
                alert("Evento eliminado con éxito");
                window.location.reload(false)
            }).catch(err => {
                alert("Error al eliminar el evento"+ JSON.stringify(err));
                console.log(err)
            })
        }
    }
    const goNextWeek = (event) => {
        const calendarInstance = calendarRef.current.getInstance();
        today.setDate(today.getDate() + 7)
        tomorrow.setDate(today.getDate() + 1)
        nextWeek.setDate(today.getDate() + 7)
        todayMorning = new Date(today)
        todayMorning.setHours(0,0,0,0)
        dispatch(getClasses({filter:{studentIds:student._id, end:{"$lte":nextWeek} , start:{"$gte": todayMorning}}, selection:{}}))
        calendarInstance.next()

    }
    const goPrevWeek = () => {
        const calendarInstance = calendarRef.current.getInstance();
        today.setDate(today.getDate() - 7)
        tomorrow.setDate(today.getDate() + 1)
        nextWeek.setDate(today.getDate() + 7)
        todayMorning = new Date(today)
        todayMorning.setHours(0,0,0,0)
        dispatch(getClasses({filter:{studentIds:student._id, end:{"$lte":nextWeek} , start:{"$gte": todayMorning}}, selection:{}}))
        calendarInstance.prev()
    }
    return(
        <>
            <p><b>Dale click a la clase para ver todos sus detalles o cancelarla en caso lo necesites.</b></p>
            <br/>
            <Row>
                <Col>
                    <Button style={{marginLeft:'1%', backgroundColor:"rgba(10, 181, 145, 0.2)", borderColor:"rgba(10, 181, 145, 0.2)", color:"rgba(0,0,0)", "pointer-events": "none"}}>Pendiente</Button> 
                    <Button style={{marginLeft:'1%', backgroundColor:"rgba(10, 181, 145, 1)", borderColor:"rgba(10, 181, 145, 1)", color:"rgba(0,0,0)", "pointer-events": "none"}}>Confirmado</Button>
                    <Button onClick={goPrevWeek} style={{marginLeft:'1%',}}> <b>&#60;</b> </Button>
                    <Button onClick={goNextWeek} style={{marginLeft:'1%', }}> <b>&#62;</b> </Button>
                </Col>
            </Row>
            <br />
            <Card>
                <Calendar
                    height="500px"
                    view="week"
                    ref={calendarRef}
                    events={newClasses}
                    // onAfterRenderEvent={onAfterRenderEvent}
                    // onSelectDateTime={createEvent}
                    // onBeforeUpdateEvent={updateEvent}
                    onClickEvent={selectEvent}
                    week={{
                        taskView:false,
                        startDayOfWeek:today.getDay(),
                        eventView:['time'],
                        // dayNames:["Lunes", "Martes", "Miércoles", "Jueves", "Viernes", "Sábado", "Domingo"]
                    }}
                    isReadOnly={true}
                    // useFormPopup={true}
                    
                    // useDetailPopup={true}
                />
            </Card>
            <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                <Modal.Title>{event.title}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p><strong>Fecha:</strong> {date}</p>
                    <p><strong>Horario:</strong> {start} - {end}</p>
                    <p><strong>Estado: </strong> {selectedClass.pending ? "El tutor aún necesita confirmar la clase.":"El tutor confirmó la clase!"}</p>
                    {!selectedClass.pending &&
                        <>
                            <p><a href={`${window.location.origin}/call?id=${event.id}`} target="_blank">Link de clase</a></p>
                            <p><a href={`${window.location.origin}/studentfeedback?id=${event.id}`} target="_blank">Link de Feedback</a></p>
                        </>
                    }
                </Modal.Body>
                <Modal.Footer>
                <Button variant="secondary" onClick={handleClose}>
                    Cerrar
                </Button>
                <Button variant="danger" onClick={deleteEvent} >
                    Cancelar clase
                </Button>
                </Modal.Footer>
            </Modal>
            <br/>
        </>
    )
}

export default FutureClasses