import React, {useState, useEffect} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Select from 'react-select';
import {Col,Form,Row, Card} from 'react-bootstrap';
import NewClass from './NewClass';
import MoreHours from "./MoreHours"
import FamilyReferrals from "../AllUsers/FamilyReferrals"
import FamilyMonthReferrals from "../AllUsers/FamilyMonthReferrals"
import StudentClasses from './StudentClasses';
import { getFamily } from '../../../store/family/family.actions';
import FamilyHoursInfo from "../FamilyView/FamilyHoursInfo"
import StudentTutors from "./StudentTutors";

const StudentViewForm = () => {
    const {user} = useSelector((state) => state.auth)
    // const [option,setOption] = useState(<></>)
    const dispatch = useDispatch()
    // const options = [
    //     {label: "Ver Clases", value: <StudentClasses />},
    //     {label:"Reservar Clases", value: < NewClass />},
    //     {label:"Comprar Horas", value: <MoreHours />},
    //     // {label: "Feedback Clases Pasadas", value: <StudentPastClasses />},
    //     {label: "Ver familias referidas ", value: <FamilyReferrals />}, 
    //     {label: "Mira los perfiles de los tutores!", value: <StudentTutors/>}
    //     // {label:"Primera Clase", value: <FirstClassForm/>}
    // ] 
    const {family} = useSelector((state) => state.family);

    //Getting family
    useEffect(() => {
        dispatch(getFamily({id:user.family}));
    }, [])
    return(
        <>
                <br/>
                <Card style={{ width: '50%','margin-left':'auto','margin-right':'auto' }}>
                    <br/>
                    <h5>Hola <b>{user.name.split(" ")[0]}</b>!</h5>
                    <br/>
                    <FamilyMonthReferrals />
                    <br/>
                    <p>Comparte este código con tus amigos y reciban <b>$10 USD</b> de descuento cuando ellos compren sus primeras <b>4 horas</b> de clase!</p>
                    <p style={{color:"#0AB591"}}><u><b>{family.referral_id}</b></u></p>
                    <br/>
                </Card>
                <br/>
                <br/>
                <FamilyHoursInfo/>

            {/* <br/>
            <br/>
            <p>Hola <b>{user.name.split(" ")[0]}</b>, a tu familia le quedan <b>{family.hours}</b> horas de clase</p>
            <br/> */}
            
            {/* <br/>
            <p>Total a pagar este: <b>{10*families.length} USD</b></p> */}
            <br />
            {/* <Row className="justify-content-md-center"> 
                <Form.Group as={Col} lg="4" controlId="type">
                <Form.Control
                    as={Select}
                    placeholder="¿Qué quieres hacer?"
                    options={options}
                    onChange={opt => setOption(opt.value)}
                />
                </Form.Group>
            </Row>
            <br />
            {option} */}
        </>
    )
}

export default StudentViewForm