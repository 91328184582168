import React, {useState, useEffect} from "react"
import { useSelector } from "react-redux"
import { useNavigate } from "react-router"
import StudentPastReports from "../../components/forms/TutorView/StudentPastReports"
const Tutors = () => {
    const {user} = useSelector((state) => state.auth)
    const [option,setOption] = useState(<></>)
    const navigate = useNavigate()
    useEffect(() => {
        if(user.role === "Tutor"){
            setOption(<StudentPastReports />)
        }else{
            navigate("/")
        }
    }, [])
    return (
        <>  
            <br/>
            <h1>REPORTES DE OTROS TUTORES</h1>
            <br/>
            {option}
        </>
    )
}

export default Tutors