import React, {useState, useEffect} from 'react';
import { useSelector } from 'react-redux';
import axios from 'axios';
import Form from 'react-bootstrap/Form';
import {Row, Col, Card, Button} from 'react-bootstrap';
import Select from 'react-select';
const COLORS = {
    XPLAIN_GREEN: "#0AB591",
}
const AddUserToFamily = ({isStudent, familyId}) => {
    const [name, setName] = useState('');
    const [surname, setSurname] = useState('');
    const [email, setEmail] = useState('');
    const [disabled, setDisabled] = useState(false);
    const handleSubmit = async (e) => {
        try {
            setDisabled(true);
            let response = await axios.post(`${process.env.REACT_APP_BACKENDURL}/family/addFamilyMember`, {
                user: {
                    name: name,
                    surname: surname,
                    email: email,
                    family: familyId
                },
                isStudent: isStudent,
            });
            console.log(response.data);
            alert("Se creo correctamente! Se le acaba de enviar datos al email.");
            window.location.reload();
        } catch (error) {
            console.log(error);
            alert("Hubo un error :(")
        }
    }
    return (
        <Form noValidate onSubmit={(evt) => {
            evt.preventDefault();
            handleSubmit()
        }}>
            <div style={{display:"flex", flex:1, flexDirection:"column"}}>

        <Card style={{
            // color:"white",
            borderWidth: 2,
            borderColor: COLORS.XPLAIN_GREEN,
            borderRadius: 10, 
            flex:1,
            // padding: 5,
            margin: 10,
            width:"50%",
            height:"100%",
            alignSelf:"center",
        }}
        // className="justify-content-md-center"
        >
        <Card.Header style={{backgroundColor:COLORS.XPLAIN_GREEN, color:"white"}}><h1>{isStudent ? "Estudiante": "Padre"}</h1></Card.Header>
        <Card.Body style={{display:"flex", flexDirection:"column", alignItems:'center', flex:1}}>
        {/* <Row style={{display:"flex", flex:1}} className="justify-content-md-center"> */}
            <Form.Group as={"div"} className="mb-3" controlId="name" style={{flex: 1, width:"60%"}}>
                <Form.Label><b>Nombre</b></Form.Label>
                <Form.Control
                    required
                    type="text"
                    placeholder="Nombre"
                    onChange={e => {
                        setName(e.target.value);
                    }}
                />
                <Form.Control.Feedback>Todo bien!</Form.Control.Feedback>
            </Form.Group>
            <Form.Group as={"div"} className="mb-3" controlId="surname" style={{flex: 1, width:"60%"}}>
            <Form.Label><b>Apellido(s)</b></Form.Label>
            <Form.Control
                required
                type="text"
                placeholder="Apellido(s)"
                onChange={e => {
                    setSurname(e.target.value);
                }}

            />
            <Form.Control.Feedback>Todo bien!</Form.Control.Feedback>
            </Form.Group>
        {/* </Row> */}
        {/* <Row className="justify-content-md-center"> */}
            <Form.Group as={"div"} className="mb-3" controlId="name" style={{flex: 1, width:"60%"}}>
            <Form.Label><b>Email</b></Form.Label>
            <Form.Control
                required
                type="email"
                onChange={e => {
                    setEmail(e.target.value);
                }}
                placeholder="Gmail"
            />
            <Form.Control.Feedback>Todo bien!</Form.Control.Feedback>
            </Form.Group>
            <Button style={{backgroundColor: COLORS.XPLAIN_GREEN, border: 0}} variant="primary" disabled={disabled} type="submit">
                <h3>Unirse a familia</h3>
            </Button>
            {/* <Form.Group as={"div"} className="mb-3" controlId="surname" style={{flex:1}}>
            <Form.Label><b>Whatsapp</b></Form.Label>

            <PhoneInput
                inputProps={{required:true}}
                onChange={phone => {
                    newStudent["phone"] = "+" + phone
                    dispatch(setStudent(newStudent))
                }}  
                enableSearch={true}
            /> 
            <Form.Control.Feedback>Todo bien!</Form.Control.Feedback>
            </Form.Group> */}
            {/* <Form.Group as={"div"}  className="mb-3" controlId="school" style={{flex: 1, width:"60%"}}>
            <Form.Label><b>Colegio</b></Form.Label>
            <Form.Control
                required
                type="text"
                placeholder="Colegio"
                onChange={e => {
                    newStudent["school"] = e.target.value
                    dispatch(setStudent(newStudent))
                }}
            />
            <Form.Control.Feedback>Todo bien!</Form.Control.Feedback>
            </Form.Group>

            <Form.Group as={"div"} className="mb-3" controlId="country" style={{flex: 1, width:"60%"}}>
            <Form.Label><b>País del colegio</b></Form.Label>
            <Form.Control
                as={Select}
                required
                options={countries}
                onChange={(opt) => {
                    newStudent["country"] = opt.value
                    dispatch(setStudent(newStudent))
                }}
                placeholder="País"
            />
            <Form.Control.Feedback>Todo bien!</Form.Control.Feedback>
            </Form.Group> */}
        {/* </Row> */}
        </Card.Body>
        </Card>
        </div>
        </Form>
    )
}
// const AddParentToFamily = ({familyId}) => {
//     const [name, setName] = useState('');
//     const [surname, setSurname] = useState('');
//     const [email, setEmail] = useState('');
//     const [disabled, setDisabled] = useState(false);
//     return (
//         <Card style={{
//             borderWidth: 2,
//             borderColor:COLORS.XPLAIN_GREEN, 
//             borderRadius: 10, 
//             flex:1,
//             margin: 10,
//             width: "50%",
//             alignSelf:"center",
//         }}>
//             <Card.Header style={{backgroundColor:COLORS.XPLAIN_GREEN, color:"white"}}><h1>Padre/Madre</h1></Card.Header>
//             <Card.Body style={{display:"flex", flexDirection:"column", alignItems:'center'}}>
//         {/* <Row className="justify-content-md-center"> */}
//             <Form.Group  style={{flex: 1, width:"60%"}} className="mb-3" controlId="name">
//             <Form.Label><b>Nombre</b></Form.Label>
//             {/* <br/> */}
//             {/* <br/> */}
//             <Form.Control
//                 required
//                 type="text"
//                 placeholder="Nombre"
//                 onChange={e => {setName(e.target.value)}}
//             />
//             <Form.Control.Feedback>Todo bien!</Form.Control.Feedback>
//             </Form.Group>

//             <Form.Group as={"div"} style={{flex: 1, width:"60%"}} className="mb-3" controlId="surname">
//             <Form.Label><b>Apellido(s)</b></Form.Label>
//             {/* <br/> */}
//             {/* <br/> */}
//             <Form.Control
//                 required
//                 type="text"
//                 placeholder="Apellido(s)"
//                 onChange={e => {
//                     setSurname(e.target.value);
//                 }}

//             />
//             <Form.Control.Feedback>Todo bien!</Form.Control.Feedback>
//             </Form.Group>
//         {/* </Row> */}
//         {/* <br/> */}
//         {/* <br/> */}
//         {/* <Row className="justify-content-md-center"> */}
//             <Form.Group as={"div"} style={{flex: 1, width:"60%"}} className="mb-3" controlId="name">
//             <Form.Label><b>Email</b></Form.Label>
//             <br/>
//             <Form.Control
//                 required
//                 type="email"
//                 onChange={e => {
//                     setEmail(e.target.value);
//                 }}
//                 placeholder="Email"
//             />
//             <Form.Control.Feedback>Todo bien!</Form.Control.Feedback>
//             </Form.Group>
//             <Button style={{backgroundColor: COLORS.XPLAIN_GREEN, border: 0}} variant="primary" disabled={disabled} type="submit">
//                 <h3>Agregar a familia</h3>
//             </Button>
//             {/* <Form.Group as={"div"} style={{flex: 1}} className="mb-3" controlId="surname">
//             <Form.Label><b>Whatsapp</b></Form.Label>

//             <PhoneInput
//                 inputProps={{required:true}}
//                 onChange={phone => {
//                     newParent["phone"] = "+" + phone
//                     dispatch(setParent(newParent))
//                 }}  
//                 enableSearch={true}
//                 style={{alignItems: 'center', alignSelf: 'center', display: 'flex'}}
//             /> 
//             <Form.Control.Feedback>Todo bien!</Form.Control.Feedback>
//             </Form.Group> */}
//         </Card.Body>
//         </Card>
//     )
// }
const JoinFamilyForm = ({familyId}) => {
    const [isLoading, setIsLoading] = useState(true);
    const [isValid, setIsValid] = useState(false);
    const [isStudent, setIsStudent] = useState(null); //This assumes only student OR parent

    const [family, setFamily] = useState({});

    const getFamily = async () => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_BACKENDURL}/family/`, {params: {id: familyId}});
            console.log(response)
            setFamily(response.data);
            setIsValid(true);
            setIsLoading(false);
        } catch (error) {
            setIsValid(false)
            setIsLoading(false);
        }
    }
    useEffect(() => {
        getFamily();
    }, []);
    return (
        isLoading ? <div>Cargando...</div>:
        !isValid ? <div>Id {familyId} no es un id valido</div>:
        <div>
            <h1>Unirse a la familia {family.surname}</h1>
            <Row className="justify-content-md-center">
                <Col  xs={5}>
                    <Form.Control
                        as={Select}
                        options={[
                            {label: "Estudiante", value: true},
                            {label: "Padre/Madre", value: false},
                        ]}
                        placeholder="Tipo de usuario"
                        onChange={opt => setIsStudent(opt.value)}
                        // value={isStudent}
                        // label={isStudent? "Estudiante" : "Padre"}
                    />
                </Col>
            </Row>
            <div style={{display:"flex", flex:1, flexDirection:"column"}}>

            {
                isStudent === null ? <div>Escoga un tipo de usuario</div> :
                <AddUserToFamily
                    isStudent={isStudent}
                    familyId={familyId}
                />
            }
            </div>
            {/* <p>Padre</p> */}

        </div>
    )
}

export default JoinFamilyForm;