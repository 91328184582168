import React, {useState} from 'react';
import CalStudentClasses from './CalStudentClasses';
import TableStudentClasses from './TableStudentClasses';
import {Form, Row,Col} from "react-bootstrap"

const StudentClasses = () => {
    const [checked,setChecked] = useState(false)
    return (
        <>
            {checked
                ?
                <>
                    <Row className="justify-content-md-center">
                        <Col  xs={2}>
                            <Form.Check 
                                type="switch"
                                id="custom-switch"
                                label="Ver calendario"
                                checked={checked}
                                onClick ={() => setChecked(!checked)}
                            />
                        </Col>
                    </Row>
                    
                    
                    <br/>
                    <TableStudentClasses />
                </>
                :
                <>
                    <Row className="justify-content-md-center">
                        <Col  xs={2}>
                            <Form.Check 
                                type="switch"
                                id="custom-switch"
                                label="Ver tabla"
                                checked={checked}
                                onClick ={() => setChecked(!checked)}
                            />
                        </Col>
                    </Row>
                    <br/>
                    <CalStudentClasses />
                </>
            }
        </>
    )
}

export default StudentClasses