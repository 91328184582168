import React from "react"
import ProfileForm from "./ProfileForm"


const ProfilePage = () => {
    return (
        <div>
            {/* <Image fluid={true} src={require("../../assets/images/logo.png")}/> */}
            <br/>
            <ProfileForm />
            
        </div>
    )
}

export default ProfilePage