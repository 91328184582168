import React from "react"
import ReportForm from "./ReportForm"
const ReportPage = () => {
    return (
        <div>
            <ReportForm />
        </div>
    )
}

export default ReportPage