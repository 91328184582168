import React, {useState, useEffect} from "react"
import { useSelector } from "react-redux"
import { useNavigate } from "react-router"
import StudentTutors from "../../components/forms/StudentView/StudentTutors"
import ParentTutors from "../../components/forms/ParentView/ParentTutors"
const Tutors = () => {
    const {user} = useSelector((state) => state.auth)
    const [option,setOption] = useState(<></>)
    const navigate = useNavigate()
    useEffect(() => {
        if(user.role === "Student" || user.role === "Tutor"){
            setOption(<StudentTutors />)
        }else if(user.role === "Parent" ){
            setOption(<ParentTutors />)
        }else{
            navigate("/")
        }
    }, [])
    return (
        <>  
            <br/>
            <h1>TUTORES</h1>
            <br/>
            {option}
        </>
    )
}

export default Tutors