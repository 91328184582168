import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

export const getStudents = createAsyncThunk(
    'student/getStudents',
    async (data) => {
        const response = await axios.get(`${process.env.REACT_APP_BACKENDURL}/student/all`, {params:data})
        const students = response['data']
        return students
    }
)

export const getStudent = createAsyncThunk(
    'student/getStudent',
    async (data) => {
        const response = await axios.get(`${process.env.REACT_APP_BACKENDURL}/student`, {params:data})
        const student = response['data']
        return student
    }
)