import axios from "axios";

export const Axios = axios.create({
  baseURL: process.env.REACT_APP_BACKENDURL,
  headers: {
    "Content-Type": "application/json",
  },
});

export async function createStrike(data) {
  try {
    const response = await Axios.post("/strike/", data);
    if (response.status !== 200) {
      console.error(response);
      return;
    }
    return response.data;
  } catch (err) {
    console.error(err);
    return;
  }
}

export async function getStrikes(filter = {}, selection = {}) {
  let params = { filter, selection };
  try {
    const response = await Axios.get("/strike/all", { params });
    if (response.status !== 200) {
      console.error(response);
      return;
    }
    return response.data;
  } catch (err) {
    console.error(err);
    return;
  }
}

export async function updateStrike(strikeId, update) {
  const response = await Axios.patch("/strike/", { id: strikeId, update });
  return response?.data;

  //   try {
  //     const response = await Axios.patch("/strike/", { id: strikeId, update });
  //     if (response.status !== 200) {
  //       console.error(response);
  //       return;
  //     }
  //     return response.data;
  //   } catch (err) {
  //     console.error(err);
  //     return;
  //   }
}

export async function deleteStrike(userId, strikeId) {
  let data = { userId, strikeId };
  const response = await Axios.delete("/strike/", { data });
  return response?.data;

  //   try {
  //     const response = await Axios.delete("/strike/", { userId, strikeId });
  //     if (response.status !== 200) {
  //       console.error(response);
  //       return;
  //     }
  //     return response.data;
  //   } catch (err) {
  //     console.error(err);
  //     return;
  //   }
}
