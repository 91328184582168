import React, { useState, useEffect } from "react";
import Select from "react-select";
import { useDispatch, useSelector } from "react-redux";
import { Form, Col, Row, Button } from "react-bootstrap";
import { getFamilies } from "../../../store/family/family.actions";
import { setFamilies, setFamily } from "../../../store/family/family.slice";
import { setParent, setParents } from "../../../store/parent/parent.slice";
import { setStudents } from "../../../store/student/student.slice";
import axios from "axios";
import { getStudents } from "../../../store/student/student.actions";
import { getParents } from "../../../store/parent/parent.actions";
import Table from "react-bootstrap/Table";

import { listFormat } from "../../../name_utils";

// const listFormat = (array) => {
//     let newArray = []
//     for (const elem of array){
//         let newElem = {...elem}
//         if ('surname' in newElem){
//             newElem['label'] = elem['name'] + " " + elem['surname']
//         }else{
//             newElem['label'] = elem['name']
//         }
//         newElem['value'] = elem['_id']
//         newArray.push(newElem)
//     }
//     return newArray
// }
const MilisecondsPerMinute = 60000;

export const addMinutes = (start, minutes) => {
  return new Date(start.getTime() + minutes * MilisecondsPerMinute);
};
const THREE_MONTHS = 3 * 30 * 24 * 60;

const AllFamiliesForm = () => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getFamilies({ filter: {}, selection: {} }));
    dispatch(getStudents({ filter: {}, selection: {} }));
    dispatch(getParents({ filter: {}, selection: {} }));
  }, []);
  const { students, selectedStudents } = useSelector((state) => state.student);
  const { parents, selectedParents } = useSelector((state) => state.parent);
  const { family, families, selectedFamilies } = useSelector(
    (state) => state.family
  );
  const newFamily = { ...family };
  const [edit, setEdit] = useState(false);
  const [disabled, setDisabled] = useState(false);
  const [isInvalid, setIsInvalid] = useState(false);
  const [newAdminNote, setNewAdminNote] = useState("");
  const [addDays, setAddDays] = useState(0);

  const handleSubmit = (event) => {
    console.log(event);
    event.preventDefault();
    const form = event.currentTarget;
    console.log(form);
    //TODO: Check why form.checkValidity() returns false when changing hours of a family
    if (false && form.checkValidity() === false) {
      alert("No se mandaron los campos :(");
      event.stopPropagation();
      setIsInvalid(true);
    } else {
      alert("Campos validos, mandando...");
      // let answer = window.confirm("Estás seguro quieres actualizar esta info?");
      setIsInvalid(false);
      setDisabled(true);
      delete newFamily._id;
      delete newFamily.label;
      delete newFamily.value;
      if (newFamily.lastTimePurchase && addDays !== 0) {
        let future = addMinutes(
          new Date(newFamily.lastTimePurchase),
          addDays * 24 * 60
        );
        newFamily.lastTimePurchase = future;
      }
      axios
        .patch(`${process.env.REACT_APP_BACKENDURL}/family`, {
          id: family._id,
          update: newFamily,
          newAdminNote: newAdminNote,
        })
        .then((response) => {
          alert("Se enviaron los campos correctamente!");
          window.location.reload(false);
        })
        .catch((error) => {
          alert(error);
          console.log(error);
        });
      // if(answer){

      // }
    }
  };
  const familyData = Object.keys(newFamily).map((key, index) => {
    if (
      key == "_id" ||
      key == "role" ||
      key == "isReferred" ||
      key == "password"
    ) {
      return (
        <>
          <Row className="justify-content-md-center">
            <Form.Group as={Col} lg="2" controlId={key}>
              <Form.Label>{key}</Form.Label>
              <Form.Control
                type="text"
                disabled
                value={newFamily[key]}
                readOnly={!edit}
              />
              <Form.Control.Feedback>Todo bien!</Form.Control.Feedback>
            </Form.Group>
          </Row>
          <br />
        </>
      );
    } else if (key == "__v" || key == "label" || key == "value") {
      return <></>;
    } else if (key === "lastTimePurchase") {
      return (
        <>
          <Row className="justify-content-md-center">
            <Form.Group as={Col} lg="2" controlId={key}>
              <Form.Label>{key}</Form.Label>
              <Form.Control
                type="text"
                disabled
                value={newFamily[key]}
                readOnly={!edit}
              />
              <Form.Control.Feedback>Todo bien!</Form.Control.Feedback>
            </Form.Group>
            {newFamily[key] && (
              <>
                <div>
                  Las horas de esta familia se vencerán 90 días despues en{" "}
                  {addMinutes(
                    new Date(newFamily[key]),
                    THREE_MONTHS
                  ).toString()}
                </div>
              </>
            )}
            {edit && (
              <>
                <Form.Group as={Col} lg="2" controlId={key}>
                  <br />
                  <Form.Label>
                    Por cuantos dias quieres extender la fecha de vencimiento?
                  </Form.Label>
                  <Form.Control
                    type="number"
                    inputMode="numeric"
                    value={addDays}
                    onChange={(e) => setAddDays(Number(e.target.value))}
                  />
                </Form.Group>

                {addDays !== 0 && (
                  <div style={{ color: "green" }}>
                    Como si hubiese hecho una compra en{" "}
                    {addMinutes(
                      new Date(newFamily[key]),
                      addDays * 24 * 60
                    ).toString()}
                    <br /> <br />
                    Nueva fecha de vencimiento sería en{" "}
                    {addMinutes(
                      new Date(newFamily[key]),
                      THREE_MONTHS + addDays * 24 * 60
                    ).toString()}
                  </div>
                )}
              </>
            )}
          </Row>
          <br />
        </>
      );
    } else if (key === "adminNotes") {
      return (
        <></> //its under everything
      );
    } else if (key == "students" || key == "parents" || key == "referrals") {
      let value;
      let options;
      let func;
      if (key == "students") {
        value = [...selectedStudents];
        options = students;
        func = setStudents;
      } else if (key == "parents") {
        value = [...selectedParents];
        options = parents;
        func = setParents;
      } else {
        value = [...selectedFamilies];
        options = families;
        func = setFamilies;
      }
      return (
        <>
          <Row className="justify-content-md-center">
            <Form.Group as={Col} lg="3" controlId="surname">
              <Form.Label>{key}</Form.Label>
              <br />
              <br />
              <Form.Control
                as={Select}
                required
                isMulti={true}
                value={listFormat(value)}
                options={listFormat(options)}
                isDisabled={!edit}
                onChange={(opt) => {
                  let newValues = [];
                  for (const value of opt) {
                    newValues.push(value.value);
                  }
                  newFamily[key] = newValues;
                  dispatch(func(opt));
                  dispatch(setFamily(newFamily));
                }}
                placeholder={key}
              />
              <Form.Control.Feedback>Todo bien!</Form.Control.Feedback>
            </Form.Group>
          </Row>
          <br />
        </>
      );
    } else {
      return (
        <>
          <Row className="justify-content-md-center">
            <Form.Group as={Col} lg="2" controlId={key}>
              <Form.Label>{key}</Form.Label>
              <Form.Control
                type="text"
                value={newFamily[key]}
                readOnly={!edit}
                onChange={(e) => {
                  newFamily[key] = e.target.value;
                  dispatch(setFamily(newFamily));
                }}
              />
              <Form.Control.Feedback>Todo bien!</Form.Control.Feedback>
            </Form.Group>
          </Row>
          <br />
        </>
      );
    }
  });
  return (
    <Form noValidate validated={isInvalid} onSubmit={handleSubmit}>
      <Row className="justify-content-md-center">
        <Form.Group as={Col} lg="4" controlId="student">
          <Form.Label>Escoja a la familia</Form.Label>
          <br />
          <br />
          <Form.Control
            as={Select}
            options={listFormat(families)}
            required
            onChange={(opt) => {
              dispatch(setFamily(opt));
              let newStudents = [];
              let newParents = [];
              let newFamilies = [];
              for (const studentId of opt.students) {
                for (const student of students) {
                  if (student._id == studentId) {
                    newStudents.push(student);
                  }
                }
              }
              for (const parentId of opt.parents) {
                for (const parent of parents) {
                  if (parent._id == parentId) {
                    newParents.push(parent);
                  }
                }
              }
              for (const familyId of opt.referrals) {
                for (const family of families) {
                  if (family._id == familyId) {
                    newFamilies.push(family);
                  }
                }
              }
              dispatch(setStudents(newStudents));
              dispatch(setParents(newParents));
              dispatch(setFamilies(newFamilies));
            }}
          />
        </Form.Group>
      </Row>
      <br />
      {newFamily._id == "" ? (
        <></>
      ) : (
        <>
          {familyData}
          {family && (
            <Row
              className="justify-content-md-center"
              style={{ width: "50%", marginLeft: "auto", marginRight: "auto" }}
            >
              <h3>Notas de admin</h3>
              {
                <Table striped bordered hover>
                  <thead>
                    <tr>
                      {/* <th>#</th> */}
                      <th>Nota</th>
                      <th>Cuando</th>
                    </tr>
                  </thead>
                  <tbody style={{ verticalAlign: "middle" }}>
                    {family.adminNotes &&
                      family["adminNotes"].map((note) => (
                        <tr key={note.timestamp}>
                          <td>{note.text}</td>
                          <td>{new Date(note.timestamp).toLocaleString()}</td>
                        </tr>
                      ))}
                  </tbody>
                </Table>
              }
            </Row>
          )}
          {edit ? (
            <>
              <Row className="justify-content-md-center">
                <Form.Group as={Col} lg="2" controlId={"adminNote"}>
                  <Form.Label>Nueva nota para la familia</Form.Label>
                  <Form.Control
                    type="text"
                    value={newAdminNote}
                    readOnly={!edit}
                    onChange={(e) => {
                      setNewAdminNote(e.target.value);
                    }}
                  />
                  <Form.Control.Feedback>Todo bien!</Form.Control.Feedback>
                </Form.Group>
              </Row>
              <Row className="justify-content-md-center">
                <Col lg="2">
                  <Button type="submit" variant="success" disabled={disabled}>
                    Enviar
                  </Button>
                </Col>
                <Col lg="2">
                  <Button
                    variant="danger"
                    disabled={disabled}
                    onClick={() => setEdit(false)}
                  >
                    Cancelar
                  </Button>
                </Col>
              </Row>
            </>
          ) : (
            <Button variant="success" onClick={() => setEdit(true)}>
              Editar
            </Button>
          )}
        </>
      )}
    </Form>
  );
};

export default AllFamiliesForm;
