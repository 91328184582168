import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { editedClass, selectClass } from "../../../store/class/class.slice";
import axios from "axios";
import { Col, Form, Row } from "react-bootstrap";
import { Button } from "react-bootstrap";

const DeleteClassForm = () => {
  const { _id, edited } = useSelector((state) => state.class);
  const refresh_token = localStorage.getItem("googleRefreshToken");
  const dispatch = useDispatch();
  const [disabled, setDisabled] = useState(false);
  const [isInvalid, setIsInvalid] = useState(false);
  const { user } = useSelector((state) => state.auth);
  const [cancelReason, setCancelReason] = useState("");
  const [classId, setClassId] = useState("");

  const handleSubmit = (event) => {
    event.preventDefault();
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.stopPropagation();
      setIsInvalid(true);
    } else {
      let answer = window.confirm(
        "Estás seguro que quieres eliminar la clase?"
      );
      setIsInvalid(false);
      setDisabled(true);
      if (answer) {
        let user_who_canceled = {
          _id: user._id,
          name: user.name,
          role: user.role,
          surname: user.surname,
          phone: user.phone,
        };
        // Removing \n \s \t in acse they come from copying-and-pasting the id
        let validClassId = classId.replace(/^\s+|\s+$/g, "");

        axios
          .delete(`${process.env.REACT_APP_BACKENDURL}/class`, {
            data: {
              id: validClassId,
              info: { user_who_canceled, cancelReason },
            },
          })
          .then((response) => {
            alert("La clase ha sido eliminada");
            window.location.reload(false);
          })
          .catch((error) => {
            alert(
              "Ha ocurrido un error al eliminar la clase" +
                JSON.stringify(error)
            );
            console.log(error);
          });
      }
    }
  };
  return (
    <Form noValidate validated={isInvalid} onSubmit={handleSubmit}>
      <Row className="justify-content-md-center">
        <Form.Group as={Col} lg="4" controlId="id">
          <Form.Label>
            <b>Cual es el id de la clase?</b>
          </Form.Label>
          <br />
          <br />
          <Form.Control
            // onChange={(e) => dispatch(selectClass(e.target.value))}
            type="text"
            value={classId}
            onChange={(e) => setClassId(e.target.value)}
          />
          <Form.Control.Feedback>Todo bien!</Form.Control.Feedback>
        </Form.Group>
      </Row>
      <br />

      <Row className="justify-content-md-center">
        <Form.Group as={Col} lg="4" controlId="reason">
          <Form.Label>
            <b>Por qué se está cancelando esta clase?</b>
          </Form.Label>
          <br />
          <Form.Control
            type="text"
            value={cancelReason}
            onChange={(e) => setCancelReason(e.target.value)}
          />
          <Form.Control.Feedback>Todo bien!</Form.Control.Feedback>
        </Form.Group>
      </Row>
      <br />
      <br />
      {!(classId === "") && classId.length > 23 && cancelReason ? (
        <Button type="submit" variant="danger" disabled={disabled}>
          Eliminar clase
        </Button>
      ) : (
        <></>
      )}
    </Form>
  );
};

export default DeleteClassForm;
