import React, {useState,useEffect} from 'react';
import axios from 'axios';
import {Form, Row, Col, Button} from "react-bootstrap"
import { getStudent } from '../../store/student/student.actions';
import { getTutor } from "../../store/tutor/tutor.actions";
import {getSubject} from "../../store/subject/subject.actions";
import { useDispatch, useSelector } from 'react-redux';
import {encodeFeedback} from '../../format_feedback';

const TutorFeedbackForm = () => {
    const dispatch = useDispatch()
    const [isInvalid, setIsInvalid] = useState(false);
    const [topic,setTopic] = useState("")
    const [oldHw,setOldHw] = useState("Sí")
    const [newHw,setNewHw] = useState("")
    const [feedback,setFeedback] = useState("")
    const [extra,setExtra] = useState("")
    const [question,setQuestion] = useState("")
    const [id, setId] = useState("")
    const [disabled,setDisabled] = useState(false)
    const [submitted, setSubmitted] = useState(false)
    const {student} = useSelector((state) => state.student)
    const {tutor} = useSelector((state) => state.tutor)
    const {subject} = useSelector((state) => state.subject)
    const [start,setStart] = useState("")
    const [end,setEnd] = useState("")
    const [date,setDate] = useState("")
    useEffect(() => {
        let params = new URLSearchParams(window.location.search)
        let _id = params.get("id")
        if (typeof _id === "string" || _id instanceof String ){
            axios.get(`${process.env.REACT_APP_BACKENDURL}/class`, {
                params: {id:_id}
            }).then((response) => {
                let newStart = new Date(response.data.start)
                let newEnd = new Date(response.data.end)
                setDate(`${(newStart.getDate()).toString().padStart(2, '0')}/${(newStart.getMonth() + 1).toString().padStart(2, '0')}`)
                setStart(`${newStart.getHours().toString().padStart(2, '0')}:${newStart.getMinutes().toString().padStart(2, '0')}`)
                setEnd(`${newEnd.getHours().toString().padStart(2, '0')}:${newEnd.getMinutes().toString().padStart(2, '0')}`)
                dispatch(getStudent({id:response.data.studentIds[0]}))
                dispatch(getTutor({id:response.data.tutorId}))
                dispatch(getSubject({id:response.data.subjectId}))
                setId(_id)
            })
        }
    }, [])
    const handleSubmit = (event) => {
        event.preventDefault()
        const form = event.currentTarget;
        if (form.checkValidity() === false) {
            event.stopPropagation();
            setIsInvalid(true)
        }else{
            setIsInvalid(false);
            setDisabled(true)
            let tutorFeedback = `-Los temas de esta clase y los que se verán en la siguiente clase:\n\n${encodeFeedback(topic)}\n\n-¿Se completaron las tareas para esta clase?:\n\n${encodeFeedback(oldHw)}\n\n-La nueva tarea para el Xplainer:\n\n${encodeFeedback(newHw)}\n\n-Lo que se le dió bien al Xplainer y en lo que puede mejorar:\n\n${encodeFeedback(feedback)}\n\n-Preguntas que puedes hacerle al Xplainer para ver cuanto ha progresado:\n\n${encodeFeedback(question)}\n\n-Información adicional:\n\n${encodeFeedback(extra)}`
            let update = {"tutorFeedback":tutorFeedback}
            let data = {"id": id, "update":update, tutor:tutor, student:student, subject:subject}
            axios.patch(`${process.env.REACT_APP_BACKENDURL}/class/tutorfeedback`,data).then(response => {
                setSubmitted(true)
                console.log(response)
            }).catch(error => console.log(error))
        }
    }
    return(
        <>
            {!submitted
            ?
                <Form noValidate validated={isInvalid} onSubmit={handleSubmit}>
                    <br />
                    <br />
                    <h1><b>Reporte de clases para {student.name} {student.surname}</b></h1>
                    <br />
                    <p><b>Materia: </b> {subject.name}</p>
                    <p><b>Fecha: </b>{date}</p>
                    <p><b>Horario: </b> {start} - {end}</p>
                    <p>Hola tutor Xplainer, por favor completa este reporte para conocer el desarrollo del alumno!</p>
                    <p>Mientras seas mas detallado, nuestros alumnos podrán mejorar mucho más!</p>
                    {/* <br />
                    <br />
                    <Row className="justify-content-md-center">
                        <Form.Group as={Col} lg="2" controlId="id">
                        <Form.Label><b>Id de la clase (En Google Calendar)</b></Form.Label>
                        <br/>
                        <br/>
                        <Form.Control
                            required
                            type="text"
                            maxlength="24"
                            placeholder="62d9#############"
                            value={id}
                            onChange={e => setId(e.target.value)}
                        />
                        <Form.Control.Feedback>Todo bien!</Form.Control.Feedback>
                        </Form.Group>
                    </Row> */}
                    <br />
                    <Row className="justify-content-md-center">
                        <Form.Group as={Col} lg="6" controlId="feedback">
                        <Form.Label><b>¿Qué temas se estudiaron y que temas se estudiarán?</b></Form.Label>
                        <br/>
                        <br/>
                        <Form.Control
                            as="textarea"
                            required
                            onChange={e => setTopic(e.target.value)}
                        />
                        {/* <Form.Control.Feedback type="valid">Todo bien!</Form.Control.Feedback> */}
                        <Form.Control.Feedback type="invalid">
                            Respuestas es necesaria
                        </Form.Control.Feedback>
                        </Form.Group>
                    </Row>
                    <br />
                    <br />
                    <Row className="justify-content-md-center">
                        <Form.Group as={Col} lg="2" controlId="feedback">
                        <Form.Label><b>¿Se hicieron las tareas?</b></Form.Label>
                        <br/>
                        <br/>
                        <Form.Control
                            required
                            as="select"
                            onChange={e => setOldHw(e.target.value)}
                            value={oldHw}
                        >
                            <option value="Sí">Sí</option>
                            <option value="No">No</option>
                            <option value="No se dejaron tareas la clase pasada">No se dejaron tareas la clase pasada</option>
                        </Form.Control>
                        <Form.Control.Feedback type="invalid">
                            Respuestas es necesaria
                        </Form.Control.Feedback>
                        </Form.Group>
                    </Row>
                    <br />
                    <br />
                    <Row className="justify-content-md-center">
                        <Form.Group as={Col} lg="6" controlId="feedback">
                        <Form.Label><b>¿Qué nuevas tareas se ha dejado al estudiante?</b></Form.Label>
                        <br/>
                        <br/>
                        <Form.Control
                            required
                            as="textarea"
                            onChange={e => setNewHw(e.target.value)}
                        />
                        <Form.Control.Feedback type="invalid">
                            Respuestas es necesaria
                        </Form.Control.Feedback>
                        </Form.Group>
                    </Row>
                    <br />
                    <br />
                    <Row className="justify-content-md-center">
                        <Form.Group as={Col} lg="6" controlId="feedback">
                        <Form.Label><b>¿Qué se le ha dado bien al estudiante? <br /> ¿Y en qué cosas puede mejorar el estudiante?</b></Form.Label>
                        <br/>
                        <br/>
                        <Form.Control
                            as="textarea"
                            required
                            onChange={e => setFeedback(e.target.value)}
                        />
                        <Form.Control.Feedback type="invalid">
                            Respuestas es necesaria
                        </Form.Control.Feedback>
                        </Form.Group>
                    </Row>
                    <br />
                    <br />
                    <Row className="justify-content-md-center">
                        <Form.Group as={Col} lg="6" controlId="feedback">
                        <Form.Label><b>3 preguntas variadas acerca del TEMA ESTUDIADO DE HOY con sus respuestas.</b></Form.Label>
                        <p>Por ejemplo, si han hecho hoy trigonometría, una de las preguntas puede ser:<br /> "Cuanto es el coseno de 90? La respues esta  0".<br/> Aségurate que sean preguntas que el estudiante pueda responder despues de esta clase.</p>
                        <br/>
                        <Form.Control
                            as="textarea"
                            required
                            onChange={e => setQuestion(e.target.value)}
                        />
                        <Form.Control.Feedback type="invalid">
                            Respuestas es necesaria
                        </Form.Control.Feedback>
                        </Form.Group>
                    </Row>
                    <br />
                    <br />
                    <Row className="justify-content-md-center">
                        <Form.Group as={Col} lg="6" controlId="feedback">
                        <Form.Label><b>¿Algúna cosa extra que quiera comentar de la clase? (Opcional)</b></Form.Label>
                        <br/>
                        <br/>
                        <Form.Control
                            as="textarea"
                            onChange={e => setExtra(e.target.value)}
                        />
                        </Form.Group>
                    </Row>
                    <br />
                    <br />
                    {!(id ==="") && id.length > 23
                        ?
                        <Button variant="success" type="submit" disabled={disabled}>Envíalo!</Button>
                        :
                        <Button variant="success" type="submit" disabled={true}>Envíalo!</Button>
                    }
                </Form>
            :
            <p>Muchas gracias por proveer tu valioso reporte! Gracias a ti podremos mejorar las clases en Xplain.</p>
            }
            
        </>
    )
}

export default TutorFeedbackForm
