import React from "react"
import NewCalendarForm from "./NewCalendarForm"
const NewCalendarPage = () => {
    return (
        <div>
            {/* <Image fluid={true} src={require("../../assets/images/logo.png")}/> */}
            <br/>

            <NewCalendarForm />
        </div>
    )
}

export default NewCalendarPage