import React, { useState, useEffect } from "react";
import Select from "react-select";
import { useDispatch, useSelector } from "react-redux";
import { Form, Col, Row, Button, Modal } from "react-bootstrap";
import { setUser } from "../../store/auth/auth.slice";
import { getStudents } from "../../store/student/student.actions";
import { getSubjects } from "../../store/subject/subject.actions";
import { getTutors } from "../../store/tutor/tutor.actions";
import { getParents } from "../../store/parent/parent.actions";
import axios from "axios";
import { useNavigate } from "react-router";
import { listFormat } from "../../name_utils";
import JoinFamilyForm from "../../components/forms/FamilyView/JoinFamilyForm";
// const listFormat = (array) => {
//     let newArray = []
//     for (const elem of array){
//         let newElem = {...elem}
//         if ('surname' in newElem){
//             newElem['label'] = elem['name'] + " " + elem['surname']
//         }else{
//             newElem['label'] = elem['name']
//         }
//         newElem['value'] = elem['_id']
//         newArray.push(newElem)
//     }
//     return newArray
// }
const COLORS = {
  XPLAIN_GREEN: "#0AB591",
};
export const ParentForm = () => {
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.auth);
  const { students } = useSelector((state) => state.student);
  const newUser = { ...user };
  const [edit, setEdit] = useState(false);
  const [disabled, setDisabled] = useState(false);
  const [isInvalid, setIsInvalid] = useState(false);
  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    dispatch(
      getStudents({ filter: { _id: { $in: user.students } }, selection: {} })
    );
  }, []);
  const handleSubmit = (event) => {
    event.preventDefault();
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.stopPropagation();
      setIsInvalid(true);
    } else {
      // let answer = window.confirm("Estás seguro quieres actualizar esta info?");
      setIsInvalid(false);
      setDisabled(true);
      delete newUser._id;
      delete newUser.__v;
      delete newUser.label;
      delete newUser.value;
      axios
        .patch(`${process.env.REACT_APP_BACKENDURL}/auth`, {
          id: user._id,
          update: newUser,
        })
        .then((response) => {
          if (response.data._id) {
            dispatch(setUser(response.data));
          }
          setDisabled(false);
          setEdit(false);
        })
        .catch((error) => {
          console.log(error);
          setDisabled(false);
        });
    }
  };
  let EDITABLE_FIELDS = ["birthday", "name", "surname", "email", "phone"];
  let INVISIBLE_FIELDS = ["__v", "label", "value", "_id", "password"];
  const userData = Object.keys(newUser).map((key, index) => {
    if (EDITABLE_FIELDS.includes(key)) {
      return (
        <>
          <Row className="justify-content-md-center">
            <Form.Group as={Col} lg="2" controlId={key}>
              <Form.Label>{key}</Form.Label>
              <Form.Control
                type="text"
                value={newUser[key]}
                readOnly={!edit}
                onChange={(e) => {
                  newUser[key] = e.target.value;
                  dispatch(setUser(newUser));
                }}
              />
              <Form.Control.Feedback>Todo bien!</Form.Control.Feedback>
            </Form.Group>
          </Row>
          <br />
        </>
      );
    } else if (INVISIBLE_FIELDS.includes(key)) {
      return <></>;
    } else if (key == "students") {
      return (
        <>
          <Row className="justify-content-md-center">
            <Form.Group as={Col} lg="3" controlId="surname">
              <Form.Label>students</Form.Label>
              <br />
              <br />
              <Form.Control
                as={Select}
                required
                isMulti={true}
                value={listFormat(students)}
                options={listFormat(students)}
                isDisabled={true}
                placeholder="Padres"
              />
              <Form.Control.Feedback>Todo bien!</Form.Control.Feedback>
            </Form.Group>
          </Row>
          <br />
        </>
      );
    } else {
      return (
        <>
          <Row className="justify-content-md-center">
            <Form.Group as={Col} lg="2" controlId={key}>
              <Form.Label>{key}</Form.Label>
              <Form.Control
                type="text"
                disabled
                value={newUser[key]}
                readOnly={!edit}
              />
              <Form.Control.Feedback>Todo bien!</Form.Control.Feedback>
            </Form.Group>
          </Row>
          <br />
        </>
      );
    }
  });
  return (
    <div>
      <Button
        style={{ backgroundColor: COLORS.XPLAIN_GREEN, border: 0 }}
        onClick={() => setShowModal(true)}
      >
        Agregar miembro de familia
      </Button>
      <Modal size="lg" show={showModal} onHide={() => setShowModal(false)}>
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body>
          <div
            className="justify-content-md-center"
            style={{ textAlign: "center" }}
          >
            <JoinFamilyForm familyId={user.family} />
          </div>
        </Modal.Body>
      </Modal>
      <h1>Data</h1>
      <Form noValidate validated={isInvalid} onSubmit={handleSubmit}>
        {userData}
        {edit ? (
          <Row className="justify-content-md-center">
            <Col lg="2">
              <Button type="submit" variant="success" disabled={disabled}>
                Enviar
              </Button>
            </Col>
            <Col lg="2">
              <Button
                variant="danger"
                disabled={disabled}
                onClick={() => setEdit(false)}
              >
                Cancelar
              </Button>
            </Col>
          </Row>
        ) : (
          <Button variant="success" onClick={() => setEdit(true)}>
            Editar
          </Button>
        )}
      </Form>
    </div>
  );
};

export const StudentForm = () => {
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.auth);
  const { tutors } = useSelector((state) => state.tutor);
  const { parents } = useSelector((state) => state.parent);
  const { subjects } = useSelector((state) => state.subject);
  const newUser = { ...user };
  const [edit, setEdit] = useState(false);
  const [disabled, setDisabled] = useState(false);
  const [isInvalid, setIsInvalid] = useState(false);
  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    dispatch(
      getSubjects({ filter: { _id: { $in: user.subjects } }, selection: {} })
    );
    dispatch(
      getTutors({ filter: { _id: { $in: user.tutors } }, selection: {} })
    );
    dispatch(
      getParents({ filter: { _id: { $in: user.parents } }, selection: {} })
    );
  }, []);
  const handleSubmit = (event) => {
    event.preventDefault();
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.stopPropagation();
      setIsInvalid(true);
    } else {
      // let answer = window.confirm("Estás seguro quieres actualizar esta info?");
      setIsInvalid(false);
      setDisabled(true);
      delete newUser._id;
      delete newUser.__v;
      delete newUser.label;
      delete newUser.value;
      axios
        .patch(`${process.env.REACT_APP_BACKENDURL}/auth`, {
          id: user._id,
          update: newUser,
        })
        .then((response) => {
          window.location.reload(false);
        })
        .catch((error) => {
          console.log(error);
          setDisabled(false);
        });
    }
  };
  let INVISIBLE_FIELDS = [
    "__v",
    "label",
    "value",
    "password",
    "_id",
    "family",
    "hours",
  ];
  let EDITABLE_FIELDS = [
    "year",
    "school",
    "birthday",
    "name",
    "surname",
    "email",
    "phone",
  ];
  const userData = Object.keys(newUser).map((key, index) => {
    if (EDITABLE_FIELDS.includes(key)) {
      return (
        <>
          <Row className="justify-content-md-center">
            <Form.Group as={Col} lg="2" controlId={key}>
              <Form.Label>{key}</Form.Label>
              <Form.Control
                type="text"
                value={newUser[key]}
                readOnly={!edit}
                onChange={(e) => {
                  newUser[key] = e.target.value;
                  dispatch(setUser(newUser));
                }}
              />
              <Form.Control.Feedback>Todo bien!</Form.Control.Feedback>
            </Form.Group>
          </Row>
          <br />
        </>
      );
    } else if (INVISIBLE_FIELDS.includes(key)) {
      return <></>;
    } else if (key == "subjects") {
      return (
        <>
          <Row className="justify-content-md-center">
            <Form.Group as={Col} lg="3" controlId="surname">
              <Form.Label>subjects</Form.Label>
              <br />
              <br />
              <Form.Control
                as={Select}
                required
                isMulti={true}
                value={listFormat(subjects)}
                options={listFormat(subjects)}
                isDisabled={true}
                placeholder="Materias"
              />
              <Form.Control.Feedback>Todo bien!</Form.Control.Feedback>
            </Form.Group>
          </Row>
          <br />
        </>
      );
    } else if (key == "parents") {
      return (
        <>
          <Row className="justify-content-md-center">
            <Form.Group as={Col} lg="3" controlId="surname">
              <Form.Label>parents</Form.Label>
              <br />
              <br />
              <Form.Control
                as={Select}
                required
                isMulti={true}
                value={listFormat(parents)}
                options={listFormat(parents)}
                isDisabled={true}
                placeholder="Padres"
              />
              <Form.Control.Feedback>Todo bien!</Form.Control.Feedback>
            </Form.Group>
          </Row>
          <br />
        </>
      );
    } else if (key == "tutors") {
      return (
        <>
          <Row className="justify-content-md-center">
            <Form.Group as={Col} lg="3" controlId="surname">
              <Form.Label>tutors</Form.Label>
              <br />
              <br />
              <Form.Control
                as={Select}
                required
                isMulti={true}
                value={listFormat(tutors, { isTutor: true })}
                options={listFormat(tutors, { isTutor: true })}
                isDisabled={true}
                placeholder="Tutores"
              />
              <Form.Control.Feedback>Todo bien!</Form.Control.Feedback>
            </Form.Group>
          </Row>
          <br />
        </>
      );
    } else {
      return (
        <>
          <Row className="justify-content-md-center">
            <Form.Group as={Col} lg="2" controlId={key}>
              <Form.Label>{key}</Form.Label>
              <Form.Control
                type="text"
                disabled
                value={newUser[key]}
                readOnly={!edit}
              />
              <Form.Control.Feedback>Todo bien!</Form.Control.Feedback>
            </Form.Group>
          </Row>
          <br />
        </>
      );
    }
  });
  return (
    <div>
      <Button
        style={{ backgroundColor: COLORS.XPLAIN_GREEN, border: 0 }}
        onClick={() => setShowModal(true)}
      >
        Agregar miembro de familia
      </Button>
      <Modal size="lg" show={showModal} onHide={() => setShowModal(false)}>
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body>
          <div
            className="justify-content-md-center"
            style={{ textAlign: "center" }}
          >
            <JoinFamilyForm familyId={user.family} />
          </div>
        </Modal.Body>
      </Modal>
      <h1>Data</h1>
      <Form noValidate validated={isInvalid} onSubmit={handleSubmit}>
        {userData}
        {edit ? (
          <Row className="justify-content-md-center">
            <Col lg="2">
              <Button type="submit" variant="success" disabled={disabled}>
                Enviar
              </Button>
            </Col>
            <Col lg="2">
              <Button
                variant="danger"
                disabled={disabled}
                onClick={() => setEdit(false)}
              >
                Cancelar
              </Button>
            </Col>
          </Row>
        ) : (
          <Button variant="success" onClick={() => setEdit(true)}>
            Editar
          </Button>
        )}
      </Form>
    </div>
  );
};

export const TutorForm = () => {
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.auth);
  const { students } = useSelector((state) => state.student);
  const { subjects } = useSelector((state) => state.subject);
  const newUser = { ...user };
  const [edit, setEdit] = useState(false);
  const [disabled, setDisabled] = useState(false);
  const [isInvalid, setIsInvalid] = useState(false);
  useEffect(() => {
    dispatch(
      getSubjects({ filter: { _id: { $in: user.subjects } }, selection: {} })
    );
    dispatch(
      getStudents({ filter: { _id: { $in: user.students } }, selection: {} })
    );
  }, []);
  const handleSubmit = (event) => {
    event.preventDefault();
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.stopPropagation();
      setIsInvalid(true);
    } else {
      // let answer = window.confirm("Estás seguro quieres actualizar esta info?");
      setIsInvalid(false);
      setDisabled(true);
      delete newUser._id;
      delete newUser.__v;
      delete newUser.label;
      delete newUser.value;
      axios
        .patch(`${process.env.REACT_APP_BACKENDURL}/auth`, {
          id: user._id,
          update: newUser,
        })
        .then((response) => {
          // window.location.reload(false)
          console.log(response);
        })
        .catch((error) => {
          console.log(error);
          setDisabled(false);
        });
      // if(answer){

      // }
    }
  };
  let EDITABLE_FIELDS = [
    "degree",
    "year",
    "birthday",
    "instagram",
    "linkedin",
    "name",
    "surname",
    "email",
    "phone",
    "tutorDescription",
    "imageDriveLink",
    "school",
  ];
  let INVISIBLE_FIELDS = [
    "__v",
    "label",
    "status",
    "value",
    "password",
    "_id",
    "resume",
    "video",
    "photo",
    "calendarId",
    // "students",
    "essays",
    "googleRefreshToken",
    "tutorDescriptionList",
  ];
  const userData = Object.keys(newUser).map((key, index) => {
    if (EDITABLE_FIELDS.includes(key)) {
      return (
        <>
          <Row className="justify-content-md-center">
            <Form.Group as={Col} lg="2" controlId={key}>
              <Form.Label>{key}</Form.Label>
              <Form.Control
                type="text"
                value={newUser[key]}
                readOnly={!edit}
                onChange={(e) => {
                  newUser[key] = e.target.value;
                  dispatch(setUser(newUser));
                }}
              />
              <Form.Control.Feedback>Todo bien!</Form.Control.Feedback>
            </Form.Group>
          </Row>
          <br />
        </>
      );
    } else if (INVISIBLE_FIELDS.includes(key)) {
      return <></>;
    } else if (key == "subjects") {
      return (
        <>
          <Row className="justify-content-md-center">
            <Form.Group as={Col} lg="3" controlId="surname">
              <Form.Label>subjects</Form.Label>
              <br />
              <br />
              <Form.Control
                as={Select}
                required
                isMulti={true}
                value={listFormat(subjects)}
                options={listFormat(subjects)}
                isDisabled={true}
                placeholder="Materias"
              />
              <Form.Control.Feedback>Todo bien!</Form.Control.Feedback>
            </Form.Group>
          </Row>
          <br />
        </>
      );
    } else if (key == "students") {
      return (
        <>
          <Row className="justify-content-md-center">
            <Form.Group as={Col} lg="3" controlId="surname">
              <Form.Label>students</Form.Label>
              <br />
              <br />
              <Form.Control
                as={Select}
                required
                isMulti={true}
                value={listFormat(students)}
                options={listFormat(students)}
                isDisabled={true}
                placeholder="Estudiantes"
              />
              <Form.Control.Feedback>Todo bien!</Form.Control.Feedback>
            </Form.Group>
          </Row>
          <br />
        </>
      );
    } else {
      return (
        <>
          <Row className="justify-content-md-center">
            <Form.Group as={Col} lg="2" controlId={key}>
              <Form.Label>{key}</Form.Label>
              <Form.Control
                type="text"
                disabled
                value={newUser[key]}
                readOnly={!edit}
              />
              <Form.Control.Feedback>Todo bien!</Form.Control.Feedback>
            </Form.Group>
          </Row>
          <br />
        </>
      );
    }
  });
  return (
    <Form noValidate validated={isInvalid} onSubmit={handleSubmit}>
      {userData}
      {edit ? (
        <Row className="justify-content-md-center">
          <Col lg="2">
            <Button type="submit" variant="success" disabled={disabled}>
              Enviar
            </Button>
          </Col>
          <Col lg="2">
            <Button
              variant="danger"
              disabled={disabled}
              onClick={() => setEdit(false)}
            >
              Cancelar
            </Button>
          </Col>
        </Row>
      ) : (
        <Button variant="success" onClick={() => setEdit(true)}>
          Editar
        </Button>
      )}
    </Form>
  );
};

export const AdminForm = () => {
  const { user } = useSelector((state) => state.auth);
  const newUser = { ...user };
  const userData = Object.keys(newUser).map((key, index) => {
    if (key == "name" || key == "surname" || key == "email" || key == "phone") {
      return (
        <>
          <Row className="justify-content-md-center">
            <Form.Group as={Col} lg="2" controlId={key}>
              <Form.Label>{key}</Form.Label>
              <Form.Control
                type="text"
                disabled
                value={newUser[key]}
                readOnly={false}
              />
              <Form.Control.Feedback>Todo bien!</Form.Control.Feedback>
            </Form.Group>
          </Row>
          <br />
        </>
      );
    } else if (key == "__v" || key == "password" || key == "_id") {
      return <></>;
    }
  });
  return <Form>{userData}</Form>;
};
export const VendorForm = () => {
  const { user } = useSelector((state) => state.auth);
  const newUser = { ...user };
  const userData = Object.keys(newUser).map((key, index) => {
    if (key == "name" || key == "surname" || key == "email" || key == "phone") {
      return (
        <>
          <Row className="justify-content-md-center">
            <Form.Group as={Col} lg="2" controlId={key}>
              <Form.Label>{key}</Form.Label>
              <Form.Control
                type="text"
                disabled
                value={newUser[key]}
                readOnly={false}
              />
              <Form.Control.Feedback>Todo bien!</Form.Control.Feedback>
            </Form.Group>
          </Row>
          <br />
        </>
      );
    } else if (key == "__v" || key == "password" || key == "_id") {
      return <></>;
    }
  });
  return <Form>{userData}</Form>;
};
const ProfileForm = () => {
  const { user } = useSelector((state) => state.auth);
  const navigate = useNavigate();
  let view;
  if (user.role === "Student") {
    view = <StudentForm />;
  } else if (user.role === "Admin") {
    view = <AdminForm />;
  } else if (user.role === "Tutor") {
    view = <TutorForm />;
  } else if (user.role === "Parent") {
    view = <ParentForm />;
  } else if (user.role === "Vendor") {
    view = <VendorForm />;
  }
  useEffect(() => {
    if (
      user &&
      Object.keys(user).length === 0 &&
      Object.getPrototypeOf(user) === Object.prototype
    )
      navigate("/login");
  }, []);
  return <>{view}</>;
};

export default ProfileForm;
