import { useState } from "react";
import Accordion from "react-bootstrap/Accordion";
import Image from "react-bootstrap/Image";
import Figure from "react-bootstrap/Figure";
import guide_mercadopago from "../../assets/images/guide/guide_mercadopago.png";
import guide_reserve_1 from "../../assets/images/guide/guide_reserve_1_new.png";
import guide_reserve_2 from "../../assets/images/guide/guide_reserve_2.png";
import guide_landing_page from "../../assets/images/guide/guide_landing_page.png";
import guide_hour_types from "../../assets/images/guide/guide_hour_type.png";
import guide_see_classes from "../../assets/images/guide/guide_see_classes.png";
import guide_see_classes_calendar from "../../assets/images/guide/guide_see_classes_calendar.png";
import guide_cancel_class from "../../assets/images/guide/guide_cancel_class.png";
import guide_report from "../../assets/images/guide/guide_report.png";
import guide_see_profile from "../../assets/images/guide/guide_see_profile.png";
import guide_editing_profile from "../../assets/images/guide/guide_editing_profile.png";
import guide_referred_id from "../../assets/images/guide/guide_referred_id.png";
import guide_referred_family from "../../assets/images/guide/guide_referred_family.png";
let COLORS = {
  XPLAIN_GREEN: "#0AB591",
};
const FAQ = () => {
  const FAQ_BEFORE_CLASS = [
    {
      header: "¿Cómo comprar horas de clase?",
      body: "En primer lugar, debes loguearte desde la cuenta de alguno de los padres. Después debe ubicarse en la sección “¿Qué quieres hacer?” y seleccionar la opción “comprar horas”, luego seleccionar el programa donde se desea comprar las horas. Seguidamente elegir la moneda de preferencia y finalmente la cantidad. Una vez realizado lo anterior saldrá una ventana de “mercado pago” donde podrá realizar la compra de horas que desee. Después que realice el pago las horas se verán reflejadas en el menú general de la familia",
      images: [
        {
          src: guide_mercadopago,
          caption: "Usando Mercado Pago para comprar horas",
        },
      ],
    },
    {
      header: "¿Cuál es el horario de atención del coordinador académico?",
      body: (
        <div>
          El área de coordinación académica sirve para resolver cualquier duda o
          inconveniente que tengan los padres de familia y los estudiantes. Si
          tienes algún problema con la clase es necesario que primero hayas
          leído este manual para saber los protocolos. Seguidamente te comunicas
          con el área de coordinación académica y explicas tu caso. El horario
          de trabajo es de lunes a viernes de{" "}
          <strong>10 am hasta las 7pm (GMT-5)</strong>. Fuera de esos horarios
          no hay atención de la coordinación académica, pero sí se pueden dictar
          clases, previamente programadas por la APP. De suceder algún incidente
          se resolverá en el siguiente horario laboral, hasta un máximo de{" "}
          <strong>48 horas desde la notificación</strong>.
        </div>
      ),
    },
    {
      header: "¿Cómo separar una clase?",
      body: "En primer lugar, te ubicas en el menú principal y seleccionas “reservar clases”, luego escoges la materia en la cual deseas tener clases. Seguidamente eliges al tutor de tu mayor agrado, te saldra su disponibilidad horaria y puedes seleccionar un horario para realizar una solicitud de clase, esta se pondra de color verde suave y cuando el profesor confirme el horario cambiara a un verde vivo. En caso demore en confirmar. Contactar con coordinación académica. Mediante la aplicación solo se pueden separar clases con 24 horas de anticipación. El link de la clase, el del feedback y las opciones de la clase las podrás ver haciendo click en el cuadro del horario separado.",
      images: [
        {
          src: guide_reserve_1,
          caption: "Reservando clases en la App de Xplain",
        },
        {
          src: guide_reserve_2,
          caption: "Calendario de clases",
        },
      ],
    },
    {
      header: "¿Puedo separar una clase para el mismo día?",
      body: "Si es posible separar una clase de emergencia, sin embargo, el costo de cada hora incrementa un 50%. Las clases de 1 hora serán tomadas como 1.5 horas, las cuales serán descontadas de las horas totales de su paquete, estas clases no se pueden cancelar ni reprogramar. Tienes que comunicarte con la coordinación académica y realizar tu solicitud.",
    },
    {
      header: "¿Cómo ver cuántas horas te queda en la app?",
      body: (
        <div>
          En primer lugar, debes loguearte en la app haciendo{" "}
          <a href="https://xplain-la.web.app/">click aquí</a> con el correo o
          gmail que nos proporcionaste (aplica para las cuentas de padres y
          estudiantes). Y en la pantalla inicial podrás ver cuántas horas le
          quedan al estudiante o la familia de cada subtipo de servicio.
        </div>
      ),
      images: [
        {
          src: guide_landing_page,
          caption: "Página de inicio",
        },
        {
          src: guide_hour_types,
          caption: "Distintos tipos de horas",
        },
      ],
    },
    {
      header: "¿Cómo ver qué clases tienes programadas?",
      body: "Pueden ver todas las clases programadas en la APP de Xplain. En el menú de  inicio seleccionas “ver clases”. Te aparecerá un calendario donde podrás ver las próximas clases que tengas solicitadas o programadas.",
      images: [
        {
          src: guide_see_classes,
          caption: "Elegir próximas clases en la App de Xplain.",
        },
        {
          src: guide_see_classes_calendar,
          caption: "Ver programa de clases en manera de calendario.",
        },
      ],
    },
    {
      header: "¿Cómo ver el link de la reunión de mi clase?",
      body: "En primer lugar, debes estar en la sección de  “ver clases” luego dale click a la clase sobre la cual quieres ver la información y te saldrá un cuadro con la información y links de la clase.",
    },
    {
      header: "¿Cómo cancelar o reprogramar clases?",
      body: (
        <div>
          Las clases solo se pueden cancelar o reprogramar con{" "}
          <strong>24 horas de anticipación</strong>. Caso contrario se
          penalizará <strong>una hora de clase</strong>, la cual será contada
          como realizada, salvo por motivos de salud para lo cual debe enviarse
          el certificado médico a{" "}
          <a href="mailto:hello@xplain.com">hello@xplain.com</a>. En el
          calendario de “ver clases” selecciona la clase que deseas cancelar, al
          darle click te mostrará las diversas opciones de la clase, selecciona
          “cancelar clase”. Los alumnos tienen un tiempo de tolerancia de 10
          minutos para entrar a clases, luego los tutores son libres de
          retirarse y la clase se considerará realizada, a menos que se avise
          que se llegará tarde, bajo la penalidad previamente mencionada.",
        </div>
      ),
      images: [
        {
          src: guide_cancel_class,
          caption: "Solo puedes cancelar clase con 24 horas de anticipación.",
        },
      ],
    },
    {
      header: "¿Cómo entrar a las clases por Whatsapp?",
      body: (
        <div>
          <strong>30 minutos</strong> antes del inicio de la clase recibirás un
          mensaje recordatorio. Este mensaje incluirá el enlace para acceder a
          la clase. Por ellos que es muy importante que nos hayan brindado los
          números de whatsapp correctos
        </div>
      ),
    },
    {
      header: "¿Para qué sirven los grupos de Whatsapp?",
      body: "Los grupos de Whatsapp son de vital importancia ya que permiten que te comuniques con tus tutores de forma segura y eficiente para presentar tus dudas o avances y ellos te puedan dar pequeños feedbacks, además que permiten a todas las partes ver el desarrollo de los estudiantes. Es importante que le comentes al tutor el tema que quieres tratar con la mayor anticipación posible y si tienes algún material o avance que se quiera ver en especifico es importante que se lo hagas saber y se lo envíes por el grupo. Recuerda que las clases no se separan por este medio. No te olvides de etiquetar al profesor al cual busques dirigirte a él/ella, en caso no responda y sea urgente escribe al coordinador al privado, el se encargara de llamarlo y hacer que responda tus dudas. Por ningún motivo le escribas de forma directa al tutor.",
    },
    {
      header: "¿En qué horarios puedo tener clase?",
      body: (
        <div>
          Las clases se pueden dictar de acuerdo a la disponibilidad de los
          tutores. El horario usual de trabajo es entre las{" "}
          <strong>9 am a las 10 pm (GMT-5)</strong>. Sin embargo, si desea un
          horario fuera de este rango consultar con el coordinador académico, el
          costo de las horas en este horario será <strong>1.5 horas</strong>,
          las cuales se descontarán del total del paquete adquirido.
        </div>
      ),
    },
    {
      header: "¿Cómo me comunico con mi tutor Xplain?",
      body: (
        <div>
          Toda la comunicación con los tutores es por el grupo de Whatsapp del
          estudiante. <strong>Por ningún motivo</strong> se deben comunicar de
          forma directa, esto supone en primera instancia la pérdida de 2 horas
          del total de horas adquiridas y en segunda la pérdida del total de
          horas{" "}
          <strong>
            (leer{" "}
            <a href="https://docs.google.com/document/d/1auVZqGzZPRLEVYCYGiQ0or6LYLOYCJAkAkC5WTbFWiY/edit">
              T & C
            </a>
            )
          </strong>{" "}
          . Si deseas tener comunicación directa de forma oficial con algún
          tutor consultar con la coordinación académica.
        </div>
      ),
    },
  ];
  const FAQ_DURING_CLASS = [
    {
      header: "¿Qué hacer si mi tutor demora en entrar o no se presenta?",
      body: (
        <body>
          Si tu tutor demora más de <strong>10 minutos</strong> en entrar
          inmediatamente comunícate con el número de coordinación académica y
          comentale tu situación para solucionar el asunto a la brevedad . Si el
          tutor si puede dar la clase luego de los <strong>10 minutos</strong>,
          se dará la clase completa y se te abonarán <strong>0.5 horas</strong>{" "}
          de clase. En caso de no presentarse avísanos a la brevedad y se
          tomarán las medidas correspondientes con el tutor, se reprograma la
          hora perdida y se te regalará 1 hora extra como compensación.
        </body>
      ),
    },
    {
      header: "¿Qué hacer si me falla la señal de red a mi o a mi tutor?",
      body: (
        <div>
          Si luego de <strong>10 minutos</strong> continuas con problemas de red
          se sugiere cambiar de red o de dispositivo. Debes tomar todas las
          medidas para que esto no suceda, el profesor te acompañará hasta el
          final de la hora pero no habrá reprogramación. En caso el problema
          persista y sea por parte del tutor, contacta con el coordinador
          académico para que registre la incidencia. La clase se cancelará y
          programará y se te compensará con <strong>0.5 horas</strong> de clase
          extra.
        </div>
      ),
    },
    {
      header: "¿Qué hacer si mi tutor no domina el tema que necesito?",
      body: (
        <div>
          Es importante verificar que el curso que hayas solicitado sea el
          correcto. Si el curso es el correcto y el tutor no domina el tema,
          comunicate con el coordinador académico comentando tu situación para
          solucionar el asunto a la brevedad, se cancelara la clase y se
          reprograma la clase con otro tutor. Además se te compensará con{" "}
          <strong>0.5 horas adicionales</strong>.
        </div>
      ),
    },
    {
      header: "¿Qué hacer si no me gusta la metodología del tutor?",
      body: (
        <div>
          Es importante que antes de la clase le comentes al tutor cómo te
          gustaría trabajar, por ejemplo, si deseas avanzar rápido, lento o si
          deseas full problemas o teoría, entre otras. En caso sientas que el
          tutor no tiene la metodología que requieres comentale al coordinador
          académico para que te cambien tutor.de tutor a la brevedad. Se te hará
          la devolución efectiva de <strong>1 hora</strong>. Solo válido 1 vez.
        </div>
      ),
    },
    {
      header:
        "¿Qué hacer si tengo un inconveniente y estoy fuera del horario de atención del coordinador?",
      body: (
        <div>
          Si tienes un inconveniente con alguna clase dependiendo de la gravedad
          te pedimos que llenes el feedback, te retires de la misma y le dejes
          un mensaje al coordinador explicando lo sucedido para poder dar una
          solución lo antes posible. Recibirás una respuesta en un plazo máximo
          de <strong>48 horas</strong> después del envío del mensaje, el plazo
          usual es de <strong>24 horas</strong>
        </div>
      ),
    },
  ];
  const FAQ_AFTER_CLASS = [
    {
      header: "¿Cómo enviar mi feedback de cada clase? ",
      body: "En el mensaje recordatorio también estará incluido el link único del feedback de la clase. Tu evaluación es indispensable para que nosotros podamos mejorar tu experiencia de aprendizaje.También se pueden ver los links en la aplicación de Xplain en la tabla de próximas clases, siendo específicos en la columna de  “Link de Feedback”. ",
    },
    {
      header: "¿Cómo funcionan los reportes?",
      body: (
        <body>
          Los tutores de Xplain una vez que terminan una clase envían un reporte
          con una descripción del avance de los estudiantes. Estos reportes se
          enviaran al padre de familia mediante 2 medios. En primer lugar, de
          forma automática por correo electrónico y en segundo lugar en dos
          rondas horarias por Whatsapp. El primer envío se realizará a las{" "}
          <strong>7.30 pm (GMT-5)</strong> e incluye el reporte de todas las
          clases que se brindaron antes de esa hora y el segundo envió se
          realizará al día siguiente a las <strong>7.30 am (GMT-5)</strong> para
          las clases posteriores al primer envío. En este reporte se comentarán
          los avances y situación de su hijo para que lo pueda tomar en cuenta y
          de ser necesario tomar las acciones pertinentes. Por otro lado, se
          enviaran algunas preguntas sugeridas de temas que se vieron en clase
          para que usted como padre pueda verificar el aprendizaje de su hijo y
          acompañarlo en este proceso que a veces puede ser complejo.
        </body>
      ),
      images: [{ src: guide_report, caption: "Reporte de clases" }],
    },
    {
      header: "¿Cómo cambiar tu información de estudiante/padre?",
      body: "Si deseas cambiar tu información de estudiante debes ubicarte en la pantalla de inicio y darle click a “Ver perfil”. Luego te aparecerá una pantalla con toda tu información, debes deslizar hacia abajo y darle click al botón verde “editar”. Se desbloquearán algunas celdas para que las puedas modificar, algunas no se desbloquearan por ser información no editable.",
      images: [
        {
          src: guide_see_profile,
          caption: "Ver perfil",
        },
        {
          src: guide_editing_profile,
          caption: "Editando el perfil",
        },
      ],
    },
  ];
  const FAQ_REFERIDOS = [
    {
      header: "¿Cómo ver mi código de referidos?",
      body: "Cuando entres a la app, ahí podrás ver tu código único de referidos. A la gente nueva que traigas, diles que nos pasen este código cuando hagan su primera compra.",
      images: [{ src: guide_referred_id, caption: "Código de referidos" }],
    },
    {
      header: "¿Cuándo se me pagará por los referidos?",
      body: "Se te pagará al final de mes o se aplicará como descuento para tu siguiente compra.",
    },
    {
      header: "¿Cómo se me pagará por estos referidos?",
      body: (
        <div>
          Se te pagará por la cuenta de banco o paypal que nos hayas pasado. En
          caso nunca nos hayas pasado tus datos bancarios, escribele por
          whatsapp a{" "}
          <a href="https://wa.me/51933516182?text=Hola">
            <strong>+51 933 516 182</strong>
          </a>{" "}
          para que actualice el método de pago de tu familia dentro de la app.
        </div>
      ),
    },
    {
      header: "¿Cómo puedo ver a toda la gente que mi familia ha referido?",
      body: "Para ver todas las familias que has referido, puedes seleccionar “Ver Familias Referidas” dentro de la app",
      images: [
        { src: guide_referred_family, caption: "Ver Familias Referidas" },
      ],
    },
  ];
  const ALL_QUESTIONS = [
    FAQ_BEFORE_CLASS,
    FAQ_DURING_CLASS,
    FAQ_AFTER_CLASS,
    FAQ_REFERIDOS,
  ];

  const [questionGroup, setQuestionGroup] = useState(0); // index of ALL_QUESTIONS
  const [questionIndex, setQuestionIndex] = useState(0);
  const QuestionsSection = ({ header, questions }) => (
    <>
      <h2>{header}</h2>
      <Accordion style={{ width: "50%", alignSelf: "center" }}>
        {questions.map((question, index) => (
          <Accordion.Item eventKey={index}>
            <Accordion.Header as="strong">{question.header}</Accordion.Header>
            <Accordion.Body>
              {question.body}
              {question.images &&
                question.images.map((image, index) => (
                  <Figure key={index}>
                    <Figure.Image
                      width="100%"
                      src={image.src}
                      key={index}
                      fluid
                      style={{ border: "1px solid black" }}
                    />
                    <Figure.Caption>
                      {/* <strong> */}
                      <i>{image.caption}</i>
                      {/* </strong> */}
                    </Figure.Caption>
                  </Figure>
                ))}
            </Accordion.Body>
          </Accordion.Item>
        ))}
      </Accordion>
    </>
  );
  const QuestionsSection2 = ({ title, questions, group, intro }) => (
    <>
      <h1>{title}</h1>
      <div>{intro}</div>
      <div
        style={{
          display: "flex",
          flexWrap: "wrap",
          alignSelf: "center",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        {questions.map((question, index) => {
          let isChosen = questionGroup === group && questionIndex === index;
          return (
          <div
            onClick={() => {
              setQuestionGroup(group);
              setQuestionIndex(index);
            }}
            style={{
              border: "2px solid black",
              borderRadius: "10px",
              margin: "10px",
              width: "40%",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              minHeight: "80px",
              fontWeight: isChosen? "bold": "normal",
              color: isChosen ? "white":"black",
              backgroundColor: isChosen ? COLORS.XPLAIN_GREEN: "white",
              cursor: "pointer",
            }}
          >
            {question.header}
          </div>
          )
          })}
      </div>
    </>
  );
  return (
    <div
      style={{
        display: "flex",
        // flexDirection: "column",
        alignContent: "center",
        alignSelf: "center",
        justifyContent: "center",
        flexDirection: "row",
        flex: 1,
        margin: "initial",
        // width: "100vw",
        // backgroundColor: "yellow",
      }}
    >
      <div
        style={{
          //   backgroundColor: "red",
          flex: 1,
          //   width: "50%",
          maxHeight: "80vh",
          overflow: "scroll",
          margin: "10px",
        }}
      >
        <h1>Preguntas Frecuentes</h1>
        <QuestionsSection2
          title="Antes de la clase"
          questions={FAQ_BEFORE_CLASS}
          group={0}
        />
        <QuestionsSection2
          title="Durante de la clase"
          questions={FAQ_DURING_CLASS}
          group={1}
        />
        <QuestionsSection2
          title="Después de la clase"
          questions={FAQ_AFTER_CLASS}
          group={2}
        />
        <QuestionsSection2
          intro={
            "Con nuestro sistema de referidos, por cada cliente nuevo que traigas y compre un mínimo de 4 horas de clases, ambos se llevarán $10 o 40 S/ que pueden ser canjeados."
          }
          title="Sistema de referidos"
          questions={FAQ_REFERIDOS}
          group={3}
        />
      </div>
      <div
        style={{
          //   backgroundColor: "blue",
          flex: 1,
          width: "50%",
          maxHeight: "80vh",
          overflow: "scroll",
          margin: "10px",
        }}
      >
        <h1>Respuesta</h1>
        <div>{ALL_QUESTIONS[questionGroup][questionIndex].body}</div>
        {ALL_QUESTIONS[questionGroup][questionIndex].images &&
          ALL_QUESTIONS[questionGroup][questionIndex].images.map(
            (image, index) => (
              <Figure key={index}>
                <Figure.Image
                  width="100%"
                  src={image.src}
                  key={index}
                  rounded
                  style={{ border: "1px solid black" }}
                />
                <Figure.Caption>
                  {/* <strong> */}
                  <i>{image.caption}</i>
                  {/* </strong> */}
                </Figure.Caption>
              </Figure>
            )
          )}
      </div>
    </div>
  );
};

export default FAQ;
