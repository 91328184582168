import React from "react";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { Navbar, Container, NavDropdown, Button, Nav } from "react-bootstrap";
import Avatar from "react-avatar";
import { setUser } from "../../store/auth/auth.slice";

const NavBar = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { user } = useSelector((state) => state.auth);
  const logOut = () => {
    localStorage.removeItem("token");
    localStorage.removeItem("googleRefreshToken");
    dispatch(setUser({}));
    navigate("/login");
  };
  return (
    <Navbar bg="light" variant="light">
      {!(
        user &&
        Object.keys(user).length === 0 &&
        Object.getPrototypeOf(user) === Object.prototype
      ) ? (
        <>
          {user.role === "Student" ? (
            <>
              <Container>
                <Navbar.Brand
                  onClick={() => navigate("/")}
                  style={{ marginLeft: "2%", cursor: "pointer" }}
                >
                  <img
                    alt=""
                    src={require("../../assets/images/navbarIcon.png")}
                    height="40"
                    className="d-inline-block align-top"
                  />
                </Navbar.Brand>
                <Navbar.Toggle aria-controls="responsive-navbar-nav" />
                <Navbar.Collapse id="responsive-navbar-nav">
                  <Nav className="me-auto">
                    <Nav.Link onClick={() => navigate("/classes")}>
                      MIS CLASES
                    </Nav.Link>
                    <Nav.Link onClick={() => navigate("/reserveclasses")}>
                      RESERVAR CLASES
                    </Nav.Link>
                    <Nav.Link onClick={() => navigate("/tutors")}>
                      TUTORES
                    </Nav.Link>
                    <Nav.Link onClick={() => navigate("/payments")}>
                      COMPRAS
                    </Nav.Link>
                    <Nav.Link onClick={() => navigate("/referrals")}>
                      REFERIDOS
                    </Nav.Link>
                    <Nav.Link onClick={() => navigate("/history")}>
                      HISTORIAL
                    </Nav.Link>
                    {/* <Nav.Link onClick={() => navigate("/notes")}>NOTAS</Nav.Link> */}
                    <Nav.Link onClick={() => navigate("/chat")}>CHAT</Nav.Link>
                  </Nav>
                  <Nav>
                    <NavDropdown
                      drop="start"
                      title={
                        <Avatar
                          name={`${user.name.split(" ")[0]} ${
                            user.surname.split(" ")[0]
                          }`}
                          round={true}
                          size="40"
                        />
                      }
                    >
                      <NavDropdown.Item onClick={() => navigate("/profile")}>
                        Ver Perfil
                      </NavDropdown.Item>
                      <NavDropdown.Item onClick={() => navigate("/faq")}>
                        Preguntas Frecuentes
                      </NavDropdown.Item>
                      <NavDropdown.Divider />
                      <NavDropdown.Item>
                        <Button variant="danger" onClick={logOut}>
                          Cerrar Sesión
                        </Button>
                      </NavDropdown.Item>
                    </NavDropdown>
                  </Nav>
                </Navbar.Collapse>
              </Container>
            </>
          ) : (
            <>
              {user.role === "Parent" ? (
                <>
                  <Container>
                    <Navbar.Brand
                      onClick={() => navigate("/")}
                      style={{ marginLeft: "2%", cursor: "pointer" }}
                    >
                      <img
                        alt=""
                        src={require("../../assets/images/navbarIcon.png")}
                        height="40"
                        className="d-inline-block align-top"
                      />
                    </Navbar.Brand>
                    <Navbar.Toggle aria-controls="responsive-navbar-nav" />
                    <Navbar.Collapse id="responsive-navbar-nav">
                      <Nav className="me-auto">
                        <Nav.Link onClick={() => navigate("/classes")}>
                          MIS CLASES
                        </Nav.Link>
                        <Nav.Link onClick={() => navigate("/reserveclasses")}>
                          RESERVAR CLASES
                        </Nav.Link>
                        <Nav.Link onClick={() => navigate("/tutors")}>
                          TUTORES
                        </Nav.Link>
                        <Nav.Link onClick={() => navigate("/payments")}>
                          COMPRAS
                        </Nav.Link>
                        <Nav.Link onClick={() => navigate("/referrals")}>
                          REFERIDOS
                        </Nav.Link>
                        <Nav.Link onClick={() => navigate("/history")}>
                          HISTORIAL
                        </Nav.Link>
                        {/* <Nav.Link onClick={() => navigate("/notes")}>NOTAS</Nav.Link> */}
                        <Nav.Link onClick={() => navigate("/chat")}>
                          CHAT
                        </Nav.Link>
                      </Nav>
                      <Nav>
                        <NavDropdown
                          drop="start"
                          title={
                            <Avatar
                              name={`${user.name.split(" ")[0]} ${
                                user.surname.split(" ")[0]
                              }`}
                              round={true}
                              size="40"
                            />
                          }
                        >
                          <NavDropdown.Item
                            onClick={() => navigate("/profile")}
                          >
                            Ver Perfil
                          </NavDropdown.Item>
                          <NavDropdown.Item onClick={() => navigate("/faq")}>
                            Preguntas Frecuentes
                          </NavDropdown.Item>
                          <NavDropdown.Divider />
                          <NavDropdown.Item>
                            <Button variant="danger" onClick={logOut}>
                              Cerrar Sesión
                            </Button>
                          </NavDropdown.Item>
                        </NavDropdown>
                      </Nav>
                    </Navbar.Collapse>
                  </Container>
                </>
              ) : (
                <>
                  {user.role === "Tutor" ? (
                    <>
                      <Container>
                        <Navbar.Brand
                          onClick={() => navigate("/")}
                          style={{ marginLeft: "2%", cursor: "pointer" }}
                        >
                          <img
                            alt=""
                            src={require("../../assets/images/navbarIcon.png")}
                            height="40"
                            className="d-inline-block align-top"
                          />
                        </Navbar.Brand>
                        <Navbar.Toggle aria-controls="responsive-navbar-nav" />
                        <Navbar.Collapse id="responsive-navbar-nav">
                          <Nav className="me-auto">
                            <Nav.Link onClick={() => navigate("/classes")}>
                              MIS CLASES
                            </Nav.Link>
                            {/* <Nav.Link onClick={() => navigate("/students")}>ESTUDIANTES</Nav.Link> */}
                            <Nav.Link
                              onClick={() => navigate("/publicprofile")}
                            >
                              PERFIL
                            </Nav.Link>
                            <Nav.Link onClick={() => navigate("/materials")}>
                              MATERIALES
                            </Nav.Link>
                            <Nav.Link onClick={() => navigate("/referrals")}>
                              REFERIDOS
                            </Nav.Link>
                            <Nav.Link onClick={() => navigate("/history")}>
                              HISTORIAL
                            </Nav.Link>
                            <Nav.Link onClick={() => navigate("/tutors")}>
                              TUTORES
                            </Nav.Link>
                            <Nav.Link onClick={() => navigate("/chat")}>
                              CHAT
                            </Nav.Link>
                          </Nav>
                          <Nav>
                            <NavDropdown
                              drop="start"
                              title={
                                <Avatar
                                  name={`${user.name.split(" ")[0]} ${
                                    user.surname.split(" ")[0]
                                  }`}
                                  round={true}
                                  size="40"
                                />
                              }
                            >
                              <NavDropdown.Item
                                onClick={() => navigate("/profile")}
                              >
                                Ver Perfil
                              </NavDropdown.Item>
                              <NavDropdown.Item
                                onClick={() => navigate("/faq")}
                              >
                                Preguntas Frecuentes
                              </NavDropdown.Item>
                              <NavDropdown.Divider />
                              <NavDropdown.Item>
                                <Button variant="danger" onClick={logOut}>
                                  Cerrar Sesión
                                </Button>
                              </NavDropdown.Item>
                            </NavDropdown>
                          </Nav>
                        </Navbar.Collapse>
                      </Container>
                    </>
                  ) : user.role === "Vendor" ? (
                    <>
                      <>
                        <Container>
                          <Navbar.Brand
                            onClick={() => navigate("/")}
                            style={{ marginLeft: "2%", cursor: "pointer" }}
                          >
                            <img
                              alt=""
                              src={require("../../assets/images/navbarIcon.png")}
                              height="40"
                              className="d-inline-block align-top"
                            />
                          </Navbar.Brand>
                          <Navbar.Toggle aria-controls="responsive-navbar-nav" />
                          <Navbar.Collapse id="responsive-navbar-nav">
                            <Nav className="me-auto">
                              <Nav.Link
                                onClick={() => navigate("/vendor-families")}
                              >
                                FAMILIAS
                              </Nav.Link>
                            </Nav>
                            <Nav>
                              <NavDropdown
                                drop="start"
                                title={
                                  <Avatar
                                    name={`${user.name.split(" ")[0]} ${
                                      user.surname.split(" ")[0]
                                    }`}
                                    round={true}
                                    size="40"
                                  />
                                }
                              >
                                <NavDropdown.Item
                                  onClick={() => navigate("/profile")}
                                >
                                  Ver Perfil
                                </NavDropdown.Item>
                                <NavDropdown.Item
                                  onClick={() => navigate("/faq")}
                                >
                                  Preguntas Frecuentes
                                </NavDropdown.Item>
                                <NavDropdown.Divider />
                                <NavDropdown.Item>
                                  <Button variant="danger" onClick={logOut}>
                                    Cerrar Sesión
                                  </Button>
                                </NavDropdown.Item>
                              </NavDropdown>
                            </Nav>
                          </Navbar.Collapse>
                        </Container>
                      </>
                    </>
                  ) : (
                    <>
                      <Container>
                        <Navbar.Brand
                          onClick={() => navigate("/")}
                          style={{ marginLeft: "2%", cursor: "pointer" }}
                        >
                          <img
                            alt=""
                            src={require("../../assets/images/navbarIcon.png")}
                            height="40"
                            className="d-inline-block align-top"
                          />
                        </Navbar.Brand>
                        <Navbar.Toggle aria-controls="responsive-navbar-nav" />
                        <Navbar.Collapse id="responsive-navbar-nav">
                          <Nav className="me-auto"></Nav>
                          <Nav>
                            <NavDropdown
                              drop="start"
                              title={
                                <Avatar
                                  name={`${user.name.split(" ")[0]} ${
                                    user.surname.split(" ")[0]
                                  }`}
                                  round={true}
                                  size="40"
                                />
                              }
                            >
                              <NavDropdown.Item
                                onClick={() => navigate("/profile")}
                              >
                                Ver Perfil
                              </NavDropdown.Item>
                              <NavDropdown.Item
                                onClick={() => navigate("/faq")}
                              >
                                Preguntas Frecuentes
                              </NavDropdown.Item>
                              <NavDropdown.Divider />
                              <NavDropdown.Item>
                                <Button variant="danger" onClick={logOut}>
                                  Cerrar Sesión
                                </Button>
                              </NavDropdown.Item>
                            </NavDropdown>
                          </Nav>
                        </Navbar.Collapse>
                      </Container>
                    </>
                  )}
                </>
              )}
            </>
          )}
        </>
      ) : (
        <Container>
          <Navbar.Brand
            onClick={() => navigate("/")}
            style={{ marginLeft: "2%", cursor: "pointer" }}
          >
            <img
              alt=""
              src={require("../../assets/images/navbarIcon.png")}
              height="40"
              className="d-inline-block align-top"
            />
          </Navbar.Brand>
        </Container>
      )}
    </Navbar>
  );
};

export default NavBar;
