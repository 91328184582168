import React, {useState, useEffect} from 'react';
import { useSelector, useDispatch} from 'react-redux';
import axios from 'axios';

let today = new Date()
function getFirstDayOfMonth() {
    return new Date(today.getFullYear(), today.getMonth(), 1);
}
function getLastDayOfMonth() {
    return new Date(today.getFullYear(), today.getMonth() + 1, 0);
}

const TutorMonthReferrals = () => {
    const {user} = useSelector((state) => state.auth)
    const [salesThisMonth, setSalesThisMonth] = useState([]);

    useEffect(()=>{
        let referralId =  user.referral_id;
        if (!referralId){
            return;
        }
        console.log(`referrak = ${referralId}`)
        async function getSales(){
            console.log(`Looking for referralId: ${referralId}`)
            let data = {
            filter: {
                date: {
                "$gte": getFirstDayOfMonth().toISOString(), 
                "$lte": getLastDayOfMonth().toISOString()
                },
                referral: referralId
            }, 
            selection:{}
            }
            let sales = await axios.get(`${process.env.REACT_APP_BACKENDURL}/sale/all`, {params: data}); 
            setSalesThisMonth(sales.data);
        }
        getSales();
    }, [])
    console.log(salesThisMonth);
    let total = salesThisMonth.length;
    return(
        <>
            <p>Este mes has referido a <b>{total}</b> {total===1 ? 'familia': 'familias'}!</p>
        </>
    )
}
export default TutorMonthReferrals;