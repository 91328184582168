import React, { useEffect, useState, useMemo } from "react"
import axios from "axios"
import Select from "react-select"
import {Form, Row, Col, Button} from "react-bootstrap"
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'
import Card from "react-bootstrap/Card"

let COLORS = {
    XPLAIN_GREEN: "#0AB591",
  };
const SignupNewVendor = () => {
    const [name, setName] = useState("");
    const [surname, setSurname] = useState("");
    const [email, setEmail] = useState("");
    const [phone, setPhone] = useState("");

    const [disabled,setDisabled] = useState(false)
    const [isInvalid, setIsInvalid] = useState(false);
    const handleSubmit = async (event) => {
        event.preventDefault();
        const form = event.currentTarget;
        if (form.checkValidity() === false) {
            event.stopPropagation();
            setIsInvalid(true)
        }else{
            // let answer = window.confirm("Estás seguro quieres actualizar esta info?");
            setIsInvalid(false)
            setDisabled(true)
            try{
                let data = {name, surname, email, phone};
                console.log("data");
                console.log(data);

                let response = await axios.post(`${process.env.REACT_APP_BACKENDURL}/vendor`, data);
                let vendor = response.data;
                console.log(vendor);
                await axios.post(`${process.env.REACT_APP_BACKENDURL}/jobs/welcomeVendor`, {vendorId: vendor._id});
                alert("Se ha registrado correctamente. Revise su correo electrónico para mas detalles.");
                window.location.reload(false)
            } catch (err) {
                console.log("Error creating student and parent: " + err);
                console.log(err);
                alert(`Hubo un error: ${err.response.data.message}`);
                setDisabled(false);
            }
        }
    }
    return(
        <Form noValidate validated={isInvalid} onSubmit={handleSubmit}>
            <div style={{display:"flex", flex:1, flexDirection:"column"}}>
            <Card style={{
                // color:"white",
                borderWidth: 2,
                borderColor: COLORS.XPLAIN_GREEN,
                borderRadius: 10, 
                flex:1,
                // padding: 5,
                margin: 10,
                width:"50%",
                height:"100%",
                alignSelf:"center",
            }}
            // className="justify-content-md-center"
            >
            <Card.Header style={{backgroundColor:COLORS.XPLAIN_GREEN, color:"white"}}><h1>Vendedor</h1></Card.Header>
            <Card.Body style={{display:"flex", flexDirection:"column", alignItems:'center', flex:1}}>
            {/* <Row style={{display:"flex", flex:1}} className="justify-content-md-center"> */}
                <Form.Group as={"div"} className="mb-3" controlId="name" style={{flex: 1, width:"60%"}}>
                    <Form.Label><b>Nombre</b></Form.Label>
                    <Form.Control
                        required
                        type="text"
                        placeholder="Nombre"
                        value={name}
                        onChange={e => {
                            setName(e.target.value)
                        }}
                    />
                    <Form.Control.Feedback>Todo bien!</Form.Control.Feedback>
                </Form.Group>
                <Form.Group as={"div"} className="mb-3" controlId="surname" style={{flex: 1, width:"60%"}}>
                <Form.Label><b>Apellido(s)</b></Form.Label>
                <Form.Control
                    required
                    type="text"
                    placeholder="Apellido(s)"
                    value={surname}
                    onChange={e => {
                        setSurname(e.target.value)
                    }}

                />
                <Form.Control.Feedback>Todo bien!</Form.Control.Feedback>
                </Form.Group>
            {/* </Row> */}
            {/* <Row className="justify-content-md-center"> */}
                <Form.Group as={"div"} className="mb-3" controlId="name" style={{flex: 1, width:"60%"}}>
                <Form.Label><b>Email</b></Form.Label>
                <Form.Control
                    required
                    type="email"
                    onChange={e => {
                        setEmail(e.target.value)
                    }}
                    value={email}
                    placeholder="Gmail"
                />
                <Form.Control.Feedback>Todo bien!</Form.Control.Feedback>
                </Form.Group>
                <Form.Group as={"div"} className="mb-3" controlId="surname" style={{flex:1}}>
                <Form.Label><b>Whatsapp</b></Form.Label>

                <PhoneInput
                    inputProps={{required:true}}
                    onChange={phone => {
                        setPhone("+" + phone)
                    }}  
                    enableSearch={true}
                    value={phone}
                /> 
                <Form.Control.Feedback>Todo bien!</Form.Control.Feedback>
                </Form.Group>
            </Card.Body>
            </Card>
            </div>
            <p>
                <strong>
                Una vez registrado, se le mandará un correo electrónico para
                que se creen una contraseña.
                </strong>
            </p>
            <Button style={{backgroundColor: COLORS.XPLAIN_GREEN, border: 0}} variant="primary" disabled={disabled} type="submit">
                <h3>Crear cuenta</h3>
            </Button>

        </Form>
    )
}

export default SignupNewVendor;