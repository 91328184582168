import React, {useState, useEffect, useCallback} from 'react';
import Calendar from '@toast-ui/react-calendar';
import '@toast-ui/calendar/dist/toastui-calendar.min.css';
import 'tui-date-picker/dist/tui-date-picker.css';
import 'tui-time-picker/dist/tui-time-picker.css';
import axios from 'axios';
import {Card, Modal, Button, Row, Col} from "react-bootstrap"
import { useSelector,useDispatch } from 'react-redux';
import {getClasses} from "../../../store/class/class.actions"
import {getSubjects} from "../../../store/subject/subject.actions"
import {getParents} from "../../../store/parent/parent.actions"
import {getStudents} from "../../../store/student/student.actions"

const CalTutorClasses = () => {
    const dispatch = useDispatch() 
    const {user} = useSelector((state) => state.auth)
    const {classes} = useSelector((state) => state.class)
    const {subjects} = useSelector((state) => state.subject)
    const {students} = useSelector((state) => state.student)
    const {parents} = useSelector((state) => state.parent)
    const [date, setDate] = useState("")
    const [start,setStart] = useState("")
    const [end,setEnd] = useState("")
    const [show,setShow] = useState(false)
    const [event, setEvent] = useState({})
    let today = new Date()
    let tomorrow = new Date(today)
    tomorrow.setDate(today.getDate() + 1)
    let nextWeek = new Date(today)
    nextWeek.setDate(today.getDate() + 7)
    let todayMorning = new Date(today)
    todayMorning.setHours(0,0,0,0)
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const calendarRef = React.createRef();
    useEffect(() => {
        dispatch(getClasses({filter:{tutorId:user._id, end:{"$lte":nextWeek} , start:{"$gte": todayMorning}}, selection:{}}))
        dispatch(getSubjects({filter:{_id:{"$in":user.subjects}},selection:{}}))
        dispatch(getStudents({filter:{_id:{"$in":user.students}}, selection:{}}))
        dispatch(getParents({filter:{}, selection: {}}))
    }, [])
    let newClasses = []
    for (const currentClass of classes){
        let newClass = {...currentClass}
        console.log(students)
        for (const student of students){
            if (newClass.studentIds.includes(student._id)){
                newClass["studentName"] = student['name'].split(" ")[0] + " " + student['surname'].split(" ")[0]
                newClass["calendarId"] = user.calendarId
            }
        }
        for (const subject of subjects){
            if (subject._id == newClass.subjectId){
                newClass["subjectName"] = subject['name']
            }
        }
        let calendarClass = {
            start: new Date(newClass.start),
            end: new Date(newClass.end),
            id: newClass._id,
            body: newClass.googleId,
            title: `${newClass.subjectName} con ${newClass.studentName}`,
            isReadOnly: true,
            calendarId: newClass.calendarId,
            isPending: newClass.pending
        }
        if (calendarClass.isPending){
            calendarClass["backgroundColor"] = 'rgba(10, 181, 145, 0.2)'
            calendarClass["borderColor"] = 'rgba(10, 181, 145, 0.2)'
        }else{
            calendarClass["backgroundColor"] = 'rgba(10, 181, 145, 1)'
            calendarClass["borderColor"] = 'rgba(10, 181, 145, 1)'
        }
        newClasses.push(calendarClass)
    }
    const selectEvent = (e) => {
        const {event} = e
        setEvent(event)
        let classStart = new Date(event.start.d.d)
        
        let month = classStart.getMonth().toString().padStart(2, '0')
        month = Number(month) + 1
        let day = `${classStart.getDate().toString().padStart(2, '0')}/${month.toString().padStart(2, '0')}`
        let classEnd = new Date(event.end.d.d)
        setStart(`${classStart.getHours()}:${classStart.getMinutes().toString().padStart(2, '0')}`)
        setEnd( `${classEnd.getHours()}:${classEnd.getMinutes().toString().padStart(2, '0')}`)
        setDate(day)
        handleShow()
    }
    const deleteEvent = () => {
        if (event.start.d.d < tomorrow){
            alert("No se pueden cancelar clases con menos de 24 horas de antelación. En caso sea una emergencia, contacta con el coordinador académico.")
        }else{
            let studentIds = []
            let newStudents = []
            let subject = {}
            let currentClass = {}
            for (const thisClass of classes){
                if(thisClass._id === event.id){
                    studentIds = thisClass.studentIds
                    currentClass = thisClass
                }
            }
            for (const student of students){
                if (studentIds.includes(student._id)){
                    newStudents.push(student)
                }
            }
            
            for (const thisSubject of subjects){
                if (event.title.includes(thisSubject.name)){
                    subject = thisSubject
                }
            }
            let newParents = []
            for (const parent of parents){
                if (currentClass.families.includes(parent.family)){
                    newParents.push(parent)
                }
            }
            axios.delete(`${process.env.REACT_APP_BACKENDURL}/class/newclass`,{data:{
                tutor:user,
                students:newStudents,
                subject:subject,
                parents:newParents,
                event: {
                    id:event.id,
                    googleId:event.body,
                    start:event.start.d.d, //Not needed
                    end: event.end.d.d, //Not needed
                    calendarId:event.calendarId //Not needed
                }}
            }).then(response => {
                alert("La clase se ha eliminado correctamente");
                window.location.reload(false)
            }).catch(err => {
                alert("No se pudo eliminar la clase: " + JSON.stringify(err))
                console.log(err)
            })
        }
    }
    const goNextWeek = (event) => {
        const calendarInstance = calendarRef.current.getInstance();
        today.setDate(today.getDate() + 7)
        tomorrow.setDate(today.getDate() + 1)
        nextWeek.setDate(today.getDate() + 7)
        todayMorning = new Date(today)
        todayMorning.setHours(0,0,0,0)
        dispatch(getClasses({filter:{tutorId:user._id, end:{"$lte":nextWeek} , start:{"$gte": todayMorning}}, selection:{}}))
        calendarInstance.next()

    }
    const goPrevWeek = () => {
        const calendarInstance = calendarRef.current.getInstance();
        today.setDate(today.getDate() - 7)
        tomorrow.setDate(today.getDate() + 1)
        nextWeek.setDate(today.getDate() + 7)
        todayMorning = new Date(today)
        todayMorning.setHours(0,0,0,0)
        dispatch(getClasses({filter:{tutorId:user._id, end:{"$lte":nextWeek} , start:{"$gte": todayMorning}}, selection:{}}))
        calendarInstance.prev()
    }
    let NEED_TO_CONFIRM = <strong style={{color:'red'}}>Necesita confirmar antes de ver los links de la clase</strong>
    return(
        <>
            
            <Row>
                <Col>
                    <Button style={{marginLeft:'1%', backgroundColor:"rgba(10, 181, 145, 0.2)", borderColor:"rgba(10, 181, 145, 0.2)", color:"rgba(0,0,0)", "pointer-events": "none"}}>Pendiente</Button> 
                    <Button  style={{marginLeft:'1%', backgroundColor:"rgba(10, 181, 145, 1)", borderColor:"rgba(10, 181, 145, 1)", color:"rgba(0,0,0)", "pointer-events": "none"}}>Confirmado</Button>
                    <Button onClick={goPrevWeek} style={{marginLeft:'1%' }}> <b>&#60;</b> </Button>
                    <Button onClick={goNextWeek} style={{marginLeft:'1%' }}> <b>&#62;</b> </Button>
                </Col>
            </Row>
            <br />
            <p><b>Dale click a la clase para ver todos sus detalles o cancelarla en caso lo necesites.</b></p>
            <br />
            <Card>
                <Calendar
                    height="500px"
                    view="week"
                    ref={calendarRef}
                    events={newClasses}
                    // onAfterRenderEvent={onAfterRenderEvent}
                    onSelectDateTime={() => {
                        const calendarInstance = calendarRef.current.getInstance();
                        calendarInstance.clearGridSelections()
                    }}
                    // onBeforeUpdateEvent={updateEvent}
                    onClickEvent={selectEvent}
                    week={{
                        taskView:false,
                        startDayOfWeek:today.getDay(),
                        eventView:['time'],
                        // dayNames:["Lunes", "Martes", "Miércoles", "Jueves", "Viernes", "Sábado", "Domingo"]
                    }}
                    // useFormPopup={true}
                    
                    // useDetailPopup={true}
                />
            </Card>
            <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                <Modal.Title>{event.title}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p>Fecha: {date}</p>
                    <p>Horario: {start} - {end}</p>
                    {
                    event.isPending ?
                    NEED_TO_CONFIRM :
                    <>
                    <p><a href={`${window.location.origin}/call?id=${event.id}`} target="_blank">Link de clase</a></p>
                    <p><a href={`${window.location.origin}/tutorfeedback?id=${event.id}`} target="_blank">Link de Reporte</a></p>
                    <p><a href={`${window.location.origin}/feedback?id=${event.id}`} target="_blank">Feedback del estudiante</a></p>
                    </>}
                </Modal.Body>
                <Modal.Footer>
                
                <Button variant="secondary" onClick={handleClose}>
                    Cerrar
                </Button>
                {
                    event.isPending
                    ?
                    <>
                        <p style={{backgroundColor:"green", padding: 6, borderRadius:10}}>
                            <a href={`${window.location.origin}/confirm?id=${event.id}`} style={{color:"white"}} target="_blank">
                                Confirmar Clase
                                </a>
                            </p>
                    </>
                    :
                    <Button variant="danger" onClick={deleteEvent} disa>
                        Cancelar clase
                    </Button>
                }
                
                </Modal.Footer>
            </Modal>
            <br/>
        </>
    )
}

export default CalTutorClasses