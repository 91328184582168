import React, { useEffect, useState } from "react";
import axios from "axios";
import { useSelector, useDispatch } from "react-redux";
import { setParent } from "../../../store/parent/parent.slice";
import Select from "react-select";
import { Form, Row, Col, Button } from "react-bootstrap";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { getFamilies } from "../../../store/family/family.actions";
import { listFormat } from "../../../name_utils";

// const listFormat = (array) => {
//     let newArray = []
//     for (const elem of array){
//         let newElem = {...elem}
//         if ('surname' in newElem){
//             newElem['label'] = elem['name'] + " " + elem['surname']
//         }else{
//             newElem['label'] = elem['name']
//         }
//         newElem['value'] = elem['_id']
//         newArray.push(newElem)
//     }
//     return newArray
// }

const NewParent = () => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getFamilies({ filter: {}, selection: {} }));
  }, []);
  const { parent } = useSelector((state) => state.parent);
  const { families } = useSelector((state) => state.family);
  let newParent = { ...parent, role: "Parent" };
  const [disabled, setDisabled] = useState(false);
  const [isInvalid, setIsInvalid] = useState(false);
  const handleSubmit = async (event) => {
    event.preventDefault();
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.stopPropagation();
      setIsInvalid(true);
    } else {
      // let answer = window.confirm("Estás seguro quieres actualizar esta info?");
      setIsInvalid(false);
      setDisabled(true);
      try {
        let response = await axios.post(
          `${process.env.REACT_APP_BACKENDURL}/parent`,
          newParent
        );
        let savedParent = response.data;
        await axios.patch(`${process.env.REACT_APP_BACKENDURL}/student`, {
          id: savedParent["family"],
          update: { $addToSet: { parents: savedParent._id } },
        });
        await axios.post(
          `${process.env.REACT_APP_BACKENDURL}/jobs/welcomeParent`,
          { parentId: savedParent._id }
        );
      } catch (err) {
        console.log("Error for new parent: " + err);
      }
      window.location.reload(false);

      // if(answer){
      // }
    }
  };
  return (
    <div>
      Registra un padre yendo a{" "}
      <a
        href={`${process.env.REACT_APP_FRONTEND_URL}/register`}
        target="_blank"
      >
        {`${process.env.REACT_APP_FRONTEND_URL}/register`}
      </a>
      <div style={{ textAlign: "center", margin: 10, color: "red" }}>
        Nota: Si es que el padre quiere pertenecer a una familia existente, uno
        de ellos tiene que ir a Ajustes > Familia e invitar al padre para que le
        llege un link de registro personalizado.
      </div>
    </div>
  );
  return (
    <Form noValidate validated={isInvalid} onSubmit={handleSubmit}>
      <Row className="justify-content-md-center">
        <Form.Group as={Col} lg="2" controlId="name">
          <Form.Label>
            <b>Nombre del Padre</b>
          </Form.Label>
          <br />
          <br />
          <Form.Control
            required
            type="text"
            placeholder="Nombre"
            onChange={(e) => {
              newParent["name"] = e.target.value;
              dispatch(setParent(newParent));
            }}
          />
          <Form.Control.Feedback>Todo bien!</Form.Control.Feedback>
        </Form.Group>

        <Form.Group as={Col} lg="2" controlId="surname">
          <Form.Label>
            <b>Apellido(s) del Padre</b>
          </Form.Label>
          <br />
          <br />
          <Form.Control
            required
            type="text"
            placeholder="Apellido(s)"
            onChange={(e) => {
              newParent["surname"] = e.target.value;
              dispatch(setParent(newParent));
            }}
          />
          <Form.Control.Feedback>Todo bien!</Form.Control.Feedback>
        </Form.Group>
      </Row>
      <br />
      <br />
      <Row className="justify-content-md-center">
        <Form.Group as={Col} lg="2" controlId="name">
          <Form.Label>
            <b>Email del Padre</b>
          </Form.Label>
          <br />
          <Form.Control
            required
            type="email"
            onChange={(e) => {
              newParent["email"] = e.target.value;
              dispatch(setParent(newParent));
            }}
            placeholder="Email"
          />
          <Form.Control.Feedback>Todo bien!</Form.Control.Feedback>
        </Form.Group>
        <Form.Group as={Col} lg="2" controlId="surname">
          <Form.Label>
            <b>Whatsapp del padre</b>
          </Form.Label>

          <PhoneInput
            inputProps={{ required: true }}
            onChange={(phone) => {
              newParent["phone"] = "+" + phone;
              dispatch(setParent(newParent));
            }}
            enableSearch={true}
          />
          <Form.Control.Feedback>Todo bien!</Form.Control.Feedback>
        </Form.Group>
      </Row>
      <br />
      <br />
      <Row className="justify-content-md-center">
        <Form.Group as={Col} lg="3" controlId="surname">
          <Form.Label>
            <b>Familia</b>
          </Form.Label>
          <Form.Control
            as={Select}
            required
            options={listFormat(families)}
            onChange={(opt) => {
              newParent["family"] = opt.value;
              dispatch(setParent(newParent));
            }}
            placeholder="Familias"
          />
          <Form.Control.Feedback>Todo bien!</Form.Control.Feedback>
        </Form.Group>
      </Row>
      <br />
      <br />
      <Button variant="success" disabled={disabled} type="submit">
        Crear Padre
      </Button>
    </Form>
  );
};

export default NewParent;
