import React from "react"
import XplainForm from "./HomeForm"
import { Image } from "react-bootstrap"
const HomePage = () => {
    return (
        <div>
            {/* <Image fluid={true} src={require("../../assets/images/logo.png")}/> */}
            <XplainForm />
            
        </div>
    )
}

export default HomePage