import React, { useEffect, useState, useMemo } from "react";
import axios from "axios";
import { useSelector, useDispatch } from "react-redux";
import Select from "react-select";
import { Form, Row, Col, Button } from "react-bootstrap";
import PhoneInput from "react-phone-input-2";
import { setStudent } from "../../../store/student/student.slice";
import countryList from "react-select-country-list";
import "react-phone-input-2/lib/style.css";
import { getFamilies } from "../../../store/family/family.actions";
import { listFormat } from "../../../name_utils";

// const listFormat = (array) => {
//     let newArray = []
//     for (const elem of array){
//         let newElem = {...elem}
//         if ('surname' in newElem){
//             newElem['label'] = elem['name'] + " " + elem['surname']
//         }else{
//             newElem['label'] = elem['name']
//         }
//         newElem['value'] = elem['_id']
//         newArray.push(newElem)
//     }
//     return newArray
// }

const NewStudent = () => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getFamilies({ filter: {}, selection: {} }));
  }, []);
  const { families } = useSelector((state) => state.family);
  const { student } = useSelector((state) => state.student);
  let newStudent = { ...student, role: "Student" };
  const [disabled, setDisabled] = useState(false);
  const [isInvalid, setIsInvalid] = useState(false);
  const countries = useMemo(() => countryList().getData(), []);

  const handleSubmit = async (event) => {
    event.preventDefault();
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.stopPropagation();
      setIsInvalid(true);
    } else {
      // let answer = window.confirm("Estás seguro quieres actualizar esta info?");
      setIsInvalid(false);
      delete newStudent.parentName;
      delete newStudent._id;
      setDisabled(true);
      try {
        let response = await axios.post(
          `${process.env.REACT_APP_BACKENDURL}/student`,
          newStudent
        );
        let savedStudent = response.data;
        await axios.patch(`${process.env.REACT_APP_BACKENDURL}/family`, {
          id: savedStudent["family"],
          update: { $addToSet: { students: savedStudent._id } },
        });
        await axios.post(
          `${process.env.REACT_APP_BACKENDURL}/jobs/welcomeStudent`,
          { studentId: savedStudent._id }
        );
      } catch (err) {
        console.log("error for new student: " + err);
      }

      window.location.reload(false);
    }
  };
  return (
    <div>
      Registra un estudiante yendo a{" "}
      <a
        href={`${process.env.REACT_APP_FRONTEND_URL}/register`}
        target="_blank"
      >
        {`${process.env.REACT_APP_FRONTEND_URL}/register`}
      </a>
      <div style={{ textAlign: "center", margin: 10, color: "red" }}>
        Nota: Si es que el estudiante quiere pertenecer a una familia existente,
        uno de ellos tiene que ir a Ajustes > Familia e invitar al estudiante
        para que le llege un link de registro personalizado.
      </div>
    </div>
  );
  // TODO: Deprecated, need to check if still works
  return (
    <Form noValidate validated={isInvalid} onSubmit={handleSubmit}>
      <Row className="justify-content-md-center">
        <Form.Group as={Col} lg="2" controlId="name">
          <Form.Label>
            <b>Nombre del Estudiante</b>
          </Form.Label>
          <br />
          <br />
          <Form.Control
            required
            type="text"
            placeholder="Nombre"
            onChange={(e) => {
              newStudent["name"] = e.target.value;
              dispatch(setStudent(newStudent));
            }}
          />
          <Form.Control.Feedback>Todo bien!</Form.Control.Feedback>
        </Form.Group>

        <Form.Group as={Col} lg="2" controlId="surname">
          <Form.Label>
            <b>Apellido(s) del Estudiante</b>
          </Form.Label>
          <br />
          <br />
          <Form.Control
            required
            type="text"
            placeholder="Apellido(s)"
            onChange={(e) => {
              newStudent["surname"] = e.target.value;
              dispatch(setStudent(newStudent));
            }}
          />
          <Form.Control.Feedback>Todo bien!</Form.Control.Feedback>
        </Form.Group>
      </Row>
      <br />
      <br />
      <Row className="justify-content-md-center">
        <Form.Group as={Col} lg="2" controlId="name">
          <Form.Label>
            <b>Email del estudiante</b>
          </Form.Label>
          <br />
          <Form.Control
            required
            type="email"
            onChange={(e) => {
              newStudent["email"] = e.target.value;
              dispatch(setStudent(newStudent));
            }}
            placeholder="Gmail"
          />
          <Form.Control.Feedback>Todo bien!</Form.Control.Feedback>
        </Form.Group>
        <Form.Group as={Col} lg="2" controlId="surname">
          <Form.Label>
            <b>Whatsapp del estudiante</b>
          </Form.Label>

          <PhoneInput
            inputProps={{ required: true }}
            onChange={(phone) => {
              newStudent["phone"] = "+" + phone;
              dispatch(setStudent(newStudent));
            }}
            enableSearch={true}
          />
          <Form.Control.Feedback>Todo bien!</Form.Control.Feedback>
        </Form.Group>
      </Row>
      <br />
      <br />
      <Row className="justify-content-md-center">
        <Form.Group as={Col} lg="2" controlId="school">
          <Form.Label>
            <b>Colegio</b>
          </Form.Label>
          <br />
          <br />
          <Form.Control
            required
            type="text"
            placeholder="Colegio"
            onChange={(e) => {
              newStudent["school"] = e.target.value;
              dispatch(setStudent(newStudent));
            }}
          />
          <Form.Control.Feedback>Todo bien!</Form.Control.Feedback>
        </Form.Group>

        <Form.Group as={Col} lg="2" controlId="country">
          <Form.Label>
            <b>País del colegio</b>
          </Form.Label>
          <br />
          <br />
          <Form.Control
            as={Select}
            required
            options={countries}
            onChange={(opt) => {
              newStudent["country"] = opt.value;
              dispatch(setStudent(newStudent));
            }}
            placeholder="País"
          />
          <Form.Control.Feedback>Todo bien!</Form.Control.Feedback>
        </Form.Group>
      </Row>
      <br />
      <br />
      <Row className="justify-content-md-center">
        <Form.Group as={Col} lg="3" controlId="surname">
          <Form.Label>
            <b>Familia</b>
          </Form.Label>
          <Form.Control
            as={Select}
            required
            options={listFormat(families)}
            onChange={(opt) => {
              newStudent["family"] = opt.value;
              dispatch(setStudent(newStudent));
            }}
            placeholder="Familia"
          />
          <Form.Control.Feedback>Todo bien!</Form.Control.Feedback>
        </Form.Group>
      </Row>
      <br />
      <br />

      <Button variant="success" disabled={disabled} type="submit">
        Crear Estudiante
      </Button>
    </Form>
  );
};

export default NewStudent;
