import React, {useState, useEffect} from 'react';
import { useSelector } from 'react-redux';
import Table from 'react-bootstrap/Table';
import axios from 'axios';

const FamilyReferrals = () => {
    const {user} = useSelector((state) => state.auth)
    const [referrals, setReferrals] = useState([]);
    const [families, setFamilies] = useState([]);
    //Get family 
    let hasFamily = user.role === 'Student' || user.role === 'Parent';

    useEffect(() => {
        if (hasFamily) {
            //If student or parent, get family object first
            async function getFamily(){
                let fam = await axios.get(`${process.env.REACT_APP_BACKENDURL}/family`, {params:{id: user.family}});
                setReferrals(fam.data.referrals);
            }
            getFamily();
        } else{
            //A tutor
            setReferrals(user.referrals);
        }

    }, [])
    //Get all families that have used their referral
    useEffect(() => {
        if (referrals.length === 0){
            //Avoid when family is not there
            return;
        }
        async function getFamilies(){
            let data = {filter:{_id:{"$in":referrals}},selection:{}}
           let fams = await axios.get(`${process.env.REACT_APP_BACKENDURL}/family/all`, {params:data});
           setFamilies(fams.data);                                     
        }
        getFamilies();
    }, [referrals])  
    return(
        <>
        {
        families.length === 0 
        ?
            <p>{hasFamily ? "Su familia aún no ha": "Aún no has"} referido a ninguna familia :(</p>
            :
            (
            <>
                <p>
                    En total {hasFamily ? "su familia": "usted"} ha referido <b>{families.length}</b> familia{families.length > 1 && `s`}, y ganado <b>${10*families.length}</b>!
                </p>
                <Table striped bordered hover>
                    <thead>
                        <tr>
                            <th>#</th>
                            <th>Nombre</th>
                            <th>Apellido</th>
                            <th>Fecha</th>
                        </tr>
                    </thead>
                    <tbody>
                    {
                        families.map((family, i) => {
                            return(
                            <tr id={i+1}>
                                <td>{i+1}</td>
                                <td>{family.name}</td>
                                <td>{family.surname}</td>
                                <td>{family.created.substring(0, 10)}</td>
                            </tr>
                            )
                        })
                    }
                    </tbody>
                </Table>
            </>)
            }
        </>
    )
}

export default FamilyReferrals;