import React, {useState, useEffect} from 'react';
import axios from 'axios';
import { getStudent } from '../../store/student/student.actions';
import { getTutor } from "../../store/tutor/tutor.actions";
import {getSubject} from "../../store/subject/subject.actions";
import { useDispatch, useSelector } from 'react-redux';
import Table from 'react-bootstrap/Table';
import { formatFeedback } from '../../format_feedback';
import { getTutorVisibleName } from '../../name_utils';

const Feedback = () => {
    const dispatch = useDispatch()
    const [individualFeedback,setIndividualFeedback] = useState([]);
    const {student} = useSelector((state) => state.student)
    const {tutor} = useSelector((state) => state.tutor)
    const {subject} = useSelector((state) => state.subject)
    const {user} = useSelector((state) => state.auth)
    const [rating,setRating] = useState(0)
    const [start,setStart] = useState("")
    const [end,setEnd] = useState("")
    const [date,setDate] = useState("")
    useEffect(() => {
        let params = new URLSearchParams(window.location.search)
        let _id = params.get("id")
        if (typeof _id === "string" || _id instanceof String ){
            axios.get(`${process.env.REACT_APP_BACKENDURL}/class`, {
                params: {id:_id}
            }).then((response) => {
                setIndividualFeedback(response.data.individualStudentFeedback);
                setRating(response.data.rating)
                let newStart = new Date(response.data.start)
                let newEnd = new Date(response.data.end)
                setDate(`${(newStart.getDate()).toString().padStart(2, '0')}/${(newStart.getMonth() + 1).toString().padStart(2, '0')}`)
                setStart(`${newStart.getHours().toString().padStart(2, '0')}:${newStart.getMinutes().toString().padStart(2, '0')}`)
                setEnd(`${newEnd.getHours().toString().padStart(2, '0')}:${newEnd.getMinutes().toString().padStart(2, '0')}`)
                dispatch(getStudent({id:response.data.studentIds[0]}))
                dispatch(getTutor({id:response.data.tutorId}))
                dispatch(getSubject({id:response.data.subjectId}))
            })
        }
    }, [])
    return(
        <>
        <h1><b>Feedback de clases: {student.name} {student.surname}</b></h1>
        <br/>
        {user._id !== "" 
            ?
            <p>Hola {user.name}, aquí está el feedback de clases proporcionado por el/la Xplainer {student.name}</p>
            :
            <p>Hola, aquí está el feedback de clases proporcionado por el/la Xplainer {student.name}</p>
        }
        <div style={{display:"flex", flexDirection:"column", alignSelf:"center", alignItems:"center"}}>
        <h2>Detalles de la clase</h2>
        <Table striped bordered hover style={{width:"50%"}}>
            <thead>
                <th>Materia</th>
                <th>Tutor</th>
                <th>Fecha</th>
                <th>Horario</th>
            </thead>
            <tbody style={{verticalAlign:"middle"}}>
                <tr>
                    <td>{subject.name}</td>
                    <td>{getTutorVisibleName(tutor)}</td>
                    <td>{date}</td>
                    <td>{start} - {end}</td>
                </tr>
            </tbody>
        </Table>
        <h2>Feedback de Xplainer(s)</h2>
        
        {
            individualFeedback.map((feedback) => 
            <>
            <h2>{feedback.sender.name}: {`${feedback.rating}/10`}</h2>
            <Table striped bordered hover style={{width:"50%", verticalAlign:"center"}}>
                <tbody style={{verticalAlign:"middle"}}>
                    {feedback.text.split("-").map(value => {
                        if (!value){return null}
                        let newText = value.split(":")
                        return(
                            <tr>
                                <th>{newText[0]}</th>
                                <td texAlign="center">{formatFeedback(newText[1])}</td>
                            </tr>
                        )
                    })}
                </tbody>
            </Table>
            </>
            )
        }
        </div>
        </>
    )
}

export default Feedback