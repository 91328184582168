import { createSlice } from "@reduxjs/toolkit";
import {getFreeEvents} from "./calendar.actions"
import {differenceInMinutes} from "date-fns"

function makeid(length) {
    var result           = '';
    var characters       = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    var charactersLength = characters.length;
    for ( var i = 0; i < length; i++ ) {
      result += characters.charAt(Math.floor(Math.random() * 
 charactersLength));
   }
   return result;
}


const calendarSlice = createSlice({
    name: "calendar",
    initialState: {
        eventId:"",
        start:"",
        end:"",
        name: "",
        calendarId:"",
        events: [],
        freeEvents: []
    },
    reducers:{
        selectEvent: (state,action) => {
            state.eventId = action.payload.eventId
            state.start = action.payload.start.toISOString()
            state.end = action.payload.end.toISOString()
            state.name = action.payload.name
            state.calendarId = action.payload.calendarId
        },
        filterEventsByTutors: (state,action) => {
            let newFreeEvents = []
            let tutorCalendars = []
            for (const tutor of action.payload){
                tutorCalendars.push(tutor["calendarId"])
            }

            for (const event of state.allFreeEvents){
                if(tutorCalendars.includes(event.calendarId)){
                    newFreeEvents.push(event)
                }
            }
            state.freeEvents = newFreeEvents
        },
        resetFreeEvents: (state,action) => {
            state.freeEvents = []
        },
        setFreeEvents : (state,action) => {
            state.freeEvents = action.payload
        },
        setEvents : (state,action) => {
            console.log(action.payload);
            // FreeEvents: 
            // tutorClasses: all classes
            // classes: subset of tutorClasses, where user._id is included class.studentIds and class.subjectId = subject._id  
            let {freeEvents,classes,tutor,subject, tutorClasses} =  action.payload;
            console.log("freeEvents");
            console.log(freeEvents);
            console.log("tutorClasses")
            console.log(tutorClasses)

            if (!tutorClasses){tutorClasses = [];}
            let {today} = action.payload;
            today = new Date(today);
            let tomorrow = new Date(today);
            tomorrow.setDate(today.getDate() + 1);
            let todayMorning = new Date(today);
            todayMorning.setHours(0,0,0,0);
            let endWeek = new Date(today);
            endWeek.setDate(today.getDate() + 7);
            endWeek.setHours(0, 0, 0, 0);
            let newTutorClasses = tutorClasses.map((value) => {
                return {...value, start:new Date(value.start), end: new Date(value.end), calendarId: tutor.calendarId, 
                    id: value._id, title:subject ? subject.name: "", body:value.googleId,
                        isReadOnly: true,
                        category:"time", isPending: value.pending, 
                }
            })
            let newFreeEvents = freeEvents.map((value) => {
                if (value){
                    let start = new Date(value.start.dateTime)
                    let end = new Date(value.end.dateTime)
                    if (differenceInMinutes(end,start) > 30){
                        return {...value, start:start , end: end, calendarId: tutor.calendarId}
                    }
                }
            })
            let sortedFreeEvents = newFreeEvents.slice().sort((a,b) => a.start - b.start)
            let eventsDict = {} // index_day -> events that started and ended on that day (will split if needed)
            for (const event of sortedFreeEvents){
                if (!event){
                    continue;
                }
                let start = event.start
                let end = event.end
                if (start.getDay() == end.getDay()){
                    if (start > tomorrow || event.body){
                        if (start.getDay() in eventsDict){
                            eventsDict[start.getDay()].push(event)
                        }else{
                            eventsDict[start.getDay()] = [event]
                        }
                    }
                }else{
                    let breakPoint = new Date(start)
                    breakPoint.setDate(start.getDate() + 1)
                    breakPoint.setHours(0,0,0,0)
                    console.log(`For start = ${start} and end = ${end}: `)
                    console.log(`Adding breakpoint ${breakPoint}`)
                    let newEvent1 = {
                        id: event.id,
                        title: event.title,
                        body:event.body,
                        start: start, //.toJSON(),
                        end: breakPoint, //.toJSON(),
                        calendarId: event.calendarId,
                    }
                    let newEvent2 = {
                        id: event.id,
                        start: breakPoint,//.toJSON(),
                        title: event.title,
                        body:event.body,
                        end: end,//.toJSON(),
                        calendarId: event.calendarId
                    }
                    if (start > todayMorning){
                        if (start.getDay() in eventsDict){
                            eventsDict[start.getDay()].push(newEvent1)
                        }else{
                            eventsDict[start.getDay()] = [newEvent1]
                        }
                    }
                    if (end < endWeek){
                        if (end.getDay() in eventsDict){
                            eventsDict[end.getDay()].push(newEvent2)
                        }else{
                            eventsDict[end.getDay()] = [newEvent2]
                        }
                    }
                }
            }
            console.log(eventsDict);
            //Now making the blocked events from the freeEvents (basically the oppositve to the freeEvents)
            let blockedEvents = []
            for (let key = 0; key < 7; key ++) {
                let start = new Date(today)
                start.setHours(0,0,0,0)
                start.setDate(today.getDate() + key)
                let end = new Date(start)
                end.setDate(start.getDate() + 1)
                console.log(`Start: ${start.getDay()}`);
                if (!(start.getDay() in eventsDict)){
                    console.log("Not found, so add all day")
                    // If there were no events for that day, then add a blocked time for the full day
                    blockedEvents.push({
                        id: makeid(8),
                        calendarId: tutor.calendarId,
                        start: start,
                        end: end, 
                        category: 'time',
                        isReadOnly: true,
                        backgroundColor: 'rgba(52, 52, 52, 0.2)',
                        borderColor: 'rgba(52, 52, 52, 0.2)'
                    });
                    continue;             
                } 
                let eventsThatDay = eventsDict[start.getDay()]
                if (!eventsThatDay){
                    console.log("Not found :(")
                    continue;
                }
                // If the first one doesn't start at 00:00, then add [0, start] as a blocked time
                console.log(eventsThatDay[0].start);
                console.log(start);
                if (eventsThatDay[0].start > start){
                    console.log("First one doesn't start at 00:00, so add [0, start] as a blocked time")
                    blockedEvents.push({
                        id: makeid(8),
                        start: start,
                        end:eventsThatDay[0].start, 
                        calendarId: tutor.calendarId,
                        category: 'time',
                        isReadOnly: true,
                        backgroundColor: 'rgba(52, 52, 52, 0.2)',
                        borderColor: 'rgba(52, 52, 52, 0.2)'
                    })
                }
                // If it doesn't end at 23:59, then add [end, 00:00] as a blocked time
                if (eventsThatDay[eventsThatDay.length-1].end < end){
                    console.log("Last one doesn't end at 23:59, then add [end, 00:00] as a blocked time")
                    console.log("Adding ")
                    console.log(eventsThatDay[eventsThatDay.length-1].end)
                    console.log("to" + end)
                    blockedEvents.push({
                        id: makeid(8),
                        calendarId: tutor.calendarId,
                        start: eventsThatDay[eventsThatDay.length-1].end,
                        end: end, 
                        category: 'time',
                        isReadOnly: true,
                        backgroundColor: 'rgba(52, 52, 52, 0.2)',
                        borderColor: 'rgba(52, 52, 52, 0.2)'
                    })
                }
                //Go through all events for that day and add gaps as blocked times 
                for (let i = 0; i < eventsThatDay.length - 1; i++){
                    // If there is a gap with the next one, add that as a blocked time
                    if (eventsThatDay[i+1].start > eventsThatDay[i].end){
                        console.log("Added gap!")
                        blockedEvents.push({
                            id: makeid(8),
                            start: eventsThatDay[i].end, //.toJSON(),
                            end: eventsThatDay[i+1].start, //.toJSON(),
                            calendarId: tutor.calendarId,
                            category: 'time',
                            isReadOnly: true,
                            backgroundColor: 'rgba(52, 52, 52, 0.2)',
                            borderColor: 'rgba(52, 52, 52, 0.2)'
                        })
                    }
                }  
            }
            console.log("blocked events only counting free events")
            console.log(blockedEvents);
            //Add all classes as blocked events, where each event is on one day only
            let sortedClasses = newTutorClasses.slice().sort((a,b) => a.start - b.start);
            for (let currentClass of sortedClasses){
                let start = currentClass.start;
                let end = currentClass.end;
                if (start.getDay() === end.getDay()){
                    blockedEvents.push({
                        id: makeid(8),
                        start: currentClass.start, //.toJSON(),
                        end: currentClass.end, //.toJSON(),
                        calendarId: tutor.calendarId,
                        category: 'time',
                        isReadOnly: true,
                        backgroundColor: 'rgba(52, 52, 52, 0.2)',
                        borderColor: 'rgba(52, 52, 52, 0.2)'
                    })
                } else {
                    let breakPoint = new Date(start)
                    breakPoint.setDate(start.getDate() + 1)
                    breakPoint.setHours(0,0,0,0);
                    blockedEvents.push({
                        id: currentClass.id,
                        title: currentClass.title,
                        body:currentClass.body,
                        start: start, //.toJSON(),
                        end: breakPoint, //.toJSON(),
                        calendarId: currentClass.calendarId,
                    })
                    blockedEvents.push({
                        id: currentClass.id,
                        start: breakPoint, //.toJSON(),
                        title: currentClass.title,
                        body:currentClass.body,
                        end: end, //.toJSON(),
                        calendarId: currentClass.calendarId
                    })
                }
            }
            console.log("blocked events counting free events and classes, possible overlapping")
            console.log(blockedEvents);

            // Now `blockedEvents` are possibly-crashing, so merge overlappting ones
            let noCrashEvents = [];
            let eventsSorted = blockedEvents.slice().sort((a,b) => a.start - b.start)
            console.log("sorting by start...")
            console.log(eventsSorted);
            let i = 0;
            while (i < eventsSorted.length){
                let noCrashStart = eventsSorted[i].start;
                let noCrashEnd = eventsSorted[i].end;
                let j = i;
                //As long as it overlaps, merge the blocked event
                while ( 
                    j+1 < eventsSorted.length && //valid index
                    noCrashStart <= eventsSorted[j+1].start && //valid start
                    eventsSorted[j+1].start <= noCrashEnd && //overlap
                    eventsSorted[j+1].start.getDay() === noCrashStart.getDay() //same day
                    ){
                    // Only update the end if it's bigger than the current one
                    if (eventsSorted[j+1].end > noCrashEnd){
                        noCrashEnd = eventsSorted[j+1].end;
                    }
                    j+= 1;
                }
                i = j+1;
                //Add merged event
                noCrashEvents.push({
                    id: makeid(8),
                    start: noCrashStart.toJSON(),
                    end: noCrashEnd.toJSON(),
                    calendarId: tutor.calendarId,
                    category: 'time',
                    isReadOnly: true,
                    backgroundColor: 'rgba(52, 52, 52, 0.2)',
                    borderColor: 'rgba(52, 52, 52, 0.2)'
                }) 
            }
            console.log("Final no crash events: ")
            console.log(noCrashEvents)
            // for (const thisClass of newClasses){
            //     if (thisClass.pending){
            //         thisClass["backgroundColor"] = 'rgba(11, 156, 49, 0.2)'
            //         thisClass["borderColor"] = 'rgba(11, 156, 49, 0.2)'
            //     }else{
            //         thisClass["backgroundColor"] = 'rgba(11, 156, 49, 1)'
            //         thisClass["borderColor"] = 'rgba(11, 156, 49, 1)'
            //     }
            //     events.push(thisClass)
            // }
            state.events = noCrashEvents

        }
    },
    extraReducers: {
        [getFreeEvents.pending] : (state,action) => {
            console.log("fetching events")
        },
        [getFreeEvents.fulfilled]: (state,action) => {
            // let newFreeEvents = []
            // for (const event of action.payload){
            //     let newEvent = {}
            //     newEvent["start"] = event.start.dateTime
            //     newEvent["end"] = event.end.dateTime
            //     newEvent["title"] = "Libre"
            //     newEvent["calendarId"] = event.organizer.email
            //     newEvent["id"] = event.id
            //     newEvent["category"] = "time"
            //     newFreeEvents.push(newEvent)
            // }
            // state.freeEvents = newFreeEvents
            state.freeEvents = action.payload
        }
    }
})

export const {selectEvent, filterEventsByTutors,resetFreeEvents, setFreeEvents, setEvents} = calendarSlice.actions;
export default calendarSlice.reducer