import React from "react"
import { Image } from "react-bootstrap"
import { Row, Col} from "react-bootstrap"

const TCPage = () => {
    return (
        <div>
            <br/>
            <h1>TÉRMINOS Y CONDICIONES XPLAIN (T&C)</h1>
            <br />
            <br />
            <Row className="justify-content-md-center">
                <Col lg="7">
                    <h2>1. MEDIO</h2>
                    <br />
                    <p>1.1. Xplain S.A.C., con sede en Lima, inscrita en el registro mercantil del Tribunal de Comercio de Lima(en adelante, "Xplain"), ofrece una plataforma en línea para servicios de tutoría (en adelante, la "Plataforma") bajo los dominios xplain.la.</p>
                    <p>1.2. Estos T&C se aplicarán a todas las personas registradas en la plataforma como Usuarios (en adelante, el “Usuario”) y regulan los derechos y obligaciones de los Usuarios al visitar la plataforma y al utilizar las funciones y servicios ofrecidos en la misma.</p>
                    <p>1.3. Estos T&C se aplicarán exclusivamente al uso de la plataforma como Usuario. Cualquier T&C divergente, contradictorio o complementario no formará parte de la relación comercial entre el Usuario y Xplain, incluso si no ha sido expresamente contradicho. Los T&C divergentes, contradictorios o complementarios solo serán válidos si Xplain los ha aceptado por escrito.</p>
                    <p>1.4. Xplain puede cambiar las disposiciones de estos TyC en cualquier momento sin indicar los motivos. Dichos cambios se publicarán en la plataforma. Si el Usuario continúa utilizando la Plataforma después de conocer los T&C modificados, se considerarán aceptados los cambios.</p>
                    <p>1.5. Xplain almacena, procesa y utiliza datos personales del alumno con la finalidad del cumplimiento de un contrato firmado por el mismo o con la finalidad de cumplir con una declaración de conformidad en régimen de protección de datos que le haya sido impuesta. Para más detalles, le remitimos expresamente la declaración adicional sobre protección de datos.</p>
                    <br />
                    <h2>2.DESCRIPCIÓN DEL SERVICIO</h2>
                    <br />
                    <p>2.1. Los usuarios y las personas registradas como Tutores (en adelante, "Tutor") pueden organizar lecciones de video en línea (en adelante, "lecciones de video") a través de la plataforma.</p>
                    <p>2.2. Todas las personas que deseen ofrecer lecciones en video a través de la Plataforma pueden registrarse como Tutores. Estas personas no tienen que ser maestros de escuela. En caso de que los servicios ofrecidos por los Tutores a través de la Plataforma estén sujetos a pago, así se indicará de forma expresa y clara.</p>
                    <p>2.3. Xplain opera la Plataforma y organiza clases de tutores en nombre de los Tutores para los Usuarios de la plataforma. Además, Xplain ofrece ciertos servicios en relación con la incorporación de Usuarios y también brinda apoyo en el procesamiento de tutorías. Xplain está autorizado a aceptar pagos en nombre y por cuenta de los Tutores.</p>
                    <p>2.4. Xplain también puede proporcionar otros servicios a través de la Plataforma (por ejemplo, un chat de tarea o una lección de prueba gratuita para probar lecciones en video). Sin embargo, Xplain no está obligado a ofrecer dichas ofertas.</p>
                    <br />
                    <h2>3.REGISTRO, CONSENTIMIENTO DEL REPRESENTANTE LEGAL</h2>
                    <br />
                    <p>3.1. El uso de las clases requiere el correspondiente registro. En el curso del registro en las clases, el Usuario acepta la validez de estos T&C. El uso de las clases como Usuario o Tutor está reservado a las personas registradas. La inscripción es gratuita.</p>
                    <p>3.2. En el curso del registro, el Usuario debe proporcionar toda la información de forma completa y veraz y actualizarla inmediatamente en caso de cualquier cambio.</p>
                    <p>3.3. No existe derecho de registro en la Plataforma. Xplain se reserva el derecho de rechazar el registro de una persona como Usuario sin indicar los motivos o de excluir a los Usuarios ya registrados del uso de la Plataforma sin indicar los motivos.</p>
                    <p>3.4. El registro en la Plataforma es posible independientemente de la edad. Sin embargo, para utilizar los servicios de pago (lecciones en video) a través de la Plataforma, los Usuarios deben tener al menos 14 años y los Tutores al menos 18 años. Si un Usuario o Tutor aún no ha alcanzado la edad mínima respectiva, se requiere el consentimiento previo de su representante legal.</p>
                    <p>3.5. Xplain proporcionará al Usuario acceso a la Plataforma mediante la transmisión de datos de acceso al Gmail proporcionado por el Usuario. En este sentido, el Usuario está obligado a proporcionar a Xplain una dirección de correo electrónico a la que se puedan enviar los documentos al Usuario. Si esta dirección de correo electrónico cambia, el Usuario debe notificar a Xplain inmediatamente proporcionando la nueva dirección de correo electrónico.</p>
                    <br />
                    <h2>4.VIDEOLECCIONES, EXTENSIÓN AUTOMÁTICA</h2>
                    <br />
                    <p>4.1. Las tutoría pueden celebrarse (en particular) en forma de diferentes paquetes de lecciones/horas para ser consumidos por el Usuario dentro de 6 meses después del día de la compra. Los precios de los diferentes paquetes pueden variar en función del número de unidades y del periodo de tiempo previsto para el consumo de las unidades.</p>
                    <p>4.2. Los respectivos precios vigentes se anuncian en la Plataforma. Xplain se reserva el derecho de cambiar los precios en cualquier momento. Dichos cambios solo se aplican a unidades/paquetes comprados después de dicho cambio.</p>
                    <p>4.3. El pago del crédito para lecciones en video se realiza totalmente por adelantado.</p>
                    <p>4.4. Además de las lecciones de video individuales, los Tutores también pueden ofrecer cursos grupales grupales en vivo pagados a través de la Plataforma. Estas son básicamente compras únicas (y no paquetes) y suelen tener un mayor descuento a lo de las clases normales.</p>
                    <br />
                    <h2>5.DERECHOS Y OBLIGACIONES DE LOS USUARIOS</h2>
                    <br />
                    <p>5.1. El Usuario acepta que al usar lecciones en video a través de la Plataforma, Xplain puede deducir la cantidad por unidad acordada al concluir el contrato de tutor del saldo de crédito del Usuario y acreditar al Tutor el saldo de crédito que se le debe por esta unidad. Si el crédito caduca por no ser utilizado por el Usuario dentro del plazo previsto para tal fin, también se descontará de la cuenta del Usuario.</p>
                    <p>5.2. El Usuario tiene la posibilidad de crear una solicitud de reprogramación con un mínimo de 24 horas antes de una lección en video ya acordada con Xplain. Siempre que ésta sea aceptada por el Tutor al menos 24 horas antes del inicio de la unidad, el Usuario será automáticamente reprogramado para la nueva fecha seleccionada sin cargo. En caso de cancelación o reprogramación que no cumpla con las normas anteriores, así como en caso de que un Usuario no asista, Xplain se reserva el derecho de deducir una unidad de lecciones en video del saldo de crédito del Usuario en nombre y para el cuenta del Tutor.</p>
                    <p>5.3. El Usuario se compromete a mantener en secreto los datos de acceso proporcionados por Xplain. El Usuario es responsable de todas las actividades que emane de su cuenta de usuario.</p>
                    <p>5.4. El Usuario se compromete a notificar inmediatamente a Xplain si existe alguna sospecha de uso no autorizado de su cuenta de usuario. Si existe sospecha de uso no autorizado de una cuenta de usuario o si un Usuario viola las normas legales aplicables o estos T&C, Xplain se reserva el derecho de bloquear la cuenta de usuario.</p>
                    <br />
                    <h2>6.INFORMACIÓN SOBRE TUTORES</h2>
                    <br />
                    <p>6.1. Durante el registro, los tutores indican en qué áreas temáticas (por ejemplo, materias escolares) les gustaría ofrecer lecciones en video. Si se reciben solicitudes de lecciones en video en un área temática relevante de los Usuarios a través de la Plataforma, Xplain puede notificar a un Tutor; sin embargo, no hay obligación de hacerlo. Por otra parte, no existe obligación por parte del Tutor de responder a las solicitudes de lecciones en video.</p>
                    <p>6.2. Un Usuario no tiene derecho a ser referido a un Tutor en particular. La disponibilidad de los Tutores se basa exclusivamente en los horarios que ellos mismos han indicado en la Plataforma.</p>
                    <br />
                    <h2>7.USO DE LA PLATAFORMA</h2>
                    <br />
                    <p>7.1. Xplain hará que la Plataforma esté disponible para su uso las 24,7. Sin embargo, no existe ningún procedimiento que pueda garantizar que el software esté libre de errores en función del estado actual de la tecnología. Por lo tanto, Xplain no garantiza que el software en el que se basa la Plataforma esté completamente libre de errores y que no haya tiempo de inactividad de la plataforma. Además, la Plataforma puede no estar disponible temporalmente debido a trabajos de mantenimiento. Xplain anunciará el trabajo de mantenimiento programado en la Plataforma de manera oportuna. Sin embargo, Xplain no está obligado a actualizar la Plataforma.</p>
                    <p>7.2. La Plataforma y el software subyacente tienen derechos de autor y no se pueden usar ni modificar más allá de los derechos de uso acordados sin el consentimiento previo por escrito de Xplain. Asimismo, el diseño de la Plataforma y, en particular, el contenido colocado en la Plataforma (por ejemplo, bases de datos, fotos, imágenes, logotipos, videos, textos, gráficos, etc.) tienen derechos de autor o están sujetos a otras leyes para la protección de la propiedad intelectual y , a menos que se indique lo contrario, Xplain tiene derecho a estos.</p>
                    <p>7.3. Xplain puede hacer que la Plataforma sea interactiva. Esto brinda a los usuarios y tutores la oportunidad de revisarse entre sí y escribir sus propios comentarios. Xplain no verifica si las reseñas son potencialmente infundadas o engañosas. Los usuarios se comprometen a proporcionar únicamente información veraz en las reseñas y a observar las normas legales así como los presentes T&C. Los usuarios sólo podrán reproducir los datos personales con la autorización expresa del interesado.</p>
                    <p>7.4. Xplain se reserva el derecho de eliminar reseñas u otro contenido en su totalidad o en parte, especialmente si contienen contenido ilegal.</p>
                    <p>7.5. El Usuario reconoce y acepta expresamente que, en particular, pero no exclusivamente, se prohíbe la distribución a través de la Plataforma de los siguientes contenidos:
                        a. contenido racista, pornográfico, obsceno, insultante, vulgar, violento, denigrante e inmoral;
                        b. comentarios insultantes, hirientes o amenazas contra otros Usuarios, Tutores o terceros;
                        c. contenidos que perjudiquen o pongan en peligro a los niños o jóvenes en su desarrollo o crianza o que atenten contra la dignidad humana u otros bienes jurídicamente protegidos;
                        d. contenido que podría llevar a otros a cometer actos criminales u otros actos inmorales;
                        e. contenido copiado sobre el cual el Tutor no tiene derechos o que fue proporcionado por otras personas registradas;
                        f. contenido que presenta riesgos de seguridad, como virus;
                        g. Enviar correos electrónicos publicitarios no solicitados, correos no deseados, otros mensajes no solicitados, los llamados correos electrónicos bomba, etc. a otros Usuarios, Tutores o terceros o establecer enlaces a páginas de terceros con fines de distribución.</p>
                    <p>7.6. El Usuario otorga a Xplain el derecho gratuito, no exclusivo, perpetuo y sin restricciones de usar, editar, copiar y distribuir reseñas, fotos, gráficos, textos, mensajes de audio y videos publicados en la Plataforma para su provisión en la Plataforma y para publicidad de la Plataforma.</p>
                    <p>7.7. No se permite el uso de herramientas de análisis de datos del contenido de la Plataforma.</p>
                    <p>7.8. Xplain tiene derecho en cualquier momento a restringir o interrumpir por completo los servicios ofrecidos a través de la Plataforma sin indicar los motivos. Los cambios o restricciones significativos en los servicios ofrecidos se anunciarán en la Plataforma con la debida antelación.</p>
                    <p>7.9. Una restricción o interrupción de los servicios ofrecidos por Xplain no cambia estos TyC. Xplain solo puede cambiar los T&C de acuerdo con el procedimiento estipulado en la Sección 1.4.</p>
                    <br />
                    <h2>8.GARANTÍA, DESISTIMIENTO DEL CONTRATO DE TUTOR</h2>
                    <br />
                    <p>8.1. La relación entre el Usuario y Xplain se rige por las disposiciones legales de garantía.</p>
                    <p>8.2. Si un Tutor no ha cumplido o solo ha cumplido parcialmente sus clases (por ejemplo, unidades individuales) o lo ha cumplido mal (por ejemplo, incomparecencia o llegada tardía del Tutor, falta de competencia profesional, trato inadecuado del Usuario), el Usuario puede hacer una reclamación justificada. queja a hello@xplain.la . Xplain investigará el caso  e intentará mediar entre el Usuario y el Tutor. El Usuario y el Tutor acuerdan que Xplain tendrá la última palabra sobre la existencia y el monto de cualquier reclamo de reembolso de crédito. Si Xplain considera que la queja del Usuario está justificada, el crédito por una unidad de lecciones en video se acreditará a la cuenta de crédito del Usuario y cualquier crédito ya transferido a la cuenta de crédito del Tutor por la unidad se deducirá del saldo de crédito del Tutor.</p>
                    <p>8.3. En caso de violaciones graves de un Tutor o en caso de incumplimiento del contrato de tutor, se intentará encontrar un Tutor de reemplazo para el Usuario a petición del Usuario. Si se encuentra un Tutor de reemplazo, las clases del tutor se transfiere al tutor de reemplazo y el crédito no utilizado estará disponible para lecciones de video con el tutor de reemplazo en las condiciones previamente acordadas (unidades abiertas / período de tiempo acordado). Si no se puede encontrar un tutor de reemplazo o si el Usuario no desea tener un tutor de reemplazo, tiene derecho a rescindir las clases de conformidad con el punto 12.8.</p>
                    <br />
                    <h2>9.RESPONSABILIDAD</h2>
                    <br />
                    <p>9.1. Xplain solo será responsable, independientemente de los motivos legales, si el daño fue causado a través de Xplain como resultado de negligencia grave o dolo. Se excluye la responsabilidad de Xplain por negligencia leve, excepto por lesiones personales.</p>
                    <p>9.2. Xplain no asumirá ninguna responsabilidad por el cumplimiento de las obligaciones contractuales o de otro tipo de los Tutores hacia los Usuarios. En particular, Xplain no es responsable de la disponibilidad de Tutores o de cualquier daño sufrido por el Usuario en relación con la ejecución de las clases. Con respecto al reembolso del crédito utilizado para una unidad de videolección, un Usuario debe proceder de acuerdo con el punto 8.2. Todas las reclamaciones por encima de esto deben hacerse directamente al Tutor respectivo.</p>
                    <p>9.3. Xplain no será responsable por el contenido proporcionado por los Usuarios o Tutores en la Plataforma o el contenido de los sitios web a los que Xplain está vinculado. Por contenido ilegal, incorrecto o incompleto y especialmente por daños resultantes del uso o no uso de dicha información, el proveedor del sitio al que está vinculado Xplain es el único responsable. Por lo tanto, el Usuario es el único responsable de revisar y evaluar críticamente el contenido de otros Usuarios y Tutores.</p>
                    <br />
                    <h2>10.EXENCIÓN DE RESPONSABILIDAD POR PARTE DEL USUARIO</h2>
                    <br />
                    <p>10.1. El Usuario se compromete a indemnizar y mantener completamente indemne a Xplain si los Tutores responsabilizan a Xplain por reclamaciones derivadas de las clases o por parte de otros Usuarios, Tutores o terceros por reclamaciones civiles o penales, judiciales o extrajudiciales derivadas del contenido publicado por el Usuario en la plataforma. La indemnización también incluye sanciones, daños y perjuicios y todos los gastos de defensa legal.</p>
                    <br />
                    <h2>11.DERECHO DE REVOCACIÓN DE LOS USUARIOS (TUTORÍAS)</h2>
                    <br />
                    <p>11.1. En el caso de que un Usuario desee terminar sus clases con Xplain, el Usuario tendrá los siguientes derechos.</p>
                    <p>11.2. El Usuario tiene derecho a rescindir las clases sin necesidad de indicar los motivos.</p>
                    <p>11.3. El plazo de revocación es de 14 días a partir de la fecha de celebración de la compra.</p>
                    <p>11.4. Para ejercer el derecho de revocación, el Usuario deberá anunciar la revocación del contrato mediante una declaración clara. Para ello, el Usuario puede enviar una declaración inequívoca por correo electrónico a hello@xplain.la o por correo postal a Jirón Sinchi Roca 2198, Lima, Perú (la tramitación de la revocación es especialmente rápida si el Usuario utiliza el formulario o envía un correo electrónico). Si el Usuario hace uso de esta opción, Xplain enviará inmediatamente al Usuario una confirmación de recepción de dicha revocación.</p>
                    <p>11.5. Para cumplir con el plazo de revocación, basta con que el Usuario envíe la notificación del ejercicio del derecho de revocación antes de que finalice el plazo de revocación.</p>
                    <p>11.6. Consecuencias de la revocación: Si el Usuario revoca las horas de tutoría, todos los pagos realizados por la compra de créditos para videoclases deberán ser reembolsados ​​al Usuario de forma inmediata y a más tardar dentro de los catorce días siguientes al día en que se haya notificado la revocación del contrato. recibió. Para esta devolución se utilizará el mismo medio de pago que el utilizado para la compra original del crédito de horas.</p>
                    <p>11.7. Excepción al derecho de revocación: El derecho de revocación ya no se aplica desde que se realiza la primera sesión de tutoría paga dentro del período de 14 días. No se aplica independientemente de si el Usuario efectivamente ha hecho uso de la unidad o no se ha presentado.</p>
                    <p>11.8. Después de la expiración del período de revocación de 14 días desde el día en que se celebró el contrato, el Usuario ya no puede rescindir el contrato de tutoría y no puede reclamar el monto pagado por la compra de créditos. Queda intacto el derecho a la revocación extraordinaria del contrato de tutoría. En caso de tal revocación extraordinaria, el Usuario podrá reclamar el importe pagado por la adquisición del crédito (no utilizado).</p>
                    <br />
                    <h2>12.DURACIÓN/TERMINACIÓN DE LAS TUTORÍAS</h2>
                    <br />
                    <p>12.1. La relación entre Xplain  y el Usuario respectivo con respecto al uso de la Plataforma comienza con el registro exitoso del Usuario en la Plataforma. Tanto el Usuario como Xplain tienen derecho a rescindir esta relación (en lo que respecta al uso de la Plataforma) en cualquier momento con efecto inmediato, en cuyo caso deberá cancelarse el registro como Usuario. Esto no se aplica a las clases del tutor permanente concluido, cuyo procesamiento, en cualquier caso, aún debe ser facilitado por Xplain. Las clases sólo podrán ser rescindididas extraordinariamente mediante prueba oficial de las siguientes causas:</p>
                        <p>- en caso de enfermedad grave y persistente del usuario, que haga irrazonable la participación en las unidades.</p>
                        <p>- en caso de desempleo de al menos 3 meses y la consiguiente pérdida de ingresos (siempre que el empleo estuviera vigente al finalizar la compra de clases inicial).</p>
                        <p>- en caso de reubicación en el extranjero con una diferencia horaria tan grande que la participación en las unidades no sea razonable.</p>
                        <p>- si no se puede encontrar un maestro sustituto dentro de un plazo razonable de acuerdo con el punto 8.3.</p>
                    <br />
                    <h2>13.DURACIÓN/TERMINACIÓN DE LAS TUTORÍAS</h2>
                    <br />
                    <p>13.1. Estos T&C y todos los demás acuerdos con Xplain están sujetos exclusivamente a la ley peruana, excluyendo la Convención de las Naciones Unidas sobre Contratos para la Venta Internacional de Mercaderías y las normas de referencia del derecho internacional privado. Esta elección de ley solo se aplica en la medida en que no prive a un Usuario de la protección que le otorgan las disposiciones imperativas de la ley del estado en el que tiene su residencia habitual.</p>
                    <p>13.2. El poder judicial de Perú es responsable de todas las acciones legales iniciadas contra Xplain por un Usuario debido a disputas que surjan de la relación contractual. Esto no afecta el derecho del Usuario a demandar en cualquier otro lugar de jurisdicción legal. Para todas las acciones legales entabladas contra un Usuario que tenga su residencia, domicilio habitual o lugar de trabajo en Perú por disputas derivadas de la relación contractual, uno de los tribunales en cuyo distrito el Usuario tenga su residencia, domicilio habitual o lugar de empleo será competente. Para los Usuarios que no residían en Perú en el momento de la celebración del contrato, se aplicarán los lugares de jurisdicción legales.</p>
                    <br />
                    <h2>14.DISPOSICIONES FINALES</h2>
                    <br />
                    <p>14.1. En caso de contradicciones entre estos T&C y acuerdos escritos divergentes entre un Usuario y Xplain, las disposiciones de los acuerdos divergentes prevalecerán sobre estos T&C.</p>
                    <p>14.2. En caso de que las disposiciones individuales de estos T&C sean o dejen de ser válidas, esto no afectará el contenido restante de los T&C. La disposición inválida será reemplazada por una disposición que sea legalmente válida y se acerque más al propósito económico del Usuario y de Xplain.</p>
                    <p>14.3. Un "tercero" en el sentido de estos T&C es cualquier persona física o jurídica que sea diferente de la persona registrada como Usuario y de Xplain, incluso si existieran relaciones jurídicas y/o económicas con dicha persona.</p>
                    <p>14.4. A menos que se estipule lo contrario en estos Términos y condiciones, ni un Usuario ni Xplain pueden transferir o ceder las disposiciones y los derechos de estos Términos y condiciones a terceros sin el consentimiento por escrito de la otra parte.</p>
                </Col>
            </Row>
            <br/>
            
        </div>
    )
}

export default TCPage