import React, {useEffect} from 'react';
import {getClasses} from "../../../store/class/class.actions"
import {getSubjects} from "../../../store/subject/subject.actions"
import { setStudent } from '../../../store/student/student.slice';
import {Col,Form,Row, Table, Button} from 'react-bootstrap';
import { useSelector,useDispatch } from 'react-redux';
import Select from 'react-select';
import { listFormat } from '../../../name_utils';
import { useUserIfLoggedIn } from '../../../auth_utils';
let today = new Date()
let lastMonth = new Date(today)
lastMonth.setDate(today.getDate() - 30)

function getFirstDayOfMonth() {
    return new Date(today.getFullYear(), today.getMonth(), 1);
}

function getLastDayOfMonth() {
    return new Date(today.getFullYear(), today.getMonth() + 1, 0);
}

// const listFormat = (array) => {
//     let newArray = []
//     for (const elem of array){
//         let newElem = {...elem}
//         if ('surname' in newElem){
//             newElem['label'] = elem['name'] + " " + elem['surname']
//         }else{
//             newElem['label'] = elem['name']
//         }
//         newElem['value'] = elem['_id']
//         newArray.push(newElem)
//     }
//     return newArray
// }
// Weird subject id: 63faabd77cdb6f0eb8c17a0a <- find out if its a document's id
const StudentPastReports = () => {
    useUserIfLoggedIn();
    const dispatch = useDispatch() 
    const {user} = useSelector((state) => state.auth)
    const {classes} = useSelector((state) => state.class)
    const {subjects} = useSelector((state) => state.subject)
    const {students} = useSelector((state) => state.student)
    useEffect(() => {
        dispatch(getClasses({filter:{tutorId:user._id, subjectId:{"$in":user.subjects}, end:{"$lte":today.toISOString()},  start:{"$gte": lastMonth}}, selection:{}}))
        dispatch(getSubjects({filter:{_id:{"$in":user.subjects}}, selection:{}}))
    }, [])
    let newClasses = []
    for (const currentClass of classes){
        let newClass = {...currentClass}
        for (const subject of subjects){
            if (subject._id == newClass.subjectId){
                newClass["subjectName"] = subject['name']
            }
        }
        newClass["studentNames"] = []
        for (const student of students){
            if (newClass.studentIds.includes(student._id)){
                newClass["studentNames"] += student['name'].split(" ")[0] + " " + student['surname'].split(" ")[0] + ","
            }
        }
        newClass["studentNames"] = newClass["studentNames"].slice(0, -1); 

        newClasses.push(newClass)
    }
    newClasses = newClasses.slice().sort((a,b) => new Date(b.start) -new Date(a.start))

    return(
        <>
            <h3>Clases Pasadas (Últimos 30 días)</h3>
            <br/>
            <Row className="justify-content-md-center"> 
            <Form.Group as={Col} lg="4" controlId="student">
            <Form.Control
                as={Select}
                options={[{label:"Todos", value:"everything"},...listFormat(students)]}
                required
                placeholder="Xplainer"
                onChange={opt => {
                    if(opt.value == "everything"){
                        dispatch(getClasses({filter:{ end:{"$lt":today.toISOString()}, subjectId:{"$in":user.subjects}}, selection:{}}))
                    }else{
                        dispatch(getClasses({filter:{studentIds:{"$in": [opt._id]}, end:{"$lt":today.toISOString()}, subjectId:{"$in":user.subjects}}, selection:{}}))
                    }
                }}
            />
            </Form.Group>
            </Row>
            {/* <br />
            <Row className="justify-content-md-center"> 
            <Form.Group as={Col} lg="4" controlId="student">
            <Form.Label>Escoge la materia</Form.Label>
            <Form.Control
                as={Select}
                options={listFormat(subjects)}
                required
                onChange={opt => {
                    dispatch(setSubject(opt))
                    dispatch(getClasses({filter:{studentIds:{"$in": [student._id]}, subjectId: opt.value,end:{"$lt":today.toISOString()}}, selection:{}}))
                }}
            />
            </Form.Group>
            </Row> */}
            <br />
            
            <Table striped bordered hover style={{ width: '80%','marginLeft':'auto','marginRight':'auto' }}>
                <thead>
                <tr>
                <th>Fecha</th>
                <th>Comienzo</th>
                <th>Final</th>
                <th>Xplainer(s)</th>
                <th>Materia</th>
                <th>Extra info</th>
                <th>Rating</th>
                <th>Feedback</th>
                <th>Reporte</th>
                </tr>
                </thead>
                <tbody>
                {newClasses.map((currentClass,id) => {
                    let start = new Date(currentClass.start)
                    let month = start.getMonth().toString().padStart(2, '0')
                    month = Number(month) + 1
                    let day = `${start.getDate().toString().padStart(2, '0')}/${month.toString().padStart(2, '0')}`
                    let end = new Date(currentClass.end)
                    start = `${start.getHours()}:${start.getMinutes().toString().padStart(2, '0')}`
                    end = `${end.getHours()}:${end.getMinutes().toString().padStart(2, '0')}`
                    let report;
                    if(currentClass.tutorFeedback == ""){
                        report = <Button><a href={`${window.location.origin}/tutorfeedback?id=${currentClass._id}`}  style={{color:"rgb(255,255,255)"}} target="_blank">Enviar reporte</a></Button>
                    }else{
                        report = <Button style={{backgroundColor:"rgba(10, 181, 145, 1)", borderColor:"rgba(10, 181, 145, 1)"}}><a href={`${window.location.origin}/report?id=${currentClass._id}`}  style={{color:"rgb(255,255,255)"}} target="_blank">Ver el reporte</a></Button>
                    }
                    let feedback = ""
                    let rating = ""
                    if (currentClass.individualStudentFeedback.length === 0){
                        feedback = "N/A"
                        rating = "N/A"
                    }else{
                        feedback = <Button variant="secondary" ><a href={`${window.location.origin}/feedback?id=${currentClass._id}`} target="_blank" style={{color:"rgb(255,255,255)"}}>Leer Feedback</a></Button>
                        rating = `${currentClass.rating}/10`
                    }
                    return(
                        <tr>
                            <td>{day}</td>
                            <td>{start}</td>
                            <td>{end}</td>
                            <td>{currentClass.studentNames}</td>
                            <td>{currentClass.subjectName}</td>
                            <td>{currentClass.prevInfo}</td>
                            <td>{rating}</td>
                            <td>{feedback}</td>
                            <td>{report}</td>
                        </tr>
                    )
                })}
                </tbody>
            </Table>
        
        </>
    )

}

export default StudentPastReports