import axios from 'axios';
import React, {useState, useEffect, useRef} from 'react';
import { useSelector } from 'react-redux';
import {useUserIfLoggedIn} from "../../auth_utils";
import {Input, ChatList, SideBar, Navbar, MessageList, Avatar } from "react-chat-elements"
import "react-chat-elements/dist/main.css"
import "./Chat.css";
import { Button, Table, Image } from 'react-bootstrap';
import {IoSendSharp} from 'react-icons/io5';
import {io} from 'socket.io-client';
import {formatFeedback} from "../../format_feedback";
let clearRef = () => {};

let COLORS = {
    XPLAIN_GREEN: "#0AB591",
  };

let socket;
const Chat = () => {
    useUserIfLoggedIn();
    const [currentChat, _setCurrentChat] = useState(null);
    const chatRef = useRef();
    const setCurrentChat = (chat) => {
        chatRef.current = chat;
        _setCurrentChat(chat);
    }
    const [currentChatMessages, setCurrentChatMessages] = useState([]);
    const [currentMessage, setCurrentMessage] = useState("");
    const {user} =  useSelector((state) => state.auth);
    const [chats, setChats] = useState([]);
    const orderChats = (prevChats) => {
        prevChats.sort((a, b) => {
            //To make sure chats with no last message are at the bottom
            if (!a.lastMessage || !a.lastMessage.timestamp){
                return 1;
            }
            if (!b.lastMessage || !b.lastMessage.timestamp){
                return -1;
            }
            return new Date(b.lastMessage.timestamp) - new Date(a.lastMessage.timestamp)});
        return prevChats;
    }
    const getChats = async () => {
        if (!user || !user._id){
            return;
        }
        let response = await axios.get(`${process.env.REACT_APP_BACKENDURL}/chat/rooms`, {params: {
            userId: user._id
        }});
        setChats(orderChats(response.data));
        // Start listening for new messages
        // for (let chat of response.data) {
        //     console.log(`Adding new chat: ${chat._id}`);
        //     socket.join(chat._id.toString());
        // }
    }
    useEffect(() => {
        getChats();
        //So that the scroll is on the bottom
        if (messageListReferance && messageListReferance.current) {
         messageListReferance.current.scrollTop = messageListReferance.current.scrollHeight;
        }
        socket = io(process.env.REACT_APP_BACKENDURL, {autoConnect: false});
        socket.connect();
        socket.on('message_received', (message) => {
            //Only update the chat if the message is from the current chat
            //TODO: Maybe keep track of the messages in all chats at once
            handleNewMessage(message);
        })
        socket.on("joined_room", async (roomId) => {
            console.log(`Detecting joining room: ${roomId}`);
            let response = await axios.get(`${process.env.REACT_APP_BACKENDURL}/chat/messages`, {params: {
                roomId: roomId,
            }});
            setCurrentChatMessages(response.data);
        })
        return () => {
            socket.off('message_received');
            socket.off('joined_room');
        }
    }, [])
    const handleNewMessage = (message) => {
        console.log(`A message has been received:`);
        console.log(message);
        if (chatRef.current && message.roomId === chatRef.current._id.toString()) {
            setCurrentChatMessages(prevMessages => [...prevMessages, message]);
            setCurrentMessage("");
        }
        //Updating chat for last message

        setChats((prevChats)=> {
            let newChats = prevChats.map(chat => {
                if (chat._id.toString() !== message.roomId){
                    return chat;
                }
                return {...chat, lastMessage: message}
            });
            return orderChats(newChats);
        });
    }
    useEffect(()=>{
        getChats()
    }, [user]);
    useEffect(()=>{
        if (!currentChat){
            return;
        }
        //If select other chat, scroll to the bottom
        if (messageListReferance && messageListReferance.current) {
            messageListReferance.current.scrollTop = messageListReferance.current.scrollHeight;
        }
    }, [currentChat]);
    const messageListReferance = useRef();
    const inputReferance = useRef();
    const handleSetCurrentChat = (chat) => {
        if (currentChat && chat._id.toString() === currentChat._id.toString()){
            console.log("Already selected chat");
            return;
        }
        setCurrentChatMessages([]);
        setCurrentChat(chat);
        setCurrentMessage("");
        socket.emit("join_room", chat._id.toString());
    }
    const sendMessage = () => {
        if (!currentChat){
            console.log("No chat selected");
            return;
        }
        clearRef();
        socket.emit("new_message", {
            roomId: currentChat._id.toString(),
            sender: user._id.toString(),
            message: currentMessage,
            timestamp: Date.now(),
        });
    }
    return (
        (!user || !user._id) ? <div>Tiene que iniciar sesión</div>:
        <div className='1rce-example' style={{
            display: 'flex',
            flexDirection: 'row',
            // flex: 1,
            // height: "100%",
            // background: "rgb(246 250 250)",
            border: "1px solid rgb(238, 235, 235)",
            height: "80vh",
            maxHeight: "80vh",
            textAlign: "initial",
        }}>
            <div className="1rce-example-component" style={{
                // backgroundColor: COLORS.XPLAIN_GREEN,
                flex: 1
                }}>
                <ChatList
                className="chat-list"
                onClick={(obj)=>{handleSetCurrentChat(obj)}}
                // toBottomHeight={1000}
                dataSource = {chats.map((chat)=>({
                    ...chat,
                    letterItem: chat.profileLetter[user.role],
                    title: chat.title,
                    className: (chatRef.current && chat._id.toString() === chatRef.current._id.toString())? "chat-selected": "",
                    subtitle: (chat.lastMessage ? chat.lastMessage.message: ""),
                    date: (chat.lastMessage? chat.lastMessage.timestamp: ""),
                }))}
                // dataSource={[...Array(2).keys()].map(_ =>{
                //     return {
                //         letterItem:{letter:"R", id:"COLORS.XPLAIN_GREEN"},
                //         title: 'Kursat',
                //         subtitle: "Why don't we go to the No Way Home movie this weekend ?",
                //         date: new Date(),
                //         unread: 3,
                //         // statusColor: 'lightgreen',
                //         statusColorType: "encircle",
                //         // statusText: "Online",
                //         // muted: true,
                //         // showMute: true,
                //         // showVideoCall: true,
    
                //     }
                // })}
                />
            </div>

            <div className='1right-panel' style={{  
                flex: 2,
                display: "flex",
                flexDirection: "column",
                opacity: 0.9,
            }}>
                {
                    (currentChat === null) ? (
                        <div style={{
                                display: "flex",
                                flex: 1, 
                                backgroundColor: COLORS.XPLAIN_GREEN,
                                alignItems: "center",
                                justifyContent: "center",
                                flexDirection: 'column',
                            }}>
                            <div style={{textAlign: "center", backgroundColor: "", padding: 10, borderRadius: 10}}>
                                <Image src={require("../../assets/images/xplain_logo_png.png")} height="60"/>
                                <h1 style={{color: "white"}}><i>Seleccione un chat</i></h1>
                            </div>
                        </div>
                    ):
                    <>
                        <div style={{
                            padding: 10,
                            borderBottom: `5px solid ${COLORS.XPLAIN_GREEN}`,
                            backgroundColor: "white",
                            }}>
                            <h2><i>{currentChat.title}</i></h2>
                        </div>
                        <MessageList
                            className='message-list'
                            referance={messageListReferance}
                            // dataSource={[]}
                            // lockable={true}
                            downButton={true}
                            onDownButtonClick={(obj)=>{}}
                            sendMessagePreview={true}
                            // toBottomHeight={'100%'}
                            // replyButton={true}
                            dataSource={currentChatMessages.map((message)=>({
                                position: message.sender._id.toString() === user._id.toString() ? "right" : "left",
                                type: "text",
                                title: (
                                    <div style={{display:"flex", justifyContent:"center", alignItems:"center"}}>
                                        <Avatar 
                                            type="non-default" //The 'default' type messs up with the width/height of avatar
                                            size="no-default"  //The 'default' type messs up with the width/height of avatar
                                            className="chat-icon" 
                                            letterItem={{letter: message.sender.name[0], id: message.sender.name}}
                                        />
                                        <div>{message.sender.role === "Tutor" ? `Tutor ${message.sender.name}`: message.sender.name}</div>
                                    </div>
                                ),
                                text: (
                                <div>
                                    {!message.isSystemMessage ? message.message:
                                    <>
                                    <h1>{message.systemTextTitle}</h1>
                                    <Table striped bordered hover style={{width:"50%", verticalAlign:"center"}}>
                                        <tbody style={{verticalAlign:"middle"}}>
                                            {message.message.split("-").map(value => {
                                                if (!value){return null}
                                                let newText = value.split(":")
                                                return(
                                                    <tr>
                                                        <th>{newText[0]}</th>
                                                        <td texAlign="center">{formatFeedback(newText[1])}</td>
                                                    </tr>
                                                )
                                            })}
                                        </tbody>
                                    </Table>
                                    </>
                                    }
                                </div>
                                ),
                                date: message.timestamp,
                                isSystem: message.isSystemMessage
                            }))}
                            dataSource_fake={[...Array(50).keys()].map(_ =>{
                                return {
                                    position: Math.random() < 0.5 ? "left": "right",
                                    type:"text",
                                    title:(
                                        <div style={{display:"flex", justifyContent:"center", alignItems:"center"}}>
                                            <Avatar 
                                                type="non-default" //The 'default' type messs up with the width/height of avatar
                                                size="no-default"  //The 'default' type messs up with the width/height of avatar
                                                className="chat-icon" 
                                                letterItem={{letter: "R", id: "23"}}
                                            />
                    
                                            <div>klingon</div>
                                        </div>
                                    ),
                                    text:"Give me a message list example !",
                                    // letterItem:{letter:"R", id:"COLORS.XPLAIN_GREEN"},
                                    // id: "aaa",
                                    // letterItem: "R",
                                    // avatar: "a",
                                    // avatar: "a", 
                                    date: new Date(),
                                    // titleColor: "red",
                                }
                            })}
                            messageBoxStyles={{
                                // border: `2px solid ${COLORS.XPLAIN_GREEN}`
                                // height: 300,
                            }}
                        />
                        <Input
                        referance={inputReferance}
                        clear={(clear) => clearRef = clear}
                        lockable={true}
                        multiline={false}
                        className='rce-example-input'
                        placeholder='Escribe un mensaje...'
                        defaultValue=''
                        // minHeight={100}
                        maxHeight={200}
                        onKeyPress={(e) => {
                            if (e.key === 'Enter') {
                                sendMessage();
                            }
                        }}
                        value={currentMessage}
                        onChange={(e) => {setCurrentMessage(e.target.value)}}
                        rightButtons={
                                <Button 
                                    size="lg"
                                    className="chat-send-button"
                                    variant="light"
                                    style={{
                                        border: `2px solid ${COLORS.XPLAIN_GREEN}`,
                                        margin: 0,
                                        width: 100,
                                        flex: 1,
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                        
                                    }} 

                                    onClick={sendMessage} 
                                >
                                    <IoSendSharp size={30} className="icon" color={COLORS.XPLAIN_GREEN}/>
                                </Button>
                            }
                        />
                    </>
                }
            </div>
        </div>
    )
}

export default Chat;