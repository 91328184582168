import React from "react"
import LoginForm from "./LoginForm"
import { Image } from "react-bootstrap"
const LoginPage = () => {
    return (
        <div>
            <br/>
            <br/>
            <LoginForm />
        </div>
    )
}

export default LoginPage