import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import axios from "axios";
import Image from "react-bootstrap/Image";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Spinner from "react-bootstrap/Spinner";
import ListGroup from "react-bootstrap/ListGroup";
import Card from "react-bootstrap/Card";
import Accordion from "react-bootstrap/Accordion";
import { getTutor } from "../../store/tutor/tutor.actions";
import ReactPlayer from 'react-player'
// import Button from "react-bootstrap/Button"
import "./TutorPage.css";
import { Button } from "react-bootstrap";
import {useNavigate} from "react-router-dom"
import { getTutorVisibleName } from "../../name_utils";

let COLORS = {
  XPLAIN_GREEN: "#0AB591",
};
// Assumes that s = ... [a] x [b] ... only in one place
// returns x
const LinkedinIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="34"
      height="34"
      viewBox="0 0 34 34"
      class="global-nav__logo"
    >
      <title>LinkedIn</title>
      <g>
        <path
          d="M34,2.5v29A2.5,2.5,0,0,1,31.5,34H2.5A2.5,2.5,0,0,1,0,31.5V2.5A2.5,2.5,0,0,1,2.5,0h29A2.5,2.5,0,0,1,34,2.5ZM10,13H5V29h5Zm.45-5.5A2.88,2.88,0,0,0,7.59,4.6H7.5a2.9,2.9,0,0,0,0,5.8h0a2.88,2.88,0,0,0,2.95-2.81ZM29,19.28c0-4.81-3.06-6.68-6.1-6.68a5.7,5.7,0,0,0-5.06,2.58H17.7V13H13V29h5V20.49a3.32,3.32,0,0,1,3-3.58h.19c1.59,0,2.77,1,2.77,3.52V29h5Z"
          fill={"#0072b1"}
        ></path>
      </g>
    </svg>
  );
};
const getStringBetween = (s, a, b) => {
  let begin = s.indexOf(a) + a.length;
  let end = s.indexOf(b);
  return s.substring(begin, end);
};

let TUTOR_NO_PIC_LINK = require("../../assets/images/tutor_no_pic.png");

const getValidDriveLink = (link) => {
  try {
    let id = getStringBetween(link, "/file/d/", "/view?");
    let driveLink = `https://drive.google.com/uc?export=view&id=${id}`;
    console.log(`Final driveLink:`);
    console.log(driveLink);
    return driveLink;
  } catch (err) {
    console.log(`Error: ${err}`);
    return TUTOR_NO_PIC_LINK;
  }
};

const getAirtableLink = (tutor) => {
  //TODO: Either make sure that the links are in a better format (just link)
  //      or make sure every link follows that format
  if (!tutor) {
    return;
  }
  let photoLink = tutor["photo"];
  if (!photoLink || photoLink == "" || !photoLink.includes("airtable")) {
    return;
  }
  let first = photoLink.indexOf("http");
  let last;
  if (photoLink[photoLink.length - 1] === ")") {
    //It's an airtable link
    last = photoLink.length - 2;
  } else {
    //It's just a regular link
    last = photoLink.length - 1;
  }
  return photoLink.substring(first, last + 1);
};

const getImageLink = (tutor) => {
  let link = getAirtableLink(tutor);
  if (link) {
    return link;
  } else {
    if (!tutor["imageDriveLink"]){
      return TUTOR_NO_PIC_LINK;
    }
    link = getValidDriveLink(tutor["imageDriveLink"]);
    if (link) {
      return link;
    } else {
      return TUTOR_NO_PIC_LINK;
    }
  }
};
const TutorPage = ({ tutorId }) => {
  const navigate = useNavigate();
  const { user } = useSelector((state) => state.auth);
  // console.log("found");
  // console.log(tutorId);
  // console.log(user._id);
  const { tutor } = useSelector((state) => state.tutor);
  const [subjectNames, setSubjectNames] = useState();
  const [errorMessage, setErrorMessage] = useState("");
  const dispatch = useDispatch();
  useEffect(() => {
    if (!tutorId) {
      return;
    }
    dispatch(getTutor({ id: tutorId }));
    // async function getTutorInfo(){
    //     let idInfo = {tutorId};
    //     console.log(idInfo);
    //     let tut;
    //     try{
    //         tut = await axios.get(`${process.env.REACT_APP_BACKENDURL}/tutor/publicInfo`, {params: idInfo});
    //         tut = tut.data;
    //     } catch(err){
    //         let msg = err.response.data.msg;
    //         setErrorMessage(msg);
    //         return;
    //     }

    //     console.log(tut)
    //     let info = tut.info;
    //     setTutor(info);
    //     console.log("Tutor info:")
    //     console.log(info);
    // }
    // getTutorInfo();
  }, []);
  /**
   * Assumes that the value of the key is of the form 'agahjs.jpg (http://url.com)'
   * @param {*} key
   * @returns
   */

  useEffect(() => {
    if (!tutor) {
      return;
    }
    async function getSubjectNames() {
      let subjectIds = tutor.subjects; //list of ids
      let subNames;
      try {
        let params = {
          filter: {
            _id: { $in: subjectIds },
          },
          selection: {
            name: 1,
          },
        };
        let res = await axios.get(
          `${process.env.REACT_APP_BACKENDURL}/subject/all`,
          { params }
        );
        subNames = res.data.map((x) => x.name);
      } catch (err) {
        let msg = err.response.data.msg;
        setErrorMessage(msg);
        return;
      }
      setSubjectNames(subNames);
    }
    getSubjectNames();
  }, [tutor]);
  let sizedProfilePicture = (height) => {
    return (
      <Image
        style={{
          height: height,
          border: `2px solid ${COLORS.XPLAIN_GREEN}`,
          borderRadius: 10,
        }}
        // src={getLink("photo") || TUTOR_NO_PIC_LINK}
        // src={"https://drive.google.com/uc?export=view&id=1pYIEcftcwE_0dqv12om7AlM3nT-ZWrUJ"}
        src={getImageLink(tutor)}
        fluid
        // roundedCircle
        thumbnail
        rounded
      />
    )
  }
  return !tutor ? (
    <Spinner animation="border"></Spinner>
  ) : errorMessage.length > 0 ? (
    <p>{errorMessage}</p>
  ) : (
    <>
      <h1 style={{ color: COLORS.XPLAIN_GREEN }}>
        {`${getTutorVisibleName(tutor)}`}
        <br/>
        <Button 
        variant="outline-success"
        style={{
          // backgroundColor: "yellow",
          // color: "black",
          borderColor: COLORS.XPLAIN_GREEN
          }} onClick={()=>navigate(`/newclass?tutor=${tutorId}`)}>Agendar clase</Button>
      </h1>
    <div style={{display:"flex", minHeight:"70vh", flex:1, flexWrap:"wrap", marginLeft:20, marginRight:20 }}>

        <div style={{
          flex:1, 
          display:"flex", 
          flexDirection:"column", 
          alignItems:"center", 
          justifyContent:'center',
          //  backgroundColor:"yellow" 
        }}>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >

            </div>
            {
              tutor.tutorYoutubeLink && ReactPlayer.canPlay(tutor.tutorYoutubeLink) ? 
              <div style={{borderRadius: 10, border: "1px solid black"}}>
                <h4 style={{borderTopLeftRadius:10, borderTopRightRadius:10, fontWeight:"bold", marginBottom: 0, padding: 10, color:"white", backgroundColor:COLORS.XPLAIN_GREEN}}>Tutor Xplain</h4>
                <ReactPlayer url={tutor.tutorYoutubeLink} style={{borderBottomLeftRadius:10, borderBottomRightRadius:10}}/>
              </div>:
              sizedProfilePicture("60vh")
            }
        </div>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            flexDirection: "column",
            // backgroundColor:"red",
            flex: 1,
            minWidth:"50%"
          }}
        >
          <Card style={{}}>   
            <Card.Header
              as="h4"
              style={{ color: "white", backgroundColor: COLORS.XPLAIN_GREEN }}
            >
              Conoce a tu tutor
              
            </Card.Header>
            <Card.Body>
              {
                tutor.tutorYoutubeLink &&
                ReactPlayer.canPlay(tutor.tutorYoutubeLink) &&
                <Card.Title>
                  {sizedProfilePicture("20vh")}
                </Card.Title>
              }
              {tutor.tutorDescriptionList && (
                <ListGroup
                  variant="flush"
                  style={{ "text-align": "left" }}
                  as="ul"
                >
                  {tutor.tutorDescriptionList.map((description) => {
                    if (!description) {
                      return null;
                    } else{
                      return (<ListGroup.Item as="li" className="TutorPage-Description">
                      {description}
                    </ListGroup.Item>)
                    }
                  })}
                </ListGroup>
              )}
            </Card.Body>
          </Card>
          <br />
          <Accordion style={{ }}>
            <Accordion.Item eventKey="0">
              <Accordion.Header
                as="h4"
                style={{
                  textAlign: "center",
                  color: "white",
                  backgroundColor: COLORS.XPLAIN_GREEN,
                }}
              >
                <div>Materias que el tutor enseña</div>
              </Accordion.Header>
              <Accordion.Body>
                {subjectNames && (
                  <ListGroup variant="flush">
                    {subjectNames.map((subjectName) => (
                      <ListGroup.Item>{subjectName}</ListGroup.Item>
                    ))}
                  </ListGroup>
                )}
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
        </div>

    </div>
    </>
  );
};

export default TutorPage;
