import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

export const getParents = createAsyncThunk(
    'parent/getParents',
    async (data) => {
        const response = await axios.get(`${process.env.REACT_APP_BACKENDURL}/parent/all`, {params:data})
        const parents = response['data']
        return parents
    }
)