import React, {useEffect, useState} from 'react';
import { useSelector,useDispatch } from 'react-redux';
import {getClasses} from "../../../store/class/class.actions"
import {getSubjects} from "../../../store/subject/subject.actions"
import {getTutors} from "../../../store/tutor/tutor.actions"
import {getStudents} from "../../../store/student/student.actions"
import {setStudent} from "../../../store/student/student.slice"
import { getFamilies, getFamily } from '../../../store/family/family.actions';
import FamilyReferrals from "../AllUsers/FamilyReferrals"
import FamilyHoursInfo from "../FamilyView/FamilyHoursInfo"
import SaleForm from "../Sale/SaleForm"
import Select from 'react-select';
import {Col,Form,Row, Table, Card} from 'react-bootstrap';
import FamilyMonthReferrals from "../AllUsers/FamilyMonthReferrals"
import NewClass from "./NewClass"
import ParentTutors from "./ParentTutors"

let today = new Date();



const ParentViewForm = () => {
    const dispatch = useDispatch()
    const {family} = useSelector((state) => state.family)
    const {user} = useSelector((state) => state.auth)
    // const {students,student} = useSelector((state) => state.student)
    // // const [family, setFamily] = useState({});
    // const [option,setOption] = useState(<></>)
    // const options = [
    //     {label: "Ver Clases", value: <StudentClasses />},
    //     {label:"Reservar Clases", value: < NewClass />},
    //     {label: "Comprar horas", value: <SaleForm/>},
    //     // {label: "Reportes de Clases Pasadas", value: <StudentPastClasses />},
    //     {label: "Ver familias referidas ", value: <FamilyReferrals />},
    //     {label: "Mira los perfiles de los tutores!", value: <ParentTutors />}
    //     // {label:"Primera Clase", value: <FirstClassForm/>}
    // ]
    useEffect(() => {
        dispatch(getStudents({filter:{family:user.family}, selection:{}}));
        dispatch(getFamilies(({filter:{}, selection:{}})));
        dispatch(getFamily({id:user.family}));

        // async function getFamily(){
        //     let fam = await axios.get(`${process.env.REACT_APP_BACKENDURL}/family`, {params:{id: user.family}});
        //     setFamily(fam.data);
        //  }
        //  getFamily()
    },[])
    return(
        <>
                <br/>
                <Card style={{ width: '50%','margin-left':'auto','margin-right':'auto' }}>
                    <br/>
                    <h5>Hola <b>{user.name.split(" ")[0]}</b>!</h5>
                    <br/>
                    <FamilyMonthReferrals />
                    <br/>
                    <p>Comparte este código con tus amigos y reciban <b>$10 USD</b> de descuento cuando ellos compren sus primeras <b>4 horas</b> de clase!</p>
                    <p style={{color:"#0AB591"}}><u><b>{family.referral_id}</b></u></p>
                    <br/>
                </Card>
                <br/>
                <br/>
                <FamilyHoursInfo/>
            
            {/* <br/>
            {student.name !== "" 
                ?
                <Row className="justify-content-md-center"> 
                <Form.Group as={Col} lg="4" controlId="type">
                <Form.Control
                    as={Select}
                    placeholder="¿Qué quieres hacer?"
                    options={options}
                    onChange={opt => setOption(opt.value)}
                />
                </Form.Group>
                </Row>
                :
                <></>
            }

            <br />
            {option} */}
        </>
    )
}

export default ParentViewForm