import React from "react"
import ConfirmForm from "./ConfirmForm"
const ConfirmPage = () => {
    return (
        <div>
            <ConfirmForm />
        </div>
    )
}

export default ConfirmPage