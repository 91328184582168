// .replace(/-/g, "[dash]") -> replaces dash with [dash]
// .replace(/\[dash\]/g, "-") -> replaces [dash] with -
// .replace(/:/g, "[colon]") -> replaces : with [colon]
// .replace(/\[colon\]/g,":") -> replaces [colon] with :

/**
 * Replaces [colon] and [dash] with : and -
 * @param {} feedback 
 */
export const formatFeedback = (feedback) => {
    return feedback.replace(/\[colon\]/g, ":").replace(/\[dash\]/g, '-');
}

/**
 * Replaces : with [colon] and - with [dash]
 * @param {*} feedback 
 * @returns 
 */
export const encodeFeedback = (feedback) => {
    return feedback.replace(/:/g, "[colon]").replace(/-/g, "[dash]");
}
/**
 * 
 * @param {*} studentFeedbacks Array of {sender: , text: } object
 * @returns 
 */
export const formatIndividualStudentFeedback = (studentFeedbacks) => {
    let res = "";
    for (const studentFeedback of studentFeedbacks){
        res += "Enviado por: " + studentFeedback.sender.name + "\n"
        res += formatFeedback(studentFeedback.text) + "\n";
    }
    return res;
}