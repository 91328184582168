import React, {useState, useEffect} from 'react';
import axios from 'axios';
import { getStudents } from '../../store/student/student.actions';
import { getTutor } from "../../store/tutor/tutor.actions";
import {getParents} from "../../store/parent/parent.actions"
import {getSubject} from "../../store/subject/subject.actions";
import { useDispatch, useSelector } from 'react-redux';
import {Card, Button, Row, Col} from "react-bootstrap"


const ConfirmForm = () => {
    const dispatch = useDispatch()
    const {students} = useSelector((state) => state.student)
    const {tutor} = useSelector((state) => state.tutor)
    const {subject} = useSelector((state) => state.subject)
    const {parents} = useSelector((state) => state.parent)
    const [start,setStart] = useState("")
    const [end,setEnd] = useState("")
    const [date,setDate] = useState("")
    const [confirmed,setConfirmed] = useState(false)
    const [disabled,setDisabled] = useState(true)
    const [id,setId] = useState("")
    const [prevInfo, setPrevInfo] = useState("");
    useEffect(() => {
        let params = new URLSearchParams(window.location.search)
        let _id = params.get("id")
        if (typeof _id === "string" || _id instanceof String ){
            axios.get(`${process.env.REACT_APP_BACKENDURL}/class`, {
                params: {id:_id}
            }).then((response) => {
                let newStart = new Date(response.data.start)
                let newEnd = new Date(response.data.end)
                setDate(`${(newStart.getDate()).toString().padStart(2, '0')}/${(newStart.getMonth() + 1).toString().padStart(2, '0')}`)
                setStart(`${newStart.getHours().toString().padStart(2, '0')}:${newStart.getMinutes().toString().padStart(2, '0')}`)
                setEnd(`${newEnd.getHours().toString().padStart(2, '0')}:${newEnd.getMinutes().toString().padStart(2, '0')}`)
                setPrevInfo(response.data.prevInfo)
                setConfirmed(!response.data.pending)
                dispatch(getStudents({filter:{_id:{$in:response.data.studentIds}}, selection:{}}))
                dispatch(getTutor({id:response.data.tutorId}))
                dispatch(getSubject({id:response.data.subjectId}))
                dispatch(getParents({filter:{family:{$in:response.data.families}}, selection:{}}))
                setDisabled(false)
                setId(_id)
            })
        }
    }, [])
    let studentNames = students.map((student) => `${student.name.split(" ")[0]} ${student.surname.split(" ")[0]}`)
    return(
        <>
        <br/>
        <br/>
        <Card style={{ width: '50rem','margin-left':'auto','margin-right':'auto' }}>
        <br />

        <h1><b>Confirmación de clases</b></h1>
        <br/>
        {tutor._id !== "" 
            ?
            <p>Hola {tutor.name}, por favor confirma la clase</p>
            :
            <p>Hola, por favor confirma la clase</p>
        }
        <p><b>Materia: </b> {subject.name}</p>
        <p><b>Xplainer(s): </b> {studentNames.join(" - ")}</p>
        <p><b>Fecha: </b>{date}</p>
        <p><b>Horario: </b> {start} - {end}</p>
        <p><b>Notas:</b> {prevInfo}</p>
        <br/>
            {confirmed 
                ?
                <>
                    <p>Muchas gracias por confirmar la clase. Por favor, asegúrate siempre que tu calendario de trabajo este actualizado en Google Calendar.</p>
                </>
                :
                <>
                        <Row className="justify-content-md-center">
                            <Col lg="2">
                                <Button 
                                    variant="success"
                                    disabled={disabled}
                                    onClick={() => {
                                        setDisabled(true)
                                        axios.post(`${process.env.REACT_APP_BACKENDURL}/class/acceptclass`, {
                                            classId:id,
                                            tutor:tutor,
                                            students:students,
                                            subject:subject,
                                            parents:parents
                                        }).then(response => setConfirmed(true)).catch(err => console.log(err))
                                    }}
                                >
                                    Aceptar
                                </Button>
                            </Col>
                            <Col lg="2">
                                <Button 
                                    variant="danger"
                                    disabled={disabled}
                                    onClick={() => {
                                        axios.post(`${process.env.REACT_APP_BACKENDURL}/class/rejectclass`, {
                                            classId:id,
                                            tutor:tutor,
                                            students:students,
                                            subject:subject,
                                            parents:parents
                                        }).then(response => setConfirmed(true)).catch(err => console.log(err))
                                    }}
                                >
                                    Rechazar
                                </Button>
                            </Col>
                        </Row>
                        <br />
                </>
            }
            </Card>
        </>
    )
}

export default ConfirmForm