import { setUser } from "./store/auth/auth.slice";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";

/**
 * sets global variable user in case it's in localStorage
 */
export const useUserIfLoggedIn = () => {
  const { user } = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  if (user && user._id) {
    //Already in global state, nothing to do
    return;
  }
  let token = localStorage.getItem("token");
  if (typeof token === "string" || token instanceof String) {
    axios
      .post(`${process.env.REACT_APP_BACKENDURL}/auth/checktoken`, {
        token: token,
      })
      .then((response) => {
        let { token, user } = response.data;
        localStorage.setItem("token", token);
        // if (user?._id) {
        // }
        dispatch(setUser(user));
      })
      .catch((err) => {
        console.log(err);
      });
  } else {
    let googleRefreshToken = localStorage.getItem("googleRefreshToken");
    if (
      typeof googleRefreshToken === "string" ||
      googleRefreshToken instanceof String
    ) {
      axios
        .post(
          `${process.env.REACT_APP_BACKENDURL}/auth/checkGoogleRefreshToken`,
          { refresh_token: googleRefreshToken }
        )
        .then((response) => {
          let { user } = response.data;
          // if (user?._id) {
          // }
          dispatch(setUser(user));
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }
};
