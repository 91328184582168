import React, { useEffect, useState} from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {getFamilyHistory} from "../../store/history/history.actions";
import {Container, Col, Form, Text, Button, Row, Toast} from "react-bootstrap";
import Table from 'react-bootstrap/Table';
import Modal from 'react-bootstrap/Modal';
import axios from "axios";
import { getTutorVisibleName } from '../../name_utils';

let modelTranslation = {
    SubjectHistory: "Curso",
    ParentHistory: "Padre",
    UserHistory: "Usuario",
    StudentHistory: "Estudiante",
    TutorHistory: "Tutor",
    ClassHistory: "Clase",
    FamilyHistory: "Familia",
    SaleHistory: "Venta",
    TempTutorHistory: "Tutor Temporal"
}
let typeTranslation = {
    create: 'creado',
    update: 'actualizado',
    delete: 'borrado'
}
const parseTimestamp = (timestamp) => {
    let time = Date.parse(timestamp)
    return new Date(time).toLocaleString();
}
const getTypeColor = (type) => {
    switch (type) {
        case "create":
            return "green";
        case "update":
            return "blue";
        case "delete":
            return "red";
    }
}
/**
 * Provides comprehensive information about this history item
 * TODO: Delete and only use `familySummary` field later
 * @param {*} history 
 */
const getFamilyHistorySummary = async (history) => {
    if (history.model === "SaleHistory"){
        if (history.success){
            return `Pagó ${history.diff.amount} ${history.diff.currency} por ${history.diff.hoursBought} hora(s) ${history.diff.hourType}`;
        } else {
            return `Error pagando ${history.diff.amount} ${history.diff.currency} por ${history.diff.hoursBought} hora(s) ${history.diff.hourType}`;
        }
    } else if (history.model === "ClassHistory"){
        let obj = history.type === "create" ? history.diff : history.type === "delete"? history.prev: {};
        const responseTutor = await axios.get(`${process.env.REACT_APP_BACKENDURL}/tutor`, {
            params:{id: obj.tutorId}
        })
        let tutor = responseTutor.data;

        const responseSubject = await axios.get(`${process.env.REACT_APP_BACKENDURL}/subject`, {
            params:{id: obj.subjectId}
        })
        let subject = responseSubject.data;

        if (history.type === "create"){
            if (history.success){
                return `Se agendó una nueva clase de ${obj.duration} hora(s) de ${subject.name} con tutor ${getTutorVisibleName(tutor)}`;
            } else {
                return `Error agendando una clase de ${obj.duration} hora(s) de ${subject.name} con tutor ${getTutorVisibleName(tutor)}`;
            }
        } else if (history.type === "delete"){
            if (history.success){
                return `Se eliminó una clase de ${obj.duration} hora(s) de ${subject.name} con tutor ${getTutorVisibleName(tutor)}`;
            } else {
                return `Error eliminando una clase de ${obj.duration} hora(s) de ${subject.name} con tutor ${getTutorVisibleName(tutor)}`;
            }
        }
    } else if (history.model === "FamilyHistory"){
        if (history.type === "update"){
            let res = "";
            for (let key in history.diff){
                let oldValue = history.prev[key];
                let newValue = history.diff[key];
                let isNumber = typeof oldValue === "number"
                let line = "";
                if (!isNumber){
                    line = `${key}: ${oldValue} -> ${newValue}\n`;
                } else {
                    oldValue = parseFloat(oldValue);
                    newValue = parseFloat(newValue);
                    let increased = newValue > oldValue;
                    let changed_message = increased? "incrementando" : "disminuyendo";
                    line = `Horas de tipo '${key}' ${changed_message} de ${oldValue} a ${newValue}\n`;
                }
                res += line;
            }
            if (history.success){
                return res;
            } else{
                return `Error con los cambios: ${res}`
            }
        }
    }
    return "No se encontró el historial";

}
const HistoryComponentRow = ({i, data}) => {
    const [showModal, setShowModal] = useState(false);
    const [summary, setSummary] = useState("");
    useEffect(() => {
        const getSummary = async () => {
            if (!data.familySummary){
                let summary =  await getFamilyHistorySummary(data);
                setSummary(summary);
            } else {
                setSummary(data.familySummary)
            }
        }
        getSummary();
    }, [])
    return (
        <tr>
            <td>{i}</td>
            {/* <td>{data.ref_id}</td> */}
            {/* <td>{modelTranslation[data.model]}</td> */}
            <td style={{
                color: getTypeColor(data.type)
            }}>{typeTranslation[data.type]}</td>
            <td>{summary}</td>
            <td>{parseTimestamp(data.timestamp)}</td>
            {/* <td>
                <Button variant="success" onClick={handleShow}>👀</Button>
            </td> */}
        </tr>
    )
}
const FamilyHistory = ({familyId}) => {
    const dispatch = useDispatch();
    const [showSale, setShowSale] = useState(true);
    const [showClass, setShowClass] = useState(true);
    const [showFamily, setShowFamily] = useState(true);

    const {familyHistory, gettingFamilyHistory} = useSelector((state) => state.history);
    console.log("familyHistory")
    console.log(gettingFamilyHistory);
    const getLatestHistory = () => {
        dispatch(getFamilyHistory({familyId}));
    }
    useEffect(() => {
        getLatestHistory();
    }, []);
    const showHistory = (list) => {
        return list.map((item, i) => (
            <HistoryComponentRow i={i+1} data={item} key={item._id}/>
        ))
    }
    const filterResults = (history) => {
        // Apply all filters
        return history.filter((item) => {
            return (
                (item.model === "SaleHistory" && showSale) ||
                (item.model === "ClassHistory" && showClass) ||
                (item.model === "FamilyHistory" && showFamily)
            )
        })
    }
    return (
        <Container style={{}}>
            <Modal show={gettingFamilyHistory}>
                <Modal.Header closeButton>Cargando...</Modal.Header>
            </Modal>
            <h1>
                Se encontró {filterResults(familyHistory).length} transacciones
            </h1>
            <div style={{marginBottom: 10}}>
                {/* <div style={{display:"flex", alignItems:"center", justifyContent:"center"}}>
                    <span>{showSuccess? 'Transacciones exitosas' : 'Transacciones erroneas'}</span>
                </div> */}
                <Button onClick={getLatestHistory}>Usar la información más actual.</Button>
            </div>
            <div>
                <h2>Filtros</h2>
                <Form className="mb-3">
                    <Form.Check
                        inline
                        label="Ventas"
                        name="filters"
                        type="checkbox"
                        id={"Ventas"}
                        checked={showSale}
                        onChange={(e) => {setShowSale(e.target.checked)}}
                    />
                    <Form.Check
                        inline
                        label="Clases"
                        name="filters"
                        type="checkbox"
                        checked={showClass}
                        id={"Clases"}
                        onChange={(e) => {setShowClass(e.target.checked)}}
                    />
                    <Form.Check
                        inline
                        label="Horas"
                        name="filters"
                        type="checkbox"
                        id={"Horas"}
                        checked={showFamily}
                        onChange={(e) => {setShowFamily(e.target.checked)}}
                    />
                </Form>
            </div>
            <Table striped bordered hover>
                <thead>
                    <tr>
                        <th>#</th>
                        {/* <th>ref_id</th> */}
                        {/* <th>Tipo</th> */}
                        <th>Operacion</th>
                        <th>Resumen</th>
                        <th>Cuando</th>
                        {/* <th>Ver</th> */}
                    </tr>
                </thead>
                <tbody>
                    { showHistory(filterResults(familyHistory)) }
                </tbody>
            </Table>
            <div>
                Nota: Solo se muestra transacciones que hayan pasado desde Feb 8, 2023 (y algunas cuantas más que pasaron un poco antes)
            </div>
    </Container>
    )
}

export default FamilyHistory;