import React, { useState, useEffect } from "react";
import Select from "react-select";
import { setStudent } from "../../../store/student/student.slice";
import { useDispatch, useSelector } from "react-redux";
import { Form, Col, Row, Button } from "react-bootstrap";
import { getStudents } from "../../../store/student/student.actions";
import { getSubjects } from "../../../store/subject/subject.actions";
import { getTutors } from "../../../store/tutor/tutor.actions";
import { getSchools } from "../../../store/school/school.actions";
import axios from "axios";
import { listFormat } from "../../../name_utils";

const AllStudentsForm = () => {
  const dispatch = useDispatch();
  const [schools, setSchools] = useState({});
  useEffect(() => {
    const getAllSchools = async () => {
      const schoolsDict = await getSchools();
      setSchools(schoolsDict);
    };
    dispatch(getStudents({ filter: {}, selection: {} }));
    dispatch(getSubjects({ filter: {}, selection: {} }));
    dispatch(getTutors({ filter: {}, selection: {} }));
    getAllSchools();
  }, []);
  const allSubjects = useSelector((state) => state.subject.subjects);
  const subjectFormat = () => {
    let allSubjectsValues = listFormat(allSubjects);
    let formatSubjects = [];
    for (const studentSubject of student.subjects) {
      for (const subject of allSubjectsValues) {
        if (studentSubject === subject._id) {
          formatSubjects.push(subject);
        }
      }
    }
    return formatSubjects;
  };
  const { student, students } = useSelector((state) => state.student);
  const { tutors } = useSelector((state) => state.tutor);
  const tutorFormat = () => {
    let allTutorValues = listFormat(tutors, { isTutor: true });
    let formatTutors = [];
    for (const studentTutor of student.tutors) {
      for (const tutor of allTutorValues) {
        if (studentTutor === tutor._id) {
          formatTutors.push(tutor);
        }
      }
    }
    return formatTutors;
  };
  const newStudent = { ...student };
  const [edit, setEdit] = useState(false);
  const [disabled, setDisabled] = useState(false);
  const [isInvalid, setIsInvalid] = useState(false);
  // const listFormat = (array) => {
  //     let newArray = []
  //     for (const elem of array){
  //         let newElem = {...elem}
  //         if ('surname' in newElem){
  //             newElem['label'] = elem['name'] + " " + elem['surname']
  //         }else{
  //             newElem['label'] = elem['name']
  //         }
  //         newElem['value'] = elem['_id']
  //         newArray.push(newElem)
  //     }
  //     return newArray
  // }
  const handleSubmit = (event) => {
    event.preventDefault();
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.stopPropagation();
      setIsInvalid(true);
    } else {
      // let answer = window.confirm("Estás seguro quieres actualizar esta info?");
      setIsInvalid(false);
      setDisabled(true);
      delete newStudent._id;
      delete newStudent.channel;
      delete newStudent.__v;
      delete newStudent.label;
      delete newStudent.value;
      axios
        .patch(`${process.env.REACT_APP_BACKENDURL}/student`, {
          id: student._id,
          update: newStudent,
        })
        .then((response) => {
          window.location.reload(false);
        })
        .catch((error) => console.log(error));
      // if(answer){

      // }
    }
  };
  const studentData = Object.keys(newStudent).map((key, index) => {
    if (key == "_id" || key == "channel") {
      return (
        <>
          <Row className="justify-content-md-center">
            <Form.Group as={Col} lg="2" controlId={key}>
              <Form.Label>{key}</Form.Label>
              <Form.Control
                type="text"
                disabled
                value={newStudent[key]}
                readOnly={!edit}
              />
              <Form.Control.Feedback>Todo bien!</Form.Control.Feedback>
            </Form.Group>
          </Row>
          <br />
        </>
      );
    } else if (key === "school") {
      return (
        <>
          <Row className="justify-content-md-center">
            <Form.Group as={Col} lg="2" controlId={key}>
              <Form.Label>{key}</Form.Label>
              <Form.Control
                type="text"
                disabled
                value={
                  schools.hasOwnProperty(newStudent[key])
                    ? schools[newStudent[key]]
                    : newStudent[key]
                }
                readOnly={true}
              />
              <Form.Control.Feedback>Todo bien!</Form.Control.Feedback>
            </Form.Group>
          </Row>
          <br />
        </>
      );
    } else if (key == "__v" || key == "label" || key == "value") {
      return <></>;
    } else if (key == "subjects") {
      return (
        <>
          <Row className="justify-content-md-center">
            <Form.Group as={Col} lg="3" controlId="surname">
              <Form.Label>subjects</Form.Label>
              <br />
              <br />
              <Form.Control
                as={Select}
                required
                isMulti={true}
                value={subjectFormat()}
                options={listFormat(allSubjects)}
                isDisabled={!edit}
                onChange={(opt) => {
                  let newSubjects = [];
                  for (const subject of opt) {
                    newSubjects.push(subject.value);
                  }
                  newStudent[key] = newSubjects;
                  dispatch(setStudent(newStudent));
                }}
                placeholder="Materias"
              />
              <Form.Control.Feedback>Todo bien!</Form.Control.Feedback>
            </Form.Group>
          </Row>
          <br />
        </>
      );
    } else if (key == "tutors") {
      return (
        <>
          <Row className="justify-content-md-center">
            <Form.Group as={Col} lg="3" controlId="surname">
              <Form.Label>tutors</Form.Label>
              <br />
              <br />
              <Form.Control
                as={Select}
                required
                isMulti={true}
                value={tutorFormat()}
                options={listFormat(tutors, { isTutor: true })}
                isDisabled={!edit}
                onChange={(opt) => {
                  let newTutors = [];
                  for (const tutor of opt) {
                    newTutors.push(tutor.value);
                  }
                  newStudent[key] = newTutors;
                  dispatch(setStudent(newStudent));
                }}
                placeholder="Tutores"
              />
              <Form.Control.Feedback>Todo bien!</Form.Control.Feedback>
            </Form.Group>
          </Row>
          <br />
        </>
      );
    } else {
      return (
        <>
          <Row className="justify-content-md-center">
            <Form.Group as={Col} lg="2" controlId={key}>
              <Form.Label>{key}</Form.Label>
              <Form.Control
                type="text"
                value={newStudent[key]}
                readOnly={!edit}
                onChange={(e) => {
                  newStudent[key] = e.target.value;
                  dispatch(setStudent(newStudent));
                }}
              />
              <Form.Control.Feedback>Todo bien!</Form.Control.Feedback>
            </Form.Group>
          </Row>
          <br />
        </>
      );
    }
  });
  return (
    <Form noValidate validated={isInvalid} onSubmit={handleSubmit}>
      <Row className="justify-content-md-center">
        <Form.Group as={Col} lg="4" controlId="student">
          <Form.Label>Escoja al estudiante</Form.Label>
          <br />
          <br />
          <Form.Control
            as={Select}
            options={listFormat(students)}
            required
            onChange={(opt) => dispatch(setStudent(opt))}
          />
        </Form.Group>
      </Row>
      <br />
      {student._id == "" ? (
        <></>
      ) : (
        <>
          {studentData}
          {edit ? (
            <Row className="justify-content-md-center">
              <Col lg="2">
                <Button type="submit" variant="success" disabled={disabled}>
                  Enviar
                </Button>
              </Col>
              <Col lg="2">
                <Button
                  variant="danger"
                  disabled={disabled}
                  onClick={() => setEdit(false)}
                >
                  Cancelar
                </Button>
              </Col>
            </Row>
          ) : (
            <Button variant="success" onClick={() => setEdit(true)}>
              Editar
            </Button>
          )}
        </>
      )}
    </Form>
  );
};

export default AllStudentsForm;
