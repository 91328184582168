import React, {useEffect, useState} from 'react';
import { useSelector,useDispatch } from 'react-redux';
import { getFamilies } from '../../../store/family/family.actions';

import ListGroup from 'react-bootstrap/ListGroup';
import Badge from 'react-bootstrap/Badge';
import Card from 'react-bootstrap/Card';
import Table from 'react-bootstrap/Table';

const FamilyHoursInfo = ()=>{
    const dispatch = useDispatch()
    const {families,family} = useSelector((state) => state.family)
    useEffect(()=>{
        dispatch(getFamilies(({filter:{}, selection:{}})));
    }, [])

    let ALL_HOURS = [
        {description: "IB", individual: family.hoursIB || 0, group:family.hoursIBGroup || 0},
        {description: "SAT", individual: family.hoursSAT || 0, group: family.hoursSATGroup || 0},
        {description: "Olimpiadas", individual: family.hoursOlympiad || 0, group: family.hoursOlympiadGroup || 0},
        {description: "Tutoría", individual: family.hoursTutoring || 0, group: family.hoursTutoringGroup || 0},
    ]
    const getVariantFromCount =(numHours)=>{
        if (numHours > 10){
            return "success";
        } else if (numHours > 3) {
            return "warning";
        } else {
            return "danger";
        }
    }
    return(
        <>
            <div style={{display:'flex', 'align-items': 'center', 'justify-content': 'center'}}>
                <Card className="text-center" style={{width: '25rem'}}>
                    <Card.Header>A su familia le queda:</Card.Header>
                    <Card.Body>
                        <Table hover variant="flush">
                            <thead>
                                <tr>
                                <th>Tipo de Hora</th>
                                <th>Individuales</th>
                                <th>Grupales</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    ALL_HOURS.map(({description, individual, group}) => (
                                        <tr>
                                            <td>{description}</td>
                                            <td>
                                                <Badge bg={getVariantFromCount(individual)} pill>{individual}</Badge>
                                            </td>
                                            <td>
                                                <Badge bg={getVariantFromCount(group)} pill>{group}</Badge>
                                            </td>
                                        </tr>
                                    ))
                                }
                            </tbody>
                        </Table>
                        {/* <ListGroup variant="flush">

                        {
                            ALL_HOURS.map(({msg, value}) => (
                                <ListGroup.Item className=" ">
                                        <div className="fw-bold">
                                            {msg}: {' '}
                                        <Badge bg="primary" pill>
                                            {value}
                                        </Badge>
                                        </div>

                                </ListGroup.Item>
                            ))
                        }
                        </ListGroup> */}
                    </Card.Body>
                </Card>
            </div>
        </>
    )
}

export default FamilyHoursInfo;