import React, { useState, useEffect } from "react";
import "./App.css";
import { Route, Routes, BrowserRouter as Router } from "react-router-dom";
import HomePage from "./pages/Home/HomePage";
import NewStudentPage from "./pages/NewStudent/NewStudentPage";
import StudentFeedbackPage from "./pages/StudentFeedback/StudentFeedbackPage";
import TutorFeedbackPage from "./pages/TutorFeedback/TutorFeedbackPage";
import NewTutorPage from "./pages/NewTutor/NewTutorPage";
import TutorPageGeneral from "./pages/Tutor/TutorPageGeneral";
import TCPage from "./pages/T&C/T&CPage";
import CallPage from "./pages/Call/CallPage";
import LoginPage from "./pages/Login/LoginPage";
import ProfilePage from "./pages/Profile/ProfilePage";
import NewCalendarPage from "./pages/NewCalendar/NewCalendarPage";
import NewPasswordPage from "./pages/NewPassword/NewPasswordPage";
import NavBar from "./components/navbar/NavBar";
import Footer from "./components/footer/Footer";
import ReportPage from "./pages/Report/ReportPage";
import ConfirmPage from "./pages/Confirm/ConfirmPage";
import { Container } from "react-bootstrap";
import Classes from "./pages/Classes/Classes";
import ReserveClasses from "./pages/ReserveClasses/ReserveClasses";
import Tutors from "./pages/Tutors/Tutors";
import Payments from "./pages/Payments/Payments";
import Referrals from "./pages/Referrals/Referrals";
import Materials from "./pages/Materials/Materials";
import PublicProfile from "./pages/PublicProfile/PublicProfile";
import Students from "./pages/Students/Students";
import Feedback from "./pages/Feedback/Feedback";
import Signup from "./pages/Signup/Signup";
import FAQ from "./pages/FAQ/FAQ";
import History from "./pages/History/History";
import FamilyNotesFromAdmin from "./components/forms/FamilyView/FamilyNotesFromAdmin";
import NewClassPage from "./pages/NewClass/NewClassPage";
// import socketIO from 'socket.io-client';
import Chat from "./pages/Chat/Chat";
import VendorFamilies from "./components/forms/VendorView/VendorFamilies";
import JoinFamilyView from "./components/forms/FamilyView/JoinFamilyView";

// const socket = socketIO.connect('http://localhost:4000');
// const socket = socketIO.connect(process.env.REACT_APP_BACKENDURL);

// const socket = io();

function App() {
  // const [isConnected, setIsConnected] = useState(socket.connected);
  // const [lastPong, setLastPong] = useState(null);
  // useEffect(() => {
  //   socket.on('connect', () => {
  //     setIsConnected(true);
  //   });

  //   socket.on('disconnect', () => {
  //     setIsConnected(false);
  //   });
  //   return () => {
  //     socket.off('connect');
  //     socket.off('disconnect');
  //   };
  // }, []);
  return (
    <div className="App">
      <Router>
        <NavBar />
        <div className="App-content">
          <Routes>
            <Route path="/" exact element={<HomePage />} />
            <Route path="/classes" exact element={<Classes />} />
            <Route path="/reserveclasses" exact element={<ReserveClasses />} />
            <Route path="/students" exact element={<Students />} />
            <Route path="/tutors" exact element={<Tutors />} />
            <Route path="/payments" exact element={<Payments />} />
            <Route path="/materials" exact element={<Materials />} />
            <Route path="/referrals" exact element={<Referrals />} />
            <Route path="/publicprofile" exact element={<PublicProfile />} />
            {/* <Route path="newstudent" exact element={<NewStudentPage /> }/>           
            <Route path="newtutor" exact element={<NewTutorPage /> }/>            */}
            <Route
              path="studentfeedback"
              exact
              element={<StudentFeedbackPage />}
            />
            <Route path="tutorfeedback" exact element={<TutorFeedbackPage />} />
            <Route path="feedback" exact element={<Feedback />} />
            <Route path="tc" exact element={<TCPage />} />
            <Route path="call" exact element={<CallPage />} />
            <Route path="login" exact element={<LoginPage />} />
            <Route path="profile" exact element={<ProfilePage />} />
            <Route path="createcalendar" exact element={<NewCalendarPage />} />
            <Route path="newpassword" exact element={<NewPasswordPage />} />
            <Route path="report" exact element={<ReportPage />} />
            <Route path="confirm" exact element={<ConfirmPage />} />
            <Route path="tutor" exact element={<TutorPageGeneral />} />
            <Route path="faq" exact element={<FAQ />} />
            <Route path="signup" exact element={<Signup />} />
            <Route path="history" exact element={<History />} />
            {/* <Route path="notes" exact element={<FamilyNotesFromAdmin />} /> */}
            <Route path="/newclass" exact element={<NewClassPage />} />
            <Route path="/chat" exact element={<Chat/>} />
            <Route path="/vendor-families" exact element={<VendorFamilies />} />
            <Route path="/join-family/:familyId" element={<JoinFamilyView />} />


            {/* <Route path="pay" exact element={<PaymentCardPage /> }/>          */}
          </Routes>
        </div>
        <Footer />
      </Router>
    </div>
  );
}
export default App;
