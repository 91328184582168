import axios from 'axios';
import React, {useState, useEffect} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {Form, Button, Table, Spinner} from 'react-bootstrap'

const VendorFamilyInfo = ({familyId}) => {
    const [family, setFamily] = useState({});
    const [newAdminNote, setNewAdminNote] = useState('');
    const [disabled, setDisabled] = useState(false);
    const [loading, setLoading] = useState(true);
    const pullFamilyFromDb = async () => {
        let response = await axios.get(`${process.env.REACT_APP_BACKENDURL}/vendor/familyInfo`, {params: {
            familyId: familyId
        }})
        setFamily(response.data);
        setLoading(false);
    }
    useEffect(() => {
        pullFamilyFromDb()
    }, [])
    
    const handleSubmitNewNote = async (evt) => {
        evt.preventDefault();
        if (newAdminNote.length === 0){
            alert("No puede dejar vacío");
            return;
        }
        setDisabled(true);
        try {
            await axios.patch(`${process.env.REACT_APP_BACKENDURL}/family/`, {
                id: familyId,
                update: {},
                newAdminNote: newAdminNote
            })
            alert("Operación realizada con éxito");
            pullFamilyFromDb(); //To update UI
            setDisabled(false)
            setNewAdminNote("")
        } catch (error) {
            alert("Hubo un error");
            console.log(error);
            setDisabled(false)
        }
    }
    return (
        loading ? (<div>
            Cargando info de familia <Spinner animation="border"></Spinner>
        </div>):
        <>        
            <h2>Notas anteriores</h2>
            {(!family.adminNotes || family.adminNotes.length === 0) ? <div>No se encontraron notas</div>:
                <Table striped bordered hover>
                    <thead>
                        <tr>
                            <th>Nota</th>
                            <th>Cuando</th>
                        </tr>
                    </thead>
                    <tbody style={{verticalAlign:"middle"}}>
                        {
                            family.adminNotes && 
                            family.adminNotes.map((note) => (
                        <tr key={note.timestamp}>
                            <td>{note.text}</td>
                            <td>{new Date(note.timestamp).toLocaleString()}</td>
                        </tr>))
                        }
                    </tbody>
                </Table>
            }
            <h2>Ultima clase pasada</h2>
            {
            !family.previousClass ? <i>No se encontro clase pasada</i>:
            <div>
                Clase de <strong>{family.previousClass.subjectId.name}</strong> en <strong>{new Date(family.previousClass.start).toLocaleString()}</strong>
            </div>
            }
            <h2>Proxima clase</h2>
            {
            !family.futureClass ? <i>No se encontro clase futura</i>:
            <div>
                Clase de {family.futureClass.subjectId.name} en {new Date(family.futureClass.start).toLocaleString()}
            </div>
            }

            <Form onSubmit={(evt) => handleSubmitNewNote(evt)}>
                <Form.Group lg="2" controlId={"adminNote"}>
                    <Form.Label><h2>Nueva nota para la familia</h2></Form.Label>
                    <Form.Control
                        type="text"
                        value={newAdminNote}
                        onChange={e => {
                            setNewAdminNote(e.target.value)
                        }}
                    />
                    <Form.Control.Feedback>Todo bien!</Form.Control.Feedback>
                </Form.Group>
                <br/>
                <Button type="submit" variant="success" disabled={disabled}>Enviar</Button>
            </Form>
        </>
    )
}
export default VendorFamilyInfo;