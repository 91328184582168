import axios from "axios";

export const getSchools = async () => {
  const response = await axios.get(
    `${process.env.REACT_APP_BACKENDURL}/school/all`,
    {
      params: {
        filter: {},
        selection: {},
      },
    }
  );
  const schools = response["data"];
  const schoolsDict = {};
  for (const school of schools) {
    schoolsDict[school._id] = school.name;
  }
  return schoolsDict;
};
