import React, { useState, useEffect } from "react"
import { Form, Button, Card, Alert, Row } from "react-bootstrap"
import {useNavigate} from "react-router-dom"
import axios from "axios"
import {setUser} from "../../store/auth/auth.slice"
const SendEmail = () => {
    const [email,setEmail] = useState("")
    const [isInvalid,setIsInvalid] = useState(false)
    const [loading,setLoading] = useState(false)
    const [error,setError] = useState()
    const [sent,setSent] = useState(false)
    const handleSubmit = (event) => {
        event.preventDefault()
        const form = event.currentTarget;
        if (form.checkValidity() === false) {
          event.stopPropagation();
          setIsInvalid(true)
        }else{
          setIsInvalid(false);
          setLoading(true)
          axios.post(`${process.env.REACT_APP_BACKENDURL}/auth/checkemail`,{email:email}).then((response) => {
            setSent(true)
            setLoading(false)
          }).catch(err => {
            setError(err.response.data.message)
            setLoading(false)
          })
        }
    }

    return(
        <>
        <Card style={{ width: '25rem','margin-left':'auto','margin-right':'auto' }}>
        <Card.Body>

          {sent
            ?
                <p>Se ha enviado un link al correo que ha proporcionado para cambiar su contraseña.</p>
            :
            <>
                <h2 className="text-center mb-4">Escribe el email de tu cuenta</h2>
                {error && <Alert variant="danger">{error}</Alert>}
                <Form onSubmit={handleSubmit} validated={isInvalid}>
                <Form.Group id="email">
                    <Form.Label>Email</Form.Label>
                    
                    <Form.Control type="email" value={email} required onChange={(e) => setEmail(e.target.value)} />
                </Form.Group>
                <br/>
                <br/>
                <Button disabled={loading} className="w-100" type="submit">
                    Enviar
                </Button>
                </Form>
            </>
          }
          </Card.Body>
          <br />
          
        </Card>
        </>
    )
}

const SetPassword = () => {
    let token = localStorage.getItem('token')
    const navigate = useNavigate("/")
    const [password,setPassword] = useState("")
    const [repeatedPassword,setRepeatedPassword] = useState("")
    const [isInvalid,setIsInvalid] = useState(false)
    const [loading,setLoading] = useState(false)
    const [error,setError] = useState()
    const handleSubmit = (event) => {
        event.preventDefault()
        const form = event.currentTarget;
        if (form.checkValidity() === false) {
            event.stopPropagation();
            setIsInvalid(true)
        }else if(password !== repeatedPassword){
            event.stopPropagation();
            setIsInvalid(true)
            setError("Las contraseñas no son iguales")
        }
        else{
          setIsInvalid(false);
          setLoading(true)
          axios.post(`${process.env.REACT_APP_BACKENDURL}/auth/newpassword`,{password:password, token:token}).then((response) => {
            // let {user} = response.data
            // let newToken = response.data.token
            // setUser(user)
            // localStorage.setItem('token',newToken)
            // setLoading(false)
            navigate("/")
          }).catch(err => {
            setError(err.response.data.message)
            setLoading(false)
          })
        }
      }
    return (
        <Card style={{ width: '25rem','margin-left':'auto','margin-right':'auto' }}>
          <Card.Body>
            <h2 className="text-center mb-4"></h2>
            <p>Por favor, escribe la nueva contraseña</p>
            {error && <Alert variant="danger">{error}</Alert>}
            <Form onSubmit={handleSubmit} validated={isInvalid}>
              <Form.Group id="email">
                <Form.Label>Nueva Contraseña</Form.Label>
                <Form.Control type="password" value={password} required onChange={(e) => setPassword(e.target.value)} />
              </Form.Group>
              <br/>
              <br/>
              <Form.Group id="email">
                <Form.Label>Escribe otra vez la nueva Contraseña</Form.Label>
                <Form.Control type="password" value={repeatedPassword} required onChange={(e) => setRepeatedPassword(e.target.value)} />
              </Form.Group>
              <br/>
              <Button disabled={loading} className="w-100" type="submit">
                Enviar
              </Button>
            </Form>
          </Card.Body>
          <br />
        </Card>
    )
}

const NewPasswordForm = () => {
    const [option,setOption] = useState(<></>)
    useEffect(() => {
        let params = new URLSearchParams(window.location.search)
        let token = params.get("token")
        if (typeof token === "string" || token instanceof String ){
            localStorage.setItem('token', token)
            setOption(<SetPassword />)
        }else{
            setOption(<SendEmail/>)
        }
    }, [])
    return(
      <>
        {option}
      </>
    )
}


export default NewPasswordForm