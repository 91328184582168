import React, { useState, useEffect } from "react";
import Select from "react-select";
import { Col, Form, Row } from "react-bootstrap";
import UpdateClassForm from "../UpdateClass/UpdateClassForm";
import DeleteClassForm from "../DeleteClass/DeleteClassForm";
import AllStudentsForm from "../AllUsers/AllStudentsForm";
import AllTutorsForm from "../AllUsers/AllTutorsForm";
import AllFamiliesForm from "../AllUsers/AllFamiliesForm";
import AllParentsForm from "../AllUsers/AllParentsForm";
import ClassTable from "../../tables/ClassTable";
import ReserveClassForm from "../ReserveClass/ReserveClassForm";
import SaleFormAdmin from "../Sale/SaleFormAdmin";
import NewUserForm from "../NewUser/NewUserForm";
import ActivateTutorForm from "./ActivateTutorForm";
import ManageSubjects from "./ManageSubjects";
import AllHistory from "./AllHistory";
import ManageTutors from "./ManageTutors";
import PayTutors from "./PayTutors";
import AdminMetrics from "./AdminMetrics";
import ManageStrikes from "./ManageStrikes";
import { useNavigate } from "react-router-dom";
import CreateStrike from "./CreateStrike";

// import FirstClassForm from '../FirstClass/FirstClassForm';
const AllUsersForm = () => {
  const options = [
    { label: "Ver estudiante", value: <AllStudentsForm /> },
    { label: "Ver tutor", value: <AllTutorsForm /> },
    { label: "Ver padre", value: <AllParentsForm /> },
    { label: "Ver familias", value: <AllFamiliesForm /> },
  ];
  const [option, setOption] = useState(<></>);
  return (
    <>
      <Row className="justify-content-md-center">
        <Form.Group as={Col} lg="4" controlId="type">
          <Form.Label>Escoge tipo de usuario</Form.Label>
          <br />
          <br />
          <Form.Control
            as={Select}
            options={options}
            onChange={(opt) => setOption(opt.value)}
          />
        </Form.Group>
      </Row>
      <br />
      {option}
    </>
  );
};

const ChatAdmin = () => {
  const navigate = useNavigate();
  useEffect(() => {
    navigate("/chat");
  }, []);
  return <div>Redireccionando a chats...</div>;
};

const AdminViewForm = () => {
  const options = [
    { label: "Comprar horas", value: <SaleFormAdmin /> },
    { label: "Reservar Clases (Manual)", value: <ReserveClassForm /> },
    // {label:"Actualizar Clase", value: <UpdateClassForm />},
    { label: "Eliminar Clase", value: <DeleteClassForm /> },
    { label: "Ver Clases", value: <ClassTable /> },
    { label: "Ver Usuario", value: <AllUsersForm /> },
    { label: "Nuevo Usuario", value: <NewUserForm /> },
    { label: "Activar Tutor", value: <ActivateTutorForm /> },
    { label: "Manejar cursos", value: <ManageSubjects /> },
    { label: "Manejar tutores", value: <ManageTutors /> },
    { label: "Pagar tutores", value: <PayTutors /> },
    { label: "Ver Historial", value: <AllHistory /> },
    { label: "Ver Métricas", value: <AdminMetrics /> },
    { label: "Ver chat", value: <ChatAdmin /> },
    { label: "Crear a Strike", value: <CreateStrike /> },
    { label: "Manage Strikes", value: <ManageStrikes /> },

    // {label:"Primera Clase", value: <FirstClassForm/>}
  ];
  const [option, setOption] = useState(<></>);
  return (
    <div>
      <br />
      <br />
      <p>Hola Admin!</p>
      <>
        <Row className="justify-content-md-center">
          <Form.Group as={Col} lg="4" controlId="type">
            <Form.Label>¿Qué quieres hacer?</Form.Label>
            <br />
            <br />
            <Form.Control
              as={Select}
              options={options}
              onChange={(opt) => setOption(opt.value)}
            />
          </Form.Group>
        </Row>
        <br />
        {option}
      </>
    </div>
  );
};

export default AdminViewForm;
