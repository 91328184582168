import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

export const getTutors = createAsyncThunk(
    'tutor/getTutors',
    async (data) => {
        const response = await axios.get(`${process.env.REACT_APP_BACKENDURL}/tutor/all`, {
            params:data
        })
        const tutors = response['data']
        return tutors
    }
)

export const getTutor = createAsyncThunk(
    'tutor/getTutor',
    async (data) => {
        const response = await axios.get(`${process.env.REACT_APP_BACKENDURL}/tutor`, {
            params:data
        })
        const tutor = response['data']
        return tutor
    }
)




export const getTutorsFirstClass = createAsyncThunk(
    'tutor/getTutorsFirstClass',
    async () => {
        const response = await axios.get(`${process.env.REACT_APP_BACKENDURL}/tutor/allMVP`)
        const tutors = response['data']
        return tutors
    }
)