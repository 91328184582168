import React, {useEffect} from 'react';
import { useSelector,useDispatch } from 'react-redux';
import {Table} from 'react-bootstrap';
import {getSubjects} from "../../../store/subject/subject.actions"



const TutorSubjects = () => {
    const dispatch = useDispatch()
    const {user} = useSelector((state) => state.auth)
    const {subjects} = useSelector((state) => state.subject)
    useEffect(() => {
        dispatch(getSubjects({filter:{_id:{"$in":user.subjects}},selection:{}}))
    }, [])
    return (
        <>
            <p>Puedes poner o encontrar material para estas materias en estos folderes!</p>
            <br/>
            <Table striped bordered hover style={{ width: '50%','margin-left':'auto','margin-right':'auto' }}>
            <thead>
                <tr>
                <th>Materia</th>
                <th>Folder Link</th>
                </tr>
                </thead>
                <tbody>
                    {subjects.map((value,id) => {
                        return(
                            <tr>
                                <th>{value.name}</th>
                                <th><a href={value.folder} target="_blank">Link</a></th>
                            </tr>
                        )
                    })}
                </tbody>
            </Table>

        </>
    )
}

export default TutorSubjects