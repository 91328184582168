import React, {useState, useEffect} from 'react';
import NewClassTutorStudent from './NewClassTutorStudent';
import NewClassTutorParent from "./NewClassTutorParent";
import NewClassTutorStatic from "./NewClassTutorStatic";
import { useSelector } from 'react-redux';
import {useUserIfLoggedIn} from "../../auth_utils";

const NewClassPage = () => {
    useUserIfLoggedIn();
    const [tutorId, setTutorId] = useState();
    const {user} = useSelector((state) => state.auth);
    useEffect(() => {
        let params = new URLSearchParams(window.location.search);
        let id = params.get('tutor')
        if (id){
            setTutorId(id);
        }
    }, []);
    return (
            (!user || !user._id) ? <div>Tiene que <a href="/login">iniciar sesión</a></div>:
            !tutorId ? <div>Id inválido</div>:
            user.role === 'Student'? <NewClassTutorStudent tutorId={tutorId}/>:
            user.role === 'Parent'? <NewClassTutorParent tutorId={tutorId}/>:
            <NewClassTutorStatic tutorId={tutorId}/>
            // <div>Contenido no disponible para usuarios tipo <strong>{user.role}</strong></div>
    )
}

export default NewClassPage;