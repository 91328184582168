import Table from 'react-bootstrap/Table';
import { useState, useEffect } from 'react';
import {useDispatch, useSelector} from 'react-redux';
import { getTutors } from '../../../store/tutor/tutor.actions';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import { parseTimestamp, isActiveInWeek, formatWorkingWeek } from '../../../time_utils';
import axios from 'axios';


const ManageTutors = () => {
    const dispatch = useDispatch();
    const [onlyActive, setOnlyActive] = useState(true);
    const getAllTutors = () => {
        dispatch(getTutors({filter: {}, selection: {}}))
    }
    const {tutors} = useSelector((state) => state.tutor);
    const [filteredTutors, setFilteredTutors] = useState(tutors);
    useEffect(() => {
        getAllTutors();
    }, [])
    useEffect(() => {
        setFilteredTutors(tutors.filter((tutor) => (tutor.status === 'Activo') === onlyActive));
    }, [tutors, onlyActive])
    const changeStatus = async (tutor) => {
        let prev = tutor.status;
        let newStatus = prev === 'Activo'? 'Inactivo' : 'Activo';
        let confirmation = window.confirm(`Está seguro que desea cambiar el estado del tutor ${tutor.name} de ${prev} a ${newStatus}?`);
        if (!confirmation){
            return;
        }
        await axios.patch(`${process.env.REACT_APP_BACKENDURL}/tutor/`, {
            id: tutor._id, 
            update: {
                status: newStatus
            }
        });

        getAllTutors();
    }
    const isSunday = new Date().getDay() === 0;
    const workingWeek = isSunday? formatWorkingWeek(1) : formatWorkingWeek(0);
    return(
        <div>
            <div style={{marginBottom: "10px"}}>
                <div style={{display:"flex", alignItems:"center", justifyContent:"center"}}>
                    <Form.Switch
                        checked={onlyActive}
                        onChange={(e) => setOnlyActive(prev => !prev)}
                    />
                    <span>{onlyActive? `Mostrando ${filteredTutors.length} tutores activos` : `Mostrando ${filteredTutors.length} tutores inactivos`}</span>
                </div>
                <Button onClick={getAllTutors}>Usar la información más actual.</Button>
            </div>
            <Table striped bordered hover >
                <thead>
                    <tr>
                        <th>#</th>
                        <th>Nombre</th>
                        <th>Apellido</th>
                        <th>Celular</th>
                        <th>Ultima vez que actualizo su calendario</th>
                        <th>Actualizo calendario para semana ({workingWeek})</th>
                        <th>Status</th>
                    </tr>
                </thead>
                <tbody style={{verticalAlign:"middle"}}>
                    {
                        filteredTutors && filteredTutors.map((tutor, index) => {
                            let lastUpdate = tutor.lastCalendarUpdate;
                            const needsCalendarUpdate = (isSunday && !isActiveInWeek(lastUpdate, 1)) || (!isSunday && !isActiveInWeek(lastUpdate, 0));
                            return(
                            <tr key={tutor._id}>
                                <td>{index+1}</td>
                                <td>{tutor.name}</td>
                                <td>{tutor.surname}</td>
                                <td>{tutor.phone}</td>
                                <td>{parseTimestamp(tutor.lastCalendarUpdate)}</td>
                                <td style={{
                                    backgroundColor: needsCalendarUpdate? "red" : "green"
                                }}>{needsCalendarUpdate ? "No :(": "Si!"}</td>
                                <td>{tutor.status}<Button variant="danger" onClick={() => changeStatus(tutor)}>Cambiar</Button></td>
                            </tr>)
                        } )
                    }
                </tbody>
            </Table>
        </div>
    )
}


export default ManageTutors
