import React from 'react';
import { useSelector } from 'react-redux';

const MoreHours = () => {
    const {user} = useSelector((state) => state.auth)
    return(
        <>
        <br/>
        <br/>
        <p><b>Por favor, dile a tu padre/madre que compre más horas a traves de su cuenta.</b></p>
        </>
    )
}

export default MoreHours