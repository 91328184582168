import React from "react"
import NewPasswordForm from "./NewPasswordForm"
import { Image } from "react-bootstrap"
const NewPasswordPage = () => {
    return (
        <div>
            <br/>
            <NewPasswordForm />
        </div>
    )
}

export default NewPasswordPage