/**
 * Only shows the first name, and the first letter of the second name
 * @param {*} tutor 
 * @returns 
 */
export const getTutorVisibleName = (tutor) => {
    return `${tutor.name.split(" ")[0]} ${tutor.surname && tutor.surname.length > 0 ? `${tutor.surname[0]}.` : ""}`
}
/**
 * 
 * @param {*} array 
 * @param {*} options currently supported `emptyChoice` default to `false`, `emptyText` in case it's true, and `isTutor` default to `false`
 * @returns 
 */
export const listFormat = (array, options={}) => {
    let newArray = []
    let emptyChoice = options.emptyChoice || false;
    let emptyText = options.emptyText || "";
    let isTutor = options.isTutor || false;
    if (emptyChoice) {
        newArray.push({value: null, label: emptyText})
    }
    for (const elem of array){
        let newElem = {...elem}
        if ('surname' in newElem){
            if (isTutor){
                newElem['label'] = getTutorVisibleName(elem);
            } else{
                newElem['label'] = elem['name'].split(" ")[0] + " " + elem['surname'].split(" ")[0]
            }
        }else{
            newElem['label'] = elem['name']
        }
        newElem['value'] = elem['_id']
        newArray.push(newElem)
    }
    return newArray
}