import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

export const getHistory = createAsyncThunk(
    "history/getHistory",
    async (data) => {
        console.log(`Found`)
        console.log(data)
        if (!data || !data.userId){
            console.log("Need userId to check that it's coming from admin");
            return {}
        }
        const response = await axios.get(`${process.env.REACT_APP_BACKENDURL}/history/`, {
            params:data
        })
        const history = response['data'];
        return history
    }
)

export const getFamilyHistory = createAsyncThunk(
    "history/getFamilyHistory",
    async (data) => {
        if (!data || !data.familyId){
            console.log("Need familyId");
            return {}
        }
        const response = await axios.get(`${process.env.REACT_APP_BACKENDURL}/history/family/`, {
            params:data
        })
        const history = response['data'];
        return history
    }
)

export const getTutorHistory = createAsyncThunk(
    "history/getTutorHistory",
    async (data) => {
        if (!data || !data.tutorId){
            console.log("Need tutorId");
            return {}
        }
        const response = await axios.get(`${process.env.REACT_APP_BACKENDURL}/history/tutor/`, {
            params:data
        })
        const history = response['data'];
        return history
    }
)