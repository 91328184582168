import React, {useState, useEffect} from 'react';
import { useSelector, useDispatch} from 'react-redux';
import axios from 'axios';
import { getFamily } from '../../../store/family/family.actions';

let today = new Date()

const FamilyMonthReferrals = () => {
    const {user} = useSelector((state) => state.auth)
    // const [families, setFamilies] = useState([]);
    const [toPay, setToPay] = useState(0);

    const dispatch = useDispatch()
    const {family} = useSelector((state) => state.family);
    useEffect(() => {
        dispatch(getFamily({id:user.family}));
    }, [])

    useEffect(()=>{
        setToPay(family.toPay)
    }, [family])
    return(
        <>
            <p>Tu familia ha referido <b>{toPay}</b> {toPay===1 ? 'vez': 'veces'}, asi que tienes <b>${10*toPay}</b> para usar en futuras clases!</p>
        </>
    )
}
export default FamilyMonthReferrals;