import React, {useEffect, useState} from 'react';
import axios from "axios"
import { useSelector,useDispatch } from 'react-redux';

import {getStudents} from "../../../store/student/student.actions"
import {setUser} from "../../../store/auth/auth.slice"
import Select from 'react-select';
import {Col,Form,Row,Card} from 'react-bootstrap';
import TutorSubjects from "./TutorSubjects"
import TutorClasses from "./TutorClasses"
import FamilyReferrals from "../AllUsers/FamilyReferrals"
import TutorMonthReferrals from "../AllUsers/TutorMonthReferrals"
import TutorPageEdit from "../../../pages/Tutor/TutorPageEdit"
import StudentPastReports from "./StudentPastReports"
import Button from "react-bootstrap/Button"
import {
    parseTimestamp,
    formatWorkingWeek,
    isActiveInWeek
} from "../../../time_utils.js"
let today = new Date()

const PERU_TIMEZONE = -5; // TODO: What if there are some other timezones?

//Hora Peru
function getFirstDayOfMonth() {
    let year = today.getFullYear();
    let month = today.getMonth();
    // return new Date(today.getFullYear(), today.getMonth(), 1);
    return new Date(Date.UTC(year, month, 1, -PERU_TIMEZONE, 0, 0));
}
//Hora Peru
function getLastDayOfMonth() {
    let year = today.getFullYear();
    let month = today.getMonth();
    // return new Date(today.getFullYear(), today.getMonth(), 1);
    return new Date(Date.UTC(year, month+1, 1, -PERU_TIMEZONE, 0, 0));

}

const TutorViewForm = () => {
    const dispatch = useDispatch()
    const {user} = useSelector((state) => state.auth)
    const [hours,setHours] = useState(0)
    // const [option,setOption] = useState(<></>)
    const [classes,setClasses] = useState(0)
    const [pendingClasses,setPending] = useState(0)
    const [missingReports,setReports] = useState(0)
    // const options = [
    //     {label: "Ver Clases", value: <TutorClasses />},
    //     // {label: "Reportes y Feedback de Clases Pasadas", value: <TutorPastClasses />},
    //     {label: "Añadir/Ver Material", value: <TutorSubjects />},
    //     {label: "Reportes de otros Tutores", value: <StudentPastReports />},
    //     {label: "Ver familias referidas ", value: <FamilyReferrals />},
    //     {label: "Editar perfil", value: <TutorPageEdit tutorId={user._id}/>}
    //     // {label:"Primera Clase", value: <FirstClassForm/>}
    // ]
    useEffect(() => {
        dispatch(getStudents({filter:{_id:{"$in":user.students}}, selection:{}}))
        axios.get(`${process.env.REACT_APP_BACKENDURL}/class/all`, {
            params: {
                filter:{tutorId:user._id, start:{"$gte":getFirstDayOfMonth().toISOString()}, end:{"$lte":getLastDayOfMonth().toISOString()}},
                selection:{}
            }
        }).then(response => {
            let classes = response.data
            let totalHours = 0
            let totalPending = 0
            let totalMissingReports = 0
            for (const thisClass of classes){
                totalHours += thisClass.duration
                if(thisClass.pending){
                    totalPending += 1
                }
                if(thisClass.tutorFeedback == ""){
                    totalMissingReports += 1
                }
            }
            setReports(totalMissingReports)
            setHours(totalHours)
            setClasses(response.data.length)
            setPending(totalPending)
        }).catch(err => console.log(err.message));
    },[])
    const confirmCalendarUpdate = async () => {
        let confirmation = window.confirm("Esta seguro que ya actualizaste tu calendario? Esa es la unica manera" + 
        " que tus estudiantes puedan registrar clases contigo.");
        if (!confirmation){
            return;
        }
        let response = await axios.patch(`${process.env.REACT_APP_BACKENDURL}/tutor`, {
            id: user._id,
            update: {
                lastCalendarUpdate: Date.now(),
                status: "Activo", // If they do this they are considered active
            }
        })
        dispatch(setUser(response.data));
    }
    //nWeeks is the number of (working weeks) from now
    // If active week is Monday x - Sunday x+6,
    // then today should be Sunday x-1 to Saturday x+5
    const isActiveInWeekUser = (nWeeks) => isActiveInWeek(user.lastCalendarUpdate, nWeeks);
    const isSunday = new Date().getDay() === 0;
    const needsCalendarUpdate = (isSunday && !isActiveInWeekUser(1)) || (!isSunday && !isActiveInWeekUser(0));

    return(
        <>
            <h5>Asegúrate de actualizar tu calendario de trabajo todas las semanas</h5>
            <Row style={{
                display: "flex",
                alignSelf:"center",
                justifyContent:"center",
                alignItems:"center",
                flex: 1,
                paddingBottom: 0,
                marginBottom: 10,
             }}>
            <Card style={{
                width: "50%",
                borderWidth: 2,
                borderColor: needsCalendarUpdate ? "red" : "green"
            }}>
                <Card.Header
                style={{
                    color:"black"
                }}
                >Última vez que actualizaste tu calendario: <strong>{parseTimestamp(user.lastCalendarUpdate)}</strong></Card.Header>
                <Card.Body
                >
                {
                    (isSunday) ? 
                        <>
                            {
                                isActiveInWeekUser(1) ?
                                <div>Actualizaste tu calendario para la semana ({formatWorkingWeek(1)}). Buen trabajo! </div>:
                                <>
                                    <div>Necesitas actualizar el calendario para la semana {formatWorkingWeek(1)}</div>
                                    <Button variant="primary" onClick={confirmCalendarUpdate}>
                                        Confirmar que tu calendario esta actualizado
                                    </Button>
                                </>
                            }
                        </>
                        :
                        <>
                          {
                                isActiveInWeekUser(0) ?
                                <div>Actualizaste tu calendario para la semana ({formatWorkingWeek(0)}). Buen trabajo! </div>:
                                <>
                                <div>Necesitas actualizar el calendario para la semana {formatWorkingWeek(0)}</div>
                                <Button variant="primary" onClick={confirmCalendarUpdate}>
                                    Confirmar que tu calendario esta actualizado
                                </Button>
                                </>
                            }
                        </>
                }   
                </Card.Body>
            </Card>
            </Row>
            <Row>
                <Col>
                    <Card>
                        <br/>
                        <h4>Hola <b>{user.name.split(" ")[0]}</b>!</h4>
                        <br/>
                        <TutorMonthReferrals/>
                        {user.currency == "PEN"
                            ?
                            <p>Recibe <b>S/.40</b> extras cada vez que familias compren sus primeras <b>4 horas</b> de clase usando este código!</p>
                            :
                            <p>Recibe <b>$10</b> extras cada vez que familias compren sus primeras <b>4 horas</b> de clase usando este código!</p>
                        }
                        <br/>

                        <p style={{color:"#0AB591"}}><u><b>{user.referral_id}</b></u></p>
                        <br/>
                    </Card>
                </Col>
                <Col>
                    <Card>
                        <br/>
                        <h4><u>Información del mes</u></h4>
                        <br/>
                        <p><b>Clases Programadas</b>: {classes}</p>
                        <p><b>Horas Programadas</b>: {hours}</p>
                        {user.currency == "PEN"
                            ?
                            <p><b>Pago previsto</b>: S/. {user.salary * hours}</p>
                            :
                            <p><b>Pago previsto</b>: $ {user.salary * hours}</p>
                        }
                        <p><u style={{color:"#0AB591"}}><b style={{color:"#0AB591"}}>Clases por confirmar</b></u>: <b>{pendingClasses} clases</b></p>
                        <p><u style={{color:"#0AB591"}}><b style={{color:"#0AB591"}}>Reportes por enviar</b></u>: <b>{missingReports} reportes</b></p>
                    </Card>
                </Col>
            </Row>
            <br/>
            {/* <p>Recuerda envíar el reporte de clases a máximo <b>12 horas después</b> de la clase, de lo contrario solo cobrarás el <b>70%</b> de esa clase</p> */}
            {/**
             * Rules for updating calendar:
             *  - You can only update calendar for the week Monday X - Sunday X+6 on 
             *     Sunday x-1 through Saturday X+5
             *  - Once you update for a week, you cannot mark it again 
             * 
             * - If Monday, Tuesday, Wednesday, Thursday, Friday, Saturday 
             *        -> Can only check for this week (update)
             * - If Sunday
             *        -> Can check for next week 
             */}

            {/* <br />
            <Row className="justify-content-md-center"> 
                <Form.Group as={Col} lg="4" controlId="type">
                <Form.Control
                    as={Select}
                    placeholder="¿Qué quieres hacer?"
                    options={options}
                    onChange={opt => setOption(opt.value)}
                />
                </Form.Group>
            </Row>
            <br />
            {option} */}
        </>
    )
}

export default TutorViewForm