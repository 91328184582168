import React, {useState} from 'react';
import CalTutorClasses from './CalTutorClasses';
import TableTutorClasses from './TableTutorClasses';
import {Form, Row,Col} from "react-bootstrap"
const TutorClasses = () => {
    const [checked,setChecked] = useState(false)
    return (
        <>
            {checked
                ?
                <>
                    <Row className="justify-content-md-center">
                        <Col  xs={2}>
                            <Form.Check 
                                type="switch"
                                id="custom-switch"
                                label="Ver calendario"
                                checked={checked}
                                onClick ={() => setChecked(!checked)}
                            />
                        </Col>
                    </Row>
                    
                    
                    <br/>
                    <TableTutorClasses />
                </>
                :
                <>
                    <Row className="justify-content-md-center">
                        <Col  xs={2}>
                            <Form.Check 
                                type="switch"
                                id="custom-switch"
                                label="Ver tabla"
                                checked={checked}
                                onClick ={() => setChecked(!checked)}
                            />
                        </Col>
                    </Row>
                    <br/>
                    <CalTutorClasses />
                </>
            }
        </>
    )
}

export default TutorClasses