import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

export const getFamilies = createAsyncThunk(
    'family/getFamilies',
    async (data) => {
        const response = await axios.get(`${process.env.REACT_APP_BACKENDURL}/family/all`, {params:data})
        const families = response['data']
        return families
    }
)

export const getFamily = createAsyncThunk(
    'family/getFamily',
    async (data) => {
        const response = await axios.get(`${process.env.REACT_APP_BACKENDURL}/family`, {params:data})
        const families = response['data']
        return families
    }
)