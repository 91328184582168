import React, { useEffect, useState} from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {getTutorHistory} from "../../store/history/history.actions";
import {Container, Col, Form, Text, Button, Row, Toast} from "react-bootstrap";
import Table from 'react-bootstrap/Table';
import Modal from 'react-bootstrap/Modal';
import axios from "axios";
import TutorMetrics from '../TimeMetrics/TutorMetrics';
import { useUserIfLoggedIn } from '../../auth_utils';

let modelTranslation = {
    SubjectHistory: "Curso",
    ParentHistory: "Padre",
    UserHistory: "Usuario",
    StudentHistory: "Estudiante",
    TutorHistory: "Tutor",
    ClassHistory: "Clase",
    FamilyHistory: "Familia",
    SaleHistory: "Venta",
    TempTutorHistory: "Tutor Temporal"
}
let typeTranslation = {
    create: 'creado',
    update: 'actualizado',
    delete: 'borrado'
}
const CREATE = 'create';
const UPDATE = 'update';
const DELETE = 'delete';
const parseTimestamp = (timestamp) => {
    let time = Date.parse(timestamp)
    return new Date(time).toLocaleString();
}
//It will only show the updates to these fields
const ONLY_VALID_UPDATES = ["status", "salary", "currency"];

const getTypeColor = (type) => {
    switch (type) {
        case "create":
            return "green";
        case "update":
            return "blue";
        case "delete":
            return "red";
    }
}
/**
 * Provides comprehensive information about this history item
 * TODO: Delete and only use `familySummary` field later
 * @param {*} history 
 */
const getTutorHistorySummary = async (history) => {
    switch (history.model) {
        case "TutorHistory":
            switch (history.type) {
                case CREATE:
                    return "Tutor creado";
                case UPDATE:
                    let res = "";
                    for (let key in history.diff){
                        if (!ONLY_VALID_UPDATES.includes(key)){
                            continue;
                        }
                        let line;
                        if (key !== "salary"){
                            line = `${key}: ${history.prev[key]} -> ${history.diff[key]}\n`; 
                        } else {
                            let oldCurrency = history.prev.currency;
                            let newCurrency = history.diff.currency || oldCurrency;
                            line = `${key}: ${history.prev[key]} ${oldCurrency} -> ${history.diff[key]} ${newCurrency}\n`; 
                        }
                        res += line;
                    }
                    if (history.success){
                        return `${res}`;
                    } else{
                        return `Error al cambiar: ${res}`
                    }
                case DELETE:
                    return "Tutor borrado";
                default:
                    return history.summary;
            }
        default:
            return history.summary; //Just use the default
    }
    return history.summary; //For tutors lets just 
}
const HistoryComponentRow = ({i, data}) => {
    const [showModal, setShowModal] = useState(false);
    const [summary, setSummary] = useState("");
    useEffect(() => {
        const getSummary = async () => {
            if (!data.tutorSummary){
                let summary =  await getTutorHistorySummary(data);
                setSummary(summary);
            } else {
                setSummary(data.tutorSummary)
            }
        }
        getSummary();
    }, [])
    return (
        <tr>
            <td>{i}</td>
            {/* <td>{data.ref_id}</td> */}
            {/* <td>{modelTranslation[data.model]}</td> */}
            <td style={{
                color: getTypeColor(data.type)
            }}>{typeTranslation[data.type]}</td>
            <td>{summary}</td>
            <td>{data.info && data.info.updateReason ? `Razón = ${data.info.updateReason}`: ""}</td>
            <td>{parseTimestamp(data.timestamp)}</td>
            {/* <td>
                <Button variant="success" onClick={handleShow}>👀</Button>
            </td> */}
        </tr>
    )
}
const atLeastOneKey = (obj, arr) => {
    for (let el of arr){
        if (obj.hasOwnProperty(el)){
            return true;
        }
    }
    return false;
}
const TutorHistory = ({tutorId}) => {
    useUserIfLoggedIn();
    const {user} = useSelector(state => state.auth);
    const dispatch = useDispatch();
    // No filters for now
    // const [showSale, setShowSale] = useState(true);
    // const [showClass, setShowClass] = useState(true);
    // const [showFamily, setShowFamily] = useState(true);

    const {tutorHistory, gettingTutorHistory} = useSelector((state) => state.history);
    const getLatestHistory = () => {
        dispatch(getTutorHistory({tutorId}));
    }
    useEffect(() => {
        getLatestHistory();
    }, []);
    const showHistory = (list) => {
        return list.map((item, i) => (
            <HistoryComponentRow i={i+1} data={item} key={item._id}/>
        ))
    }
    //Only show the results that have a valid update
    const filterResults = (history) => {
        return history.filter((item) => {
            return (
                item.model === "TutorHistory" && item.type === "update" && atLeastOneKey(item.diff, ONLY_VALID_UPDATES)
                )
        })
        return history; // No filter for now
        // Apply all filters
        // return history.filter((item) => {
        //     return (
        //         (item.model === "TutorHistory" && showSale) ||
        //         (item.model === "ClassHistory" && showClass) ||
        //         (item.model === "FamilyHistory" && showFamily)
        //     )
        // })
    }
    return (
        <Container style={{}}>
            <Modal show={gettingTutorHistory}>
                <Modal.Header closeButton>Cargando...</Modal.Header>
            </Modal>
            <h1>
                Se encontró {filterResults(tutorHistory).length} cambios
            </h1>
            <div style={{marginBottom: 10}}>
                {/* <div style={{display:"flex", alignItems:"center", justifyContent:"center"}}>
                    <span>{showSuccess? 'Transacciones exitosas' : 'Transacciones erroneas'}</span>
                </div> */}
                <Button onClick={getLatestHistory}>Usar la información más actual.</Button>
            </div>
            <div class="mb-3">
                Note que solo se muestra cambios de salario o de estado (Activo, Inactivo)
            </div>
            {/* <div>
                <h2>Filtros</h2>
                <Form className="mb-3">
                    <Form.Check
                        inline
                        label="Ventas"
                        name="filters"
                        type="checkbox"
                        id={"Ventas"}
                        checked={showSale}
                        onChange={(e) => {setShowSale(e.target.checked)}}
                    />
                    <Form.Check
                        inline
                        label="Clases"
                        name="filters"
                        type="checkbox"
                        checked={showClass}
                        id={"Clases"}
                        onChange={(e) => {setShowClass(e.target.checked)}}
                    />
                    <Form.Check
                        inline
                        label="Horas"
                        name="filters"
                        type="checkbox"
                        id={"Horas"}
                        checked={showFamily}
                        onChange={(e) => {setShowFamily(e.target.checked)}}
                    />
                </Form>
            </div> */}
            <Table striped bordered hover>
                <thead>
                    <tr>
                        <th>#</th>
                        {/* <th>ref_id</th> */}
                        {/* <th>Tipo</th> */}
                        <th>Operacion</th>
                        <th>Resumen</th>
                        <th>Info adicional</th>
                        <th>Cuando</th>
                        {/* <th>Ver</th> */}
                    </tr>
                </thead>
                <tbody>
                    { showHistory(filterResults(tutorHistory)) }
                </tbody>
            </Table>
            <div>
                Nota: Solo se muestra cambios que hayan pasado desde Feb 8, 2023 (y algunas cuantas más que pasaron un poco antes)
            </div>
            {/* <TutorMetrics tutorId={user._id}/> */}
    </Container>
    )
}

export default TutorHistory;