import React, {useEffect, useState} from 'react';
import Select from "react-select"
import { useSelector, useDispatch } from 'react-redux';
import { setStudent, updateType } from '../../../store/student/student.slice';
import { filterTutors, selectTutor } from '../../../store/tutor/tutor.slice';
import { getTutors,getTutorsFirstClass } from '../../../store/tutor/tutor.actions';
import { setSubject } from '../../../store/subject/subject.slice';
import { getSubjects } from '../../../store/subject/subject.actions';
import {updateClassType} from '../../../store/class/class.slice';
import { getStudents } from '../../../store/student/student.actions';
import { setFamily } from '../../../store/family/family.slice';
import { selectEvent, setEvents, setFreeEvents} from '../../../store/calendar/calendar.slice';
import { getClasses } from '../../../store/class/class.actions';
import {setClasses} from '../../../store/class/class.slice';
import axios from "axios"
import {Col, Form,Row} from 'react-bootstrap';
import { getFreeEvents } from '../../../store/calendar/calendar.actions';
import {nextMonday} from "date-fns"
import { getTutorVisibleName } from '../../../name_utils';
import { listFormat } from '../../../name_utils';

const listFormatEvents = (array) => {
    let newArray = []
    for (const elem of array){
        let newElem = {...elem}
        let start = new Date(newElem.start)
        let startTime = `${start.getHours()}:${String(start.getMinutes()).padStart(2, "0")}`
        let end = new Date(newElem.end)
        let endTime = `${end.getHours()}:${String(start.getMinutes()).padStart(2, "0")}`
        let month = start.getMonth()
        let day = start.getDate()
        let date = `${day}/${month}`
        newElem['label'] = `${elem['name'].split(" -")[0]} el ${date} entre ${startTime} - ${endTime}`
        newElem['value'] = elem['eventId']
        newArray.push(newElem)
    }
    return newArray
}

export const StudentSelect = () => {
    const dispatch = useDispatch()
    const {students} = useSelector((state) => state.student)
    return (
        <Row className="justify-content-md-center"> 
            <Form.Group as={Col} lg="4" controlId="student">
            <Form.Label>Escoja al estudiante</Form.Label>
            <br />
            {/* <br /> */}
            <Form.Control
                as={Select}
                options={listFormat(students, {emptyChoice: true, emptyText:"Seleccione un estudiante"})}
                required
                onChange={opt => {
                    if (opt.value){
                        dispatch(setStudent(opt))
                    } else{
                        dispatch(setStudent({}));
                    }
                }}
            />
            </Form.Group>
        </Row>

    )
}
export const FamilySelect = () => {
    const dispatch = useDispatch();
    const {families} = useSelector((state) => state.family);
    return (
        <Row className="justify-content-md-center"> 
            <Form.Group as={Col} lg="4" controlId="student">
            <Form.Label>Escoja a la familia </Form.Label>
            <br />
            <Form.Control
                as={Select}
                options={listFormat(families, {emptyChoice: true, emptyText:"Seleccione una familia"})}
                required
                onChange={opt => {
                    if (opt.value){
                        dispatch(setFamily(opt))
                    } else{
                        dispatch(setFamily({}))
                    }
                }}  
            />
            </Form.Group>
        </Row>
    )
}


export const StudentTypeSelect = () => {
    const {hours} = useSelector((state) => state.student.student)
    const dispatch = useDispatch()
    const typesA = [{label:"Comprar Horas",value:"sale"},{label:"Reservar Clases",value:"class"}]
    const typesB = [{label:"Comprar Horas",value:"sale"}]
    return (
        <Row className="justify-content-md-center"> 
            <Form.Group as={Col} lg="4" controlId="type">
            <Form.Label>Que quiere hacer el estudiante?</Form.Label>
            <br />
            <br />
            {hours > 0 
                ?
                <Form.Control
                as={Select}
                options={typesA}
                onChange={opt => dispatch(updateType(opt.value))}
                />
                :
                <Form.Control
                as={Select}
                options={typesB}
                onChange={opt => dispatch(updateType(opt.value))}
                />
            }
            </Form.Group>
        </Row>
            

    )
}

export const ClassTypeSelect = () => {
    const dispatch = useDispatch()
    const actions = [{label:"Cambiar la clase", value:"update"}, {label:"Eliminar la clase", value:"edit"}]
    return (
        <Row className="justify-content-md-center"> 
            <Form.Group as={Col} lg="4" controlId="tutor">
            <Form.Label>Que quieres hacer con la clase?</Form.Label>
            <br />
            <br />
            <Form.Control
                required
                as={Select}
                options={actions}
                onChange={opt => dispatch(updateClassType(opt.value))}
            />
            </Form.Group>
        </Row>
            

    )
}



export const SubjectSelect = ({label}) => {
    const dispatch = useDispatch()
    const {subjects} = useSelector((state) => state.subject)
    return (
        <Row className="justify-content-md-center"> 
            <Form.Group as={Col} lg="4" controlId="subject">
            {label && <Form.Label>{label}</Form.Label>}
            <br />
            <Form.Control
                required
                placeholder="Materia"
                as={Select}
                options={listFormat(subjects, {emptyChoice: true, emptyText:"Seleccione una materia"})}
                onChange={opt => {
                    if (opt.value){
                        dispatch(setSubject(opt))
                        dispatch(filterTutors(opt._id))
                        dispatch(selectTutor({}))
                    } else{
                        dispatch(setSubject({}))
                    }
                }}
            />
            </Form.Group>
        </Row>
    )
}
//Does not reset tutor when subject is chosen
export const TutorCalendarSubjectSelect = ({label}) => {
    const dispatch = useDispatch()
    const {subjects} = useSelector((state) => state.subject)
    return (
        <Row className="justify-content-md-center"> 
            <Form.Group as={Col} lg="4" controlId="subject">
            {label && <Form.Label>{label}</Form.Label>}
            <br />
            <Form.Control
                required
                placeholder="Materia"
                as={Select}
                options={listFormat(subjects, {emptyChoice: true, emptyText:"Seleccione una materia"})}
                onChange={opt => {
                    if (opt.value){
                        dispatch(setSubject(opt))
                    } else{
                        dispatch(setSubject({}))
                    }
                }}
            />
            </Form.Group>
        </Row>
    )
}

//Change into fture
export const SubjectSelectFirstClass = () => {
    const dispatch = useDispatch()
    useEffect(() => {
        dispatch(getSubjects({filter:{},selection:{}}))
        dispatch(getTutorsFirstClass())
    }, [])
    const {subjects} = useSelector((state) => state.subject)

    return (
        <Row className="justify-content-md-center"> 
            <Form.Group as={Col} lg="4" controlId="subject">
            <Form.Label>Que materia quiere estudiar el estudiante?</Form.Label>
            <br />
            <br />
            <Form.Control
                required
                as={Select}
                options={listFormat(subjects)}
                onChange={opt => {
                    dispatch(setSubject(opt))
                    dispatch(filterTutors(opt._id))
                    // dispatch(filterEventsByTutors(tutors))
                }}
            />
            </Form.Group>
        </Row>
    )
}

export const TutorSelect = ({label}) => {
    const dispatch = useDispatch()
    const {tutors} = useSelector((state) => state.tutor)
    return (
        <Row className="justify-content-md-center"> 
            <Form.Group as={Col} lg="4" controlId="tutor">
            {label && <Form.Label>{label}</Form.Label>}
            <br />
            <Form.Control
                required
                as={Select}
                placeholder="Escoja al tutor"
                options={listFormat(tutors, {emptyChoice: true, emptyText:"Seleccione un tutor", isTutor:true})}
                onChange={opt => {
                    if (opt.value){
                        dispatch(selectTutor(opt))
                    } else{
                        dispatch(selectTutor({}))
                    }
                }}
            />
            </Form.Group>
        </Row>
    )
}

let today = new Date()
let nextWeek = new Date(today)
nextWeek.setDate(today.getDate() + 7)
let tomorrow = new Date(today)
tomorrow.setDate(today.getDate() + 1)
let todayMorning = new Date()
todayMorning.setHours(0,0,0,0)

export const TutorSelectEvents = () => {
    const dispatch = useDispatch()
    const {subject} = useSelector((state) => state.subject)
    const {tutors} = useSelector((state) => state.tutor)
    const {user} = useSelector((state) => state.auth)
    return (
        <Row className="justify-content-md-center"> 
            <Form.Group as={Col} lg="4" controlId="tutor">
            <Form.Control
                required
                as={Select}
                placeholder="Tutor"
                options={listFormat(tutors, {isTutor: true})}
                onChange={opt => {
                    dispatch(selectTutor(opt))
                }}
            />
            </Form.Group>
        </Row>
    )
}



export const EventSelect = () => {
    const dispatch = useDispatch()
    const {tutors} = useSelector((state) => state.tutor)
    // console.log(tutors)
    const {allFreeEvents} = useSelector((state) => state.calendar)
    let freeEvents = []
    let tutorCalendars = []
    for (const tutor of tutors){
        tutorCalendars.push(tutor["calendarId"])
    }
    for (const event of allFreeEvents){
        if(tutorCalendars.includes(event.calendarId)){
            freeEvents.push(event)
        }
    }
    return (
        <Row className="justify-content-md-center"> 
            <Form.Group as={Col} lg="4" controlId="tutor">
            <Form.Label>Selecciona el evento que quieres usar</Form.Label>
            <br />
            <br />
            <Form.Control
                required
                as={Select}
                options={listFormatEvents(freeEvents)}
                onChange={opt => dispatch(selectEvent(opt))}
            />
            </Form.Group>
        </Row>
    )
}



