import React, { useState, useEffect } from "react";
import Table from "react-bootstrap/Table";
import { getStrikes, updateStrike, deleteStrike } from "../../../ApiConfig";
import { parseTimestamp, addMinutes } from "../../../time_utils";
import { Button, Modal, Row, Form, Col } from "react-bootstrap";
import Select from "react-select";
import { useSelector } from "react-redux";

const EditStrikeModal = ({
  strike,
  showModal,
  handleClose,
  handleShow,
  onUpdate,
}) => {
  const [value, setValue] = useState(strike.value);
  const [reason, setReason] = useState(strike.reason);
  const [loading, setLoading] = useState(false);
  const { user } = useSelector((state) => state.auth);
  const handleEdit = async (e) => {
    e.preventDefault();
    setLoading(true);
    let update = {
      reason,
      value,
    };
    try {
      await updateStrike(strike._id, update);
      onUpdate();
    } catch (err) {
      alert(`Hubo un error editando: ${err.message}`);
    }

    setLoading(false);
  };
  const handleDelete = async () => {
    setLoading(true);

    try {
      await deleteStrike(user._id, strike._id);
      onUpdate();
    } catch (err) {
      alert(`Hubo un error eliminando: ${err.message}`);
      console.log(err);
    }
    setLoading(false);
  };
  if (!user?._id) {
    return "Necesita estar logeado para ver esto!";
  }
  return (
    <Modal show={showModal} onHide={handleClose} size="lg">
      <Modal.Header closeButton>
        <Modal.Title>Editar Strike</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form noValidate onSubmit={handleEdit}>
          <Row className="justify-content-md-center">
            <Form.Group as={Col} lg="5" controlId="hours" noValidate>
              <Form.Label>
                Cuanto se le debe de descontar a {strike.tutor.name}{" "}
                {strike.tutor.surname}?
              </Form.Label>

              <Form.Control
                type="text" //not 'number' to prevent showing arrows
                fontSize={32}
                // inputMode="numeric"
                noValidate
                required
                //   value={value}
                placeholder={strike.value}
                //   onChange={(e) => setValue(e.target.value)}
                as={Select}
                options={[0.1, 0.2, 0.25, 0.5, 1, 1.5, 2, 2.5, 3].map((v) => ({
                  label: v,
                  value: v,
                }))}
                onChange={(opt) => setValue(opt.value)}
              />
            </Form.Group>
          </Row>
          <br />
          <Row className="justify-content-md-center">
            <Form.Group as={Col} lg="5" controlId="tutor">
              <Form.Label>Cual es la razon?</Form.Label>

              <br />
              <Form.Control
                required
                type="text" //not 'number' to prevent showing arrows
                fontSize={32}
                placeholder="Escriba una razon"
                value={reason}
                onChange={(e) => setReason(e.target.value)}
              />
            </Form.Group>
          </Row>
          <br />
          <Button type="submit" variant="success" disabled={loading}>
            {loading ? "Cargando..." : "Guardar cambios"}
          </Button>
        </Form>
        <Button onClick={handleDelete} variant="danger" disabled={loading}>
          {loading ? "Cargando..." : "Eliminar strike"}
        </Button>
      </Modal.Body>
    </Modal>
  );
};
const StrikeComponentRow = ({ i, strike, onUpdate }) => {
  const [showModal, setShowModal] = useState(false);
  const handleClose = () => setShowModal(false);
  const handleShow = () => setShowModal(true);
  return (
    <tr key={strike._id}>
      <EditStrikeModal
        strike={strike}
        showModal={showModal}
        handleClose={handleClose}
        handleShow={handleShow}
        onUpdate={onUpdate}
      />
      <td>{i}</td>
      <td>{strike.tutor._id}</td>
      <td>{strike.type}</td>
      <td>{strike.value}</td>
      <td>
        {`[Tutor ${strike.tutor.name}]: `} {strike.reason}
      </td>
      <td>{parseTimestamp(strike.created)}</td>
      <td>
        <Button variant="warning" onClick={handleShow}>
          Editar
        </Button>
      </td>
    </tr>
  );
};
const ManageStrikes = () => {
  const [strikes, setStrikes] = useState([]);
  const [loading, setLoading] = useState(false);
  const get_all_strikes = async () => {
    setLoading(true);
    let now = new Date();
    let TIME_TO_LOOK_BACK = 2 * 30 * 24 * 60; // 2 months
    let past = addMinutes(now, -TIME_TO_LOOK_BACK);
    let strikes = await getStrikes({ created: { $gte: past } });
    setStrikes(strikes);
    setLoading(false);
  };
  useEffect(() => {
    get_all_strikes();
  }, []);
  if (loading) {
    return "Cargando...";
  }
  return (
    <div>
      <div style={{ marginBottom: "10px" }}>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            fontSize: 20,
          }}
        >
          Mostrando {strikes.length} strike
          {`${strikes.length !== 1 ? "s" : ""}`}
        </div>
        <div style={{ fontStyle: "italic", fontWeight: "bold", margin: 5 }}>
          Solo se muestran los strikes de los últimos 2 meses.
        </div>
        <Button onClick={get_all_strikes}>
          Usar la información más actual.
        </Button>
      </div>
      <Table striped bordered hover>
        <thead>
          <tr>
            <th>#</th>
            <th>Tutor id</th>
            <th>Tipo</th>
            <th>Descuento</th>
            <th>Razón</th>
            <th>Fecha</th>
            <th>Editar</th>
          </tr>
        </thead>
        <tbody style={{ verticalAlign: "middle" }}>
          {strikes.map((strike, index) => (
            <StrikeComponentRow
              i={index + 1}
              strike={strike}
              key={strike._id}
              onUpdate={get_all_strikes}
            />
          ))}
          {/* {filteredTutors &&
            filteredTutors.map((tutor, index) => {
              let lastUpdate = tutor.lastCalendarUpdate;
              const needsCalendarUpdate =
                (isSunday && !isActiveInWeek(lastUpdate, 1)) ||
                (!isSunday && !isActiveInWeek(lastUpdate, 0));
              return (
                <tr key={tutor._id}>
                  <td>{index + 1}</td>
                  <td>{tutor.name}</td>
                  <td>{tutor.surname}</td>
                  <td>{tutor.phone}</td>
                  <td>{parseTimestamp(tutor.lastCalendarUpdate)}</td>
                  <td
                    style={{
                      backgroundColor: needsCalendarUpdate ? "red" : "green",
                    }}
                  >
                    {needsCalendarUpdate ? "No :(" : "Si!"}
                  </td>
                  <td>
                    {tutor.status}
                    <Button
                      variant="danger"
                      onClick={() => changeStatus(tutor)}
                    >
                      Cambiar
                    </Button>
                  </td>
                </tr>
              );
            })} */}
        </tbody>
      </Table>
    </div>
  );
};

export default ManageStrikes;
