import React from "react"
import StudentFeedbackForm from "./StudentFeedbackForm"
import { Image } from "react-bootstrap"
const StudentFeedbackPage = () => {
    return (
        <div>
            <br/>
            <StudentFeedbackForm />
        </div>
    )
}

export default StudentFeedbackPage