import React, { useEffect, useState, useMemo } from "react"
import axios from "axios"
import { useSelector,useDispatch } from 'react-redux';
import Select from "react-select"
import {Form, Row, Col, Button, Toast} from "react-bootstrap"
import PhoneInput from 'react-phone-input-2'
import { getSubjects } from "../../../store/subject/subject.actions";
import { selectTutor } from "../../../store/tutor/tutor.slice";
import 'react-phone-input-2/lib/style.css'
import Card from "react-bootstrap/Card";
import { listFormat } from "../../../name_utils";
// const listFormat = (array) => {
//     let newArray = []
//     for (const elem of array){
//         let newElem = {...elem}
//         if ('surname' in newElem){
//             newElem['label'] = elem['name'] + " " + elem['surname']
//         }else{
//             newElem['label'] = elem['name']
//         }
//         newElem['value'] = elem['_id']
//         newArray.push(newElem)
//     }
//     return newArray
// }
const sleep = (milliseconds) => {
    return new Promise(resolve => setTimeout(resolve, milliseconds))
  }
let COLORS = {
XPLAIN_GREEN: "#0AB591",
};

const  SignupNewTutor = () => {
    const dispatch = useDispatch()
    useEffect(() => {
        dispatch(getSubjects({filter:{},selection:{}}))
    },[])
    let currencies = [{label:"Sol Peruano",value:"PEN"}, {label:"US Dollar", value:"USD"}]
    const {tutor} = useSelector((state) => state.tutor)
    let newTutor = {...tutor, salary: 0} 
    const {subjects} = useSelector((state) => state.subject)
    const [disabled,setDisabled] = useState(false)
    const [isInvalid, setIsInvalid] = useState(false);

    //For toast
    const [showToast, setShowToast] = useState(false);
    const [success, setSuccess] = useState(false);
    const [toastMessage, setToastMessage] = useState("");
    const handleSubmit = async (event) => {
        event.preventDefault();
        const form = event.currentTarget;
        if (form.checkValidity() === false) {
            event.stopPropagation();
            setIsInvalid(true)
        }else{
            // let answer = window.confirm("Estás seguro quieres actualizar esta info?");
            setIsInvalid(false)
            setDisabled(true)
            delete newTutor._id
            delete newTutor.calendarId
            try{
                //This will only create a temporary in the db, but the admin will need to approve it
                //before
                await axios.post(`${process.env.REACT_APP_BACKENDURL}/tutor/temp`, newTutor);
                // let savedTutor = response.data;
                // await axios.post(`${process.env.REACT_APP_BACKENDURL}/jobs/welcomeTutor`, {tutorId: savedTutor._id});
                // window.location.reload(false);
                setSuccess(true);
                alert("Tutor creado correctamente. Puedes cerrar este mensaje.");
                window.location.href = "/";

                setToastMessage("Tutor creado correctamente");
                setToastMessage("Redireccionando...");

            } catch(err){
                console.log("Error when creating new tutor: " + err);
                // alert("Error: " + err.message);
                setSuccess(false);
                setToastMessage(err.message);
            }
            setShowToast(true);
            // if(answer){
            // }
        }
    }
    const resetToast = ()=>{
        setShowToast(false);
        setSuccess(false);
        setToastMessage("");
        setDisabled(false);
    }
    return(
        <>
        <Form noValidate validated={isInvalid} onSubmit={handleSubmit}>
            <div style={{display:"flex", flex:1, flexDirection:"column"}}>
            <Card style={{
                // color:"white",
                borderWidth: 2,
                borderColor: COLORS.XPLAIN_GREEN,
                borderRadius: 10, 
                flex:1,
                // padding: 5,
                margin: 10,
                width:"50%",
                height:"100%",
                alignSelf:"center",
            }}>
                <Card.Header style={{backgroundColor:COLORS.XPLAIN_GREEN, color:"white"}}><h1>Tutor</h1></Card.Header>

                <Card.Body style={{display:"flex", flexDirection:"column", alignItems:'center', flex:1}}>
                <div>
                Recuerda que solo puedes llenar esta forma si es que ya haz hablado 
                con el equipo de Xplain sobre tu candidatura como tutor.
            </div>
            {/* <Row className="justify-content-md-center"> */}
                <Form.Group as={"div"} className="mb-3" controlId="name" style={{flex: 1, width:"60%"}}>
                <Form.Label><b>Nombre</b></Form.Label>
                {/* <br/> */}
                {/* <br/> */}
                <Form.Control
                    required
                    type="text"
                    placeholder="Nombre"
                    onChange={e => {
                        newTutor["name"] = e.target.value
                        dispatch(selectTutor(newTutor))
                    }}
                />
                <Form.Control.Feedback>Todo bien!</Form.Control.Feedback>
                </Form.Group>

                <Form.Group as={"div"} className="mb-3" controlId="surname" style={{flex: 1, width:"60%"}}>
                <Form.Label><b>Apellido(s)</b></Form.Label>
                {/* <br/> */}
                {/* <br/> */}
                <Form.Control
                    required
                    type="text"
                    placeholder="Apellido(s)"
                    onChange={e => {
                        newTutor["surname"] = e.target.value
                        dispatch(selectTutor(newTutor))
                    }}

                />
                <Form.Control.Feedback>Todo bien!</Form.Control.Feedback>
                </Form.Group>
            {/* </Row> */}
            {/* <br/> */}
            {/* <br/> */}
            {/* <Row className="justify-content-md-center"> */}
                {/* <Form.Group as={Col} lg="2" controlId="surname">
                <Form.Label><b>Salario</b></Form.Label>
                <br/>
                
                <Form.Control
                    required
                    type="number"
                    placeholder="Salario"
                    onChange={e => {
                        newTutor["salary"] = e.target.value
                        dispatch(selectTutor(newTutor))
                    }}

                />
                <Form.Control.Feedback>Todo bien!</Form.Control.Feedback>
                </Form.Group> */}

<Form.Group as={"div"} className="mb-3" controlId="moneda" style={{flex: 1, width:"60%"}}>
                <Form.Label><b>Moneda</b></Form.Label>
                {/* <br/> */}
                <Form.Control
                    as={Select}
                    required
                    options={currencies}
                    onChange={(opt) => {
                        newTutor["currency"] = opt.value
                        dispatch(selectTutor(newTutor))
                    }}
                    placeholder="Moneda"
                />
                <Form.Control.Feedback>Todo bien!</Form.Control.Feedback>
                </Form.Group>
            {/* </Row> */}
            {/* <br/> */}
            {/* <br/> */}
            {/* <Row className="justify-content-md-center"> */}
            <Form.Group as={"div"} className="mb-3" controlId="gmail" style={{flex: 1, width:"60%"}}>
                <Form.Label><b>Gmail</b></Form.Label>
                {/* <br/> */}
                <Form.Control
                    required
                    type="email"
                    onChange={e => {
                        newTutor["email"] = e.target.value
                        dispatch(selectTutor(newTutor))
                    }}
                    placeholder="Gmail"
                />
                <Form.Control.Feedback>Todo bien!</Form.Control.Feedback>
                </Form.Group>
                <Form.Group as={"div"} style={{flex: 1}} className="mb-3" controlId="whatsapp">
                <Form.Label><b>Whatsapp</b></Form.Label>

                <PhoneInput
                    inputProps={{required:true}}
                    onChange={phone => {
                        newTutor["phone"] = "+" + phone
                        dispatch(selectTutor(newTutor))
                    }}  
                    enableSearch={true}
                    style={{alignItems: 'center', alignSelf: 'center', display: 'flex'}}
                /> 
                <Form.Control.Feedback>Todo bien!</Form.Control.Feedback>
                </Form.Group>
                
                {/* <br/> */}
            {/* </Row> */}
            {/* <br/> */}
            {/* <br/> */}
            {/* <Row className="justify-content-md-center"> */}
            <Form.Group as={"div"} className="mb-3" controlId="materias" style={{flex: 1, width:"60%"}}>
                <Form.Label><b>Materias</b></Form.Label>
                <Form.Control
                    as={Select}
                    required
                    isMulti={true}
                    options={listFormat(subjects)}
                    onChange={(opt) => {
                        let newSubjects = []
                        for (const subject of opt){
                            newSubjects.push(subject.value)
                        }
                        newTutor["subjects"] = newSubjects
                        dispatch(selectTutor(newTutor))
                    }}
                    placeholder="Materias"
                />
                <Form.Control.Feedback>Todo bien!</Form.Control.Feedback>
                </Form.Group>
            {/* </Row> */}
            {/* <br/> */}
            {/* <br/> */}
            <div>
                Una vez que completes esta forma, el equipo de Xplain la revisará
                y te enviaremos un correo de confirmación con una contraseña temporal.
            </div>
            </Card.Body>
        </Card>
        </div>
        <Button  style={{backgroundColor: COLORS.XPLAIN_GREEN, border: 0}}  variant="success" disabled={disabled} type="submit">
                {disabled ? "Creando...": "Crear Tutor"}
        </Button>
        </Form>


        <Toast  show={showToast} onClose={resetToast} bg={success?"success":"danger"}>
          <Toast.Header>
            <strong className="me-auto">{success? "Yay!": "Hubo un error :("}</strong>
            <small>Ahora</small>
          </Toast.Header>
          <Toast.Body style={{color: "white"}}>{toastMessage}</Toast.Body>
        </Toast>
        </>
    )
}

export default SignupNewTutor