import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import Card from "react-bootstrap/Card";
import Spinner from "react-bootstrap/Spinner";
import Button from "react-bootstrap/Button";
import { useMercadopago } from "react-sdk-mercadopago";
import axios from "axios";
import Image from "react-bootstrap/Image";

const PaymentCardPage = ({
  description,
  hoursBought,
  referralId,
  hourType,
  currency,
  isGroupHour,
}) => {
  const { user } = useSelector((state) => state.auth);
  const [loading, setLoading] = useState(true);
  // const [description, setDescription] = useState("");
  const [amountPEN, setAmountPEN] = useState(0);
  const [amountOtherCurrency, setAmountOtherCurrency] = useState(0);
  const [errorMessage, setErrorMessage] = useState("");
  const [isValidReferral, setIsValidReferral] = useState(false);
  const [discountFromReferral, setDiscountFromReferral] = useState(0);
  const [discountFromPreviousReferrals, setDiscountFromPreviousReferrals] =
    useState(0);

  let hasFamily = user.role === "Student" || user.role === "Parent";
  if (!hasFamily) {
    console.log("Uh oh..not family!");
    setErrorMessage(
      `Usuario es ${user.role}, solo estudiantes o padres pueden comprar horas.`
    );
  }

  const [toPay, setToPay] = useState(null);

  const mercadopago = useMercadopago.v2(
    process.env.REACT_APP_MERCADO_PAGO_PUBLIC_KEY,
    { locale: "es-PE" }
  );
  let bricksBuilder;
  if (mercadopago) {
    bricksBuilder = mercadopago.bricks({ theme: "flat" });
  }
  if (currency !== "PEN" && currency !== "USD") {
    setErrorMessage(`Not valid currency ${currency}`);
  }

  useEffect(() => {
    async function get_amount() {
      if (!hoursBought || !hourType || !currency) {
        return;
      }
      if (amountPEN) {
        return;
      }
      console.log("Getting amount...");
      let formData = {
        hoursBought,
        referralId,
        hourType,
        currency,
        familyId: user.family,
      };
      let x;
      try {
        x = await axios.get(
          `${process.env.REACT_APP_BACKENDURL}/sale/calculate_amount`,
          { params: formData }
        );
        x = x.data;
      } catch (err) {
        let msg = err.response.data.msg;
        setErrorMessage(msg);
      }
      setAmountPEN(x.finalAmountPEN);
      setAmountOtherCurrency(x.finalAmountOtherCurrency);
      if (x.isValidReferral) {
        setIsValidReferral(true);
        setDiscountFromReferral(x.discountFromReferral);
      }
      setDiscountFromPreviousReferrals(x.discountFromPreviousReferrals);
    }
    get_amount();
  }, []);

  let currencyNames = {
    PEN: "soles",
    USD: "dolares",
  };
  const initializeCard = () => {
    if (!mercadopago || !bricksBuilder || !amountPEN) {
      console.log("Oops...mercadopago is not available!");
      return;
    }

    console.log("initilazing...");
    const renderCardPaymentBrick = async (bricksBuilder) => {
      const settings = {
        initialization: {
          amount: 5, //amountPEN, //valor del pago a realizar
          installments: 1,
        },
        customization: {
          paymentMethods: {
            minInstallments: 1,
            maxInstallments: 1,
            creditCard: "all",
            debitCard: "all",
          },
          visual: {
            style: {
              // customVariables:{
              //     // buttonTextColor: "#28a745"
              // }
            },
            texts: {
              formSubmit: `Pagar ${amountOtherCurrency} ${
                currencyNames[currency] || currency
              } ${
                currency !== "PEN" ? `(${amountPEN} Soles)` : ""
              }  y reservar clases`,
            },
          },
        },
        callbacks: {
          onReady: () => {
            // callback llamado cuando Brick esté listo
            setLoading(false);
          },
          onSubmit: (cardFormData) => {
            // callback llamado cuando el usuario haga clic en el botón enviar los datos
            console.log(cardFormData);

            // ejemplo de envío de los datos recolectados por el Brick a su servidor
            return new Promise((resolve, reject) => {
              fetch(`${process.env.REACT_APP_BACKENDURL}/mp/process_payment`, {
                method: "POST",
                headers: {
                  "Content-Type": "application/json",
                },
                body: JSON.stringify(cardFormData),
              })
                .then((response) => {
                  // recibir el resultado del pago
                  return response.json();
                })
                .then((res) => {
                  if (res.status === "approved") {
                    console.log("Registering class...");
                    registerClass(res.id);
                    resolve();
                    window.location.reload(false);
                  } else {
                  }
                  console.log(res);
                })
                .catch((error) => {
                  // tratar respuesta de error al intentar crear el pago
                  reject();
                });
            });
          },
          onError: (error) => {
            // callback llamado para todos los casos de error de Brick
            console.log("Error!");
            console.log(error);
          },
        },
      };
      await bricksBuilder.create(
        "cardPayment",
        "cardPaymentBrick_container",
        settings
      );
    };
    renderCardPaymentBrick(bricksBuilder);
  };
  /**
   * Only to be called when a payment has been received
   */
  const registerClass = async (payment_id) => {
    //Info available:
    //description,hoursBought, referralId, hourType, currency
    let x = await axios.post(
      `${process.env.REACT_APP_BACKENDURL}/sale/register_class`,
      {
        hoursBought,
        referralId,
        hourType,
        currency,
        payment_id,
        familyId: user.family,
        user_role: user.role,
        source: "mercadopago",
      }
    );
    console.log(x);
  };
  useEffect(initializeCard, [amountPEN, mercadopago, isValidReferral]);

  const getSpanisHourName = (r) => {
    switch (r) {
      case "IB":
        return "IB";
      case "SAT":
        return "SAT";
      case "tutoring":
        return "Tutorias";
      case "olympiad":
        return "Olimpiadas";
      default:
        return "INVALID_HOUR_TYPE";
    }
  };
  return (
    <>
      {/* <Button onClick={registerClass}>
            Register class (test)
        </Button> */}
      {errorMessage.length > 0 ? (
        <h3>{errorMessage}</h3>
      ) : (
        <>
          <div
            style={{
              display: "flex",
              "align-items": "center",
              "justify-content": "center",
            }}
          >
            <Card>
              {loading ? (
                <Card.Header as="h4">
                  Preparando transacción segura{" "}
                  <Spinner animation="border"></Spinner>
                </Card.Header>
              ) : (
                <Card.Header as="h4">
                  <Image
                    height="60"
                    variant="top"
                    src={require("../../assets/images/mercadopago_logo_transparent.png")}
                    alt="logo de mercadopago"
                  />
                  Comprando {hoursBought} hora{hoursBought > 1 && "s"} del tipo{" "}
                  {getSpanisHourName(hourType)}
                </Card.Header>
              )}
              <Card.Body id="cardPaymentBrick_container"></Card.Body>
              <Card.Footer>
                <h4>Descuentos aplicados:</h4>
                <ul>
                  <li>
                    Descuento del codigo usado:{" "}
                    {referralId.length > 0
                      ? `${discountFromReferral} ${
                          currencyNames[currency] || currency
                        }`
                      : `No se brindo un codigo de referidos.`}
                  </li>
                  <li>
                    Descuento de antiguas familias referidas:{" "}
                    {discountFromPreviousReferrals}{" "}
                    {currencyNames[currency] || currency}
                  </li>
                </ul>
              </Card.Footer>
            </Card>
          </div>
          <br />
          <i>
            Este método de pago viene directamente de{" "}
            <a href="https://www.mercadopago.com.pe/" target="_blank">
              Mercado Pago
            </a>
            . Xplain no tiene accesso a ningún tipo de información sobre su
            pago.
          </i>
        </>
      )}
    </>
  );
};
export default PaymentCardPage;
