import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import Select from "react-select";
import { useSelector, useDispatch } from "react-redux";
import { Container, Form, Button, Row, Col } from "react-bootstrap";
import { getFamilies } from "../../../store/family/family.actions";
import { setFamily } from "../../../store/family/family.slice";
import { listFormat } from "../../../name_utils";
// const listFormat = (array) => {
//     let newArray = []
//     for (const elem of array){
//         let newElem = {...elem}
//         if ('surname' in newElem){
//             newElem['label'] = elem['surname']
//         }else{
//             newElem['label'] = elem['name']
//         }
//         newElem['value'] = elem['_id']
//         newArray.push(newElem)
//     }
//     return newArray
// }

const Sale = () => {
  const [currency, setCurrency] = useState(null);
  const dispatch = useDispatch();
  const { families, family } = useSelector((state) => state.family);

  const currencies = [
    { label: "Sol Peruano", value: "PEN" },
    { label: "Dolar EEUU", value: "USD" },
  ];
  const [referralId, setReferralId] = useState("");
  const [amount, setAmount] = useState(0);
  const [hoursBought, setHoursBought] = useState(0);
  const [receipt, setReceipt] = useState(null);
  const [disabled, setDisabled] = useState(false);
  const [hourType, setHourType] = useState(null);
  const [isGroupHour, setIsGroupHour] = useState(null); //invidual vs group
  let formRef = useRef(null);

  useEffect(() => {
    dispatch(getFamilies({ filter: {}, selection: {} }));
  }, []);
  const areInputsValid = () => {
    if (hoursBought < 1 || hoursBought > 48) {
      return false;
    }
    if (!receipt || !family._id || !currency) {
      return false;
    }
    return true;
  };
  // useEffect(() => {
  //     //Enable the button only when the data is valid
  //     // Only change when necessary to avoid infinite loop
  //     const form = formRef.current;
  //     let valid = areInputsValid() && form.checkValidity();
  //     if (disabled && valid) {
  //         setDisabled(false);
  //     } else if (!disabled && !valid){
  //         setDisabled(true);
  //     }
  // })
  /*
    To make sure that a number is always inputted
        evt: onkey event
        prev: current string
        admitsDecimal: if false, only accepts whole positive numbers
    */
  const handleNumberPress = (evt, prev, admitsDecimal = false) => {
    // TODO: Do it on a cleaner way, maybe with regex
    let validChars = ["0", "1", "2", "3", "4", "5", "6", "7", "8", "9"];
    if (admitsDecimal) {
      validChars.push(".");
    }
    if (!validChars.includes(evt.key)) {
      evt.preventDefault();
    }
    //Avoid a number having multiple decimal points (e.g., 12.23.23)
    if (admitsDecimal && evt.key === "." && prev.includes(".")) {
      evt.preventDefault();
    }
  };
  const handleSubmit = (event) => {
    event.preventDefault();
    const form = event.currentTarget;
    if (!form.checkValidity() || !areInputsValid() || disabled) {
      event.stopPropagation();
    } else {
      setDisabled(true);
      const formData = new FormData();
      formData.append("amount", amount);
      formData.append("family", family._id);
      formData.append("currency", currency);
      formData.append("receipt", receipt);
      formData.append("hoursBought", hoursBought);
      formData.append("referral", referralId);
      formData.append("hourType", hourType);
      formData.append("isGroupHour", isGroupHour);
      formData.append("user_role", "Admin"); //TODO: Use user.role instead of hardcoded
      formData.append("source", "manual");
      axios
        .post(`${process.env.REACT_APP_BACKENDURL}/sale`, formData)
        .then((response) => {
          alert("Se realizo la venta exitosamente!");
          window.location.reload(false);
        })
        .catch((error) => {
          alert(
            "Ha ocurrido un error al realizar la venta" + JSON.stringify(error)
          );
          setDisabled(false);
          console.log(error);
        });
    }
  };

  return (
    <Container>
      <Form noValidate onSubmit={handleSubmit} ref={formRef}>
        <Row className="justify-content-md-center">
          <Form.Group as={Col} lg="4" controlId="student">
            <Form.Label>Escoja a la familia</Form.Label>
            <br />
            <br />
            <Form.Control
              as={Select}
              options={listFormat(families)}
              required
              isInvalid={!family || family._id == null}
              onChange={(opt) => dispatch(setFamily(opt))}
            />
            <Form.Control.Feedback type="invalid">
              Necesita escoger una familia!
            </Form.Control.Feedback>
          </Form.Group>
        </Row>
        <br />
        <br />
        <Row className="justify-content-md-center">
          <Form.Group as={Col} lg="3" controlId="hours" noValidate>
            <Form.Label>Cuantas horas quiere comprar?</Form.Label>
            <br />
            <br />
            <Form.Control
              type="text" //not 'number' to prevent showing arrows
              fontSize={32}
              inputMode="numeric"
              isInvalid={
                parseInt(hoursBought) < 1 || parseInt(hoursBought) > 48
              }
              noValidate
              required
              value={hoursBought}
              onKeyPress={(e) => {
                handleNumberPress(e, hoursBought);
              }}
              onChange={(e) => setHoursBought(e.target.value)}
            />
            <Form.Control.Feedback type="invalid">
              Solo puede escoger de 1 a 48h!
            </Form.Control.Feedback>
          </Form.Group>
        </Row>
        <br />
        <br />
        {hoursBought >= 4 && !family.isReferred && family.hoursBought === 0 ? (
          <>
            <Row className="justify-content-md-center">
              <Form.Group as={Col} lg="2" controlId="id">
                <Form.Label>
                  <b>Código de referido (opcional)</b>
                </Form.Label>
                <br />
                <br />
                <Form.Control
                  type="text"
                  maxLength="24"
                  placeholder="62d9#############"
                  onChange={(e) => setReferralId(e.target.value)}
                />
                <Form.Control.Feedback>Todo bien!</Form.Control.Feedback>
              </Form.Group>
            </Row>
            <br />
            <br />
          </>
        ) : (
          <></>
        )}
        <Row className="justify-content-md-center">
          <Form.Group as={Col} lg="5" controlId="hourType">
            <Form.Label>Que tipo de hora esta pagando?</Form.Label>
            <br />
            <br />
            <Form.Control
              as={Select}
              options={[
                // Values in english because that's the way it's stored in database
                // {label: "SAT", value: "SAT"},
                { label: "Tutorias Especializadas (IB)", value: "IB" },
                { label: "Tutorias (pre-IB)", value: "tutoring" },
                // {label: "olimpiadas", value: "olympiad"}
              ]}
              required
              isInvalid={hourType == null}
              onChange={(opt) => {
                setHourType(opt.value);
              }}
            />
            <Form.Control.Feedback type="invalid">
              Necesita escoger un tipo de horas valido!
            </Form.Control.Feedback>
          </Form.Group>
        </Row>
        <br />
        <br />
        <Row className="justify-content-md-center">
          <Form.Group as={Col} lg="5" controlId="isGroupHour">
            <Form.Label>Hora individual o grupal?</Form.Label>
            <br />
            <br />
            <Form.Control
              as={Select}
              options={[
                { label: "Individual", value: false },
                { label: "Grupal", value: true },
              ]}
              required
              isInvalid={isGroupHour == null}
              onChange={(opt) => {
                setIsGroupHour(opt.value);
              }}
            />
            <Form.Control.Feedback type="invalid">
              Necesita escoger si es que es hora individual o grupal!
            </Form.Control.Feedback>
          </Form.Group>
        </Row>
        <br />
        <br />
        <Row className="justify-content-md-center">
          <Form.Group as={Col} lg="5" controlId="currency">
            <Form.Label>Con que moneda paga?</Form.Label>
            <br />
            <br />
            <Form.Control
              as={Select}
              options={currencies}
              required
              isInvalid={currency == null}
              onChange={(opt) => setCurrency(opt.value)}
            />
            <Form.Control.Feedback type="invalid">
              Necesita escoger un typo de moneda!
            </Form.Control.Feedback>
          </Form.Group>
        </Row>
        <br />
        <br />
        <Row className="justify-content-md-center">
          <Form.Group as={Col} lg="3" controlId="amount">
            <Form.Label>Cuanto ha pagado?</Form.Label>
            <br />
            <br />
            <Form.Control
              type="text"
              inputMode="numeric"
              required
              value={amount}
              onKeyPress={(e) => {
                handleNumberPress(e, amount, true);
              }}
              onChange={(e) => setAmount(e.target.value)}
            />
          </Form.Group>
        </Row>
        <br />
        <br />
        <Row className="justify-content-md-center">
          <Form.Group as={Col} lg="3" controlId="receipt">
            <Form.Label>Comprobante de pago</Form.Label>
            <br />
            <br />
            <Form.Control
              type="file"
              required
              onChange={(e) => setReceipt(e.target.files[0])}
            />
            <Form.Control.Feedback>Todo bien!</Form.Control.Feedback>
            <Form.Control.Feedback type="invalid">
              Necesita agregar un comprobante!
            </Form.Control.Feedback>
          </Form.Group>
        </Row>

        <br />
        <br />
        <br />
        <Button type="submit" variant="success" disabled={disabled}>
          Añadir Horas
        </Button>
      </Form>
    </Container>
  );
};

export default Sale;
