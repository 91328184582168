import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getTutors } from "../../../store/tutor/tutor.actions";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import axios from "axios";
import BootstrapTable from "react-bootstrap-table-next";
import Modal from "react-bootstrap/Modal";
import Spinner from "react-bootstrap/Spinner";
// import { CSVExport } from 'react-bootstrap-table2-toolkit';
//https://github.com/react-bootstrap-table/react-bootstrap-table2/pull/1506
import ToolkitProvider, {
  CSVExport,
} from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit.min";
const { ExportCSVButton } = CSVExport;

// es6
// import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
// const products = [ {
//     id: 1,
//     name:"Product 1",
//     price: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor"
// } ];
// [["name","salaryPerHour","hours","totalSalary","currency","paymentMethod"]]
let COLORS = {
  XPLAIN_GREEN: "#0AB591",
};
function strikeFormatter(cell, row) {
  cell = Number(cell);
  if (cell === 0) {
    return cell;
  }
  return (
    <span>
      <strong style={{ color: "red" }}>-{cell}</strong>
    </span>
  );
}

const columns = [
  {
    dataField: "name",
    text: "Nombre",
  },
  {
    dataField: "salaryPerHour",
    text: "Salario por hora",
  },
  {
    dataField: "hours",
    text: "Horas",
  },
  {
    dataField: "totalStrikes",
    text: "Faltas",
    formatter: strikeFormatter,
  },
  {
    dataField: "totalSalary",
    text: "Salario total",
  },
  {
    dataField: "currency",
    text: "Moneda",
  },
  {
    dataField: "paymentMethod",
    text: "Mode de pago",
  },
];

let monthNames = [
  "Enero",
  "Febrero",
  "Marzo",
  "Abril",
  "Mayo",
  "Junio",
  "Julio",
  "Agosto",
  "Septiembre",
  "Octubre",
  "Noviembre",
  "Diciembre",
];
const PayTutors = () => {
  let today = new Date();
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.auth);
  const [totalPEN, setTotalPEN] = useState(0);
  const [totalUSD, setTotalUSD] = useState(0);
  const [totalHours, setTotalHours] = useState(0);

  const [tutorsPaid, setTutorsPaid] = useState([]);
  const [payData, setPayData] = useState([]);

  const [year, setYear] = useState(today.getFullYear());
  const [month, setMonth] = useState(today.getMonth()); //0-11
  const [loading, setLoading] = useState(true);

  const getPayData = async () => {
    // dispatch(getTutors({filter: {status: "Activo"}, selection: {}}))
    let params = {
      userId: user._id,
      year: year,
      month: month,
    };
    try {
      setLoading(true);
      let response = await axios.get(
        `${process.env.REACT_APP_BACKENDURL}/tutor/pay`,
        { params: params }
      );
      console.log(response);
      setTutorsPaid(Object.keys(response.data));
      setPayData(Object.values(response.data));
      let pen = 0;
      let usd = 0;
      let hours = 0;
      for (let id in response.data) {
        if (response.data[id].currency === "PEN") {
          pen += response.data[id].totalSalary;
        } else if (response.data[id].currency === "USD") {
          usd += response.data[id].totalSalary;
        }
        hours += response.data[id].hours;
      }
      setTotalPEN(pen);
      setTotalUSD(usd);
      setTotalHours(hours);
      // setPayData(Object.keys(response.data).map(key => {
      //     return {...response.data[key], id:key}
      // }));
      setLoading(false);
    } catch (error) {
      setLoading(false);
      alert("Un error ocurrio:" + error.message);
      console.log(error);
    }
  };
  // const {tutors} = useSelector((state) => state.tutor);
  useEffect(() => {
    getPayData();
  }, []);
  useEffect(() => {
    getPayData();
  }, [year, month]);

  const moveMonths = (nMonths) => {
    let totalMonths = year * 12 + month + nMonths;
    let newMonth = totalMonths % 12;
    let newYear = (totalMonths - newMonth) / 12;
    setYear(newYear);
    setMonth(newMonth);
  };

  const todayMonths = 12 * new Date().getFullYear() + new Date().getMonth();
  return (
    <div>
      <Modal show={loading}>
        <Modal.Header>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              textAlign: "center",
            }}
          >
            <Spinner animation="border" role="status">
              <span className="visually-hidden">Cargando...</span>
            </Spinner>{" "}
            <span style={{ marginLeft: 10 }}> Cargando...</span>
          </div>
        </Modal.Header>
        <Modal.Body>
          Calculando salarios para el mes de {monthNames[month]}, {year}
        </Modal.Body>
      </Modal>

      <div>
        <h2>
          Total: <strong>{totalPEN}</strong> soles + <strong>{totalUSD}</strong>{" "}
          dolares
        </h2>
        <h2>
          # horas de clases: <strong>{totalHours}</strong>
        </h2>
      </div>

      <div>
        <Button
          style={{ border: 0, backgroundColor: COLORS.XPLAIN_GREEN }}
          onClick={() => {
            moveMonths(-1);
          }}
        >
          {"<"}
        </Button>
        <strong style={{ margin: 10 }}>
          {monthNames[month]}, {year}{" "}
          {12 * year + month >= todayMonths && "(potencialmente)"}
        </strong>
        <Button
          style={{ border: 0, backgroundColor: COLORS.XPLAIN_GREEN }}
          onClick={() => {
            moveMonths(1);
          }}
        >
          {">"}
        </Button>
        {/* <Button style={{margin: 10}} onClick={getPayData}>Export data</Button> */}
      </div>
      {/* <BootstrapTable keyField={"name"} data={ payData } columns={ columns } /> */}

      <ToolkitProvider
        keyField="name"
        data={payData}
        columns={columns}
        exportCSV={{
          fileName: `xplain-salarios-${monthNames[month]}-${year}.csv`,
        }}
      >
        {(props) => (
          <div>
            <ExportCSVButton
              {...props.csvProps}
              style={{
                backgroundColor: "black",
                color: "white",
                marginTop: 10,
              }}
            >
              Exportar como CSV
            </ExportCSVButton>
            <hr />
            <BootstrapTable {...props.baseProps} />
          </div>
        )}
      </ToolkitProvider>
    </div>
  );
};
export default PayTutors;
