import React, {useState, useEffect, useRef} from 'react'
import axios from 'axios'
import Select from 'react-select';
import { useSelector, useDispatch } from 'react-redux';
import { Container, Form, Button, Row, Col } from 'react-bootstrap';
import { getFamilies } from '../../../store/family/family.actions';
import { setFamily } from '../../../store/family/family.slice';
import { getFamily } from '../../../store/family/family.actions';

import PaymentCardPage from "../../../pages/PaymentCard/PaymentCardPage"
// import Carousel from 'react-bootstrap/Carousel';
import Modal from 'react-bootstrap/Modal';
import { listFormat } from '../../../name_utils';
// const listFormat = (array) => {
//     let newArray = []
//     for (const elem of array){
//         let newElem = {...elem}
//         if ('surname' in newElem){
//             newElem['label'] = elem['surname']
//         }else{
//             newElem['label'] = elem['name']
//         }
//         newElem['value'] = elem['_id']
//         newArray.push(newElem)
//     }
//     return newArray
// }

const Sale = () => {
    const [showModal, setShowModal] = useState(false);

    const handleClose = () => setShowModal(false);
    const handleShow = () => setShowModal(true);


    const {user} = useSelector((state) => state.auth)
    const dispatch = useDispatch()
    const {family} = useSelector((state) => state.family)
    //Getting family
    console.log("family");
    console.log(family)
    // const {families,family} = useSelector((state) => state.family);
;
    const currencies = [{label:"Sol Peruano",value:"PEN"},{label:"Dolar EEUU",value:"USD"}];
    const hourTypes = [{label: "IB", value: "IB"},  {label: "SAT", value: "SAT"}, {label: "Tutorías para escolares", value: "tutoring"}];
    const MONEY_NAMES = {
        PEN: "Sol Peruano",
        USD: "Dolar EEUU"
    }
    const [currency, setCurrency] = useState(null);
    const [referralId,setReferralId] = useState("");
    const [hoursBought,setHoursBought] = useState(0);
    const [hourType, setHourType] = useState(null);
    const [isGroupHour,setIsGroupHour] = useState(false)
    //Final amount
    const [amount,setAmount] = useState(0);

    const [disabled,setDisabled] = useState(false)
    const [showPayment, setShowPayment] = useState(false);
    const [validNumHours, setValidNumHours] = useState({});
    const [amountPEN, setAmountPEN] = useState();

    let formRef = useRef(null);

    useEffect(() => {
        dispatch(getFamily({id:user.family}));
        dispatch(getFamilies(({filter:{}, selection:{}})));
        async function get_all_num(){
            let r = await axios.get(`${process.env.REACT_APP_BACKENDURL}/sale/get_valid_num_hours`);
            setValidNumHours(r.data);
        }
        get_all_num();
    }, [])
    const areInputsValid = ()=>{
        if (!currency || !hoursBought || !hourType){
            return false;
        }
        if(isFullAndInvalid()){
            return false;
        }
        return true;
    }
    useEffect(() => {
        //Enable the button only when the data is valid
        // Only change when necessary to avoid infinite loop
        const form = formRef.current;
        let valid = areInputsValid() && form.checkValidity();
        if (disabled && valid) {
            setDisabled(false);
        } else if (!disabled && !valid){
            setDisabled(true);
        }
    })
    /*
    To make sure that a number is always inputted
        evt: onkey event
        prev: current string
        admitsDecimal: if false, only accepts whole positive numbers
    */
    // const handleNumberPress = (evt, prev, admitsDecimal = false) =>{
    //     // TODO: Do it on a cleaner way, maybe with regex
    //     let validChars = ["0", "1", "2", "3", "4", "5", "6", "7", "8", "9"];
    //     if (admitsDecimal){
    //         validChars.push(".");
    //     }
    //     if (!validChars.includes(evt.key)){
    //         evt.preventDefault();
    //     }
    //     //Avoid a number having multiple decimal points (e.g., 12.23.23)
    //     if (admitsDecimal && evt.key==="." && prev.includes(".")){
    //         evt.preventDefault();
    //     }
    // }
    //Not being used anymore
    const handleSubmit = (event) => {
        event.preventDefault();
        const form = event.currentTarget;
        if (!form.checkValidity()){
            event.stopPropagation();
        } else{
            setDisabled(true)
            const formData = new FormData()
            formData.append('amount',amount)
            formData.append('family',family._id)
            formData.append('currency',currency)
            // formData.append('receipt',receipt)
            formData.append('hoursBought',hoursBought)
            formData.append('isGroupHour',isGroupHour)
            formData.append('referralId', referralId)
            formData.append('user_role', user.role)
            formData.append('source', "deprecated-shouldnt-be-used")
            axios.post(`${process.env.REACT_APP_BACKENDURL}/sale`, formData).then(response => {
                window.location.reload(false);
            }).catch(error => console.log(error))
        }
    }
    const calculateAmount = async (e)=>{
        console.log(family);
        let formData = {hoursBought, referralId, hourType, currency, familyId: user.family}
        let res = await axios.get(`${process.env.REACT_APP_BACKENDURL}/sale/calculate_amount`, {params: formData});
        // let amount = 20 * hoursBought; //TODO: Maybe do a get request
        let {finalAmountOtherCurrency, finalAmountPEN} = res.data;
        setAmount(finalAmountOtherCurrency);
        setAmountPEN(finalAmountPEN);
        setShowPayment(true);
    }
    const isFullAndInvalid = ()=>{
        let isFull = hourType && currency && hoursBought;
        return isFull && validNumHours[hourType][currency] && !validNumHours[hourType][currency].includes(hoursBought)
    }
    let currencyNames = {
        "PEN": "soles",
        "USD": "dolares"
    }
    const getSpanisHourName = (r)=>{
        switch(r){
            case "IB": return "IB"
            case "SAT": return "SAT"
            case "tutoring": return "Tutorías"
            case "olympiad": return "Olimpiadas"
            default: return "INVALID_HOUR_TYPE"
        }
    }
    return (

        <Container>
        <Modal show={showModal} onHide={handleClose} size="lg">
            <Modal.Header closeButton>
                {/* <Modal.Title>Modal heading</Modal.Title> */}
            </Modal.Header>
            <Modal.Body>
                <PaymentCardPage 
                    description="Test description"
                    hoursBought={hoursBought}
                    referralId={referralId}
                    hourType={hourType}
                    currency={currency}
                />
            </Modal.Body>
            <Modal.Footer>
            {/* <Button variant="secondary" onClick={handleClose}>
                Close
            </Button> */}
            {/* <Button variant="primary" onClick={handleClose}>
                Save Changes
            </Button> */}
            </Modal.Footer>
        </Modal>
        <Form noValidate onSubmit={handleSubmit} ref={formRef}>
            { validNumHours &&
            <>
                <Row className="justify-content-md-center">
                    <Form.Group as={Col} lg="5" controlId="hourType">
                    <Form.Label>Escoja el servicio</Form.Label>

                    <br />
                    <br />
                    <Form.Control
                        as={Select}
                        placeholder="Servicio"
                        options={Object.keys(validNumHours).map(r=>{return {label: getSpanisHourName(r), value: r}} )}
                        required
                        onChange={opt => {setHourType(opt.value); }}
                    />
                    <Form.Control.Feedback type="invalid">Necesita escoger un tipo de horas valido!</Form.Control.Feedback>
                    </Form.Group>
                </Row>
                {/* <br />
                <Row className="justify-content-md-center">
                    <Form.Group as={Col} lg="2" controlId="hourType">

                        <Form.Check 
                            type={"checkbox"}
                            label={"¿Son horas grupales?"}
                            onClick={() => setIsGroupHour(!isGroupHour)}
                            checked={isGroupHour}
                        />
                    </Form.Group>

                </Row> */}
                <br />

            </>
            }
            { hourType &&
                <>
                    <Row className="justify-content-md-center">
                        <Form.Group as={Col} lg="5" controlId="currency">
                        <Form.Label>Tipo de moneda</Form.Label>
                        <br />
                        <br />
                        <Form.Control
                            as={Select}
                            options={Object.keys(validNumHours[hourType]).map(r=>{return {label: MONEY_NAMES[r] || r, value: r}} )}
                            required
                            onChange={opt => setCurrency(opt.value)}
                        />
                        <Form.Control.Feedback type="invalid">Necesita escoger un tipo de moneda valido!</Form.Control.Feedback>
                        </Form.Group>
                    </Row>
                    <br />
                    <br />
                </>
            }
            {
            currency && hourType &&
            <Row className="justify-content-md-center">
                <Form.Group as={Col} lg="5" controlId="hours">
                <Form.Label>Número de horas</Form.Label>
                <br />
                <br />
                <Form.Control
                    as={Select}
                    options={validNumHours[hourType][currency].map(r =>{return {label: `${r} horas`, value: r}})}
                    required
                    onChange={opt => setHoursBought(opt.value)}
                />
                <Form.Control.Feedback type="invalid">Necesita escoger horas validas</Form.Control.Feedback>
                </Form.Group>
            </Row>
            }
            <br />
            <br />
            {hoursBought && (hoursBought >= 4 && !(family.isReferred) && family.hoursBought === 0) 
                ?
                <>
                <Row className="justify-content-md-center">
                    <Form.Group as={Col} lg="2" controlId="id">
                    <Form.Label><b>Código de referido (opcional)</b></Form.Label>
                    <br/>
                    <br/>
                    <Form.Control
                        type="text"
                        maxLength="24"
                        placeholder="###-###-####"
                        onChange={e => setReferralId(e.target.value)}
                    />
                    <Form.Control.Feedback>Todo bien!</Form.Control.Feedback>
                    </Form.Group>
                </Row>
                <br />
                <br />
                </>
                :
                <></>
            }
            <Button variant="primary" onClick={handleShow} disabled={disabled}>
                Vea el monto
            </Button>
            {/* <Button onClick={calculateAmount} variant="secondary" disabled={disabled}>Cuanto tengo que pagar?</Button> */}

            {/* <Row className="justify-content-md-center">
                <Form.Group as={Col} lg="3" controlId="receipt">
                <Form.Label>Comprobante de pago</Form.Label>
                <br />
                <br />
                <Form.Control 
                    type="file" 
                    required  
                    onChange={e => setReceipt(e.target.files[0])}/>
                <Form.Control.Feedback>Todo bien!</Form.Control.Feedback>
                <Form.Control.Feedback type="invalid">Necesita agregar un comprobante!</Form.Control.Feedback>
                </Form.Group>
            </Row> */}
            { showPayment && 
                <>
                    <br/>
                    <br/>
                    <h2>
                        Usted estara pagando...
                        <>
                            {amount} {currencyNames[currency] || currency} {currency !== "PEN" && `(${amountPEN} Soles)`}
                        </>
                    </h2>
                </>
            }
            <br/>

        </Form>
        </Container>
    )
}

export default Sale