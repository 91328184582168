import React, { useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import { getTutors } from "../../store/tutor/tutor.actions"
import { selectTutor } from "../../store/tutor/tutor.slice"
import {getStudents} from "../../store/student/student.actions"
import {setStudent} from "../../store/student/student.slice"
import {getClasses} from "../../store/class/class.actions"
import {getSubjects} from "../../store/subject/subject.actions"
import { setSubject } from "../../store/subject/subject.slice"
import { getFamilies } from "../../store/family/family.actions"
import {Table, Form, Row, Col} from "react-bootstrap"
import { formatFeedback } from "../../format_feedback"
import {TutorSelect, SubjectSelect, StudentSelect, FamilySelect} from "../forms/Select/Select.js";
import { formatIndividualStudentFeedback } from "../../format_feedback"
const ClassTable = () => {
    const dispatch = useDispatch()
    const {students, student} = useSelector((state) => state.student)
    const {tutors, tutor} = useSelector((state) => state.tutor)
    const {classes} = useSelector((state) => state.class)
    const {subjects, subject} = useSelector((state) => state.subject)
    const {family} = useSelector((state) => state.family)

    function getWeekDates(d = new Date()) {
        const date = new Date(d);
        const day = date.getDay();
        const diff = date.getDate() - day + (day === 0 ? -6 : 1);
        const startDate = new Date(date.setDate(diff));
        startDate.setHours(0)
        startDate.setMinutes(0)
        startDate.setSeconds(0)
        startDate.setMilliseconds(0)
        const endDate = new Date(startDate)
        endDate.setDate(endDate.getDate() + 7)
        return {startDate, endDate}
    }
    useEffect(() => {
        let {startDate , endDate}= getWeekDates()
        dispatch(getTutors({filter:{},selection:{}}))
        dispatch(getStudents({filter:{},selection:{}}))
        dispatch(getSubjects({filter:{},selection:{}}))
        dispatch(getFamilies({filter:{},selection:{}}))
        //Reset in case it was globably set somewhere else
        dispatch(setStudent({}));
        dispatch(selectTutor({}));
        dispatch(setSubject({}));
        dispatch(getClasses({filter:{"start":{"$gte":startDate},"end":{"$lt":endDate}} ,selection:{}, populate:["individualStudentFeedback.sender"]}))
    }, [])
    const updateClassesShown = (d = new Date())=> {
        let {startDate , endDate}= getWeekDates(d);
        let filter = {"start":{"$gte":startDate},"end":{"$lt":endDate}};
        if (student && student._id){
            filter.studentIds = student._id.toString();
        }
        if (tutor && tutor._id){
            filter.tutorId = tutor._id.toString();
        }
        if (subject && subject._id){
            filter.subjectId = subject._id.toString();
        }
        if (family && family._id){
            filter.families = family._id.toString();
        }
        dispatch(getClasses({filter:filter,selection:{},populate:["individualStudentFeedback.sender"]}));
    }
    useEffect(()=>{
        updateClassesShown();
    }, [student, tutor, subject, family])


    let newClasses = []
    for (const currentClass of classes){
        let newClass = {...currentClass}
        newClass["studentNames"] = []
        for (const tutor of tutors){
            if (tutor._id == newClass.tutorId){
                newClass["tutorName"] = tutor['name'].split(" ")[0] + " " + tutor['surname'].split(" ")[0]
            }
        }
        for (const student of students){
            if (newClass.studentIds.includes(student._id)){
                newClass["studentNames"].push(student['name'].split(" ")[0] + " " + student['surname'].split(" ")[0])
            }
        }
        for (const subject of subjects){
            if (subject._id == newClass.subjectId){
                newClass["subjectName"] = subject['name']
            }
        }
        newClasses.push(newClass)
    }
    return (
        <>
        <br />
        <Row className="justify-content-md-center">
            <Form.Group as={Col} lg="3" controlId="week">
            <Form.Label>Escoge la semana</Form.Label>
            <br />
            <br />
            <Form.Control
                type="week"
                required
                onChange={e => {
                    let year = e.target.value.split("-")[0]
                    let weekNum = e.target.value.split("-W")[1]
                    const monday = new Date(year, 0, (1 + (weekNum - 1) * 7));
                    monday.setDate(monday.getDate()  +2)
                    // let {startDate , endDate}= getWeekDates(monday)
                    updateClassesShown(monday);
                    // dispatch(getClasses({filter:{"start":{"$gte":startDate},"end":{"$lt":endDate}} ,selection:{}}))
                }}
            />
            <Form.Control.Feedback>Todo bien!</Form.Control.Feedback>
            </Form.Group>
        </Row>
        <TutorSelect label="Qué tutor?"/>

        <SubjectSelect label="Qué curso?"/>
        <StudentSelect />
        <FamilySelect />
        <br/>
        <br/>
        <Table striped bordered hover>
            <thead>
            <tr>
            <th>_id</th>
            <th>Estado</th>
            <th>Student(s)</th>
            <th>Tutor</th>
            <th>Subject</th>
            <th>Day</th>
            <th>Start</th>
            <th>End</th>
            <th>Extra info</th>
            <th>Student Rating</th>
            <th>Student Feedback</th>
            <th>Tutor Feedback</th>
            </tr>
            </thead>
            <tbody style={{verticalAlign:"middle"}}>
                {newClasses.map((currentClass,id) => {
                    let start = new Date(currentClass.start)
                    let month = start.getMonth().toString().padStart(2, '0')
                    month = Number(month) + 1
                    let day = `${start.getDate().toString().padStart(2, '0')}/${month.toString().padStart(2, '0')}`
                    let end = new Date(currentClass.end)
                    start = `${start.getHours()}:${start.getMinutes().toString().padStart(2, '0')}`
                    end = `${end.getHours()}:${end.getMinutes().toString().padStart(2, '0')}`
                    return(
                        <tr>
                            <td>{currentClass._id}</td>
                            <td style={{color: currentClass.pending ? "red":"green"}}>
                                <strong>
                                {currentClass.pending ? "Tutor necesita confirmar": "Confirmada"}
                                </strong>
                            </td>
                            <td>{currentClass.studentNames.join()}</td>
                            <td>{currentClass.tutorName}</td>
                            <td>{currentClass.subjectName}</td>
                            <td>{day}</td>
                            <td>{start}</td>
                            <td>{end}</td>
                            <td>{currentClass.prevInfo}</td>
                            <td>{currentClass.individualStudentFeedback.length > 0 ? `${currentClass.rating}/10`: "N/A"}</td>
                            <td>{formatIndividualStudentFeedback(currentClass.individualStudentFeedback)}</td>
                            <td>{formatFeedback(currentClass.tutorFeedback)}</td>
                        </tr>
                    )
                })}
            </tbody>
        </Table>
        </>
    )
}

export default ClassTable