import { createSlice } from "@reduxjs/toolkit";
import { getTempTutors } from "./tempTutor.actions";

const emptyTutor = () => {
    return {
        _id:"",
        name: "",
        surname: "",
        phone:"",
        email: "",
        currency: "",
        subjects:[]
    }
}
const tempTutorSlice = createSlice({
    name: "tempTutor",
    initialState: {
        tempTutor: emptyTutor(),
        // tutors: [emptyTutor()],
        allTempTutors: [emptyTutor()]
    },
    reducers: {
        selectTempTutor: (state,action) => {
            state.tempTutor = action.payload
        },
        // getTempTutors: (state, action) => {

        // },
        // filterTempTutors: (state, action) => {
        // }
    }, 
    extraReducers: {
        [getTempTutors.pending]: (state, action) => {
            console.log("fetching temp tutors")
        },
        [getTempTutors.fulfilled]: (state, action) => {
            console.log("fetching temp tutors succesfully!");
            console.log(action.payload);
            state.allTempTutors = action.payload
        }
    }
})

export const {selectTempTutor} = tempTutorSlice.actions;
export default tempTutorSlice.reducer