import React, { useEffect, useState, useMemo } from "react"
import axios from "axios"
import { useSelector,useDispatch } from 'react-redux';
import { setStudent } from "../../../store/student/student.slice";
import { setParent } from "../../../store/parent/parent.slice";
import countryList from 'react-select-country-list'
import Select from "react-select"
import {Form, Row, Col, Button} from "react-bootstrap"
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'
import Card from "react-bootstrap/Card"

let COLORS = {
    XPLAIN_GREEN: "#0AB591",
  };
const SignupNewStudentAndParent = () => {
    const dispatch = useDispatch()
    const {student} = useSelector((state) => state.student)
    const {parent} = useSelector((state) => state.parent)
    let newStudent = {...student, role:"Student"}
    let newParent = {...parent,role:"Parent"}
    const [disabled,setDisabled] = useState(false)
    const [isInvalid, setIsInvalid] = useState(false);
    const countries = useMemo(() => countryList().getData(), [])
    const handleSubmit = async (event) => {
        event.preventDefault();
        const form = event.currentTarget;
        if (form.checkValidity() === false) {
            event.stopPropagation();
            setIsInvalid(true)
        }else{
            // let answer = window.confirm("Estás seguro quieres actualizar esta info?");
            setIsInvalid(false)
            setDisabled(true)
            delete newStudent._id
            delete newStudent.parentName
            try{
                let data = {student:newStudent,parent:newParent}
                let family = await axios.post(`${process.env.REACT_APP_BACKENDURL}/family`, data);
                family = family.data;
                console.log(family);
                await axios.post(`${process.env.REACT_APP_BACKENDURL}/jobs/welcomeStudent`, {studentId: family.students[0]});
                await axios.post(`${process.env.REACT_APP_BACKENDURL}/jobs/welcomeParent`, {parentId: family.parents[0]});
                alert("Se ha registrado correctamente. Revise su correo electrónico para mas detalles.");
                window.location.reload(false)
            } catch (err) {
                console.log("Error creating student and parent: " + err);
                console.log(err);
                alert(`Hubo un error: ${err.response.data.message}`);
                setDisabled(false);
            }


            // axios.post(`${process.env.REACT_APP_BACKENDURL}/parent`, newParent).then(response =>{
            //     let savedParent = response.data
            //     newStudent["parents"] = [savedParent._id]
            //     axios.post(`${process.env.REACT_APP_BACKENDURL}/student`, newStudent).then(response2 => {
            //         let savedStudent = response2.data 
                    // axios.patch(`${process.env.REACT_APP_BACKENDURL}/parent`, {id:savedParent._id, update:{students:[savedStudent._id]}}).then(response3 =>{
                    //         window.location.reload(false)   
                    //     }).catch(error => {
                    //     console.log(error)
                    // })
            //     }
            //     ).catch(error => console.log(error))
            // }).catch(error => console.log(error))
        }
    }
    return(
        <Form noValidate validated={isInvalid} onSubmit={handleSubmit}>
            <div style={{display:"flex", flex:1, flexDirection:"column"}}>
            <Card style={{
                // color:"white",
                borderWidth: 2,
                borderColor: COLORS.XPLAIN_GREEN,
                borderRadius: 10, 
                flex:1,
                // padding: 5,
                margin: 10,
                width:"50%",
                height:"100%",
                alignSelf:"center",
            }}
            // className="justify-content-md-center"
            >
            <Card.Header style={{backgroundColor:COLORS.XPLAIN_GREEN, color:"white"}}><h1>Estudiante</h1></Card.Header>
            <Card.Body style={{display:"flex", flexDirection:"column", alignItems:'center', flex:1}}>
            {/* <Row style={{display:"flex", flex:1}} className="justify-content-md-center"> */}
                <Form.Group as={"div"} className="mb-3" controlId="name" style={{flex: 1, width:"60%"}}>
                    <Form.Label><b>Nombre</b></Form.Label>
                    <Form.Control
                        required
                        type="text"
                        placeholder="Nombre"
                        onChange={e => {
                            newStudent["name"] = e.target.value
                            dispatch(setStudent(newStudent))
                        }}
                    />
                    <Form.Control.Feedback>Todo bien!</Form.Control.Feedback>
                </Form.Group>
                <Form.Group as={"div"} className="mb-3" controlId="surname" style={{flex: 1, width:"60%"}}>
                <Form.Label><b>Apellido(s)</b></Form.Label>
                <Form.Control
                    required
                    type="text"
                    placeholder="Apellido(s)"
                    onChange={e => {
                        newStudent["surname"] = e.target.value
                        dispatch(setStudent(newStudent))
                    }}

                />
                <Form.Control.Feedback>Todo bien!</Form.Control.Feedback>
                </Form.Group>
            {/* </Row> */}
            {/* <Row className="justify-content-md-center"> */}
                <Form.Group as={"div"} className="mb-3" controlId="name" style={{flex: 1, width:"60%"}}>
                <Form.Label><b>Email</b></Form.Label>
                <Form.Control
                    required
                    type="email"
                    onChange={e => {
                        newStudent["email"] = e.target.value
                        dispatch(setStudent(newStudent))
                    }}
                    placeholder="Gmail"
                />
                <Form.Control.Feedback>Todo bien!</Form.Control.Feedback>
                </Form.Group>
                <Form.Group as={"div"} className="mb-3" controlId="surname" style={{flex:1}}>
                <Form.Label><b>Whatsapp</b></Form.Label>

                <PhoneInput
                    inputProps={{required:true}}
                    onChange={phone => {
                        newStudent["phone"] = "+" + phone
                        dispatch(setStudent(newStudent))
                    }}  
                    enableSearch={true}
                /> 
                <Form.Control.Feedback>Todo bien!</Form.Control.Feedback>
                </Form.Group>
            {/* </Row> */}
            {/* <Row className="xxjustify-content-md-center"> */}
                <Form.Group as={"div"}  className="mb-3" controlId="school" style={{flex: 1, width:"60%"}}>
                <Form.Label><b>Colegio</b></Form.Label>
                <Form.Control
                    required
                    type="text"
                    placeholder="Colegio"
                    onChange={e => {
                        newStudent["school"] = e.target.value
                        dispatch(setStudent(newStudent))
                    }}
                />
                <Form.Control.Feedback>Todo bien!</Form.Control.Feedback>
                </Form.Group>

                <Form.Group as={"div"} className="mb-3" controlId="country" style={{flex: 1, width:"60%"}}>
                <Form.Label><b>País del colegio</b></Form.Label>
                <Form.Control
                    as={Select}
                    required
                    options={countries}
                    onChange={(opt) => {
                        newStudent["country"] = opt.value
                        dispatch(setStudent(newStudent))
                    }}
                    placeholder="País"
                />
                <Form.Control.Feedback>Todo bien!</Form.Control.Feedback>
                </Form.Group>
            {/* </Row> */}
            </Card.Body>
            </Card>
            
            <Card style={{
                borderWidth: 2,
                borderColor:COLORS.XPLAIN_GREEN, 
                borderRadius: 10, 
                flex:1,
                margin: 10,
                width: "50%",
                alignSelf:"center",
            }}>
                <Card.Header style={{backgroundColor:COLORS.XPLAIN_GREEN, color:"white"}}><h1>Padre/Madre</h1></Card.Header>
                <Card.Body style={{display:"flex", flexDirection:"column", alignItems:'center'}}>
            {/* <Row className="justify-content-md-center"> */}
                <Form.Group  style={{flex: 1, width:"60%"}} className="mb-3" controlId="name">
                <Form.Label><b>Nombre</b></Form.Label>
                {/* <br/> */}
                {/* <br/> */}
                <Form.Control
                    required
                    type="text"
                    placeholder="Nombre"
                    onChange={e => {
                        newParent["name"] = e.target.value
                        dispatch(setParent(newParent))
                    }}
                />
                <Form.Control.Feedback>Todo bien!</Form.Control.Feedback>
                </Form.Group>

                <Form.Group as={"div"} style={{flex: 1, width:"60%"}} className="mb-3" controlId="surname">
                <Form.Label><b>Apellido(s)</b></Form.Label>
                {/* <br/> */}
                {/* <br/> */}
                <Form.Control
                    required
                    type="text"
                    placeholder="Apellido(s)"
                    onChange={e => {
                        newParent["surname"] = e.target.value
                        dispatch(setParent(newParent))
                    }}

                />
                <Form.Control.Feedback>Todo bien!</Form.Control.Feedback>
                </Form.Group>
            {/* </Row> */}
            {/* <br/> */}
            {/* <br/> */}
            {/* <Row className="justify-content-md-center"> */}
                <Form.Group as={"div"} style={{flex: 1, width:"60%"}} className="mb-3" controlId="name">
                <Form.Label><b>Email</b></Form.Label>
                <br/>
                <Form.Control
                    required
                    type="email"
                    onChange={e => {
                        newParent["email"] = e.target.value
                        dispatch(setParent(newParent))
                    }}
                    placeholder="Email"
                />
                <Form.Control.Feedback>Todo bien!</Form.Control.Feedback>
                </Form.Group>
                <Form.Group as={"div"} style={{flex: 1}} className="mb-3" controlId="surname">
                <Form.Label><b>Whatsapp</b></Form.Label>

                <PhoneInput
                    inputProps={{required:true}}
                    onChange={phone => {
                        newParent["phone"] = "+" + phone
                        dispatch(setParent(newParent))
                    }}  
                    enableSearch={true}
                    style={{alignItems: 'center', alignSelf: 'center', display: 'flex'}}
                /> 
                <Form.Control.Feedback>Todo bien!</Form.Control.Feedback>
                </Form.Group>
                {/* <br/> */}
            {/* </Row> */}
            {/* <br/>
            <br/> */}
            </Card.Body>
            </Card>
            </div>
            <p>
                <strong>
                Una vez registrados, se les mandará un correo electrónico para
                que se creen una contraseña.
                </strong>
            </p>
            <Button style={{backgroundColor: COLORS.XPLAIN_GREEN, border: 0}} variant="primary" disabled={disabled} type="submit">
                <h3>Crear cuentas</h3>
            </Button>

        </Form>
    )
}

export default SignupNewStudentAndParent