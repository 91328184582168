import React, {useEffect, useState} from 'react';
import {useSelector} from "react-redux";
import axios from 'axios';
import {Form,Button,Image,Container,Card} from "react-bootstrap";
import { useDispatch } from 'react-redux';
import {useNavigate} from "react-router-dom"
import {setUser} from "../../store/auth/auth.slice"

// Assumes that s = ... [a] x [b] ... only in one place
// returns x
const getStringBetween = (s, a, b) =>{
    let begin = s.indexOf(a) + a.length;
    let end = s.indexOf(b);
    return s.substring(begin, end);
}
let TUTOR_NO_PIC_LINK = require("../../assets/images/tutor_no_pic.png");

const getValidDriveLink = (link) => {
    try{
        let id = getStringBetween(link, '/file/d/', '/view?');
        let driveLink = `https://drive.google.com/uc?export=view&id=${id}`;
        console.log(`Final driveLink:`);
        console.log(driveLink);
        return driveLink;
    } catch(err){
        console.log(`Error: ${err}`);
        return TUTOR_NO_PIC_LINK;
    }
}

const getAirtableLink = (tutor)=>{
    //TODO: Either make sure that the links are in a better format (just link)
    //      or make sure every link follows that format
    if (!tutor){
        return;
    }
    let photoLink = tutor["photo"];
    if (!photoLink || photoLink == "" || !photoLink.includes("airtable")){
        return;
    }
    let first = photoLink.indexOf('http');
    let last;
    if (photoLink[photoLink.length -1] === ')'){
        //It's an airtable link
        last = photoLink.length - 2;
    } else {
        //It's just a regular link
        last = photoLink.length - 1;
    }
    return photoLink.substring(first, last+1);
}

const getImageLink = (tutor) => {
    let link = getAirtableLink(tutor)
    if(link){
        return link
    }else{
        link = getValidDriveLink(tutor["imageDriveLink"])
        if(link){
            return link
        }else{
            return TUTOR_NO_PIC_LINK
        }
    }
}
const TutorPageEdit = () => {
    const dispatch = useDispatch();
    const {user} = useSelector((state) => state.auth);
    const [imageLink, setImageLink] = useState();
    const [tutorDescription, setTutorDescription] = useState();
    const [linkedinLink, setLinkedinLink] = useState();
    const [photoObject, setPhotoObject] = useState();
    // Description
    const [tutorDescriptionScores, setTutorDescriptionScores] = useState("");
    const [tutorDescriptionAchievements, setTutorDescriptionAchievements] = useState("");
    const [tutorDescriptionMajor, setTutorDescriptionMajor] = useState("");
    const [tutorDescriptionLanguage, setTutorDescriptionLanguage] = useState("");
    const [tutorYoutubeLink, setTutorYoutubeLink] = useState("");
    const [isInvalid, setIsInvalid] = useState(false);
    const [disabled,setDisabled] = useState(false)

    const navigate = useNavigate()
    // const [tutorDescriptionList, setTutorDescriptionList] = useState(["","","",""]); 
    useEffect(()=>{
        if (user.photo){
            setImageLink(user.photo);
        }
        if (user.tutorDescription){
            setTutorDescription(user.tutorDescription);
        }
        if (user.linkedin){
            setLinkedinLink(user.linkedin);
        }
        if (user.tutorDescriptionList){
            setTutorDescriptionScores(user.tutorDescriptionList[0] || "");
            setTutorDescriptionAchievements(user.tutorDescriptionList[1] || "");
            setTutorDescriptionMajor(user.tutorDescriptionList[2] || "");
            setTutorDescriptionLanguage(user.tutorDescriptionList[3] || "");
        }
        if (user.tutorYoutubeLink){
            setTutorYoutubeLink(user.tutorYoutubeLink);
        }
    }, [])
    const logData = (formData) =>{
        // Display the key/value pairs
        for (var pair of formData.entries()) {
            console.log(pair[0]+ ', ' + pair[1]); 
        }
    }
    const handleSubmit = (event) => {
        event.preventDefault()
        const form = event.currentTarget;
        if (form.checkValidity() === false) {
            event.stopPropagation();
            setIsInvalid(true)
        }else{
            setIsInvalid(false);
            setDisabled(true)
            let tutorDescriptionList = [
                tutorDescriptionScores,
                tutorDescriptionAchievements,
                tutorDescriptionMajor,
                tutorDescriptionLanguage
            ];
            const formData = new FormData()

            formData.append('tutorDescriptionList', JSON.stringify(tutorDescriptionList));
            // console.log(tutorDescriptionList)
            formData.append('id', user._id);
            formData.append('photo', imageLink);
            formData.append('linkedin', linkedinLink);
            formData.append('tutorDescription', tutorDescription);
            // formData.append('tutorDescriptionList', tutorDescriptionList);
            if (photoObject){
                formData.append('photoObject', photoObject);
            }
            formData.append('tutorYoutubeLink', tutorYoutubeLink);
            // let toUpdate = {
            //     photo: imageLink,
            //     resume: resumeLink,
            //     tutorDescription: tutorDescription,
            //     tutorDescriptionList: tutorDescriptionList,
            // };
            // logData(formData);
            axios.patch(`${process.env.REACT_APP_BACKENDURL}/tutor/publicprofile`, formData).then((response) => window.location.reload(false)).catch(err => alert(err.response.data.message));
            
        }
    }
    const isActive = user.status === 'Activo';
    const changeActiveStatus = async (e) => {
        let newStatus = isActive ? 'Inactivo' : 'Activo';
        let confirmation = window.confirm(`Estas seguro que quieres cambiar tu estado de '${user.status}' a '${newStatus}'`);
        if (!confirmation) {
            return;
        }
        let update = {
            status: newStatus
        }
        let response = await axios.patch(`${process.env.REACT_APP_BACKENDURL}/tutor/`, {
            id: user._id,
            update: update
        });
        dispatch(setUser(response.data))
    }
    return(
        <>
        <div style={{            
            display:"flex", 
            alignItems:"center", 
            justifyContent:"center",
            }}>
        <div style={{
            width: '20%',
            marginBottom: 10, 
            padding: 10, 
            border:"2px solid black", 
            borderRadius: 10, 
            display:"flex",
            flexDirection:"column", 
            }}>
            <span>
             Estado actual: <strong>{user.status}</strong>
            </span>
            <Button style={{margin: 5, color:isActive ? "white":"black"}}onClick={changeActiveStatus} variant={isActive ? "danger": "warning"}>{isActive ? "Cambiar perfil a inactivo": "Cambiar perfil a Activo"}</Button>
        </div>
        </div>
        <Button  style={{ backgroundColor:"rgba(10, 181, 145, 1)", borderColor:"rgba(10, 181, 145, 1)"}} onClick={() => navigate(`/tutor?id=${user._id}`)}>Ver perfil publico</Button>
        <br/>
        <br/>
        <Button  style={{}} onClick={() => navigate(`/newclass?tutor=${user._id}`)}>Ver calendario</Button>
        <br/>
        <br/>
            <Container style={{width:"60%"}}>
            <Form noValidate validated={isInvalid} onSubmit={handleSubmit}>
            <strong>Nota: Evita cualquier tipo de errores ortográficos (y no te olvides las tildes!)</strong>
            <br/>
            <br/>
            {/* <Form onSubmit={updateInfo}> */}
                <>
                    <p>Foto Actual:</p>
                    <Image src={getImageLink(user)} style={{"height": "40vh"}}/>
                </>
                <br/>
                <br/>
                <Form.Group className="mb-3" controlId="photo">
                    <br/>
                    <Form.Label><b>Foto Profesional</b></Form.Label>
                    <p>
                        Agrega una foto de medio cuerpo, lo más profesional posible. Por favor evite imagenes que son mucho mas largas
                        que ancha. Esta foto se usara en nuestras redes y web para que te conozcan nuestros estudiantes. 
                    </p>
                    <br />
                    <Form.Control 
                        type="file" 
                        // required  
                        onChange={e => setPhotoObject(e.target.files[0])}/>
                </Form.Group>
                {/* <Form.Group className="mb-3" controlId="photo">

                    <Form.Label>Link de una foto tuya</Form.Label>
                    <Form.Control 
                    type="text"
                    onChange={opt => setImageLink(opt.target.value)}
                    value={imageLink}
                    />
                    <Form.Text className="text-muted">
                    Si es que no ves tu imagen abajo entonces no es una link valido! Tiene que empezar con 'http'
                    </Form.Text>
                </Form.Group> */}
                <br/>
                <Form.Group className="mb-3">
                    <Form.Label><b>Notas en IB y/o SAT</b></Form.Label>
                        <Form.Control
                        placeholder='34/45 en IB y 780/800 en SAT'
                        type="text"
                        required
                        onChange={(evt)=>{setTutorDescriptionScores(evt.target.value)}}
                        value={tutorDescriptionScores}
                        />
                </Form.Group>
                <br/>
                <Form.Group className="mb-3">
                    <Form.Label><b>Logros académicos / experiencia laboral</b></Form.Label>
                        <Form.Control
                        placeholder='Medallista de oro en la International Applied Biology Olympiad'
                        required
                        type="text"
                        onChange={(evt)=>{setTutorDescriptionAchievements(evt.target.value)}}
                        value={tutorDescriptionAchievements}
                        />
                </Form.Group>
                <br/>
                <Form.Group className="mb-3">
                    <Form.Label><b>Carrera que estudias y universidad</b></Form.Label>
                        <Form.Control
                        placeholder='Estudiante de Biología en la Universidad Peruana Cayetano Heredia'
                        type="text"
                        required
                        onChange={(evt)=>{setTutorDescriptionMajor(evt.target.value)}}
                        value={tutorDescriptionMajor}
                        />
                </Form.Group>
                <br/>
                <Form.Group className="mb-3">
                    <Form.Label><b>Idiomas que hablas</b></Form.Label>
                        <Form.Control
                        placeholder='IDIOMAS: Español (nativo) e Inglés (avanzado)'
                        type="text"
                        required
                        onChange={(evt)=>{setTutorDescriptionLanguage(evt.target.value)}}
                        value={tutorDescriptionLanguage}
                        />
                </Form.Group>
                <br/>
                {/* <Form.Group className="mb-3" controlId="message">
                    <Form.Label>Descripcion/mensaje para el publico. </Form.Label>
                    <Form.Control
                    type="number"
                    punta
                    />
                    // Banda 7 en IB u 800 en SAT?

                    
                    // Logros academicos /experiencia laboral

                    // Carrera  que estudias

                    // idiomas

                    <Form.Control 
                    type="text"
                    onChange={opt => setTutorDescription(opt.target.value)}
                    value={tutorDescription}
                    />
                    <Form.Text className="text-muted">
                    Este tipo de informacion puede ser muy buena para dar una buena impresion sobre como eres tu!
                    </Form.Text>
                </Form.Group> */}
                <Form.Group className="mb-3" controlId="linkedin">
                    <Form.Label><b>Link del linkedin</b></Form.Label>
                    <Form.Control 
                    type="text"
                    onChange={opt => setLinkedinLink(opt.target.value)}
                    value={linkedinLink}
                    />
                    <Form.Text className="text-muted"
                    >
                    </Form.Text>
                </Form.Group>
                <Form.Group className="mb-3" controlId="linkedin">
                    <Form.Label><b>Link de youtube para presentacion</b></Form.Label>
                    <Form.Control 
                    type="text"
                    onChange={opt => setTutorYoutubeLink(opt.target.value)}
                    value={tutorYoutubeLink}
                    />
                    <Form.Text className="text-muted"
                    >
                    </Form.Text>
                </Form.Group>
                <br/>
                <Button type="submit" variant="primary" disabled={disabled}>Guardar cambios</Button>
            </Form>
            </Container>
            {/* </Form> */}
        </>
    )

}

export default TutorPageEdit;