import React, {useEffect, useState} from 'react';
import { useSelector,useDispatch } from 'react-redux';
import {getClasses} from "../../../store/class/class.actions"
import {getSubjects} from "../../../store/subject/subject.actions"
import {getStudents} from "../../../store/student/student.actions"
import {getParents} from "../../../store/parent/parent.actions"
import {Table, Button} from 'react-bootstrap';
import axios from "axios"

let today = new Date()
let tomorrow = new Date(today)
tomorrow.setDate(today.getDate() + 1)

const TableTutorClasses = () => {
    const dispatch = useDispatch() 
    const {user} = useSelector((state) => state.auth)
    const {classes} = useSelector((state) => state.class)
    const {subjects} = useSelector((state) => state.subject)
    const {students} = useSelector((state) => state.student)
    const {parents} = useSelector((state) => state.parent)


    useEffect(() => {
        dispatch(getClasses({filter:{tutorId:user._id, end:{"$gte":today.toISOString()}}, selection:{}}))
        dispatch(getSubjects({filter:{_id:{"$in":user.subjects}},selection:{}}))
        dispatch(getStudents({filter:{_id:{"$in":user.students}},selection:{}}))
        dispatch(getParents({filter:{}, selection: {}}))
    }, [])
    let newClasses = []
    for (const currentClass of classes){
        let newClass = {...currentClass}
        newClass["studentNames"] = []
        for (const student of students){
            if (newClass.studentIds.includes(student._id)){
                newClass["studentNames"] += student['name'].split(" ")[0] + " " + student['surname'].split(" ")[0] + ","
            }
        }
        newClass["studentNames"] = newClass["studentNames"].slice(0, -1); 
        for (const subject of subjects){
            if (subject._id == newClass.subjectId){
                newClass["subjectName"] = subject['name']
            }
        }
        newClasses.push(newClass)
    }
    const deleteEvent = (currentClass) => {
        if (new Date(currentClass.start) < tomorrow){
            alert("No se pueden cancelar clases con menos de 24 horas de antelación. En caso sea una emergencia, contacta con el coordinador académico.")
        }else{
            let newStudents = []
            let subject = {}
            for (const student of students){
                if (currentClass.studentIds.includes(student._id)){
                    newStudents.push(student)
                }
            }
            
            for (const thisSubject of subjects){
                if (currentClass.subjectId = thisSubject._id){
                    subject = thisSubject
                }
            }
            let newParents = []
            for (const parent of parents){
                if (currentClass.families.includes(parent.family)){
                    newParents.push(parent)
                }
            }
            axios.delete(`${process.env.REACT_APP_BACKENDURL}/class/newclass`,{data:{
                tutor:user,
                students:newStudents,
                subject:subject,
                parents:newParents,
                event: {
                    id:currentClass._id,
                    googleId:currentClass.googleId,
                    start:currentClass.start, //Not needed
                    end: currentClass.end, //Not needed
                    calendarId:user.calendarId //Not needed
                }}
            }).then(response => window.location.reload(false)).catch(err => console.log(err))
        }
    }
    let NEED_TO_CONFIRM = (
        <strong style={{color:"red"}}>Tienes que confirmar primero</strong>
    )
    return(
        <>
            <h3>Próximas Clases</h3>
            <br/>
            <Table striped bordered hover style={{ width: '80%','margin-left':'auto','margin-right':'auto' }}>
                <thead>
                <tr>
                <th>Fecha</th>
                <th>Comienzo</th>
                <th>Final</th>
                <th>Estudiante(s)</th>
                <th>Materia</th>
                <th>Extra info</th>
                <th>Clase</th>
                <th>Reporte</th>
                <th>Cancelar</th>
                <th>Confirmar?</th>
                </tr>
                </thead>
                <tbody>
                {newClasses.map((currentClass,id) => {
                    let start = new Date(currentClass.start)
                    let month = start.getMonth().toString().padStart(2, '0')
                    month = Number(month) + 1
                    let day = `${start.getDate().toString().padStart(2, '0')}/${month.toString().padStart(2, '0')}`
                    let end = new Date(currentClass.end)
                    start = `${start.getHours()}:${start.getMinutes().toString().padStart(2, '0')}`
                    end = `${end.getHours()}:${end.getMinutes().toString().padStart(2, '0')}`
                    let confirm;
                    if (currentClass.pending){
                        confirm = <Button variant="primary"><a href={`${window.location.origin}/confirm?id=${currentClass._id}`} target="_blank" style={{color:"rgb(255,255,255)"}}>Confirmar?</a></Button>
                    }else{
                        confirm = "Confirmada"
                    }
                    let cancel;
                    if (new Date(currentClass.start) < tomorrow){
                        cancel = <Button disabled={true} variant="danger">Cancelar</Button>
                    }else{
                        cancel = <Button onClick={() => deleteEvent(currentClass) } variant="danger">Cancelar</Button>
                    }
                    let report;
                    if(currentClass.tutorFeedback == ""){
                        report = <Button style={{backgroundColor:"rgba(10, 181, 145, 1)", borderColor:"rgba(10, 181, 145, 1)"}}><a style={{color:"rgb(255,255,255)"}} href={`${window.location.origin}/tutorfeedback?id=${currentClass._id}`} target="_blank">Reporte</a></Button>
                    }else{
                        report = "Enviado"
                    }
                    return(
                        <tr>
                            <td>{day}</td>
                            <td>{start}</td>
                            <td>{end}</td>
                            <td>{currentClass.studentNames}</td>
                            <td>{currentClass.subjectName}</td>
                            <th>{currentClass.prevInfo}</th>
                            <td>{
                                currentClass.pending ? 
                                NEED_TO_CONFIRM:
                                <Button><a href={`${window.location.origin}/call?id=${currentClass._id}`} target="_blank" style={{color:"rgb(255,255,255)"}}>Ir a clase</a></Button>
                                }
                            </td>
                            <td>{
                            currentClass.pending ? 
                                NEED_TO_CONFIRM:
                                report
                                }
                            </td>
                            <td>{
                            currentClass.pending ? 
                                NEED_TO_CONFIRM:
                                cancel
                            }
                            </td>
                            <td>{confirm}</td>
                        </tr>
                    )
                })}
                </tbody>
            </Table>
        </>
    )
}

export default TableTutorClasses