import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import TimeMetrics from "../../../pages/TimeMetrics/TimeMetrics";

const AdminMetrics = () => {
  const dispatch = useDispatch();
  const [familyMetrics, setFamilyMetrics] = useState({});
  const [tutorMetrics, setTutorMetrics] = useState({});
  const [classMetrics, setClassMetrics] = useState({});
  const [saleMetricsPEN, setSaleMetricsPEN] = useState({});
  const [saleMetricsUSD, setSaleMetricsUSD] = useState({});
  const [loading, setLoading] = useState(true);

  // const [classSeries, setClassSeries] = useState();
  // const [classRange, setClassRange] = useState();

  const { user } = useSelector((state) => state.auth);
  useEffect(() => {
    let getMetrics = async () => {
      let response = await axios.get(
        `${process.env.REACT_APP_BACKENDURL}/metrics`,
        {
          params: {
            userId: user._id,
          },
        }
      );
      setLoading(false);
      setFamilyMetrics(response.data.familyMetrics);
      setTutorMetrics(response.data.tutorMetrics);
      setClassMetrics(response.data.classMetrics);
      setSaleMetricsPEN(response.data.saleMetricsPEN);
      setSaleMetricsUSD(response.data.saleMetricsUSD);
    };
    getMetrics();
  }, []);
  if (loading) {
    return "Cargando... (puede tomar unos minutos)";
  }
  return (
    <div>
      {[
        ["Familias creadas", familyMetrics],
        ["Tutores creados", tutorMetrics],
        ["Clases creadas", classMetrics],
        ["Ventas (soles)", saleMetricsPEN],
        ["Ventas (dolares)", saleMetricsUSD],
      ].map((obj) => {
        let [name, metrics] = obj;
        if (Object.keys(metrics).length === 0) {
          return <></>;
        }
        return <TimeMetrics name={name} metrics={metrics} key={name} />;
      })}
    </div>
  );
};

export default AdminMetrics;
