import JoinFamilyForm from "./JoinFamilyForm";
import {useParams} from 'react-router-dom';

const JoinFamilyView = () => {
    const {familyId} = useParams();
    
    return (
        <JoinFamilyForm familyId={familyId}/>
    )
}

export default JoinFamilyView;