import React, { useState, useEffect } from "react";
import axios from "axios";
import { SubjectSelect, TutorSelect } from "../Select/Select";
import { useSelector, useDispatch } from "react-redux";
import { Form, Col, Row, Button, Alert } from "react-bootstrap";
import { setStudents } from "../../../store/student/student.slice";
import Select from "react-select";
import { getStudents } from "../../../store/student/student.actions";
import { getFamilies } from "../../../store/family/family.actions";
import { setFamilies } from "../../../store/family/family.slice";
import { getSubjects } from "../../../store/subject/subject.actions";
import { getTutors } from "../../../store/tutor/tutor.actions";
import { listFormat } from "../../../name_utils";
// const listFormat = (array) => {
//     let newArray = []
//     for (const elem of array){
//         let newElem = {...elem}
//         if ('surname' in newElem){
//             newElem['label'] = elem['name'] + " " + elem['surname']
//         }else{
//             newElem['label'] = elem['name']
//         }
//         newElem['value'] = elem['_id']
//         newArray.push(newElem)
//     }
//     return newArray
// }
const timeOptions = [
  { label: "30 mins", value: 30 },
  { label: "1 hora", value: 60 },
  { label: "1 hora 30 mins", value: 90 },
  { label: "2 horas", value: 120 },
  { label: "2 horas 30 mins", value: 150 },
  { label: "3 horas", value: 180 },
];

const findHourType = (subject, students) => {
  if (students.length > 1) {
    if (subject.service === "SAT") {
      return "hoursSATGroup";
    } else if (subject.service === "IB") {
      return "hoursIBGroup";
    } else if (subject.service === "Tutorías") {
      return "hoursTutoringGroup";
    } else if (subject.service === "Olimpiadas") {
      return "hoursOlympiadGroup";
    } else {
      return "hours";
    }
  } else {
    if (subject.service === "SAT") {
      return "hoursSAT";
    } else if (subject.service === "IB") {
      return "hoursIB";
    } else if (subject.service === "Tutorías") {
      return "hoursTutoring";
    } else if (subject.service === "Olimpiadas") {
      return "hoursOlympiad";
    } else {
      return "hours";
    }
  }
};

const MilisecondsPerMinute = 60000;

const addMinutes = (start, minutes) => {
  return new Date(start.getTime() + minutes * MilisecondsPerMinute);
};
const Class = () => {
  const dispatch = useDispatch();
  const { subject } = useSelector((state) => state.subject);
  const { families, selectedFamilies } = useSelector((state) => state.family);
  const { selectedStudents, students } = useSelector((state) => state.student);
  const { tutor } = useSelector((state) => state.tutor);
  const [startDate, setStartDate] = useState();
  const [minuteDuration, setMinuteDuration] = useState();
  const [disabled, setDisabled] = useState(false);
  const [isInvalid, setIsInvalid] = useState(false);
  const [error, setError] = useState("");
  useEffect(() => {
    dispatch(getStudents({ filter: {}, selection: {} }));
    dispatch(getFamilies({ filter: {}, selection: {} }));
    dispatch(getSubjects({ filter: {}, selection: {} }));
    dispatch(getTutors({ filter: { status: "Activo" }, selection: {} }));
  }, []);
  const handleSubmit = async (event) => {
    event.preventDefault();
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.stopPropagation();
      setIsInvalid(true);
    } else if (minuteDuration > 180 || minuteDuration < 30) {
      setError(
        "No puedes reservar clases de mas de 3 horas o menos de 30 minutos. Asegurate que las fechas esten bien"
      );
      event.stopPropagation();
    } else {
      if (!tutor || !tutor._id) {
        alert("Error: No tutor ha sido seleccionado.");
        return;
      }
      setIsInvalid(false);
      setDisabled(true);
      let endDate = addMinutes(startDate, minuteDuration);
      let response = await axios.get(
        `${process.env.REACT_APP_BACKENDURL}/class/isValidClass`,
        {
          params: {
            start: startDate.toISOString(),
            end: endDate.toISOString(),
            tutorId: tutor._id,
          },
        }
      );
      //Both should be true
      let { valid_with_free_events, valid_with_classes, overlap_classes } =
        response.data;
      if (!valid_with_free_events) {
        if (
          !window.confirm(
            `Este tiempo no esta complementamente dentro de uno de los horarios libres del tutor. Desea continuar?`
          )
        ) {
          setDisabled(false);
          return;
        }
      }
      if (!valid_with_classes) {
        let ids = overlap_classes.map((elem) => elem._id.toString());
        if (
          !window.confirm(
            `Este evento se cruza con ${overlap_classes.length} otras clases del profesor. Deseas continuar? Las clases afectadas tienen ids ${ids}`
          )
        ) {
          setDisabled(false);
          return;
        }
      }
      axios
        .get(`${process.env.REACT_APP_BACKENDURL}/parent/all`, {
          params: {
            filter: { family: selectedFamilies.map((value) => value._id) },
            selection: {},
          },
        })
        .then((response) => {
          let parents = response.data;
          let data = {
            isAdmin: true,
            students: selectedStudents,
            subject: subject,
            parents: parents,
            tutor: tutor,
            event: {
              start: startDate.toISOString(),
              end: endDate.toISOString(),
            },
            freeEvent: {
              start: {
                dateTime: startDate.toISOString(),
              },
              end: {
                dateTime: endDate.toISOString(),
              },
              organizer: {
                email: tutor.calendarId,
              },
              // id: "" // need to know the Google Calendar event id that is crashing
            },
          };
          axios
            .post(`${process.env.REACT_APP_BACKENDURL}/class/newclass`, data)
            .then((response) => {
              window.location.reload(false);
            })
            .catch((error) => {
              setError(error.response.data.message);
              setDisabled(false);
            });
        })
        .catch((error) => console.log(error));
    }
  };
  let hourType = findHourType(subject, selectedStudents);
  console.log(hourType);
  return (
    <>
      {error && (
        <Alert variant="danger" onClose={() => setError("")} dismissible>
          {error}
        </Alert>
      )}

      <Form noValidate validated={isInvalid} onSubmit={handleSubmit}>
        <Row className="justify-content-md-center">
          <Form.Group as={Col} lg="4" controlId="students">
            <Form.Label>Escoja estudiante(s)</Form.Label>
            <br />
            <br />
            <Form.Control
              as={Select}
              options={listFormat(students)}
              isMulti={true}
              required
              onChange={(opt) => {
                dispatch(setStudents(opt));
                let newFamilies = [];
                for (const student of opt) {
                  for (const family of families) {
                    if (family.students.includes(student._id)) {
                      newFamilies.push(family);
                    }
                  }
                }
                dispatch(setFamilies(newFamilies));
              }}
            />
          </Form.Group>
        </Row>
        <br />
        <br />
        {selectedStudents.length > 0 ? (
          <>
            <SubjectSelect />
            <br />
            <br />
            {!(subject._id === "") ? (
              <>
                {selectedFamilies.map((family) => (
                  <p>
                    A la familia <b>{family.surname}</b> le quedan{" "}
                    <b>{family[hourType]}</b> horas de {hourType}
                  </p>
                ))}
                {Math.min(
                  ...selectedFamilies.map((family) => family[hourType])
                ) > 0 ? (
                  <>
                    <TutorSelect />
                    <br />
                    <br />
                    <Row className="justify-content-md-center">
                      <Form.Group as={Col} lg="3" controlId="starDate">
                        <Form.Label>Comienzo de la clase</Form.Label>
                        <br />
                        <br />
                        <Form.Control
                          type="datetime-local"
                          required
                          onChange={(e) =>
                            setStartDate(new Date(e.target.value))
                          }
                        />
                        <Form.Control.Feedback>
                          Todo bien!
                        </Form.Control.Feedback>
                      </Form.Group>
                    </Row>
                    <br />
                    <br />
                    <Row className="justify-content-md-center">
                      <Form.Group as={Col} lg="3" controlId="endDate">
                        <Form.Label>Duración de la clase</Form.Label>
                        <br />
                        <br />
                        <Form.Control
                          as={Select}
                          options={timeOptions}
                          required
                          onChange={(opt) => {
                            setMinuteDuration(opt.value);
                          }}
                        />
                        <Form.Control.Feedback>
                          Todo bien!
                        </Form.Control.Feedback>
                      </Form.Group>
                    </Row>
                    <br />
                    {startDate && minuteDuration && (
                      <p>
                        Esta clase terminará a las{" "}
                        <b>
                          {addMinutes(
                            startDate,
                            minuteDuration
                          ).toLocaleString()}
                        </b>
                      </p>
                    )}
                    {/* <br />
                                    <br /> */}
                    {/* <Row className="justify-content-md-center">
                                        <Form.Group as={Col} lg="2" controlId="weeks">
                                        <Form.Label>Cuantas semanas?</Form.Label>
                                        <br />
                                        <br />
                                        <Form.Control
                                            type="number"
                                            min="1"
                                            max={Math.min(...selectedFamilies.map(family => family.hours))}
                                            required
                                            inputMode="numeric"
                                            onChange={e => setWeeks(e.target.value)}
                                        />
                                        <Form.Control.Feedback>Todo bien!</Form.Control.Feedback>
                                        </Form.Group>
                                    </Row> */}
                    <br />
                    <br />
                    <br />
                    <Button type="submit" variant="success" disabled={disabled}>
                      Crear clases(s)
                    </Button>
                  </>
                ) : (
                  <p>
                    Asegurate que todos los estudiantes tengan horas compradas.
                  </p>
                )}
              </>
            ) : (
              <></>
            )}
          </>
        ) : (
          <></>
        )}
      </Form>
    </>
  );
};

export default Class;
