import React, { useEffect, useState, useMemo } from "react"
import axios from "axios"
import { useSelector,useDispatch } from 'react-redux';
import Select from "react-select"
import {Form, Row, Col, Button} from "react-bootstrap"
import PhoneInput from 'react-phone-input-2'
import { getSubjects } from "../../../store/subject/subject.actions";
import { selectTutor } from "../../../store/tutor/tutor.slice";
import 'react-phone-input-2/lib/style.css'
import { listFormat } from "../../../name_utils";
// const listFormat = (array) => {
//     let newArray = []
//     for (const elem of array){
//         let newElem = {...elem}
//         if ('surname' in newElem){
//             newElem['label'] = elem['name'] + " " + elem['surname']
//         }else{
//             newElem['label'] = elem['name']
//         }
//         newElem['value'] = elem['_id']
//         newArray.push(newElem)
//     }
//     return newArray
// }


const  NewTutor = () => {
    const dispatch = useDispatch()
    useEffect(() => {
        dispatch(getSubjects({filter:{},selection:{}}))
    },[])
    let currencies = [{label:"Sol Peruano",value:"PEN"}, {label:"US Dollar", value:"USD"}]
    const {tutor} = useSelector((state) => state.tutor)
    let newTutor = {...tutor}
    const {subjects} = useSelector((state) => state.subject)
    const [disabled,setDisabled] = useState(false)
    const [isInvalid, setIsInvalid] = useState(false);
    const handleSubmit = async (event) => {
        event.preventDefault();
        const form = event.currentTarget;
        if (form.checkValidity() === false) {
            event.stopPropagation();
            setIsInvalid(true)
        }else{
            // let answer = window.confirm("Estás seguro quieres actualizar esta info?");
            setIsInvalid(false)
            setDisabled(true)
            delete newTutor._id
            delete newTutor.calendarId
            try{
                let response = await axios.post(`${process.env.REACT_APP_BACKENDURL}/tutor`, newTutor);
                let savedTutor = response.data;
                await axios.post(`${process.env.REACT_APP_BACKENDURL}/jobs/welcomeTutor`, {tutorId: savedTutor._id});
                alert("Tutor creado correctamente!")
                window.location.reload(false);
            } catch(err){
                console.log("Error when creating new tutor: " + err);
                alert("Hubo un error!" + JSON.stringify(err))
            }
            // if(answer){
            // }
        }
    }
    return(
        <Form noValidate validated={isInvalid} onSubmit={handleSubmit}>
            <Row className="justify-content-md-center">
                <Form.Group as={Col} lg="2" controlId="name">
                <Form.Label><b>Nombre</b></Form.Label>
                <br/>
                <br/>
                <Form.Control
                    required
                    type="text"
                    placeholder="Nombre"
                    onChange={e => {
                        newTutor["name"] = e.target.value
                        dispatch(selectTutor(newTutor))
                    }}
                />
                <Form.Control.Feedback>Todo bien!</Form.Control.Feedback>
                </Form.Group>

                <Form.Group as={Col} lg="2" controlId="surname">
                <Form.Label><b>Apellido(s)</b></Form.Label>
                <br/>
                <br/>
                <Form.Control
                    required
                    type="text"
                    placeholder="Apellido(s)"
                    onChange={e => {
                        newTutor["surname"] = e.target.value
                        dispatch(selectTutor(newTutor))
                    }}

                />
                <Form.Control.Feedback>Todo bien!</Form.Control.Feedback>
                </Form.Group>
            </Row>
            <br/>
            <br/>
            <Row className="justify-content-md-center">
                <Form.Group as={Col} lg="2" controlId="surname">
                <Form.Label><b>Salario</b></Form.Label>
                <br/>
                
                <Form.Control
                    required
                    type="number"
                    placeholder="Salario"
                    onChange={e => {
                        newTutor["salary"] = e.target.value
                        dispatch(selectTutor(newTutor))
                    }}

                />
                <Form.Control.Feedback>Todo bien!</Form.Control.Feedback>
                </Form.Group>

                <Form.Group as={Col} lg="3" controlId="surname">
                <Form.Label><b>Moneda</b></Form.Label>
                <br/>
                <Form.Control
                    as={Select}
                    required
                    options={currencies}
                    onChange={(opt) => {
                        newTutor["currency"] = opt.value
                        dispatch(selectTutor(newTutor))
                    }}
                    placeholder="Moneda"
                />
                <Form.Control.Feedback>Todo bien!</Form.Control.Feedback>
                </Form.Group>
            </Row>
            <br/>
            <br/>
            <Row className="justify-content-md-center">
                <Form.Group as={Col} lg="2" controlId="name">
                <Form.Label><b>Gmail</b></Form.Label>
                <br/>
                <Form.Control
                    required
                    type="email"
                    onChange={e => {
                        newTutor["email"] = e.target.value
                        dispatch(selectTutor(newTutor))
                    }}
                    placeholder="Gmail"
                />
                <Form.Control.Feedback>Todo bien!</Form.Control.Feedback>
                </Form.Group>
                <Form.Group as={Col} lg="2" controlId="surname">
                <Form.Label><b>Whatsapp</b></Form.Label>

                <PhoneInput
                    inputProps={{required:true}}
                    onChange={phone => {
                        newTutor["phone"] = "+" + phone
                        dispatch(selectTutor(newTutor))
                    }}  
                    enableSearch={true}
                /> 
                <Form.Control.Feedback>Todo bien!</Form.Control.Feedback>
                </Form.Group>
                
                <br/>
            </Row>
            <br/>
            <br/>
            <Row className="justify-content-md-center">
                <Form.Group as={Col} lg="3" controlId="surname">
                <Form.Label><b>Materias</b></Form.Label>
                <Form.Control
                    as={Select}
                    required
                    isMulti={true}
                    options={listFormat(subjects)}
                    onChange={(opt) => {
                        let newSubjects = []
                        for (const subject of opt){
                            newSubjects.push(subject.value)
                        }
                        newTutor["subjects"] = newSubjects
                        dispatch(selectTutor(newTutor))
                    }}
                    placeholder="Materias"
                />
                <Form.Control.Feedback>Todo bien!</Form.Control.Feedback>
                </Form.Group>
            </Row>
            <br/>
            <br/>
            <Button variant="success" disabled={disabled} type="submit">Crear Tutor</Button>

        </Form>
    )
}

export default NewTutor