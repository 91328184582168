import React, {useState, useEffect} from "react"
import { useSelector } from "react-redux"
import { useNavigate } from "react-router"
import TutorSubjects from "../../components/forms/TutorView/TutorSubjects"

const Materials = () => {
    const {user} = useSelector((state) => state.auth)
    const [option,setOption] = useState(<></>)
    const navigate = useNavigate()
    useEffect(() => {
        if(user.role === "Tutor"){
            setOption(<TutorSubjects />)
        }else{
            navigate("/")
        }
    }, [])
    return (
        <>  
            <br/>
            <h1>MATERIAL DE CLASES</h1>
            <br/>
            {option}
        </>
    )
}

export default Materials