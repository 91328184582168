import React, { useEffect, useState } from "react";
import { TutorSelect } from "../Select/Select";
import { selectTutor } from "../../../store/tutor/tutor.slice";
import { useDispatch, useSelector } from "react-redux";
import { Form, Col, Row, Button } from "react-bootstrap";
import axios from "axios";
import Select from "react-select";
import { getTutors } from "../../../store/tutor/tutor.actions";
import { getSubjects } from "../../../store/subject/subject.actions";
import { getStudents } from "../../../store/student/student.actions";
import { listFormat } from "../../../name_utils";

const AllTutorsForm = () => {
  const [updateReason, setUpdateReason] = useState(""); //reason for updating a given tutor
  const [changed, setChanged] = useState(false); //whether there was an edit at all
  //For manging tutor description array
  const [tutorDescriptionScores, setTutorDescriptionScores] = useState("");
  const [tutorDescriptionAchievements, setTutorDescriptionAchievements] =
    useState("");
  const [tutorDescriptionMajor, setTutorDescriptionMajor] = useState("");
  const [tutorDescriptionLanguage, setTutorDescriptionLanguage] = useState("");
  const { tutor } = useSelector((state) => state.tutor);

  useEffect(() => {
    dispatch(getTutors({ filter: { status: "Activo" }, selection: {} }));
    dispatch(getSubjects({ filter: {}, selection: {} }));
    dispatch(getStudents({ filter: {}, selection: {} }));
    if (tutor.tutorDescriptionList) {
      setTutorDescriptionScores(tutor.tutorDescriptionList[0] || "");
      setTutorDescriptionAchievements(tutor.tutorDescriptionList[1] || "");
      setTutorDescriptionMajor(tutor.tutorDescriptionList[2] || "");
      setTutorDescriptionLanguage(tutor.tutorDescriptionList[3] || "");
    }
  }, []);
  useEffect(() => {
    if (tutor.tutorDescriptionList) {
      setTutorDescriptionScores(tutor.tutorDescriptionList[0] || "");
      setTutorDescriptionAchievements(tutor.tutorDescriptionList[1] || "");
      setTutorDescriptionMajor(tutor.tutorDescriptionList[2] || "");
      setTutorDescriptionLanguage(tutor.tutorDescriptionList[3] || "");
    }
  }, [tutor]);
  // const listFormat = (array) => {
  //     let newArray = []
  //     for (const elem of array){
  //         let newElem = {...elem}
  //         if ('surname' in newElem){
  //             newElem['label'] = elem['name'] + " " + elem['surname']
  //         }else{
  //             newElem['label'] = elem['name']
  //         }
  //         newElem['value'] = elem['_id']
  //         newArray.push(newElem)
  //     }
  //     return newArray
  // }
  const { students } = useSelector((state) => state.student);
  const studentFormat = () => {
    let allStudents = listFormat(students);
    let formatStudents = [];
    for (const tutorStudent of tutor.students) {
      for (const student of allStudents) {
        if (tutorStudent === student._id) {
          formatStudents.push(student);
        }
      }
    }
    return formatStudents;
  };
  const allSubjects = useSelector((state) => state.subject.subjects);
  const newTutor = { ...tutor };
  const [edit, setEdit] = useState(false);
  const dispatch = useDispatch();
  const [disabled, setDisabled] = useState(false);
  const [isInvalid, setIsInvalid] = useState(false);
  const subjectFormat = () => {
    let allSubjectsValues = listFormat(allSubjects);
    let formatSubjects = [];
    for (const tutorSubject of tutor.subjects) {
      for (const subject of allSubjectsValues) {
        if (tutorSubject === subject._id) {
          formatSubjects.push(subject);
        }
      }
    }
    return formatSubjects;
  };
  const handleSubmit = (event) => {
    event.preventDefault();
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      console.log("INVALD!!!!");
      form.reportValidity();
      event.stopPropagation();
      setIsInvalid(true);
    } else {
      // let answer = window.confirm("Estás seguro quieres actualizar esta info?");
      setIsInvalid(false);
      setDisabled(true);
      delete newTutor._id;
      delete newTutor.channel;
      delete newTutor.__v;
      delete newTutor.label;
      delete newTutor.value;
      delete newTutor.services;
      let tutorDescriptionList = [
        tutorDescriptionScores,
        tutorDescriptionAchievements,
        tutorDescriptionMajor,
        tutorDescriptionLanguage,
      ];
      newTutor.tutorDescriptionList = tutorDescriptionList;
      console.log("Found update reason: " + updateReason);
      axios
        .patch(`${process.env.REACT_APP_BACKENDURL}/tutor`, {
          id: tutor._id,
          update: newTutor,
          updateReason: updateReason,
        })
        .then(async (response) => {
          // Now that the temp tutors are a thing, don't need to call /welcomeTutor
          // (this will be done once admin activates a temp tutor)
          // if (
          //     tutor.status === "Creado" &&
          //     newTutor.status === "Activo" &&
          //     newTutor.salary > 0
          // ){
          //     //If the admin approved this tutor, then this will send welcome emails to the tutor
          //     await axios.post(`${process.env.REACT_APP_BACKENDURL}/jobs/welcomeTutor`, {tutorId: newTutor._id});
          // }
          window.location.reload(false);
          // console.log(response)
        })
        .catch((error) => console.log(error));
      // if(answer){
      // }
    }
  };
  const tutorData = Object.keys(newTutor).map((key, index) => {
    if (key == "_id") {
      return (
        <>
          <Row className="justify-content-md-center">
            <Form.Group as={Col} lg="2" controlId={key}>
              <Form.Label>{key}</Form.Label>
              <Form.Control
                type="text"
                disabled
                value={newTutor[key]}
                readOnly={!edit}
              />
              {/* <Form.Control.Feedback>Todo bien!</Form.Control.Feedback> */}
            </Form.Group>
          </Row>
          <br />
        </>
      );
    } else if (
      key == "__v" ||
      key == "label" ||
      key == "value" ||
      key === "tutorDescriptionList"
    ) {
      return <></>;
    } else if (key == "subjects") {
      return (
        <>
          <Row className="justify-content-md-center">
            <Form.Group as={Col} lg="3" controlId="subjects">
              <Form.Label>subjects</Form.Label>
              <br />
              <br />
              <Form.Control
                as={Select}
                required
                isMulti={true}
                value={subjectFormat()}
                options={listFormat(allSubjects)}
                isDisabled={!edit}
                onChange={(opt) => {
                  let newSubjects = [];
                  for (const subject of opt) {
                    newSubjects.push(subject.value);
                  }
                  newTutor[key] = newSubjects;
                  dispatch(selectTutor(newTutor));
                  setChanged(true);
                }}
                placeholder="Materias"
              />
              {/* <Form.Control.Feedback>Todo bien!</Form.Control.Feedback> */}
            </Form.Group>
          </Row>
          <br />
        </>
      );
    } else if (key == "students") {
      return (
        <>
          <Row className="justify-content-md-center">
            <Form.Group as={Col} lg="3" controlId="surname">
              <Form.Label>students</Form.Label>
              <br />
              <br />
              <Form.Control
                as={Select}
                // required //apparently this doesnt work when empty array
                isMulti={true}
                value={studentFormat()}
                options={listFormat(students)}
                isDisabled={!edit}
                onChange={(opt) => {
                  let newStudents = [];
                  for (const subject of opt) {
                    newStudents.push(subject.value);
                  }
                  newTutor[key] = newStudents;
                  dispatch(selectTutor(newTutor));
                  setChanged(true);
                }}
                placeholder="Estudiantes"
              />
              {/* <Form.Control.Feedback>Todo bien!</Form.Control.Feedback> */}
            </Form.Group>
          </Row>
          <br />
        </>
      );
    } else if (key == "services") {
      return <></>;
    } else if (key === "status") {
      return (
        <Row className="justify-content-md-center">
          <Form.Group as={Col} lg="2" controlId={key}>
            <Form.Label>{key}</Form.Label>
            <Form.Select
              value={newTutor[key]}
              // readOnly={!edit}
              disabled={!edit}
              onChange={(e) => {
                newTutor[key] = e.target.value;
                dispatch(selectTutor(newTutor));
                setChanged(true);
              }}
            >
              <option value="Activo">Activo</option>
              <option value="Inactivo">Inactivo</option>
              {/* <option value="COMPLEMENT">Complemento</option> */}
            </Form.Select>
            {/* <Form.Control.Feedback>Todo bien!</Form.Control.Feedback> */}
          </Form.Group>
        </Row>
      );
    } else {
      return (
        <>
          <Row className="justify-content-md-center">
            <Form.Group as={Col} lg="2" controlId={key}>
              <Form.Label>{key}</Form.Label>
              <Form.Control
                type="text"
                value={newTutor[key]}
                readOnly={!edit}
                onChange={(e) => {
                  newTutor[key] = e.target.value;
                  dispatch(selectTutor(newTutor));
                  setChanged(true);
                }}
              />
              {/* <Form.Control.Feedback>Todo bien!</Form.Control.Feedback> */}
            </Form.Group>
          </Row>
          <br />
        </>
      );
    }
  });
  return (
    <Form noValidate validated={isInvalid} onSubmit={handleSubmit}>
      <TutorSelect />
      <br />
      {tutor._id == "" ? (
        <></>
      ) : (
        <>
          <p>
            Link de perfil:{" "}
            <a
              href={`${process.env.REACT_APP_FRONTEND_URL}/tutor?id=${tutor._id}`}
              target="_blank"
            >{`${process.env.REACT_APP_FRONTEND_URL}/tutor?id=${tutor._id}`}</a>
          </p>
          {tutorData}
          <br />
          <Row className="justify-content-md-center">
            <Form.Group as={Col} lg="2">
              <Form.Label>
                Notas del tutor en IB y/o SAT <br />(<i>tutorDescriptionList</i>
                )
              </Form.Label>
              <Form.Control
                readOnly={!edit}
                // placeholder='34/45 en IB y 780/800 en SAT'
                type="text"
                required
                onChange={(evt) => {
                  setTutorDescriptionScores(evt.target.value);
                  setChanged(true);
                }}
                value={tutorDescriptionScores}
              />
            </Form.Group>
          </Row>
          <br />
          <Row className="justify-content-md-center">
            <Form.Group as={Col} lg="2">
              <Form.Label>
                Logros académicos / experiencia laboral del tutor <br />(
                <i>tutorDescriptionList</i>)
              </Form.Label>
              <Form.Control
                readOnly={!edit}
                // placeholder='Medallista de oro en la International Applied Biology Olympiad'
                required
                type="text"
                onChange={(evt) => {
                  setTutorDescriptionAchievements(evt.target.value);
                  setChanged(true);
                }}
                value={tutorDescriptionAchievements}
              />
            </Form.Group>
          </Row>
          <br />
          <Row className="justify-content-md-center">
            <Form.Group as={Col} lg="2">
              <Form.Label>
                Carrera que el tutor estudia e universidad <br />(
                <i>tutorDescriptionList</i>)
              </Form.Label>
              <Form.Control
                readOnly={!edit}
                // placeholder='Estudiante de Biología en la Universidad Peruana Cayetano Heredia'
                type="text"
                required
                onChange={(evt) => {
                  setTutorDescriptionMajor(evt.target.value);
                  setChanged(true);
                }}
                value={tutorDescriptionMajor}
              />
            </Form.Group>
          </Row>
          <br />
          <Row className="justify-content-md-center">
            <Form.Group as={Col} lg="2">
              <Form.Label>
                Idiomas que el tutor hablar <br />(<i>tutorDescriptionList</i>)
              </Form.Label>
              <Form.Control
                readOnly={!edit}
                // placeholder='IDIOMAS: Español (nativo) e Inglés (avanzado)'
                type="text"
                required
                onChange={(evt) => {
                  setTutorDescriptionLanguage(evt.target.value);
                  setChanged(true);
                }}
                value={tutorDescriptionLanguage}
              />
            </Form.Group>
          </Row>
          {edit ? (
            <>
              <Row className="justify-content-md-center mb-3">
                <Form.Group as={Col} lg="2" controlId={"reason"}>
                  <Form.Label>Razón de los cambios</Form.Label>
                  <Form.Control
                    type="text"
                    required
                    value={updateReason}
                    onChange={(e) => {
                      setUpdateReason(e.target.value);
                      setChanged(true);
                    }}
                  />
                  {/* <Form.Control.Feedback>Todo bien!</Form.Control.Feedback> */}
                </Form.Group>
              </Row>
              <Row className="justify-content-md-center">
                <Col lg="2">
                  <Button
                    type="submit"
                    variant="success"
                    disabled={disabled || !changed}
                  >
                    Enviar
                  </Button>
                </Col>
                <Col lg="2">
                  <Button
                    variant="danger"
                    disabled={disabled}
                    onClick={() => setEdit(false)}
                  >
                    Cancelar
                  </Button>
                </Col>
              </Row>
            </>
          ) : (
            <Button variant="success" onClick={() => setEdit(true)}>
              Editar
            </Button>
          )}
        </>
      )}
    </Form>
  );
};

export default AllTutorsForm;
