import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

export const getClasses = createAsyncThunk(
    'class/getClasses',
    async (data) => {
        const response = await axios.get(`${process.env.REACT_APP_BACKENDURL}/class/all`, {
            params: data
        })
        const classes = response['data']
        return classes
    }
)