import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

export const getFreeEvents = createAsyncThunk(
    'student/getFreeEvents',
    async (data) => {
        const response = await axios.get(`${process.env.REACT_APP_BACKENDURL}/calendar/freeEvents`, {params:data})
        const freeEvents = response['data']
        return freeEvents
    }
)