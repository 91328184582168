import React, {useState} from 'react';
import TableTutorPastClasses from './TableTutorPastClasses';
import TableTutorFutureClasses from './TableTutorFutureClasses';
import {Form, Row,Col} from "react-bootstrap"
import { useDispatch } from 'react-redux';
import { setClasses } from '../../../store/class/class.slice';
const StudentClasses = () => {
    const [checked,setChecked] = useState(false)
    const dispatch = useDispatch()
    return (
        <>
            {checked
                ?
                <>
                    <Row className="justify-content-md-center">
                        <Col  xs={2}>
                            <Form.Check 
                                type="switch"
                                id="custom-switch"
                                label="Ver próximas clases"
                                checked={checked}
                                onClick ={() => {
                                    dispatch(setClasses([]))
                                    setChecked(!checked)
                                }}
                            />
                        </Col>
                    </Row>
                    <br/>
                    <TableTutorPastClasses />
                </>
                :
                <>
                    <Row className="justify-content-md-center">
                        <Col  xs={2}>
                            <Form.Check 
                                type="switch"
                                id="custom-switch"
                                label="Ver clases pasadas"
                                checked={checked}
                                onClick ={() => {
                                    dispatch(setClasses([]))
                                    setChecked(!checked)
                                }}
                            />
                        </Col>
                    </Row>
                    <br/>
                    <TableTutorFutureClasses />
                </>
            }
        </>
    )
}

export default StudentClasses