import { createSlice } from "@reduxjs/toolkit";
import { getClasses } from "./class.actions";
const classSlice = createSlice({
    name: "class",
    initialState: {
        _id:"",
        startDate: "",
        endDate: "",
        type:"",
        edited: false,
        classes: []
    },
    reducers:{
        selectClass: (state,action) => {
            state._id = action.payload
        },
        updateClassType: (state,action) => {
            state.type = action.payload
        },
        updateClass :(state,action) => {
            state.startDate = action.payload.startDate
            state.endDate = action.payload.endDate
        },
        updateClassStart :(state,action) => {
            state.startDate = action.payload
        },
        updateClassEnd :(state,action) => {
            state.endDate = action.payload
        },
        editedClass: (state,action) => {
            state.edited = true
        },
        setClasses: (state,action) => {
            state.classes = action.payload
        }
        
    },
    extraReducers: {
        [getClasses.pending] : (state,action) => {
            console.log("fetching classes")
        },
        [getClasses.fulfilled]: (state,action) => {
            console.log("fetching classes succesfully!")
            state.classes = action.payload
        },
    }
})

export const {selectClass, updateClassType, updateClass, editedClass,updateClassStart,updateClassEnd, setClasses} = classSlice.actions;
export default classSlice.reducer