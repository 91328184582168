import React, {useState, useEffect} from 'react'
import { JitsiMeeting } from '@jitsi/react-sdk';
import { Image } from "react-bootstrap"

const CallPage = () => {
    const [id, setId] = useState("")
    useEffect(() => {
        let params = new URLSearchParams(window.location.search)
        let _id = params.get("id")
        if (typeof _id === "string" || _id instanceof String ){
            setId(_id)
        }
    }, [])
    return(
        <>
{id &&
        <JitsiMeeting
            configOverwrite = {{
                startWithAudioMuted: true,
                
            }}
            roomName = {id}
            getIFrameRef = { node => {
                node.style.height = '1000px'
            }
            }
        />
}
        </>
    )
}

export default CallPage