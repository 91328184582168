import React, {useState, useEffect} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Select from 'react-select';
import {Col,Form,Row, Card} from 'react-bootstrap';
import { getFamily } from '../../../store/family/family.actions';

const VendorViewForm = () => {
    const {user} = useSelector((state) => state.auth)
    const dispatch = useDispatch()
    const {family} = useSelector((state) => state.family);

    //Getting family
    useEffect(() => {
        dispatch(getFamily({id:user.family}));
    }, [])
    return(
        <>
            <Card style={{ width: '50%',marginLeft:'auto',marginRight:'auto' }}>
                <br/>
                <h2>Hola <b>{user.name.split(" ")[0]}</b>!</h2>
                <p>
                    Bienvenido a tu perfil de <strong>Vendedor</strong>!. Puedes ver información de
                    estudiantes y padres en las sección de <strong>Familias</strong> arriba.
                </p>
            </Card>
        </>
    )
}

export default VendorViewForm