import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Table from "react-bootstrap/Table";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import Badge from "react-bootstrap/Badge";
import { Row, Col, Form } from "react-bootstrap";
import { getFamilies } from "../../../store/family/family.actions";
import { useUserIfLoggedIn } from "../../../auth_utils";
import VendorFamilyInfo from "./VendorFamilyInfo";
import axios from "axios";

const VendorFamilies = () => {
  useUserIfLoggedIn();
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.auth);
  const { families } = useSelector((state) => state.family);
  const [showModal, setShowModal] = useState(false);
  const [showModalFamily, setShowModalFamily] = useState(false);

  const [userToShow, setUserToShow] = useState({});
  const [familyToShow, setFamilyToShow] = useState({});

  //Filters for what families to show
  const [showOwn, setShowOwn] = useState(true);
  const [showUnclaimed, setShowUnclaimed] = useState(true);
  const [showClaimed, setShowClaimed] = useState(true);

  const pullFamilyInfoFromDB = () => {
    dispatch(
      getFamilies({
        filter: {},
        selection: {},
        populate: ["vendor", "students", "parents"],
      })
    );
  };
  useEffect(() => {
    pullFamilyInfoFromDB();
  }, []);
  const getVariantFromCount = (numHours) => {
    if (numHours > 10) {
      return "success";
    } else if (numHours > 3) {
      return "warning";
    } else {
      return "danger";
    }
  };
  const UserLink = ({ user }) => {
    return (
      <Button
        onClick={() => {
          setUserToShow(user);
          setShowModal(true);
        }}
        style={{ marginRight: 5 }}
        variant="link"
      >
        {user.name}
      </Button>
    );
  };
  const FamilyLink = ({ family }) => {
    return (
      <Button
        onClick={() => {
          setFamilyToShow(family);
          setShowModalFamily(true);
        }}
        style={{ marginRight: 5, width: "100%" }}
        variant="dark"
      >
        {family.surname}
      </Button>
    );
  };
  const asignVendorToSelf = async (familyId) => {
    try {
      await axios.patch(`${process.env.REACT_APP_BACKENDURL}/family/`, {
        id: familyId,
        update: {
          vendor: user._id,
        },
      });
      alert("Operación realizada con éxito");
      pullFamilyInfoFromDB(); //To update UI
    } catch (error) {
      alert("Hubo un error");
      console.log(error);
    }
  };
  const stopFollowingFamily = async (familyId) => {
    try {
      await axios.patch(`${process.env.REACT_APP_BACKENDURL}/family/`, {
        id: familyId,
        update: {
          vendor: null,
        },
      });
      alert("Operación realizada con éxito");
      pullFamilyInfoFromDB(); //To update UI
    } catch (error) {
      alert("Hubo un error");
      console.log(error);
    }
  };
  const filterFamilies = (families) => {
    return families.filter((family) => {
      const isUnclaimed = !family.vendor;
      const isOwn = !isUnclaimed && family.vendor._id === user._id;
      const isClaimed =
        !isUnclaimed && family.vendor && family.vendor._id !== user._id;
      return (
        (showOwn && isOwn) ||
        (showUnclaimed && isUnclaimed) ||
        (showClaimed && isClaimed)
      );
    });
  };
  const EXPIRE_HOURS = 3 * 30 * 24 * 60 * 60 * 1000; //3 months
  const getTimeUntilHoursExpire = (family) => {
    let { lastTimePurchase } = family;
    if (!lastTimePurchase) {
      return "";
    }
    //   if (!lastTimePurchase) {
    //     lastTimePurchase = new Date();
    //     return
    //   }

    let purchase = new Date(lastTimePurchase);
    let deadline = new Date(purchase.getTime() + EXPIRE_HOURS);
    const MINUTE = 60;
    const HOUR = 60 * MINUTE;
    const DAY = 24 * HOUR;
    const WEEK = 7 * DAY;
    const MONTH = 30 * DAY;

    let timeDifference = (deadline.getTime() - new Date().getTime()) / 1000;
    const hasExpired = timeDifference < 0;
    if (timeDifference < 0) {
      timeDifference *= -1;
    }
    timeDifference = Math.round(timeDifference);
    let color = "green";
    if (timeDifference < DAY) {
      color = "red";
    } else if (timeDifference < WEEK) {
      color = "red";
    } else if (timeDifference < MONTH) {
      color = "orange";
    }
    let totalDays = Math.floor(timeDifference / DAY);
    let time = "";
    if (totalDays > 0) {
      time = `${totalDays} ${totalDays !== 1 ? "días" : "día"}`;
    } else {
      time = "menos de un día!";
    }

    if (hasExpired) {
      return (
        <span style={{ fontSize: 14 }}>
          Expiró el {deadline.toLocaleDateString()} <br />
          (hace {time})
        </span>
      );
    } else {
      return (
        <span style={{ color: color, fontSize: 16, fontWeight: "bold" }}>
          {deadline.toLocaleDateString()} <br />
          (en {time})
        </span>
      );
    }
  };
  return !user || !user._id ? (
    <div>Necesita iniciar sesion</div>
  ) : user.role !== "Vendor" ? (
    <div>Solo vendedores tienen permiso para ver esta página</div>
  ) : (
    <div>
      <Modal show={showModal} onHide={() => setShowModal(false)}>
        <Modal.Header closeButton>
          <h1>Información de usuario</h1>
        </Modal.Header>
        <Modal.Body>
          <Table striped bordered hover>
            <tbody>
              <tr>
                <th>Nombre completo</th>
                <td>
                  {userToShow.name} {userToShow.surname}
                </td>
              </tr>
              <tr>
                <th>Telefono</th>
                <td>
                  <a
                    href={`https://api.whatsapp.com/send?phone=${userToShow.phone}&text=Hola`}
                    target="_blank"
                  >
                    {userToShow.phone}
                  </a>
                </td>
              </tr>
            </tbody>
          </Table>
        </Modal.Body>
      </Modal>
      <Modal
        size="lg"
        show={showModalFamily}
        onHide={() => setShowModalFamily(false)}
      >
        <Modal.Header closeButton>
          <h1>Información de familia {familyToShow.surname}</h1>
        </Modal.Header>
        <Modal.Body>
          <VendorFamilyInfo familyId={familyToShow._id} />
        </Modal.Body>
      </Modal>
      <br />
      <div>
        <h2>Mostrar familias: </h2>
        <Form className="mb-3">
          <Form.Check
            inline
            label="No asignadas"
            name="unclaimed"
            type="checkbox"
            id={"unclaimed"}
            checked={showUnclaimed}
            onChange={(e) => {
              setShowUnclaimed(e.target.checked);
            }}
          />
          <Form.Check
            inline
            label="Asignadas a mi"
            name="own"
            type="checkbox"
            checked={showOwn}
            id={"own"}
            onChange={(e) => {
              setShowOwn(e.target.checked);
            }}
          />
          <Form.Check
            inline
            label="Asignadas a otro vendedor"
            name="claimed"
            type="checkbox"
            id={"claimed"}
            checked={showClaimed}
            onChange={(e) => {
              setShowClaimed(e.target.checked);
            }}
          />
        </Form>
      </div>
      <p>Nota: Horas Especializadas incluyen SAT, IB y Olimpiadas</p>
      <p>
        Nota 2: Si es que "Vencimiento de horas" no aparece, significa que no ha
        comprado horas recientemente.
      </p>
      <Table striped bordered hover>
        <thead style={{ verticalAlign: "middle" }}>
          <tr>
            <th>Familia</th>
            <th>Estudiantes</th>
            <th>Padres</th>
            {/* Especializadas  */}
            {/* Tutorias */}
            <th>Horas Especializadas </th>
            <th>Horas Especializadas Grupales</th>
            <th>Horas Tutorias</th>
            <th>Horas Tutorias Grupales</th>
            <th>Vencimiento de horas</th>
            {/* 
                        <th>Horas IB</th>
                        <th>Horas IB Grupales</th>
                        <th>Horas SAT</th>
                        <th>Horas SAT Grupales</th>
                        <th>Horas Tutorias</th>
                        <th>Horas Olimpiadas</th>
                        <th>Horas Olimpiadas Grupales</th> */}
            <th>Vendedor asignado</th>
          </tr>
        </thead>
        <tbody style={{ verticalAlign: "middle" }}>
          {filterFamilies(families).map((family) => {
            let specialized =
              family.hoursIB + family.hoursSAT + family.hoursOlympiad;
            let specializedGroup =
              family.hoursIBGroup +
              family.hoursSATGroup +
              family.hoursOlympiadGroup;
            return (
              <tr key={family._id}>
                <td>{<FamilyLink family={family} />}</td>
                <td>
                  {family.students.map((student) => (
                    <UserLink user={student} />
                  ))}
                </td>
                <td>
                  {family.parents.map((parent) => (
                    <UserLink user={parent} />
                  ))}
                </td>
                <td>
                  <h4>
                    <Badge bg={getVariantFromCount(specialized)} pill>
                      {specialized}
                    </Badge>
                  </h4>
                </td>
                <td>
                  <h4>
                    <Badge bg={getVariantFromCount(specializedGroup)} pill>
                      {specializedGroup}
                    </Badge>
                  </h4>
                </td>
                <td>
                  <h4>
                    <Badge bg={getVariantFromCount(family.hoursTutoring)} pill>
                      {family.hoursTutoring}
                    </Badge>
                  </h4>
                </td>
                <td>
                  <h4>
                    <Badge
                      bg={getVariantFromCount(family.hoursTutoringGroup)}
                      pill
                    >
                      {family.hoursTutoringGroup}
                    </Badge>
                  </h4>
                </td>
                <td>
                  <h4>{getTimeUntilHoursExpire(family)}</h4>
                </td>
                <td>
                  {!family.vendor ? (
                    <Button
                      variant="primary"
                      onClick={() => {
                        asignVendorToSelf(family._id);
                      }}
                    >
                      Asignar a mi
                    </Button>
                  ) : family.vendor._id !== user._id ? (
                    <strong>{family.vendor.name}</strong>
                  ) : (
                    <div>
                      <Button
                        variant="danger"
                        onClick={() => stopFollowingFamily(family._id)}
                      >
                        Dejar de seguir
                      </Button>
                    </div>
                  )}
                </td>
              </tr>
            );
          })}
        </tbody>
      </Table>
    </div>
  );
};

export default VendorFamilies;
