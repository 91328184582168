import React, {useState, useEffect} from "react"
import { useSelector } from "react-redux"
import { useNavigate } from "react-router"
import FamilyReferrals from "../../components/forms/AllUsers/FamilyReferrals"

const Referrals = () => {
    const {user} = useSelector((state) => state.auth)
    const [option,setOption] = useState(<></>)
    const navigate = useNavigate()
    useEffect(() => {
        if(user.role === "Student" || user.role === "Parent" || user.role === "Tutor"){
            setOption(<FamilyReferrals />)
        }else{
            navigate("/")
        }
    }, [])
    return (
        <>  
            <br/>
            <h1>FAMILIAS REFERIDAS</h1>
            <br/>
            {option}
        </>
    )
}

export default Referrals