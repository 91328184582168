import React, { useEffect, useState, useMemo } from "react";
import axios from "axios";
import { useSelector, useDispatch } from "react-redux";
import { setStudent } from "../../../store/student/student.slice";
import { setParent } from "../../../store/parent/parent.slice";
import countryList from "react-select-country-list";
import Select from "react-select";
import { Form, Row, Col, Button } from "react-bootstrap";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";

const NewStudentAndParent = () => {
  const dispatch = useDispatch();
  const { student } = useSelector((state) => state.student);
  const { parent } = useSelector((state) => state.parent);
  let newStudent = { ...student, role: "Student" };
  let newParent = { ...parent, role: "Parent" };
  const [disabled, setDisabled] = useState(false);
  const [isInvalid, setIsInvalid] = useState(false);
  const countries = useMemo(() => countryList().getData(), []);
  const handleSubmit = async (event) => {
    event.preventDefault();
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.stopPropagation();
      setIsInvalid(true);
    } else {
      // let answer = window.confirm("Estás seguro quieres actualizar esta info?");
      setIsInvalid(false);
      setDisabled(true);
      delete newStudent._id;
      delete newStudent.parentName;
      try {
        let data = { student: newStudent, parent: newParent };
        let family = await axios.post(
          `${process.env.REACT_APP_BACKENDURL}/family`,
          data
        );
        family = family.data;
        console.log(family);
        await axios.post(
          `${process.env.REACT_APP_BACKENDURL}/jobs/welcomeStudent`,
          { studentId: family.students[0] }
        );
        await axios.post(
          `${process.env.REACT_APP_BACKENDURL}/jobs/welcomeParent`,
          { parentId: family.parents[0] }
        );
      } catch (err) {
        console.log("Error creating student and parent: " + err);
      }
      window.location.reload(false);

      // axios.post(`${process.env.REACT_APP_BACKENDURL}/parent`, newParent).then(response =>{
      //     let savedParent = response.data
      //     newStudent["parents"] = [savedParent._id]
      //     axios.post(`${process.env.REACT_APP_BACKENDURL}/student`, newStudent).then(response2 => {
      //         let savedStudent = response2.data
      // axios.patch(`${process.env.REACT_APP_BACKENDURL}/parent`, {id:savedParent._id, update:{students:[savedStudent._id]}}).then(response3 =>{
      //         window.location.reload(false)
      //     }).catch(error => {
      //     console.log(error)
      // })
      //     }
      //     ).catch(error => console.log(error))
      // }).catch(error => console.log(error))
    }
  };
  return (
    <div>
      Registra un estudiante, padre yendo a{" "}
      <a
        href={`${process.env.REACT_APP_FRONTEND_URL}/register`}
        target="_blank"
      >
        {`${process.env.REACT_APP_FRONTEND_URL}/register`}
      </a>
      <br />
      <div style={{ textAlign: "center", margin: 10, color: "red" }}>
        Nota: Para que pertenezcan a la misma familia, primero uno de ellos debe
        de registrar su cuenta y luego "invitar" (en Ajustes > Familia) al otro
        para que se registren juntos.
      </div>
    </div>
  );
  return (
    <Form noValidate validated={isInvalid} onSubmit={handleSubmit}>
      <Row className="justify-content-md-center">
        <Form.Group as={Col} lg="2" controlId="name">
          <Form.Label>
            <b>Nombre del Estudiante</b>
          </Form.Label>
          <br />
          <br />
          <Form.Control
            required
            type="text"
            placeholder="Nombre"
            onChange={(e) => {
              newStudent["name"] = e.target.value;
              dispatch(setStudent(newStudent));
            }}
          />
          <Form.Control.Feedback>Todo bien!</Form.Control.Feedback>
        </Form.Group>

        <Form.Group as={Col} lg="2" controlId="surname">
          <Form.Label>
            <b>Apellido(s) del Estudiante</b>
          </Form.Label>
          <br />
          <br />
          <Form.Control
            required
            type="text"
            placeholder="Apellido(s)"
            onChange={(e) => {
              newStudent["surname"] = e.target.value;
              dispatch(setStudent(newStudent));
            }}
          />
          <Form.Control.Feedback>Todo bien!</Form.Control.Feedback>
        </Form.Group>
      </Row>
      <br />
      <br />
      <Row className="justify-content-md-center">
        <Form.Group as={Col} lg="2" controlId="name">
          <Form.Label>
            <b>Email del estudiante</b>
          </Form.Label>
          <br />
          <Form.Control
            required
            type="email"
            onChange={(e) => {
              newStudent["email"] = e.target.value;
              dispatch(setStudent(newStudent));
            }}
            placeholder="Gmail"
          />
          <Form.Control.Feedback>Todo bien!</Form.Control.Feedback>
        </Form.Group>
        <Form.Group as={Col} lg="2" controlId="surname">
          <Form.Label>
            <b>Whatsapp del estudiante</b>
          </Form.Label>

          <PhoneInput
            inputProps={{ required: true }}
            onChange={(phone) => {
              newStudent["phone"] = "+" + phone;
              dispatch(setStudent(newStudent));
            }}
            enableSearch={true}
          />
          <Form.Control.Feedback>Todo bien!</Form.Control.Feedback>
        </Form.Group>
      </Row>
      <br />
      <br />
      <Row className="justify-content-md-center">
        <Form.Group as={Col} lg="2" controlId="school">
          <Form.Label>
            <b>Colegio</b>
          </Form.Label>
          <br />
          <br />
          <Form.Control
            required
            type="text"
            placeholder="Colegio"
            onChange={(e) => {
              newStudent["school"] = e.target.value;
              dispatch(setStudent(newStudent));
            }}
          />
          <Form.Control.Feedback>Todo bien!</Form.Control.Feedback>
        </Form.Group>

        <Form.Group as={Col} lg="2" controlId="country">
          <Form.Label>
            <b>País del colegio</b>
          </Form.Label>
          <br />
          <br />
          <Form.Control
            as={Select}
            required
            options={countries}
            onChange={(opt) => {
              newStudent["country"] = opt.value;
              dispatch(setStudent(newStudent));
            }}
            placeholder="País"
          />
          <Form.Control.Feedback>Todo bien!</Form.Control.Feedback>
        </Form.Group>
      </Row>
      <br />
      <br />
      <Row className="justify-content-md-center">
        <Form.Group as={Col} lg="2" controlId="name">
          <Form.Label>
            <b>Nombre del Padre</b>
          </Form.Label>
          <br />
          <br />
          <Form.Control
            required
            type="text"
            placeholder="Nombre"
            onChange={(e) => {
              newParent["name"] = e.target.value;
              dispatch(setParent(newParent));
            }}
          />
          <Form.Control.Feedback>Todo bien!</Form.Control.Feedback>
        </Form.Group>

        <Form.Group as={Col} lg="2" controlId="surname">
          <Form.Label>
            <b>Apellido(s) del Padre</b>
          </Form.Label>
          <br />
          <br />
          <Form.Control
            required
            type="text"
            placeholder="Apellido(s)"
            onChange={(e) => {
              newParent["surname"] = e.target.value;
              dispatch(setParent(newParent));
            }}
          />
          <Form.Control.Feedback>Todo bien!</Form.Control.Feedback>
        </Form.Group>
      </Row>
      <br />
      <br />
      <Row className="justify-content-md-center">
        <Form.Group as={Col} lg="2" controlId="name">
          <Form.Label>
            <b>Email del Padre</b>
          </Form.Label>
          <br />
          <Form.Control
            required
            type="email"
            onChange={(e) => {
              newParent["email"] = e.target.value;
              dispatch(setParent(newParent));
            }}
            placeholder="Email"
          />
          <Form.Control.Feedback>Todo bien!</Form.Control.Feedback>
        </Form.Group>
        <Form.Group as={Col} lg="2" controlId="surname">
          <Form.Label>
            <b>Whatsapp del padre</b>
          </Form.Label>

          <PhoneInput
            inputProps={{ required: true }}
            onChange={(phone) => {
              newParent["phone"] = "+" + phone;
              dispatch(setParent(newParent));
            }}
            enableSearch={true}
          />
          <Form.Control.Feedback>Todo bien!</Form.Control.Feedback>
        </Form.Group>
        <br />
      </Row>
      <br />
      <br />
      <Button variant="success" disabled={disabled} type="submit">
        Crear Estudiante y Padre
      </Button>
    </Form>
  );
};

export default NewStudentAndParent;
