import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import AdminViewForm from "../../components/forms/AdminView/AdminViewForm";
import StudentViewForm from "../../components/forms/StudentView/StudentViewForm";
import ParentViewForm from "../../components/forms/ParentView/ParentViewForm";
import TutorViewForm from "../../components/forms/TutorView/TutorViewForm";
import VendorViewForm from "../../components/forms/VendorView/VendorViewForm";
// import FirstClassForm from '../FirstClass/FirstClassForm';
const XplainForm = () => {
  const navigate = useNavigate();
  const { user } = useSelector((state) => state.auth);
  useEffect(() => {
    if (
      user &&
      Object.keys(user).length === 0 &&
      Object.getPrototypeOf(user) === Object.prototype
    ) {
      navigate("/login");
    }
  }, []);
  let view;
  if (user.role === "Admin") {
    view = <AdminViewForm />;
  } else if (user.role === "Student") {
    view = <StudentViewForm />;
  } else if (user.role === "Parent") {
    view = <ParentViewForm />;
  } else if (user.role === "Tutor") {
    view = <TutorViewForm />;
  } else if (user.role === "Vendor") {
    view = <VendorViewForm />;
  }
  return <div>{view}</div>;
};

export default XplainForm;
