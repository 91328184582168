import React, {useEffect} from 'react';
import { useSelector,useDispatch } from 'react-redux';
import {getClasses} from "../../../store/class/class.actions"
import {getSubjects} from "../../../store/subject/subject.actions"
import {getTutors} from "../../../store/tutor/tutor.actions"
import {Table, Row, Col, Form, Button} from 'react-bootstrap';
import Select from "react-select"
import { listFormat } from '../../../name_utils';

let today = new Date()
let lastMonth = new Date(today)
lastMonth.setDate(today.getDate() - 30)
// const listFormat = (array) => {
//     let newArray = []
//     for (const elem of array){
//         let newElem = {...elem}
//         if ('surname' in newElem){
//             newElem['label'] = elem['name'] + " " + elem['surname']
//         }else{
//             newElem['label'] = elem['name']
//         }
//         newElem['value'] = elem['_id']
//         newArray.push(newElem)
//     }
//     return newArray
// }
const StudentPastClasses = () => {
    const dispatch = useDispatch() 
    const {student} = useSelector((state) => state.student)
    const {classes} = useSelector((state) => state.class)
    const {subjects} = useSelector((state) => state.subject)
    const {tutors} = useSelector((state) => state.tutor)
    useEffect(() => {
        dispatch(getClasses({filter:{studentId:student._id, end:{"$lte":today.toISOString()},  start:{"$gte": lastMonth} }, selection:{}}))
        dispatch(getSubjects({filter:{_id:{"$in": student.subjects}},selection:{}}))
        dispatch(getTutors({filter:{}, selection:{}}))
    }, [])
    let newClasses = []
    for (const currentClass of classes){
        let newClass = {...currentClass}
        if(newClass.studentIds.includes(student._id)){
            for (const tutor of tutors){
                if (tutor._id == newClass.tutorId){
                    newClass["tutorName"] = tutor['name'].split(" ")[0] + " " + tutor['surname'].split(" ")[0]
                }
            }
            for (const subject of subjects){
                if (subject._id == newClass.subjectId){
                    newClass["subjectName"] = subject['name']
                }
            }
            newClasses.push(newClass)
        }
    }
    newClasses = newClasses.slice().sort((a,b) => new Date(b.start) -new Date(a.start))
    let newSubjects = [{"name":"Todas","_id":"everything"},...subjects]
    return(
        <>
            <h3>Clases Pasadas (Últimos 30 días)</h3>
            <br/>
            <Row className="justify-content-md-center"> 
                <Form.Group as={Col} lg="4" controlId="tutor">
                <Form.Control
                    as={Select}
                    options={listFormat(newSubjects)}
                    placeholder="Filtra por materia"
                    required
                    onChange={(opt) => {
                        if(opt.value === "everything"){
                            dispatch(getClasses({filter:{studentId:student._id, end:{"$lt":today.toISOString()}}, selection:{}}))
                        }else{
                            dispatch(getClasses({filter:{studentId:student._id, subjectId: opt.value, end:{"$lt":today.toISOString()}}, selection:{}}))
                        }
                    }}
                />
                </Form.Group>
            </Row>
            <br/>
            <Table striped bordered hover style={{ width: '85%','margin-left':'auto','margin-right':'auto' }}>
                <thead>
                <tr>
                <th>Fecha</th>
                <th>Comienzo</th>
                <th>Final</th>
                <th>Xplainer</th>
                <th>Tutor</th>
                <th>Materia</th>
                <th>Rating</th>
                <th>Feedback</th>
                <th>Reporte</th>
                </tr>
                </thead>
                <tbody>
                {newClasses.map((currentClass,id) => {
                    let start = new Date(currentClass.start)
                    let month = start.getMonth().toString().padStart(2, '0')
                    month = Number(month) + 1
                    let day = `${start.getDate().toString().padStart(2, '0')}/${month.toString().padStart(2, '0')}`
                    let end = new Date(currentClass.end)
                    start = `${start.getHours()}:${start.getMinutes().toString().padStart(2, '0')}`
                    end = `${end.getHours()}:${end.getMinutes().toString().padStart(2, '0')}`
                    let feedback = ""
                    let rating = ""
                    if (currentClass.individualStudentFeedback.length === 0){
                        feedback = "Xplainer no ha dado feedback"
                        rating = "Xplainer no ha dado feedback"
                    }else{
                        feedback = <Button variant="secondary"><a href={`${window.location.origin}/feedback?id=${currentClass._id}`} target="_blank" style={{"color":"rgb(255,255,255)"}}>Feedback</a></Button>
                        rating = `${currentClass.rating}/10`
                    }
                    let report = ""
                    if (currentClass.tutorFeedback == ""){
                        report = "Tutor no ha emitido reporte"
                    }else{
                        report = <Button style={{backgroundColor:"rgba(10, 181, 145, 1)", borderColor:"rgba(10, 181, 145, 1)"}}><a href={`https://xplain-la.web.app/report?id=${currentClass._id}`} target="_blank" style={{color:"rgb(255,255,255)"}}>Reporte</a></Button>
                    }
                    return(
                        <tr>
                            <td>{day}</td>
                            <td>{start}</td>
                            <td>{end}</td>
                            <td>{student['name'].split(" ")[0] + " " + student['surname'].split(" ")[0]}</td>
                            <td>{currentClass.tutorName}</td>
                            <td>{currentClass.subjectName}</td>
                            <td>{rating}</td>
                            <td>{feedback}</td>
                            <td>{report}</td>
                        </tr>
                    )
                })}
                </tbody>
            </Table>
        </>
    )
}

export default StudentPastClasses
