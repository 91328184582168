import React, {useEffect} from 'react';
import {getClasses} from "../../../store/class/class.actions"
import {getSubjects} from "../../../store/subject/subject.actions"
import { setStudent } from '../../../store/student/student.slice';
import {Col,Form,Row, Table, Button} from 'react-bootstrap';
import { useSelector,useDispatch } from 'react-redux';
import {setSubject} from "../../../store/subject/subject.slice.js"
import Select from 'react-select';
import { getStudents } from '../../../store/student/student.actions';
import { getTutors } from '../../../store/tutor/tutor.actions';
import { listFormat } from '../../../name_utils';
let today = new Date()

function getFirstDayOfMonth() {
    return new Date(today.getFullYear(), today.getMonth(), 1);
}

function getLastDayOfMonth() {
    return new Date(today.getFullYear(), today.getMonth() + 1, 0);
}

// const listFormat = (array) => {
//     let newArray = []
//     for (const elem of array){
//         let newElem = {...elem}
//         if ('surname' in newElem){
//             newElem['label'] = elem['name'] + " " + elem['surname']
//         }else{
//             newElem['label'] = elem['name']
//         }
//         newElem['value'] = elem['_id']
//         newArray.push(newElem)
//     }
//     return newArray
// }
const StudentPastReports = () => {
    const dispatch = useDispatch() 
    const {user} = useSelector((state) => state.auth)
    const {subject} = useSelector((state) => state.subject)
    const {students,student} = useSelector((state) => state.student)
    const {tutors} = useSelector((state) => state.tutor)
    const {classes} = useSelector((state) => state.class)
    const {subjects} = useSelector((state) => state.subject)
    useEffect(() => {
        dispatch(getStudents({filter:{_id:{"$in":user.students}}, selection:{}}))
        dispatch(getClasses({filter:{studentIds:{"$in":user.students}, tutorId:{$ne:user._id}, subjectId:{"$in":user.subjects}, start:{"$lt":today.toISOString()}}, selection:{}}))
        dispatch(getSubjects({filter:{_id:{"$in":user.subjects}}, selection:{}}))
        dispatch(getTutors({filter:{}, selection:{}}))
    }, [])
    let newClasses = []
    for (const currentClass of classes){
        let newClass = {...currentClass}
        for (const subject of subjects){
            if (subject._id == newClass.subjectId){
                newClass["subjectName"] = subject['name']
            }
        }
        newClass["studentNames"] = []
        for (const thisStudent of students){
            if (newClass.studentIds.includes(thisStudent._id)){
                newClass["studentNames"] += thisStudent['name'].split(" ")[0] + " " + thisStudent['surname'].split(" ")[0] + ","
            }
        }
        newClass["studentNames"] = newClass["studentNames"].slice(0, -1); 
        for (const tutor of tutors){
            if (tutor._id == currentClass.tutorId){
                newClass["tutorName"] += tutor['name'].split(" ")[0] + " " + tutor['surname'].split(" ")[0]
            }
        }
        newClasses.push(newClass)
    }
    newClasses = newClasses.slice().sort((a,b) => new Date(b.start) -new Date(a.start))

    return(
        <>
            
            <p>Puedes usar esto para aprender del avance previo del Xplainer con otro tutor</p>
            <br/>
            <Row className="justify-content-md-center">
                
                <Col style={{marginRight:'-15%'}}> 
                <Row className="justify-content-md-center">
                    <Form.Group as={Col} lg="4" controlId="student">
                    <Form.Control
                        as={Select}
                        options={[{label:"Todos", value:"everything", name:"everything"},...listFormat(students)]}
                        required
                        placeholder="Xplainer"
                        onChange={opt => {
                            dispatch(setStudent(opt))
                            dispatch(setSubject({}))
                            if(opt.value == "everything"){
                                dispatch(getClasses({filter:{ end:{"$lt":today.toISOString()}, subjectId:{"$in":user.subjects}, tutorId:{$ne:user._id}, studentIds: {"$in": user.students}}, selection:{}}))
                            }else{
                                dispatch(getClasses({filter:{studentIds:opt.value, end:{"$lt":today.toISOString()}, subjectId:{"$in":user.subjects}, tutorId:{$ne:user._id}}, selection:{}}))
                            }
                        }}
                    />
                    </Form.Group>
                </Row>

                </Col>
                
                <Col style={{marginLeft:'-15%'}}>
                    <Row className="justify-content-md-center">
                    <Form.Group as={Col} lg="4" controlId="student">
                    <Form.Control
                        as={Select}
                        options={[{label:"Todos", value:"everything", name:"everything"},...listFormat(subjects)]}
                        required
                        placeholder="Materia"
                        onChange={opt => {
                            dispatch(setSubject(opt))
                            if(student.name == "everything"){
                                if(opt.value == "everything"){
                                    dispatch(getClasses({filter:{studentIds:{$in: students.map(value => value._id)}, subjectId:{$in:user.subjects}, tutorId:{$ne:user._id} ,end:{"$lt":today.toISOString()}}, selection:{}}))
                                }else{
                                    dispatch(getClasses({filter:{studentIds:{$in: students.map(value => value._id)}, tutorId:{$ne:user._id}, subjectId: opt.value,end:{"$lt":today.toISOString()}}, selection:{}}))
                                }
                            }else{
                                if(opt.value == "everything"){
                                    dispatch(getClasses({filter:{studentIds:student._id, subjectId: {$in:user.subjects}, tutorId:{$ne:user._id}, end:{"$lt":today.toISOString()}}, selection:{}}))
                                }else{
                                    dispatch(getClasses({filter:{studentIds:student._id, tutorId:{$ne:user._id}, subjectId: opt.value,end:{"$lt":today.toISOString()}}, selection:{}}))
                                }
                            }
                        }}
                    />
                    </Form.Group>
                    </Row>
                    
                </Col>

            </Row>
            <br />
            
                <Table striped bordered hover style={{ width: '80%','marginLeft':'auto','marginRight':'auto' }}>
                    <thead>
                    <tr>
                    <th>Fecha</th>
                    <th>Comienzo</th>
                    <th>Final</th>
                    <th>Xplainer(s)</th>
                    <th>Materia</th>
                    <th>Rating</th>
                    <th>Feedback</th>
                    <th>Reporte</th>
                    </tr>
                    </thead>
                    <tbody>
                    {newClasses.map((currentClass,id) => {
                        let start = new Date(currentClass.start)
                        let month = start.getMonth().toString().padStart(2, '0')
                        month = Number(month) + 1
                        let day = `${start.getDate().toString().padStart(2, '0')}/${month.toString().padStart(2, '0')}`
                        let end = new Date(currentClass.end)
                        start = `${start.getHours()}:${start.getMinutes().toString().padStart(2, '0')}`
                        end = `${end.getHours()}:${end.getMinutes().toString().padStart(2, '0')}`
                        let report;
                        if(currentClass.tutorFeedback == ""){
                            report = "N/A"
                        }else{
                            report = <Button style={{backgroundColor:"rgba(10, 181, 145, 1)", borderColor:"rgba(10, 181, 145, 1)"}}><a href={`${window.location.origin}/report?id=${currentClass._id}`}  style={{color:"rgb(255,255,255)"}} target="_blank">Ver el reporte</a></Button>
                        }
                        let feedback = ""
                        let rating = ""
                        if (currentClass.individualStudentFeedback.length === 0){
                            feedback = "N/A"
                            rating = "N/A"
                        }else{
                            feedback = <Button variant="secondary" ><a href={`${window.location.origin}/feedback?id=${currentClass._id}`} target="_blank" style={{color:"rgb(255,255,255)"}}>Leer Feedback</a></Button>
                            rating = `${currentClass.rating}/10`
                        }
                        return(
                            <tr>
                                <td>{day}</td>
                                <td>{start}</td>
                                <td>{end}</td>
                                <td>{currentClass.studentNames}</td>
                                <td>{currentClass.subjectName}</td>
                                <td>{rating}</td>
                                <td>{feedback}</td>
                                <td>{report}</td>
                            </tr>
                        )
                    })}
                    </tbody>
                </Table>
        </>
    )

}

export default StudentPastReports