import React, {useState, useEffect} from 'react';
import axios from 'axios';
import {Form, Row, Col, Button} from "react-bootstrap"
import { Rating } from '@mui/material';
import { getStudents } from '../../store/student/student.actions';
import { useDispatch, useSelector } from 'react-redux';
import Select from "react-select"
import { setStudent } from '../../store/student/student.slice';
import { encodeFeedback } from '../../format_feedback';
import { listFormat } from '../../name_utils';
// const listFormat = (array) => {
//     let newArray = []
//     for (const elem of array){
//         let newElem = {...elem}
//         if ('surname' in newElem){
//             newElem['label'] = elem['name'].split(" ")[0] + " " + elem['surname'].split(" ")[0]
//         }else{
//             newElem['label'] = elem['name']
//         }
//         newElem['value'] = elem['_id']
//         newArray.push(newElem)
//     }
//     return newArray
// }
const StudentFeedbackForm = () => {
    const [isInvalid, setIsInvalid] = useState(false);
    const [good,setGood] = useState("");
    const dispatch = useDispatch()
    const [improvement,setImprovement] = useState("");
    const [tutorImprovement,setTutorImprovement] = useState("");
    const [rating, setRating] = useState(10)
    const [id, setId] = useState("")
    const {students, student} = useSelector((state) => state.student)
    const [submittedStudents, setSubmittedStudents] = useState([]);
    useEffect(() => {
        let params = new URLSearchParams(window.location.search)
        let _id = params.get("id")
        if (typeof _id === "string" || _id instanceof String ){
            setId(_id)
            axios.get(`${process.env.REACT_APP_BACKENDURL}/class`, {
                params: {id:_id}
            }).then((response) => {
                dispatch(getStudents({filter:{_id: {"$in":response.data.studentIds}}, selection:{}}))
                let {studentIds, individualStudentFeedback} = response.data
                //Everyone submitted!
                if (studentIds.length === individualStudentFeedback.length){
                    setSubmitted(true);
                }
                setSubmittedStudents(individualStudentFeedback.map(feedback => feedback.sender));
            })
        }
    }, [])
    const [disabled,setDisabled] = useState(false)
    const [submitted, setSubmitted] = useState(false)
    const handleSubmit = (event) => {
        event.preventDefault()
        if (submitted){
            return;
        }
        if (students.length > 1 && (!student || !student._id)){
            alert("Porfavor seleccione un alumno")
            return;
        }
        let sender = student._id || students[0]._id;

        if (submittedStudents.indexOf(sender) !== -1){
            alert("Este alumno ya mando su feedback");
            return;
        }
        const form = event.currentTarget;
        if (form.checkValidity() === false) {
            event.stopPropagation();
            setIsInvalid(true)
        }else{
            setIsInvalid(false);
            setDisabled(true)
            let newStudentFeedback = `-Al estudiante le gustó:\n\n${encodeFeedback(good)}\n\n-El estudiante piensa que puede mejorar en:\n\n${encodeFeedback(improvement)}\n\n-Para mejorar la clase se podría:\n\n${encodeFeedback(tutorImprovement)}`
            let data = {id, rating, newStudentFeedback, sender: sender} //If it has not been chosen, set to the only student
            axios.patch(`${process.env.REACT_APP_BACKENDURL}/class/studentfeedback`,data).then(response => {
                setSubmitted(true)
                console.log(response)
            }).catch(error => console.log(error))
        }
    }
    return(
        <>
            {!submitted 
                ?
                <Form noValidate validated={isInvalid} onSubmit={handleSubmit}>
                    
                    <Row className="justify-content-md-center">
                        <Form.Group as={Col} lg="5" controlId="id">
                            <br/>
                        <Form.Label>Por favor, otorga feedback para comunicar al tutor como mejorar las clases!</Form.Label>
                        </Form.Group>
                    </Row>
                    <br />
                    <br />
                    {
                        (students.length > 1)
                            ?
                            <Row className="justify-content-md-center"> 
                                <Form.Group as={Col} lg="4" controlId="students">
                                <Form.Control
                                    as={Select}
                                    options={listFormat(students)}
                                    required
                                    onChange={opt => dispatch(setStudent(opt))}
                                    placeholder="Estudiante"
                                />
                                </Form.Group>
                            </Row>
                            :
                            <></>
                    }

                    {/* <Row className="justify-content-md-center">
                        <Form.Group as={Col} lg="4" controlId="id">
                        <Form.Label><b>Id de la clase (En la descripcion de la clase en Google Calendar)</b></Form.Label>
                        <br/>
                        <br/>
                        <Form.Control
                            required
                            type="text"
                            maxlength="24"
                            value={id}
                            placeholder="62d9#############"
                            onChange={e => setId(e.target.value)}
                        />
                        <Form.Control.Feedback>Todo bien!</Form.Control.Feedback>
                        </Form.Group>
                    </Row> */}
                    <br />
                    <br />
                    <Row className="justify-content-md-center">
                        <Form.Group as={Col} lg="4" controlId="feedback">
                        <Form.Label><b>¿Qué te gustó de la clase y del tutor?</b></Form.Label>
                        <br/>
                        <br/>
                        <Form.Control
                            as="textarea"
                            onChange={e => setGood(e.target.value)}
                            required
                        />
                        <Form.Control.Feedback type="invalid">
                            Respuestas es necesaria
                        </Form.Control.Feedback>
                        </Form.Group>
                    </Row>
                    <br/>
                    <br/>
                    <Row className="justify-content-md-center">
                        <Form.Group as={Col} lg="4" controlId="feedback">
                        <Form.Label><b>¿En qué crees que tú puedes mejorar?</b></Form.Label>
                        <br/>
                        <br/>
                        <Form.Control
                            as="textarea"
                            onChange={e => setImprovement(e.target.value)}
                            required
                        />
                        <Form.Control.Feedback type="invalid">
                            Respuestas es necesaria
                        </Form.Control.Feedback>
                        </Form.Group>
                    </Row>
                    <br/>
                    <br/>
                    <Row className="justify-content-md-center">
                        <Form.Group as={Col} lg="4" controlId="feedback">
                        <Form.Label><b>¿Cómo podría mejorar la clase y tu tutor?</b></Form.Label>
                        <br/>
                        <br/>
                        <Form.Control
                            as="textarea"
                            onChange={e => setTutorImprovement(e.target.value)}
                            required
                        />
                        <Form.Control.Feedback type="invalid">
                            Respuestas es necesaria
                        </Form.Control.Feedback>
                        </Form.Group>
                    </Row>
                    <br/>
                    <br/>
                    <Row className="justify-content-md-center">
                        <Form.Group as={Col} lg="2" controlId="rating">
                        <Form.Label><b>¿Cómo puntuarías la clase?</b></Form.Label>
                        <br/>
                        <br/>
                        <Form.Control
                            as={Rating}
                            required
                            defaultValue={10} 
                            max={10}
                            onChange={e => setRating(Number(e.target.value))}
                        />
                        <Form.Control.Feedback>Todo bien!</Form.Control.Feedback>
                        </Form.Group>
                    </Row>
                    <br/>
                    <br/>
                    {!(id ==="") && id.length > 23
                        ?
                        <Button variant="success" type="submit" disabled={disabled}>Envíalo!</Button>
                        :
                        <></>
                    }
                </Form>
                :
                <p>Muchas gracias por proveer tu valioso feedback! Gracias a ti podremos mejorar las clases en Xplain.</p>

            }
        </>
       
    )

}

export default StudentFeedbackForm