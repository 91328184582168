import React, { useState } from "react"
import Select from "react-select"
import 'react-phone-input-2/lib/style.css'
import {Form, Row, Col} from "react-bootstrap"
import SignupNewParent from "../../components/forms/SignupNewUser/SignupNewParent";
import SignupNewStudent from "../../components/forms/SignupNewUser/SignupNewStudent"
import SignupNewTutor from "../../components/forms/SignupNewUser/SignupNewTutor"
import SignupNewStudentAndParent from "../../components/forms/SignupNewUser/SignupNewStudentAndParent"

const Signup = () => {
    let options = [
        {label:"Estudiante",value:<SignupNewStudentAndParent />},
        {label: "Tutor",value: <SignupNewTutor/>},
                    // {label:"Padre",value: <SignupNewParent/>},
                    // {label:"Estudiante", value: <SignupNewStudent />}
                ]
    const [option,setOption] = useState(<></>)
    return(
        <>
        <Row className="justify-content-md-center"> 
            <Form.Group as={Col} lg="4" controlId="type">
            <Form.Label>Escoge tipo de usuario</Form.Label>
            <br />
            <br />
            <Form.Control
                as={Select}
                options={options}
                onChange={opt => setOption(opt.value)}
            />
            </Form.Group>
        </Row>
        <br />
        <br />
        {option}
        </>
    )
}

export default Signup